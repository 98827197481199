import {
  Backdrop as MaterialBackdrop,
  Button,
  CircularProgress as MaterialCircularProgress,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 1)};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: ${({ theme }) => theme.spacing(4.5)}px;
`;

export const Title = styled(Typography).attrs({
  variant: 'h6',
  align: 'center',
})`
  color: black;
  font-weight: bold;
  margin: ${({ theme }) => theme.spacing(2, 0, 2, 0)};
`;

export const SubTitle = styled(Typography).attrs({
  variant: 'subtitle1',
  align: 'left',
})`
  color: black;
  font-weight: bold;
  margin: ${({ theme }) => theme.spacing(2, 0, 2, 1)};
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: black;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const CircleContainer = styled.div`
  display: flex;
  border-radius: 10%;
  width: ${({ theme }) => theme.spacing(8.5)}px;
  height: ${({ theme }) => theme.spacing(3.5)}px;
  padding: ${({ theme }) => theme.spacing(0)}px;
  border: 1px solid black;
  color: black;

  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const DateInside = styled.div`
  margin-left: auto;
  margin-right: auto;
`;

export const DateOutside = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;

export const FinishShiftButton = styled(Button).attrs(({ variant }) => ({
  variant: variant || 'outlined',
}))`
  color: ${({ theme }) => theme.palette.info.dark};
  border-color: ${({ theme }) => theme.palette.info.dark};
  font-size: ${({ theme }) => theme.typography.h6.fontSize};
  font-weight: 400;
  align-self: center;
`;
