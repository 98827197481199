import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
/**
 * Error handler for cuidador de confianca API
 */

// Returns a default message, give simple response's statusCode
const getStatusCodeMessage = (error: AxiosError<APIError>) => {
  const httpStatusCode = error?.response?.status;

  switch (httpStatusCode) {
    case 400:
      return 'Um erro ocorreu! Se o problema persistir, contacte o suporte.';
    case 401:
      return 'Erro com as credenciais! Faça login novamente.';
    case 403:
      return 'Você não está autorizado a acessar essas informações! Se acredita que isto é um erro, por favor, contacte o suporte.';
    case 404:
      return 'As informações buscadas não foram encontradas! Se o problema persistir, contacte o suporte.';
    case 500:
      return 'Um problema na comunicação com o servidor ocorreu! Se o problema persistir, contacte o suporte.';
    case 503:
      return 'Esse serviço encontra-se temporariamente indisponível para manutenção! Se o problema persistir, contacte o suporte.';
    default:
      return 'Um erro ocorreu! Se o problema persistir, contacte o suporte.';
  }
};

// Extracts custom backend error displayMessage if provided
const getDisplayMessage = (error: AxiosError<APIError>) => {
  const customErrorData = error?.response?.data.displayMessage as string;
  const messages = [
    'Você não está com um plantão em andamento. Atualize a página',
    'Evento não encontrado.',
  ];

  if (messages.includes(customErrorData)) {
    return 'Você não está com um plantão em andamento. Atualize a página';
  }

  return customErrorData;
};

const getConnectivityErrorMessage = () => {
  return 'Erro na conexão! Tente novamente.';
};

/*
Shows error message acoording to the folowing priority
1- Network or unknown error
2- Backend's custom code
3- Default message, given statusCode
*/
export const resolveErrorMessage = (error: AxiosError<APIError>) => {
  const resolveMessage = (error: AxiosError<APIError>) => {
    // nullish response means connectivity error
    if (!error.response) {
      const connectivityMessage = getConnectivityErrorMessage();
      return connectivityMessage;
    }

    // nullish response means connectivity error
    const displayMessage = getDisplayMessage(error);
    if (displayMessage) {
      return displayMessage;
    }

    const defaultStatusMessage = getStatusCodeMessage(error);
    if (defaultStatusMessage) {
      return defaultStatusMessage;
    }
  };
  // If backend sent a `displayMessage`, we use it
  // Else, show default message according to statusCode
  return resolveMessage(error);
};
