import styled from 'styled-components';

export const OutterContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  max-width: 100%;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  color: ${({ theme }) => theme.palette.primary.dark};
`;
