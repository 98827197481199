import {
  EventSubCategoryModel,
  ProfessionalReportModel,
} from '@cuidador/database';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CreateProfessionalReportForm from '../../../components/CreateProfessionalReportForm';
import Header from '../../../components/Headers/Header';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import { StyledFieldset } from '../../../components/StyledFieldset';
import StyledSimpleDialog from '../../../components/StyledSimpleDialog';
import ViewProfessionalReportForm, {
  FormValues,
} from '../../../components/ViewProfessionalReportForm';
import useCanAccess from '../../../hooks/useCanAccess';
import useProfessionalReport from '../../../hooks/useProfessionalReport/indexs';
import useSubCategory from '../../../hooks/useSubCategory';
import { Container } from './styles';
import { ProfessionalReportModelToFormValues } from './utils';

const ProfessionalReportItem: React.FC = () => {
  const history = useHistory();
  const {
    getAllComplications,
    loading: loadingComplications,
  } = useSubCategory();
  const { getById, deleteById, loading, patch } = useProfessionalReport();
  const params = useParams<{ id: string }>();
  const reportId = parseInt(params.id);
  const [report, setReport] = useState<ProfessionalReportModel>();
  const [reportFormData, setReporFormData] = useState<FormValues>();
  const [dialogStatus, setDialogStatus] = useState(false);
  const [subCategories, setSubCategories] = useState<EventSubCategoryModel[]>(
    []
  );
  const { isAllowedToUpdate, isAllowedToDelete } = useCanAccess(
    'report/professional'
  );

  useEffect(() => {
    handleProfessionalReport();
  }, []);

  const handleProfessionalReport = async () => {
    if (reportId) {
      getById(reportId)
        .then((data) => {
          setReport(data);
          setReporFormData(ProfessionalReportModelToFormValues(data));
          handleSubcategories(data);
        })
        .catch(() =>
          toast.error('Erro ao carregar avaliação multiprofissional.')
        );
    }
  };

  const handleSubcategories = (report: ProfessionalReportModel) => {
    if (report.eventSubCategory && !report.isDraft) {
      setSubCategories([report.eventSubCategory]);
    } else {
      loadComplications();
    }
  };

  const loadComplications = async () => {
    const subCategories = (await getAllComplications().catch(() =>
      toast.error('Erro ao carregar lista de intercorrências')
    )) as EventSubCategoryModel[];
    const noneOption: EventSubCategoryModel = {
      id: 0,
      name: 'Nenhuma',
    };
    const subCategoriesWithNone = [noneOption, ...subCategories];
    setSubCategories(subCategoriesWithNone);
  };

  const handleRemoveProfessionalReport = async (id?: number) => {
    setDialogStatus(false);
    if (id) {
      await deleteById(id)
        .then((rowsDeleted) => {
          if (rowsDeleted > 0)
            toast.success('Avaliação multiprofissional removida.');
          else {
            throw new Error();
          }
        })
        .catch(() => {
          toast.error('Avaliação já publicada, não é possível excluir.');
        });
      history.goBack();
    }
  };

  const handleSubmit = async (formValues: ProfessionalReportModel) => {
    patch(reportId, formValues)
      .then(() => {
        toast.success('Avaliação multiprofissional atualizada!');
        history.goBack();
      })
      .catch(() => toast.error('Erro ao editar avaliação multiprofissional.'));
  };

  return (
    <>
      <Header
        title={`Avaliação Multiprofissional ${report?.patient?.name || ''}`}
        leftButtonType="goBack"
        rightButtonType={
          isAllowedToDelete && reportFormData?.isDraft ? 'remove' : undefined
        }
        rightIconClick={() => setDialogStatus(true)}
      />
      <Container>
        {loading || loadingComplications || !reportFormData ? (
          <LoadingBackdrop loading />
        ) : reportFormData.isDraft ? (
          <StyledFieldset disabled={!isAllowedToUpdate}>
            <CreateProfessionalReportForm
              subCategories={subCategories}
              formValues={reportFormData}
              cancel={history.goBack}
              submit={handleSubmit}
              patientId={Number(report?.patient?.id)}
              readOnly={!isAllowedToUpdate}
            />
          </StyledFieldset>
        ) : (
          <ViewProfessionalReportForm
            subCategories={subCategories}
            formValues={reportFormData}
          />
        )}
        <StyledSimpleDialog
          open={dialogStatus}
          handleNo={() => setDialogStatus(false)}
          handleYes={() => handleRemoveProfessionalReport(report?.id)}
          title="Excluir rascunho de avaliação multiprofissional?"
        />
      </Container>
    </>
  );
};

export default ProfessionalReportItem;
