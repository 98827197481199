import * as Yup from 'yup';
import { validatePasswordStrength } from '../../../utils/inputs';

export interface FormValues {
  currentPassword: string;
  newPassword: string;
  retypePassword: string;
}

export const formInitialValue = {
  currentPassword: '',
  newPassword: '',
  retypePassword: '',
};

export const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Por favor, insira a senha'),
  newPassword: Yup.string()
    .required('Por favor, insira a nova senha')
    .test(
      'isValidPassword',
      'Por favor, insira uma senha que atenda aos critérios listados abaixo',
      (value) => validatePasswordStrength(value || '')
    ),
  retypePassword: Yup.string()
    .required('Por favor, insira a confirmação da senha')
    .oneOf([Yup.ref('newPassword'), ''], 'As senhas devem ser iguais'),
});
