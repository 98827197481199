import { EventModel, MeasurementModel } from '@cuidador/database';
import { AxiosInstance } from 'axios';
import { createOfflineProxy } from '../../config/axios/offlineProxy';

export type CreateOfflinePostMeasurementEventParam = Prettify<
  Omit<EventModel, 'measurement'> & {
    measurement: PartialNullable<
      MeasurementModel,
      'complicationLevel' | 'isDangerousComplication'
    >;
  }
>;

export const createOfflinePostMeasurement = (
  axios: AxiosInstance,
  eventData: CreateOfflinePostMeasurementEventParam
) =>
  createOfflineProxy(axios, 'post', async () => {
    return {
      data: eventData,
      statusCode: 200,
    };
  });
