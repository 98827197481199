import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';

export const StyledMuiDialog = styled(Dialog)`
  & .MuiPaper-root {
    margin: ${({ theme }) => theme.spacing(2)}px;
    background-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 21px;
    min-width: 300px;
    color: ${({ theme }) => theme.palette.common.white};
    max-width: 100%;
  }
  & .MuiDialogActions-root {
    justify-content: center;
    padding: ${({ theme }) => theme.spacing(2, 3)};
  }
  & .MuiDialogTitle-root {
    text-align: center;
  }
  & .MuiTypography-colorTextSecondary {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiDialogTitle-root {
    z-index: 5;
  }
  & .MuiDialogContent-root {
    z-index: 5;
  }

  .MuiDialogContent-root {
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px ${({ theme }) => theme.palette.secondary.dark};
      margin-block: 5px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.palette.common.white};
      border-radius: 10px;

      &:hover {
        background-color: ${({ theme }) => theme.palette.grey[300]};
      }
    }
  }
`;

export const StyledElipseBlueTop = styled.img`
  width: 250px;
  height: 250px;
  top: -190px;
  right: 30px;
  position: absolute;
  z-index: 2;
`;

export const StyledElipseGreenTop = styled.img`
  width: 250px;
  height: 250px;
  top: -150px;
  right: -100px;
  position: absolute;
  z-index: 1;
`;

export const StyledElipseBlueBot = styled.img`
  width: 250px;
  height: 250px;
  bottom: -150px;
  left: -120px;
  position: absolute;
  z-index: 2;
`;

export const StyledElipseGreenBot = styled.img`
  width: 250px;
  height: 250px;
  bottom: -180px;
  left: 20px;
  position: absolute;
  z-index: 1;
`;

export const StyledDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // [TODO]: change to the right images if necessary
  filter: brightness(0.1) opacity(0.1);
`;
