import { useCallback, useReducer } from 'react';
import axios from '../../config/axios';
import { createOfflinePostCaregiver } from './postCaregiverMoodOffline';
import { CaregiverMoodModel } from '@cuidador/database';
import { createReducer, ReducerData, Item } from '../../utils/store/index';

const endpoint = '/care/caregiver/mood';

const initialData: ReducerData<CaregiverMoodModel> = {
  byId: {} as Record<string, Item<CaregiverMoodModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useCaregiverMood = () => {
  const [state, dispatch] = useReducer(
    createReducer<CaregiverMoodModel>(),
    initialData
  );
  const post = useCallback(async (data: CaregiverMoodModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const offlinePost = createOfflinePostCaregiver(axios);
      const response = await offlinePost(`${endpoint}`, data);
      dispatch({ type: 'CREATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    post,
  };
};

export default useCaregiverMood;
