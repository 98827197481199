import { EventModel } from '@cuidador/database';
import { AxiosInstance } from 'axios';
import { createOfflineProxy } from '../../config/axios/offlineProxy';

export const createOfflinePatchUpdatedTimeHappens = (
  axios: AxiosInstance,
  eventData: EventModel
) =>
  createOfflineProxy(axios, 'patch', async () => {
    return {
      data: eventData,
      statusCode: 200,
    };
  });
