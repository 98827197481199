import { EventModel, MedicationModel } from '@cuidador/database';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBr from 'date-fns/locale/pt-BR';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import StyledSelect from '../../Forms/FormikSelect';
import FormikTextField from '../../Forms/FormikTextField';
import MedicationInformationCard from './MedicationInformationCard';
import StyledMenuItem from '../../StyledMenuItem';
import {
  Centralizer,
  Form,
  FormContainer,
  OptionsButton,
  StyledDateTimePicker,
} from '../styles';
import { validationSchema } from './utils';

export interface Props {
  submit: (eventData: EventModel) => Promise<void>;
  cancel: () => void;
  medications: MedicationModel[];
  maxDateTime?: Date | string;
  minDateTime?: Date | string;
}

interface FormValues {
  comment: string;
  medicationId: number | string;
}

const CreateMedicationEventForm: React.FC<Props> = ({
  submit,
  cancel,
  medications,
  maxDateTime,
  minDateTime,
}) => {
  const initialValues: FormValues = {
    comment: '',
    medicationId: '',
  };
  const [dateTimeEvent, setDateTimeEvent] = useState<Date>(new Date());
  const [
    selectedMedication,
    setSelectedMedication,
  ] = useState<MedicationModel>();

  useEffect(() => {
    if (!medications) {
      toast.error(
        'Nenhum medicamento está cadastrado. Caso tenha necessidade, solicite ao familiar o cadastro de medicamento para uso "se necessário".'
      );
    }
  }, []);

  const formatAndSubmit = async (formValues: FormValues) => {
    const eventData: EventModel = {
      comment: formValues.comment,
      eventHappensAt: dateTimeEvent.toISOString(),
      medicationId: Number(formValues.medicationId),
      subCategoryId: medications.find(
        (medication) => medication.id === formValues.medicationId
      )?.subCategoryId,
    };
    await submit(eventData);
  };

  const handleGetSelectedMedication = (medicationId: number) => {
    const medication = medications.find(({ id }) => id === medicationId);
    setSelectedMedication(medication);
  };

  return (
    <FormContainer>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={formatAndSubmit}
        validateOnChange={false}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <StyledSelect
                name="medicationId"
                label="Medicamento"
                SelectDisplayProps={{
                  'data-testid': 'medicationId',
                }}
                color="primary"
              >
                {medications.map((item) => (
                  <StyledMenuItem
                    key={item.id}
                    value={item.id}
                    color="primary"
                    onClick={() => handleGetSelectedMedication(Number(item.id))}
                  >
                    {item?.subCategory?.name}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
              {!!selectedMedication && (
                <MedicationInformationCard
                  medication={selectedMedication as MedicationModel}
                />
              )}
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
                <StyledDateTimePicker
                  label="Data e horário"
                  inputVariant="outlined"
                  format="dd/MM/yyyy HH:mm"
                  name="dateTimeEvent"
                  value={dateTimeEvent}
                  cancelLabel="Cancelar"
                  ampm={false}
                  maxDate={maxDateTime}
                  minDate={minDateTime}
                  onChange={(date: Date | null) => {
                    date && setDateTimeEvent(date);
                  }}
                />
              </MuiPickersUtilsProvider>
              <FormikTextField
                color="primary"
                id="comment"
                multiline
                rows={4}
                inputProps={{ 'data-testid': 'comment', maxlength: 2000 }}
                label="Observações"
                name="comment"
                margin="normal"
                autoComplete="off"
              />
              <Centralizer>
                <OptionsButton
                  data-testid="submit-complication"
                  type="submit"
                  size="large"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Salvar
                </OptionsButton>
                <OptionsButton
                  data-testid="submit-complication"
                  onClick={cancel}
                  size="large"
                  color="primary"
                >
                  Cancelar
                </OptionsButton>
              </Centralizer>
            </Form>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

export default CreateMedicationEventForm;
