import { DateTimePicker } from '@material-ui/pickers';
import { Form as FormikForm } from 'formik';
import styled from 'styled-components';
import StyledButton from '../StyledButton';

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  margin: 0 ${({ theme }) => theme.spacing(3)}px; ;
`;

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
`;

export const Button = styled(StyledButton)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  width: 100%;
  max-width: 220px;
`;

export const OptionsButton = styled(StyledButton)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  width: 100%;
  max-width: 220px;
`;

export const StyledDateTimePicker = styled(DateTimePicker)`
  & .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.extra.color.grey.light};
  }
  & .MuiOutlinedInput-input,
  label {
    border-color: ${({ theme }) => theme.palette.primary.main};
    border-radius: ${({ theme }) => theme.spacing(1)}px;
    background-color: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.spacing(1)}px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;
