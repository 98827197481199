import React from 'react';
import { Checkbox, Radio, RadioGroup } from '@material-ui/core';
import {
  AnswerConfig,
  AnswerType,
  QuestionAnswerOption,
} from '@cuidador/database';

import StyledFormControlLabel from '../../../components/StyledFormControlLabel';
import {
  OptionsWrapper,
  OptionDescriptionContainer,
  StyledOptionDescriptionTextField,
  Divider,
} from '../styles';

type FormProps = {
  readOnly?: boolean;
  type: AnswerType['type'];
  options: AnswerType['options'];
  onOptionsChange: (option: QuestionAnswerOption[]) => void;
};

const ShiftQuestionOptions: React.FC<FormProps> = React.memo(
  ({ type, options, readOnly, onOptionsChange }: FormProps) => {
    const checkedOption = options.find((option) => option.isChecked);

    const buildChangeOptions = (
      changedData: Partial<QuestionAnswerOption>,
      optionId?: string,
      answerOptions = options
    ): QuestionAnswerOption[] => {
      const optionToChange = answerOptions.find((op) => op.id === optionId);

      return answerOptions.map((option) => {
        if (option.id !== optionId) {
          /**
           * if changed option was single and checked,
           * the other options must have isChecked false
           * and must not have description.
           */
          if (type === 'single' && changedData?.isChecked) {
            // remove nextAnswer values recursively
            const { nextAnswer } = option;
            const updatedNextAnswer = nextAnswer
              ? {
                  ...nextAnswer,
                  options: buildChangeOptions(
                    changedData,
                    undefined,
                    nextAnswer.options
                  ),
                }
              : undefined;
            return {
              ...option,
              isChecked: false,
              description: undefined,
              nextAnswer: updatedNextAnswer,
            };
          }
          return option;
        }
        return {
          ...option,
          ...optionToChange,
          ...changedData,
          // if option to change is not checked, remove description
          description: optionToChange?.isChecked
            ? changedData.description
            : undefined,
        };
      });
    };

    return (
      <>
        <OptionsWrapper>
          {type === 'multi' &&
            options.map((option) => {
              return (
                <React.Fragment key={option.id}>
                  <StyledFormControlLabel
                    color="white"
                    label={option.value}
                    control={
                      <Checkbox
                        disabled={readOnly}
                        key={`${option.id}-multi`}
                        data-testid={`${option.id}-multi`}
                        checked={option.isChecked}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          onOptionsChange(
                            buildChangeOptions(
                              {
                                isChecked: e.target.checked,
                              },
                              option.id
                            )
                          )
                        }
                      />
                    }
                  />
                </React.Fragment>
              );
            })}
          {type === 'single' && (
            <RadioGroup
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                onOptionsChange(
                  buildChangeOptions(
                    {
                      isChecked: true,
                    },
                    options.find((op) => op.id === e.target.value)!.id
                  )
                )
              }
            >
              {options.map((option) => (
                <React.Fragment key={`${option.id}-single`}>
                  <StyledFormControlLabel
                    data-testid={`${option.id}-single`}
                    color="white"
                    value={option.id}
                    control={<Radio />}
                    label={option.value}
                    checked={option.isChecked}
                    disabled={readOnly}
                  />
                </React.Fragment>
              ))}
            </RadioGroup>
          )}
        </OptionsWrapper>
        {checkedOption && checkedOption.nextAnswer && (
          <>
            <Divider />
            <ShiftQuestionOptions
              readOnly={readOnly}
              type={checkedOption.nextAnswer.type}
              options={checkedOption.nextAnswer.options}
              onOptionsChange={(updatedOptions) => {
                onOptionsChange(
                  buildChangeOptions(
                    {
                      nextAnswer: {
                        ...checkedOption.nextAnswer,
                        options: updatedOptions,
                      } as AnswerConfig,
                    },
                    checkedOption.id
                  )
                );
              }}
            />
          </>
        )}
        {checkedOption && checkedOption.needDescription && (
          <>
            <Divider />
            <OptionDescriptionContainer>
              <StyledOptionDescriptionTextField
                label="Observação"
                InputLabelProps={{ shrink: true }}
                placeholder="Digite alguma observação sobre a pergunta de cuidado"
                disabled={readOnly}
                value={checkedOption.description}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onOptionsChange(
                    buildChangeOptions(
                      {
                        description: e.target.value,
                      },
                      checkedOption.id
                    )
                  )
                }
                inputProps={{
                  maxLength: 200,
                  'data-testid': 'optionDescription',
                }}
              />
            </OptionDescriptionContainer>
          </>
        )}
      </>
    );
  }
);
ShiftQuestionOptions.displayName = 'ShiftQuestionOptions';

export default ShiftQuestionOptions;
