import { isWithinInterval } from 'date-fns';

export const getEventDateByTime = (time: string): string => {
  const [hour, minute] = time.split(':');
  const date = new Date();
  date.setHours(Number(hour));
  date.setMinutes(Number(minute));
  return date.toISOString();
};

export const isDateAtInterval = (
  dateTimeEvent: Date,
  minDateTime?: Date | string,
  maxDateTime?: Date | string
): boolean => {
  return !!(
    maxDateTime &&
    minDateTime &&
    isWithinInterval(dateTimeEvent, {
      start: new Date(minDateTime),
      end: new Date(maxDateTime),
    })
  );
};
