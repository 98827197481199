import styled, { css } from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import { AccessTime, List } from '@material-ui/icons';

const baseIconStyles = css`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  height: 20px;
  width: 20px;
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
  background-image: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.palette.primary.main},
    ${({ theme }) => theme.palette.secondary.dark}
  );
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(0.75)}px;
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  font-weight: bold;
  min-height: initial;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.7;
  }
`;

export const ButtonContainer = styled.div<{ $isExpanded?: boolean }>`
  ${({ $isExpanded }) =>
    $isExpanded
      ? `
        &::before {
          content: '';
          position: fixed;
          top: 0; right: 0; bottom: 0; left: 0;
          background-color: black;
          opacity: 0.4; 
        }
        `
      : ''}

  button {
    padding: ${({ theme }) => theme.spacing(0.5, 0.1)};
    background-image: linear-gradient(
      to bottom right,
      ${({ theme }) => theme.palette.primary.main},
      ${({ theme }) => theme.palette.secondary.dark}
    );
    min-width: 30px;
    min-height: 30px;
  }
`;

export const ShiftDetailsIcon = styled(List)`
  ${baseIconStyles}
`;

export const TimeCorrectionIcon = styled(AccessTime)`
  ${baseIconStyles}
`;
