import {
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import styled from 'styled-components';
import Button from '../../../components/StyledButton';

interface MoodImageProps {
  selected?: boolean;
}

export const OutterContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    ${(props) => props.theme.palette.primary.main} 0%,
    ${(props) => props.theme.palette.secondary.main} 100%
  );
  z-index: 10;
`;

export const HorizontalLine = styled.hr`
  width: 100%;
  margin: 0;
  border-width: 0.1px;
  opacity: 0.7;
`;

export const TitleContainer = styled.div`
  max-width: 100%;
  font-size: ${({ theme }) => theme.typography.h6.fontSize};
  margin-top: ${({ theme }) => theme.spacing(6)}px;
  color: white;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 80%;
  color: white;
  padding: 0px ${({ theme }) => theme.spacing(2)}px;
`;

export const MoodIconsLine = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

export const MoodIconContainer = styled.img<MoodImageProps>`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: none;
  margin: ${({ theme }) => theme.spacing(3)}px 0;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: ${(props) =>
    props.selected ? '0px 0px 2px 8px rgba(255, 255, 255, 0.22)' : ''};
`;

export const NextButton = styled(Button)`
  color: white;
  border-color: white;
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;
