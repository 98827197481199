import { EventModel, MeasurementModel } from '@cuidador/database';
import { useCallback } from 'react';
import axios, { getCachedAxios } from '../../config/axios';
import { ActionType, Item, ReducerData } from '../../utils/store/index';
import { createOfflineReducer } from '../../utils/store/offlineStore';
import useReducerWithLocalStorage from '../localstorage/useReducerWithLocalStorage';
import { removeLocalStorageItemsByKeySubstring } from '../localstorage/utils';
import { createOfflinePatchEventComment } from './patchEventCommentOffline';
import { createOfflinePatchEventStatus } from './patchEventStatusOffline';
import { createOfflinePatchUpdatedTimeHappens } from './patchEventUpdatedTimeHappensOffline';
import { createOfflinePostEvent } from './postEventOffline';
import {
  CreateOfflinePostMeasurementEventParam,
  createOfflinePostMeasurement,
} from './postScheduledMeasurementOffline';
import { SymptomModel } from '@cuidador/database/src/models/Symptom';
import { BloodGlycemiaAdditionalDetailsType } from '../../components/VitalSignsMeasurementInput/utils';

type UpdateScheduledMeasurementDataType = Prettify<
  {
    status: 'awaiting' | 'accomplished' | 'not_accomplished';
    patientId: number;
    measurementValue: string;
    measuredAt?: string;
    shiftExecutionId: number;
  } & Nullable<
    Required<
      Pick<MeasurementModel, 'complicationLevel' | 'isDangerousComplication'>
    >
  > & {
      symptomOptions?: {
        id?: number;
        label?: string;
        customLabel?: string;
        wasUserInput?: boolean;
      }[];
    } & {
      additionalDetails?: BloodGlycemiaAdditionalDetailsType
    }
>;

const endpoint = '/care/event';
const SHIFT_EVENTS_LOCAL_KEY = 'caregiver-shift-events';

const initialData: ReducerData<EventModel> = {
  byId: {} as Record<string, Item<EventModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useEvent = (shiftId?: number) => {
  const [state, dispatch] = useReducerWithLocalStorage<
    ReducerData<EventModel>,
    ActionType<EventModel>
  >({
    initializerArg: initialData,
    key: `${SHIFT_EVENTS_LOCAL_KEY}-${shiftId}`,
    reducer: createOfflineReducer<EventModel>(),
  });

  const clearAllLocalShiftEvents = useCallback(() => {
    removeLocalStorageItemsByKeySubstring(`${SHIFT_EVENTS_LOCAL_KEY}`);
  }, []);

  const getEventsByShiftId = useCallback(async (shiftId: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await getCachedAxios().get(
        `${endpoint}/by-shift/${shiftId}`
      );
      dispatch({ type: 'GET_ALL', payload: response.data });
      return response.data as EventModel[];
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const createShiftEvent = useCallback(
    async (
      eventType: 'complication' | 'general' | 'measurement' | 'medication',
      eventData: EventModel
    ) => {
      try {
        dispatch({ type: 'LOADING' });
        const postOfflineEvent = createOfflinePostEvent(axios, eventData);
        const response = await postOfflineEvent(
          `/care/shift/event/${eventType}`,
          { ...eventData, subCategory: undefined, medication: undefined } // send only event data to backend
        );
        dispatch({ type: 'CREATE', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const patchEventStatus = useCallback(
    async (eventId: number, eventData: EventModel) => {
      try {
        dispatch({ type: 'LOADING' });
        const patchOfflineEvent = createOfflinePatchEventStatus(
          axios,
          eventData
        );
        const response = await patchOfflineEvent(
          `${endpoint}/${eventId}/status`,
          eventData
        );
        response.data.id = eventId;
        dispatch({ type: 'UPDATE', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const patchEventComment = useCallback(
    async (eventId: number, eventData: EventModel) => {
      try {
        dispatch({ type: 'LOADING' });
        const patchOfflineEvent = createOfflinePatchEventComment(
          axios,
          eventData
        );
        const response = await patchOfflineEvent(
          `${endpoint}/${eventId}/comment`,
          eventData
        );
        dispatch({ type: 'UPDATE', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const setUpdatedTime = useCallback(
    async (eventId: number, eventData: EventModel) => {
      try {
        dispatch({ type: 'LOADING' });
        const patchOfflineEvent = createOfflinePatchUpdatedTimeHappens(
          axios,
          eventData
        );
        const response = await patchOfflineEvent(
          `${endpoint}/${eventId}/updated-time`,
          eventData
        );
        response.data.id = eventId;
        dispatch({ type: 'UPDATE', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const updateScheduledMeasurement = useCallback(
    async (
      eventId: number,
      eventData: CreateOfflinePostMeasurementEventParam,
      measurementData: UpdateScheduledMeasurementDataType
    ) => {
      try {
        dispatch({ type: 'LOADING' });
        const postOfflineMeasurement = createOfflinePostMeasurement(
          axios,
          eventData
        );
        const response = await postOfflineMeasurement(
          `${endpoint}/measurement/${eventId}`,
          measurementData
        );
        dispatch({ type: 'UPDATE', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const getSymptomsList = useCallback(async () => {
    try {
      const response = await axios.get('care/symptom?wasUserInput=false');
      return (response.data ?? []) as SymptomModel[];
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  return {
    ...state,
    getEventsByShiftId,
    createShiftEvent,
    patchEventStatus,
    patchEventComment,
    setUpdatedTime,
    clearAllLocalShiftEvents,
    updateScheduledMeasurement,
    getSymptomsList,
  };
};

export default useEvent;
