import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.primary.dark};
  form {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

export const PasswordInstructions = styled(Typography).attrs({
  variant: 'caption',
})`
  margin: ${({ theme }) => theme.spacing(1)}px;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: flex-end;
`;
