import {
  EventModel,
  ExecutionMessage,
  ExecutionMessageModel,
} from '@cuidador/database';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatEvents } from '../../components/CurrentShiftEventList/CurrentShiftCards/ScheduledMeasurementCard/utils';
import ExecutionMessageCard from '../../components/ExecutionMessageCard';
import PatientHeader from '../../components/Headers/PatientHeader';
import ShiftEventList from '../../components/ShiftEventList';
import { separateComplicationsFromActivities } from '../../components/ShiftEventList/utils';
import { ShiftInProgressContext } from '../../contexts/ShiftInProgress';
import useLastShift from '../../hooks/useLastShift';
import {
  Backdrop,
  BackdropCircularProgress,
  Button,
  ButtonBottomContainer,
  Container,
  ShiftHeader,
  Title,
  TitleContainer,
} from './styles';

const LastShift: React.FC = () => {
  const [lastShiftEvents, setLastShiftEvents] = useState<EventModel[]>([]);
  const [lastShiftMessages, setLastShiftMessages] = useState<
    ExecutionMessageModel[]
  >([]);
  const [lastShiftFormattedDate, setLastShiftFormattedDate] = useState('');
  const [loading, setLoading] = useState(false);

  const { getByPatientId: getLastShiftByPatientId } = useLastShift();

  const history = useHistory();
  const { shiftInProgress } = useContext(ShiftInProgressContext);

  const handleNextRedirect = () => {
    history.push(`/plantao/cuidar-do-cuidador/inicio`);
  };

  const showError = () =>
    toast.error(
      'Erro ao carregar eventos do último plantão. Tente novamente daqui a pouco'
    );

  async function loadPageData() {
    try {
      setLoading(true);
      const patientId = Number(shiftInProgress!.patientId);
      const lastShift = await getLastShiftByPatientId(patientId);
      if (lastShift) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setLastShiftMessages(lastShift.messages as ExecutionMessageModel[]);
        setLastShiftEvents(lastShift.events as EventModel[]);
        setLastShiftFormattedDate(
          format(new Date(lastShift.plannedToStartAt || ''), 'dd/MM/yyyy')
        );
      } else {
        handleNextRedirect();
      }
    } catch (err) {
      console.log(err);
      showError();
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    loadPageData();
  }, []);

  const {
    complicationEvents,
    eventsWithoutComplications,
  } = separateComplicationsFromActivities(lastShiftEvents);

  const formattedEventsWithoutComplications = useMemo(
    () => formatEvents(eventsWithoutComplications),
    [lastShiftEvents]
  );

  return (
    <>
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
      {/* Cached only GET signed URL */}
      <PatientHeader />
      <Container>
        <ShiftHeader>
          <Title>{'Veja o que aconteceu na última rotina de cuidado'}</Title>
          <Typography variant="subtitle1">
            Realizado em <b>{lastShiftFormattedDate}</b>
          </Typography>
        </ShiftHeader>
        {!!complicationEvents && complicationEvents.length > 0 && (
          <ShiftEventList
            events={complicationEvents || []}
            title="Intercorrências"
          />
        )}
        {!!formattedEventsWithoutComplications &&
        formattedEventsWithoutComplications.length > 0 ? (
          <ShiftEventList
            events={formattedEventsWithoutComplications || []}
            title="Atividades"
          />
        ) : (
          <TitleContainer>
            <Typography variant="h6">
              Nenhum evento registrado no plantão.
            </Typography>
          </TitleContainer>
        )}
        {lastShiftMessages && lastShiftMessages.length > 0 && (
          <>
            <TitleContainer>
              <Typography variant="h6">
                Anotações e evolução do plantão
              </Typography>
              {lastShiftMessages.map((message, index) => (
                <ExecutionMessageCard
                  executionMessage={message as ExecutionMessage}
                  key={`execution-message-card-${index}`}
                  data-testid={`execution-message-card-${index}`}
                />
              ))}
            </TitleContainer>
          </>
        )}
      </Container>
      <ButtonBottomContainer>
        <Button onClick={handleNextRedirect}>Próximo</Button>
      </ButtonBottomContainer>
    </>
  );
};

export default LastShift;
