import {
  clientSideScheme,
  setFavicon,
  setManifest,
} from '@cuidador/whitelabel';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';

import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import ErrorBoundary from './components/ErrorBoundary';
import Layout from './components/Layout';
import Toast from './components/Toast';
import './config/hotjar';
import './config/sentry';
import { AuthProvider } from './contexts/auth';
import { PermissionProvider } from './contexts/permission';
import { GlobalLoadingProvider } from './contexts/RequestInterceptor';
import ScrollToTop from './routes/ScrollToTop';
import GlobalStyle from './styles/globalStyle';
import { resolveTheme } from './styles/theme';

// handle offline queue requests
import InternetConnectionProvider from './components/InternetConnectionCheck/InternetConnectionContext';
import axios from './config/axios';
import { startQueueChecker } from './config/axios/offlineProxy';
import { PushNotificationProvider } from './contexts/PushNotification';
import ShiftInProgressProvider from './contexts/ShiftInProgress';
startQueueChecker(axios);

const App: React.FC = () => {
  const { muiTheme, styledTheme } = resolveTheme();
  const { appCFaviconUrl, appCJsonManifest } = clientSideScheme();

  useEffect(() => {
    setFavicon(appCFaviconUrl);
    setManifest(appCJsonManifest);
  }, []);

  return (
    <ErrorBoundary>
      <StylesProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <StyledThemeProvider theme={styledTheme}>
            <GlobalStyle />
            <GlobalLoadingProvider>
              <InternetConnectionProvider>
                <BrowserRouter>
                  <AuthProvider>
                    <PermissionProvider>
                      <PushNotificationProvider>
                        <ShiftInProgressProvider>
                          <ScrollToTop />
                          <Toast />
                          <Layout />
                        </ShiftInProgressProvider>
                      </PushNotificationProvider>
                    </PermissionProvider>
                  </AuthProvider>
                </BrowserRouter>
              </InternetConnectionProvider>
            </GlobalLoadingProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </StylesProvider>
    </ErrorBoundary>
  );
};
export default App;
