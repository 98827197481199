import { ShiftModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import { getCachedAxios } from '../config/axios';
import { createReducer, Item, ReducerData } from '../utils/store/index';

const endpoint = '/care/lastshift/by-patient';

const initialData: ReducerData<ShiftModel> = {
  byId: {} as Record<string, Item<ShiftModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useLastShift = () => {
  const [state, dispatch] = useReducer(
    createReducer<ShiftModel>(),
    initialData
  );

  const getByPatientId = useCallback(async (patientId: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await getCachedAxios().get(`${endpoint}/${patientId}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return response.data as ShiftModel;
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  return {
    ...state,
    getByPatientId,
  };
};

export default useLastShift;
