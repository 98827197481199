import { Typography } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { Form as FormikForm } from 'formik';
import styled from 'styled-components';
import StyledButton from '../../../StyledButton';

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(-1)}px;
`;

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const StyledBoldTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
`;

export const StyledDatePicker = styled(DateTimePicker)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  & .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.extra.color.grey.light};
  }
  & .MuiOutlinedInput-input {
    padding: ${({ theme }) => theme.spacing(1.5)}px;
    border-radius: ${({ theme }) => theme.spacing(1)}px;
  }
  & .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.spacing(1)}px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.extra.color.grey.light};
  }
`;

export const StyledOptions = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const Button = styled(StyledButton)`
  width: 45%;
`;

export const LargeButton = styled(StyledButton)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;
