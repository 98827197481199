import { EmergencyContactModel } from '@cuidador/database';
import React, { useContext, useEffect } from 'react';
import EmergencyContactCard from '../../components/EmergencyContactCard';
import HeaderWithDrawer from '../../components/Headers/HeaderWithDrawer';
import { ShiftInProgressContext } from '../../contexts/ShiftInProgress';
import useEmergencyContacts from '../../hooks/useEmergencyContacts';
import { Backdrop, BackdropCircularProgress, Container } from './styles';

const EmergencyContactsCard: React.FC<{
  contacts: EmergencyContactModel;
}> = ({ contacts }) => {
  return <EmergencyContactCard {...contacts} />;
};

const WHATSAPP_NUMBER = '5511919006699';

const EmergencyContacts: React.FC = () => {
  const { getAllByPatientId, byId, ids, loading } = useEmergencyContacts();
  const { shiftInProgress } = useContext(ShiftInProgressContext);
  const FAQ_URL =
    'https://www.cuidadordeconfianca.com.br/perguntas-frequentes-aplicativo';

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const patientId = shiftInProgress?.patientId!;
  useEffect(() => {
    if (shiftInProgress) {
      getAllByPatientId(patientId);
    }
  }, [patientId]);

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  return (
    <>
      <HeaderWithDrawer centerContent="Me Ajuda" />
      <Container>
        <EmergencyContactsCard
          contacts={{
            name: 'Samu',
            phoneNumber: '192',
            callingCondition:
              'Ligar em caso de acidente, crises convulsivas, falta de ar, suspeita de infarto ou AVC.',
          }}
        />
        <EmergencyContactsCard
          contacts={{
            name: 'Bombeiros',
            phoneNumber: '193',
            callingCondition:
              'Ligar em caso de afogamento, choque elétrico, queda e acidente doméstico.',
          }}
        />
        <EmergencyContactsCard
          contacts={{
            name: 'Dúvidas frequentes',
            relevantInformation: 'Dicas sobre o uso do aplicativo.',
            link: FAQ_URL,
          }}
        />
        <EmergencyContactsCard
          contacts={{
            name: 'Central de Apoio ao Cuidador de Confiança',
            relevantInformation: 'Dicas de cuidado e uso do aplicativo.',
            phoneNumber: WHATSAPP_NUMBER,
            isWhatsapp: true,
          }}
        />

        {shiftInProgress &&
          ids
            .map((id) => byId[id])
            .map((contacts) => (
              <EmergencyContactsCard key={contacts.id} contacts={contacts} />
            ))}
      </Container>
    </>
  );
};

export default EmergencyContacts;
