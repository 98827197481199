import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { ReactComponent as Menu } from '../../../assets/burger-menu.svg';

export const IconBackground = styled(Link)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

export const StyledMenuIcon = styled(Menu)`
  color: ${({ theme }) => theme.palette.common.white};
  height: 1em;
  width: 1em;
`;

export const StyledMenuButton = styled(IconButton)`
  padding: 0;
`;
