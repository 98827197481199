import React from 'react';
import FormikTextField from '../../../../Forms/FormikTextField';

interface DefaultInputProps {
  value?: string;
  onChange: (value: string) => void;
}

const DefaultInput: React.FC<DefaultInputProps & Partial<HTMLInputElement>> = ({
  value,
  onChange,
  ...props
}) => {
  return (
    <FormikTextField
      color="primary"
      inputProps={{
        'data-testid': 'measurementInput',
        maxlength: 255,
      }}
      label="Valor"
      margin="normal"
      autoComplete="off"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      {...props}
    />
  );
};

export default DefaultInput;
