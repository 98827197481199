import * as Sentry from '@sentry/react';
import { AxiosRequestConfig } from 'axios';
import { getApp as firebaseGetApp } from 'firebase/app';
import firebaseOld from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/messaging';
import 'firebase/compat/storage';
import { getMessaging, getToken } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

firebaseOld.initializeApp(firebaseConfig);

export const auth = firebaseOld.auth;

export const authRequestInterceptor = async (config: AxiosRequestConfig) => {
  try {
    const token = await auth().currentUser?.getIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (err) {
    console.log(err);
  }
  return config;
};

export const getFCMToken = async (): Promise<string | null> => {
  const messaging = getMessaging(getApp());
  const token = await getToken(messaging).catch((err) => {
    console.error(err);
    Sentry.captureException(err);
    return null;
  });
  return token;
};

export const getApp = firebaseGetApp;

export default firebaseOld;
