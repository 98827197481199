import { EventModel } from '@cuidador/database';
import {
  addMinutes,
  differenceInMinutes,
  format,
  isAfter,
  isBefore,
} from 'date-fns';
import { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isDateAtInterval } from '../components/CreateShiftEvent/utils';
import { AuthContext } from '../contexts/auth';
import { ShiftInProgressContext } from '../contexts/ShiftInProgress';

const STATUS_TOGGLE_TIME_ALLOWED_CHECK_INTERVAL = 10000; // 10000ms

const checkIfStatusToggleTimeIsAllowed = (event: EventModel) => {
  const eventTime = event.updatedTimeHappensAt || event.eventHappensAt;
  const minutesToEvent = differenceInMinutes(
    new Date(`${eventTime}`),
    new Date()
  );
  if (minutesToEvent >= 20) {
    return false;
  } else {
    return true;
  }
};

export const checkIfUserIsAllowedToPatchEvent = (
  event: EventModel,
  userId?: number
) => {
  if (event.status === 'accomplished' && event.caregiverId !== userId) {
    return false;
  }
  return true;
};

const useShiftEventHandling = (
  event: EventModel,
  {
    onChangeStatus,
    onChangeComment,
    onChangeTime,
  }: {
    onChangeStatus?: (id: number, newStatus: EventModel['status']) => void;
    onChangeComment?: (id: number, newComment: string) => void;
    onChangeTime?: (id: number, updatedTimeHappensAt: string | null) => void;
  }
) => {
  const date = format(new Date(`${event.eventHappensAt}`), 'HH:mm');
  const [currentStatus, setCurrentStatus] = useState<EventModel['status']>(
    event.status
  );
  const [currentComment, setCurrentComment] = useState(event.comment || '');
  const [isStatusToggleTimeAllowed, setIsStatusToggleTimeAllowed] = useState(
    checkIfStatusToggleTimeIsAllowed(event)
  );
  const { userInfo } = useContext(AuthContext);
  const [isUserAllowedToPatchEvent, setIsUserAllowedToPatchEvent] = useState(
    checkIfUserIsAllowedToPatchEvent(event, userInfo?.user?.id)
  );
  const { minShiftLimitDate, maxShiftLimitDate } = useContext(
    ShiftInProgressContext
  );

  useEffect(() => {
    const intervalId: NodeJS.Timeout = setInterval(() => {
      const statusToggleTimeAllowed = checkIfStatusToggleTimeIsAllowed(event);
      if (statusToggleTimeAllowed !== isStatusToggleTimeAllowed) {
        setIsStatusToggleTimeAllowed(statusToggleTimeAllowed);
      }
    }, STATUS_TOGGLE_TIME_ALLOWED_CHECK_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Force status refresh if event time changed
    const statusToggleTimeAllowed = checkIfStatusToggleTimeIsAllowed(event);
    if (statusToggleTimeAllowed !== isStatusToggleTimeAllowed) {
      setIsStatusToggleTimeAllowed(statusToggleTimeAllowed);
    }
  }, [event.updatedTimeHappensAt, event.eventHappensAt]);

  useEffect(() => {
    setIsUserAllowedToPatchEvent(
      checkIfUserIsAllowedToPatchEvent(event, userInfo?.user?.id)
    );
  }, [event, userInfo]);

  const handleStatusToggle = useCallback(
    (newStatus: 'accomplished' | 'not_accomplished') => {
      if (!onChangeStatus) {
        console.warn(
          'useShiftEventHandling.handleStatusToggle should not be used if onChangeStatus props is not defined'
        );
        return;
      }
      if (!isStatusToggleTimeAllowed) {
        toast.dismiss('status-toggle-warning-toast');
        toast.warning(
          'Evento ainda não disponível. Para registro com horário alterado, clique no botão "relógio"',
          { toastId: 'status-toggle-warning-toast' }
        );
        return;
      }
      setCurrentStatus((previousStatus) => {
        const toggledStatus =
          previousStatus === newStatus ? 'awaiting' : newStatus;

        onChangeStatus(event.id!, toggledStatus);

        return toggledStatus;
      });
    },
    [event.id, isStatusToggleTimeAllowed, setCurrentStatus, onChangeStatus]
  );

  const handleUpdatedTime = (updatedTimeHappens: Date | null) => {
    if (!onChangeTime || !onChangeStatus) {
      console.warn(
        'useShiftEventHandling.handleUpdatedTime should not be used if onChangeTime or onChangeStatus props are not defined'
      );
      return;
    }

    const isoUpdatedTimeHappens = updatedTimeHappens
      ? updatedTimeHappens.toISOString()
      : null;

    if (
      updatedTimeHappens &&
      !isDateAtInterval(
        updatedTimeHappens,
        minShiftLimitDate,
        maxShiftLimitDate
      )
    ) {
      toast.error('O horário deve estar dentro do horário do plantão');
      return;
    }

    // Updates time
    onChangeTime(event.id!, isoUpdatedTimeHappens);

    // If time is in the past, updates status to 'accomplished'
    if (
      isoUpdatedTimeHappens &&
      isBefore(new Date(isoUpdatedTimeHappens), new Date())
    ) {
      setCurrentStatus('accomplished');
      onChangeStatus(event.id!, 'accomplished');
    } else if (
      isoUpdatedTimeHappens &&
      isAfter(new Date(isoUpdatedTimeHappens), addMinutes(new Date(), 20))
    ) {
      setCurrentStatus('awaiting');
      onChangeStatus(event.id!, 'awaiting');
    }
  };

  const handleCommentChange = (newComment: string) => {
    if (!onChangeComment) {
      console.warn(
        'useShiftEventHandling.handleCommentChange should not be used if onChangeComment props is not defined'
      );
      return;
    }
    setCurrentComment(newComment);
    onChangeComment(event.id!, newComment);
  };

  const handleAllowedPatchEvent = (callbackFn: () => void) => {
    if (isUserAllowedToPatchEvent) {
      return callbackFn();
    } else {
      toast.dismiss('patch-warning-toast');
      toast.warning('Apenas quem registrou esse evento pode editá-lo.', {
        toastId: 'patch-warning-toast',
      });
      return;
    }
  };

  return {
    date,
    isStatusToggleTimeAllowed,
    isUserAllowedToPatchEvent,
    minShiftLimitDate,
    maxShiftLimitDate,
    currentStatus,
    currentComment,
    handleStatusToggle,
    handleUpdatedTime,
    handleCommentChange,
    handleAllowedPatchEvent,
  };
};

export default useShiftEventHandling;
