import styled from 'styled-components';
import { InputBase } from '@material-ui/core';

export const StyledInput = styled(InputBase)`
  margin: ${({ theme }) => theme.spacing(1, 0)};
  color: ${({ theme }) => theme.palette.primary.dark};
  border: 1px solid ${({ theme }) => theme.palette.primary.dark};
  padding: ${({ theme }) => theme.spacing(1, 1)};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  width: 100%;
  align-self: center;
  max-height: ${({ theme }) => theme.spacing(4.5)}px;
  input {
    font-size: ${({ theme }) => theme.typography.overline.fontSize};
    width: 100%;
    min-height: ${({ theme }) => theme.spacing(2.5)}px;
    padding: ${({ theme }) => theme.spacing(0, 1)};
  }
  .MuiInputAdornment-positionEnd {
    margin: 0;
  }
`;
