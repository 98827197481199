import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Title = styled(Typography).attrs({
  variant: 'h6',
})`
  text-align: center;
  font-size: 16px;
  display: -webkit-box;
  max-width: 250px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
