import { AnswerType, QuestionAnswerOption } from '@cuidador/database';
import React from 'react';
import { AnswerLineTypography, MultiAnswerLi, MultiAnswerUl } from './styles';

export function getAnswerContent(questionAnswerData?: string | AnswerType) {
  if (!questionAnswerData || typeof questionAnswerData === 'string')
    return null;

  const values: QuestionAnswerOption[] = [];
  let nav: AnswerType = questionAnswerData;
  while (nav && nav.type !== 'multi') {
    const checkedOption = nav.options.find((o) => o.isChecked)!;
    values.push(checkedOption);
    if (checkedOption.nextAnswer) {
      nav = checkedOption.nextAnswer;
    } else {
      break;
    }
  }

  let selectedOptions: QuestionAnswerOption[] = [];
  if (nav && nav.type === 'multi') {
    selectedOptions = nav.options.filter((o) => o.isChecked);
  }

  return (
    <>
      {values.map((v) => (
        <>
          <AnswerLineTypography variant="body2" key={`value-${v.id}`}>
            {v.value}
          </AnswerLineTypography>
          {v.needDescription && v.description && (
            <AnswerLineTypography variant="body2" key={`desc-${v.id}`}>
              <b>Observações:</b> {v.description}
            </AnswerLineTypography>
          )}
        </>
      ))}
      <MultiAnswerUl>
        {selectedOptions.map((option) => (
          <MultiAnswerLi variant="body2" key={option.id}>
            {option.value}
          </MultiAnswerLi>
        ))}
      </MultiAnswerUl>
    </>
  );
}
