import { differenceInYears } from 'date-fns'

export const subCategoryIdMapper = {
  BLOOD_PRESSURE: 35231,
  BODY_TEMPERATURE: 35237,
  BLOOD_GLYCEMIA: 35230,
  BREATH_RATE: 35236,
  HEART_RATE: 35235,
  OXIMETRY_RATE: 35232,
  WEIGHT_MEASURMENT: 35233,
}

export const staticCareLineIdMapper = {
  DIABETES_TYPE1: 36,
  DIABETES_TYPE2: 37,
}

export const bloodGlycemiaAdditionalDetails = {
  preprandial: 'pré-refeição',
  fasting: 'jejum',
  postprandial: 'pós-refeição',
  not_available: 'não informado',
} as const
export type BloodGlycemiaAdditionalDetailsType = keyof typeof bloodGlycemiaAdditionalDetails

interface PutMeasurementValidInputValueReturnType {
  complicationLevel: 3 | 2 | 1 | 0 | -1 | -2 | -3 | null
  isDangerousComplication: boolean | null
  additionalDetails?: BloodGlycemiaAdditionalDetailsType
}

export const validateOxymetryInput = (rawValue: string): PutMeasurementValidInputValueReturnType => {
  const value = Number(rawValue)

  if (!rawValue || Number.isNaN(value))
    return { complicationLevel: null, isDangerousComplication: null }

  if (value < 90)
    return {
      complicationLevel: 3,
      isDangerousComplication: true,
    }

  if (value < 95)
    return {
      complicationLevel: 2,
      isDangerousComplication: true,
    }

  return {
    complicationLevel: 0,
    isDangerousComplication: false,
  }
}

export const validateBreathRate = (rawValue: string): PutMeasurementValidInputValueReturnType => {
  const value = Number(rawValue)

  if (!rawValue || Number.isNaN(value))
    return { complicationLevel: null, isDangerousComplication: null }

  if (value < 7)
    return {
      complicationLevel: -3,
      isDangerousComplication: true,
    }

  if (value < 12)
    return {
      complicationLevel: -2,
      isDangerousComplication: true,
    }

  if (value > 25)
    return {
      complicationLevel: 3,
      isDangerousComplication: true,
    }

  if (value > 20)
    return {
      complicationLevel: 2,
      isDangerousComplication: true,
    }

  return {
    complicationLevel: 0,
    isDangerousComplication: false,
  }
}

export const validateBodyTemperature = (rawValue: string): PutMeasurementValidInputValueReturnType => {
  const value = Number(rawValue.replace(',', '.'))

  if (!rawValue || Number.isNaN(value))
    return { complicationLevel: null, isDangerousComplication: null }

  if (value <= 35)
    return {
      complicationLevel: -3,
      isDangerousComplication: true,
    }

  if (value < 36)
    return {
      complicationLevel: -2,
      isDangerousComplication: true,
    }

  if (value > 37.7)
    return {
      complicationLevel: 3,
      isDangerousComplication: true,
    }

  if (value > 37.2)
    return {
      complicationLevel: 2,
      isDangerousComplication: true,
    }

  return {
    complicationLevel: 0,
    isDangerousComplication: false,
  }
}

export const validateBloodGlycemia = (
  rawMeasurement: string,
  patientDateOfBirth: string | undefined,
  patientHasDiabetes: boolean,
  additionalDetails: BloodGlycemiaAdditionalDetailsType,
): PutMeasurementValidInputValueReturnType => {
  const measurement = Number(rawMeasurement)
  if (Number.isNaN(measurement) || !patientDateOfBirth) return { complicationLevel: null, isDangerousComplication: null }
  const age = differenceInYears(new Date(), new Date(patientDateOfBirth))

  if (patientHasDiabetes) {
    if (additionalDetails === 'postprandial') {
      if (measurement < 70)
        return {
          complicationLevel: -2,
          isDangerousComplication: true,
          additionalDetails,
        }
      if (measurement <= 180)
        return {
          complicationLevel: 0,
          isDangerousComplication: false,
          additionalDetails,
        }
    }

    if (age < 65) {
      if (measurement < 80)
        return {
          complicationLevel: -1,
          isDangerousComplication: false,
          additionalDetails,
        }
      if (measurement <= 130)
        return {
          complicationLevel: 0,
          isDangerousComplication: false,
          additionalDetails,
        }
    } else {
      if (measurement < 90)
        return {
          complicationLevel: -1,
          isDangerousComplication: false,
          additionalDetails,
        }
      if (measurement <= 150)
        return {
          complicationLevel: 0,
          isDangerousComplication: false,
          additionalDetails,
        }
    }

    if (measurement <= 250)
      return {
        complicationLevel: 2,
        isDangerousComplication: true,
        additionalDetails,
      }

    return {
      complicationLevel: 3,
      isDangerousComplication: true,
      additionalDetails,
    }
  }

  if (additionalDetails === 'postprandial') {
    if (measurement < 80)
      return {
        complicationLevel: -1,
        isDangerousComplication: false,
        additionalDetails,
      }
    if (measurement <= 126)
      return {
        complicationLevel: 0,
        isDangerousComplication: false,
        additionalDetails,
      }
    if (measurement <= 139)
      return {
        complicationLevel: 1,
        isDangerousComplication: false,
        additionalDetails,
      }
  }

  if (measurement < 65)
    return {
      complicationLevel: -3,
      isDangerousComplication: true,
      additionalDetails,
    }
  if (measurement <= 99)
    return {
      complicationLevel: 0,
      isDangerousComplication: false,
      additionalDetails,
    }
  if (measurement <= 125)
    return {
      complicationLevel: 1,
      isDangerousComplication: false,
      additionalDetails,
    }
  if (measurement <= 199)
    return {
      complicationLevel: 2,
      isDangerousComplication: true,
      additionalDetails,
    }

  return {
    complicationLevel: 3,
    isDangerousComplication: true,
    additionalDetails,
  }
}

export function validateHeartRate (rawValue: string): PutMeasurementValidInputValueReturnType {
  const value = Number(rawValue.replace(',', '.'))

  if (!rawValue || Number.isNaN(value))
    return { complicationLevel: null, isDangerousComplication: null }

  if (value < 60) return {
    complicationLevel: 2,
    isDangerousComplication: true,
  }

  if (value > 100) return {
    complicationLevel: 2,
    isDangerousComplication: true,
  }

  return {
    complicationLevel: 0,
    isDangerousComplication: false,
  }
}

export const validateBloodPressure = (
  rawSystolicValue: string,
  symptomsCount: number,
): PutMeasurementValidInputValueReturnType => {
  const systolicValue = Number(rawSystolicValue)
  if (Number.isNaN(systolicValue) || !systolicValue)
    return {
      complicationLevel: null,
      isDangerousComplication: null,
    }

  if (systolicValue < 80)
    return {
      complicationLevel: 3,
      isDangerousComplication: true,
    }

  if (systolicValue < 90 && symptomsCount > 0)
    return {
      complicationLevel: 2,
      isDangerousComplication: true,
    }

  if (systolicValue < 90 && symptomsCount < 1)
    return {
      complicationLevel: 1,
      isDangerousComplication: false,
    }

  if (systolicValue >= 170)
    return {
      complicationLevel: 3,
      isDangerousComplication: true,
    }

  if (systolicValue >= 140)
    return {
      complicationLevel: 2,
      isDangerousComplication: true,
    }

  if (systolicValue >= 130 && symptomsCount > 0)
    return {
      complicationLevel: 2,
      isDangerousComplication: true,
    }

  if (systolicValue >= 130 && symptomsCount < 1)
    return {
      complicationLevel: 1,
      isDangerousComplication: false,
    }

  return {
    complicationLevel: 0,
    isDangerousComplication: false,
  }
}
