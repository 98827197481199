import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const CardWithBackground = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(2)}px 0;
  margin: ${({ theme }) => theme.spacing(0)}px
    ${({ theme }) => theme.spacing(1)}px;
  box-shadow: 0px 3px 6px #00000042;
`;

export const TypographyWhiteText = styled(Typography)`
  color: ${({ theme }) => theme.palette.background.paper};
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;
