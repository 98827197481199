import styled from 'styled-components';
import { Typography, Accordion as MuiAccordion } from '@material-ui/core';

import StyledPaper from '../StyledPaper';

export const Accordion = styled(MuiAccordion)<{ $backgroundColor?: string }>`
  background-color: ${({ $backgroundColor, theme }) =>
    $backgroundColor ? `#${$backgroundColor}` : theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  margin: ${({ theme }) => theme.spacing(0, 0, 2)};
  &.Mui-expanded {
    margin: ${({ theme }) => theme.spacing(0, 0, 2)};
  }
  & .MuiAccordionSummary-expandIcon {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiAccordionSummary-content {
    flex-direction: column;
    margin: ${({ theme }) => theme.spacing(2, 0)};
    &.Mui-expanded {
      margin: ${({ theme }) => theme.spacing(2, 0)};
    }
  }
  & .MuiAccordionDetails-root {
    flex-direction: column;
  }
`;

export const CareLineCard = styled(StyledPaper)`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(0, 0, 2)};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  :last-child {
    margin: 0px;
  }
`;

export const CareLineInfo = styled.div`
  flex: 1;
  flex-direction: column;
`;

export const CareLineName = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const CareLineStatus = styled(Typography).attrs({
  variant: 'caption',
})<{
  $answered?: boolean;
}>`
  color: ${({ theme, $answered }) =>
    $answered ? theme.palette.success.main : theme.palette.error.light};
`;
