import { EventSubCategoryModel, MeasurementModel } from '@cuidador/database'
import Typography from '@material-ui/core/Typography'
import { Message } from '@material-ui/icons'
import React from 'react'
import MesurementIcon from '../../../../assets/Icon-measurement.svg'
import useCanAccess from '../../../../hooks/useCanAccess'
import useShiftEventHandling from '../../../../hooks/useShiftEventHandling'
import CommentModal, {
  FormValues as CommentModalFormValues,
} from '../CommentModal'
import {
  ButtonContainer,
  CardFooter,
  CaregiverTypography,
  DescriptionContainer,
  LeftContainer,
  RightContainer,
  StyledChangeStatus,
  StyledCommentButton,
  TimerContainer,
  TypographyContainer,
} from '../styles'
import { CardBox } from './styles'
import { getMeasurementUnit } from './utils'
import { getConditionWithDetail, getFormattedSymptoms } from '../../../ShiftEventList/LastShiftCards/ScheduledMeasurementCard'

const CommentIcon = <Message />

interface CardProps {
  id?: number
  subCategory?: EventSubCategoryModel
  measurement?: MeasurementModel
  eventHappensAt?: string
  description?: string
  comment?: string
  caregiverName?: string
  onChangeComment: (id: number, newComment: string) => void
}

const MeasurementCard: React.FC<CardProps> = (event) => {
  const {
    id,
    subCategory,
    measurement,
    description,
    onChangeComment,
    caregiverName,
  } = event
  const [visibleCommentModal, setVisibleCommentModal] = React.useState(false)
  const { isAllowedToUpdate: isAllowedToUpdateComment } = useCanAccess(
    'care/shift/event.comment'
  )

  const {
    date,
    currentComment,
    handleCommentChange,
    handleAllowedPatchEvent,
  } = useShiftEventHandling(event, {
    onChangeComment,
  })

  return (
    <CardBox
      data-testid={`measurementCard-${id}`}
      styledHighlight={
        !!measurement?.isDangerousComplication
          ? 'red'
          : Math.abs(measurement?.complicationLevel ?? 0) === 1
            ? 'yellow'
            : undefined
      }
    >
      <LeftContainer>
        <TimerContainer>
          <Typography variant="h6">{date}</Typography>
        </TimerContainer>
        <img src={MesurementIcon} title={`measurement-image-${id}`} />
      </LeftContainer>
      <RightContainer>
        <TypographyContainer>
          <Typography variant="h6">{subCategory?.name}</Typography>
          <DescriptionContainer>
            <Typography variant="subtitle2">
              Valores medidos: {measurement?.measurementValue}{' '}
              {getMeasurementUnit(subCategory?.id)}
            </Typography>
          </DescriptionContainer>
          <DescriptionContainer>
            <Typography variant="subtitle2">{description}</Typography>
            <Typography variant='subtitle2'>
              {getConditionWithDetail(measurement?.additionalDetails || '', subCategory)}
            </Typography>
            {measurement?.symptoms && measurement?.symptoms?.length > 0 && (
              <Typography variant='subtitle2'>{getFormattedSymptoms(measurement?.symptoms)}</Typography>
            )}
            {currentComment && (
              <Typography variant="subtitle2">Obs: {currentComment}</Typography>
            )}
          </DescriptionContainer>
        </TypographyContainer>
        <CardFooter>
          {caregiverName && (
            <CaregiverTypography>{caregiverName}</CaregiverTypography>
          )}
          {isAllowedToUpdateComment && (
            <StyledChangeStatus>
              {id! > 0 && (
                <ButtonContainer>
                  <StyledCommentButton
                    data-testid={`comment-set-${id}`}
                    onClick={() =>
                      handleAllowedPatchEvent(() =>
                        setVisibleCommentModal(true)
                      )
                    }
                  >
                    {CommentIcon}
                  </StyledCommentButton>
                </ButtonContainer>
              )}
            </StyledChangeStatus>
          )}
        </CardFooter>
      </RightContainer>
      {visibleCommentModal && id && (
        <CommentModal
          handleEventComment={(values: CommentModalFormValues) => {
            handleCommentChange(values.comment)
            setVisibleCommentModal(false)
          }}
          onClose={() => setVisibleCommentModal(false)}
          opened={visibleCommentModal}
          eventId={id}
          allowRemoveButton={!!currentComment}
          initialValues={
            currentComment ? { comment: currentComment } : undefined
          }
        />
      )}
    </CardBox>
  )
}

export default MeasurementCard
