import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  title: Yup.string().required('Por favor, insira o título.'),
  description: Yup.string()
    .required('Por favor, insira a descrição.')
    .max(5000, 'A descrição deve conter no máximo 5000 caracteres.'),
  subCategoryId: Yup.string(),
  date: Yup.date()
    .required('Por favor, insira uma data.')
    .typeError('Por favor, insira uma data válida'),
});

export enum TitleByOccupation {
  supervisor = 'Avaliação de supervisão',
  doctor = 'Avaliação de medicina',
  nurse = 'Avaliação de enfermagem',
  nursingTechnician = 'Avaliação de técnico em enfermagem',
  physicalTherapist = 'Avaliação de fisioterapia',
  psychologist = 'Avaliação de psicologia',
  occupationalTherapist = 'Avaliação de terapia ocupacional',
  physicalEducator = 'Avaliação de educação física',
  nutritionist = 'Avaliação de nutrição',
  speechTherapist = 'Avaliação de fonoaudiologia',
  dentist = 'Avaliação de odontologia',
  another = 'Outra',
}

export const titleOptions = [
  {
    value: TitleByOccupation.supervisor,
    label: TitleByOccupation.supervisor
  },
  {
    value: TitleByOccupation.doctor,
    label: TitleByOccupation.doctor
  },
  {
    value: TitleByOccupation.nurse,
    label: TitleByOccupation.nurse
  },
  {
    value: TitleByOccupation.nursingTechnician,
    label: TitleByOccupation.nursingTechnician,
  },
  {
    value: TitleByOccupation.physicalTherapist,
    label: TitleByOccupation.physicalTherapist,
  },
  {
    value: TitleByOccupation.psychologist,
    label: TitleByOccupation.psychologist,
  },
  {
    value: TitleByOccupation.occupationalTherapist,
    label: TitleByOccupation.occupationalTherapist,
  },
  {
    value: TitleByOccupation.physicalEducator,
    label: TitleByOccupation.physicalEducator,
  },
  {
    value: TitleByOccupation.nutritionist,
    label: TitleByOccupation.nutritionist,
  },
  {
    value: TitleByOccupation.speechTherapist,
    label: TitleByOccupation.speechTherapist,
  },
  { value: TitleByOccupation.dentist, label: TitleByOccupation.dentist },
  { value: TitleByOccupation.another, label: TitleByOccupation.another },
];

export const handleTitleByPatientOccupation = (
  occupation?: keyof typeof TitleByOccupation
): string => {
  return occupation ? TitleByOccupation[occupation] : TitleByOccupation.another;
};
