import React, { useCallback } from 'react';
import { AnswerType, QuestionAnswerOption } from '@cuidador/database';

import Steps from '../Steps';
import ShiftQuestionOptions from './ShiftQuestionOptions';
import {
  QuestionCard,
  StepsContainer,
  StepCounter,
  QuestionText,
} from './styles';

type FormProps = {
  backgroundColor?: string;
  careQuestionId?: number;
  careQuestionText?: string;
  questionAnswerData: AnswerType;
  onAnswerChange: (
    careQuestionId: number,
    questionAnswerData: AnswerType
  ) => void;
  totalQuestions: number;
  currentStep: number;
};

const ShiftQuestion: React.FC<FormProps> = React.memo(
  ({
    backgroundColor,
    careQuestionId,
    careQuestionText,
    questionAnswerData,
    onAnswerChange,
    currentStep,
    totalQuestions,
  }: FormProps) => {
    /**
     * Build new answer when any option changes
     */
    const handleOptionsChange = useCallback(
      (options: QuestionAnswerOption[]) => {
        const newAnswerData: AnswerType = {
          ...questionAnswerData,
          options,
        };
        onAnswerChange(careQuestionId!, newAnswerData);
      },
      [careQuestionId, questionAnswerData]
    );

    return (
      <QuestionCard $backgroundColor={backgroundColor}>
        <StepsContainer>
          <StepCounter>{`${currentStep}/${totalQuestions}`}</StepCounter>
          <Steps activeStep={currentStep} steps={totalQuestions} />
        </StepsContainer>
        <QuestionText align="center">{careQuestionText}</QuestionText>
        <ShiftQuestionOptions
          type={questionAnswerData.type}
          options={questionAnswerData.options}
          onOptionsChange={handleOptionsChange}
        />
      </QuestionCard>
    );
  }
);
ShiftQuestion.displayName = 'ShiftQuestion';

export default ShiftQuestion;
