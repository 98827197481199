import { DialogActions, DialogContent, Slider } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import React, { useRef, useState } from 'react';

import UserDefault from '../../assets/user.png';
import useCanAccess from '../../hooks/useCanAccess';
import StyledButton from '../StyledButton';
import StyledDialog from '../StyledDialog';
import { Container, Fab, StyledEditor } from './styles';

type ProfilePictureProps = {
  onChange: (file: File) => void;
  initialImage?: string;
  isCreation?: boolean;
};

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  onChange,
  initialImage,
  isCreation,
}) => {
  const [editorImage, setEditorImage] = useState('');
  const [openEditor, setOpenEditor] = useState(false);
  const [zoom, setZoom] = useState<number>(1.2);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorRef = useRef<any>();
  const originalFile = useRef<File | null>();
  const { isAllowedToUpdate: isAllowedToUpdateProfilePicture } = useCanAccess(
    'media/profile-picture'
  );
  const chooseImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event!.target!.files![0];
    if (!file) return;
    setEditorImage(URL.createObjectURL(file || ''));
    originalFile.current = file;
    setOpenEditor(true);
  };

  const handleZoomChange = (
    event: React.ChangeEvent<unknown>,
    value: number | number[]
  ) => {
    setZoom(value as number);
  };

  const handleCancel = () => {
    setOpenEditor(false);
    setEditorImage('');
  };

  const handleConfirm = () => {
    const canvasScaled = editorRef.current.getImageScaledToCanvas();
    const croppedImg = canvasScaled.toDataURL();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    canvasScaled.toBlob((blob: any) => {
      onChange(blob);
      initialImage = '';
      setEditorImage(croppedImg);
    }, originalFile!.current!.type);
    setOpenEditor(false);
  };

  return (
    <>
      <StyledDialog open={openEditor}>
        <DialogContent>
          <StyledEditor
            ref={editorRef}
            image={editorImage}
            border={10}
            rotate={0}
            scale={zoom}
          />
          <div>
            <label>Zoom</label>
            <Slider
              style={{ color: 'white' }}
              min={1}
              max={10}
              step={0.1}
              value={zoom}
              onChange={handleZoomChange}
            />
          </div>
          <DialogActions>
            <StyledButton color="inherit" onClick={handleCancel}>
              Cancelar
            </StyledButton>
            <StyledButton color="inherit" onClick={handleConfirm}>
              Salvar
            </StyledButton>
          </DialogActions>
        </DialogContent>
      </StyledDialog>
      <Container>
        <img src={editorImage || initialImage || UserDefault} />
        {(isCreation || isAllowedToUpdateProfilePicture) && (
          <>
            <label htmlFor="choose-picture">
              <Fab
                // eslint-disable-next-line
                // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
                component="span"
                size="small"
                color="primary"
                aria-label="add"
              >
                {editorImage ? <EditRoundedIcon /> : <AddRoundedIcon />}
              </Fab>
            </label>
            <input
              id="choose-picture"
              data-testid="picturePicker"
              onChange={chooseImage}
              type="file"
              hidden
              accept=".png, .jpeg"
            />
          </>
        )}
      </Container>
    </>
  );
};

export default ProfilePicture;
