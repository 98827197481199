import DateFnsUtils from '@date-io/date-fns';
import { FormHelperText } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { KeyboardDatePickerProps } from '@material-ui/pickers/DatePicker';
import { useFormikContext } from 'formik';
import React from 'react';
import { ColorsType } from '../../styles/colorMapping';
import { StyledFormControl } from './styles';

export type StyledKeyboardDatePickerProps = Omit<
  KeyboardDatePickerProps,
  'color'
> & {
  color?: ColorsType;
  maxDate?: string;
  borderType?: 'flat' | 'round';
};

const StyledKeyboardDatePicker: React.FC<StyledKeyboardDatePickerProps> = ({
  name,
  label,
  disabled,
  maxDate,
  color = 'primary',
  inputVariant = 'outlined',
  margin = 'normal',
  size = 'small',
  value,
  onChange,
  onBlur,
  borderType = 'round',
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { errors, touched } = useFormikContext<any>();
  const hasError = touched[String(name)] && errors[String(name)];

  const [selectedDate, setSelectedDate] = React.useState(value || null);

  const handleDateChange = (date: Date | null) => {
    onChange(date);
    setSelectedDate(date);
  };

  return (
    <StyledFormControl
      $color={disabled ? 'black' : color}
      $borderType={borderType}
      fullWidth
      disabled={disabled}
      error={Boolean(hasError)}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <>
          <KeyboardDatePicker
            format="dd/MM/yyyy"
            invalidDateMessage={true}
            minDateMessage={false}
            maxDateMessage={true}
            name={name}
            margin={margin}
            label={label}
            inputVariant={inputVariant}
            value={selectedDate}
            clearable
            onChange={(date) => handleDateChange(date)}
            onBlur={onBlur}
            error={Boolean(hasError)}
            size={size}
            {...props}
            maxDate={maxDate}
            disabled={disabled}
          />
          {Boolean(hasError) && (
            <FormHelperText>{errors[String(name)]}</FormHelperText>
          )}
        </>
      </MuiPickersUtilsProvider>
    </StyledFormControl>
  );
};

export default StyledKeyboardDatePicker;
