import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../contexts/auth';
import { CurrentShiftDataContext } from '../contexts/CurrentShiftData';
import { isSelfCare } from '../contexts/permission';
import useCanAccess from '../hooks/useCanAccess';
import StyledSimpleDialog from './StyledSimpleDialog';

const FinishShiftConfirmDialog: React.FC<{
  opened: boolean;
  close: () => void;
}> = ({ opened, close }) => {
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);
  const { useShiftQuestions } = useContext(CurrentShiftDataContext);
  const { total: totalOfQuestions } = useShiftQuestions();
  const { isAllowedToCreate: isAllowedToCreateCareQuestions } = useCanAccess(
    'care/shift/care-question'
  );

  const handleFinishShift = () => {
    if (isAllowedToCreateCareQuestions && totalOfQuestions > 0) {
      history.push('/perguntas', { isFinishingShift: true });
    } else {
      // if there are no questions, go to shift finish page
      history.push('/plantao/finalizar');
    }
  };

  const isUserSelfCare = isSelfCare(userInfo?.user);

  const title = `Finalizar ${isUserSelfCare ? 'dia' : 'plantão'}`;
  const subtitle = `Tem certeza que deseja finalizar o ${
    isUserSelfCare ? 'dia' : 'plantão'
  }?`;

  return (
    <StyledSimpleDialog
      open={opened}
      handleNo={() => close()}
      handleYes={() => handleFinishShift()}
      title={title}
      subTitle={subtitle}
    />
  );
};

export default FinishShiftConfirmDialog;
