import { EventSubCategoryModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import React from 'react';
import DoneIcon from '../../../../assets/Icon-done.svg';
import NotDoneIcon from '../../../../assets/Icon-notdone.svg';
import ClockIcon from '../../../../assets/Icon-watch.svg';
import {
  CardFooter,
  CaregiverTypography,
  DescriptionContainer,
  LeftContainer,
  RightContainer,
  StatusContainer,
  TimerContainer,
  TypographyContainer,
} from '../styles';
import { CardBox } from './styles';

interface CardProps {
  id?: number;
  name?: string;
  subCategory?: EventSubCategoryModel;
  eventHappensAt?: string;
  eventNotifiedAt?: string;
  description?: string;
  status?: string;
  updatedTimeHappensAt?: string | null;
  comment?: string;
  caregiverName?: string;
}

const RoutineCard: React.FC<CardProps> = ({
  id,
  name,
  subCategory,
  description,
  eventHappensAt,
  eventNotifiedAt,
  status,
  updatedTimeHappensAt,
  comment,
  caregiverName,
}) => {
  const eventDate = updatedTimeHappensAt || eventHappensAt || eventNotifiedAt;
  const formattedDate = format(new Date(`${eventDate}`), 'HH:mm');

  return (
    <CardBox data-testid={`routineCard-${id}`}>
      <LeftContainer>
        <TimerContainer>
          <Typography variant="h6">{formattedDate}</Typography>
        </TimerContainer>
        <img src={ClockIcon} title={`routine-image-${id}`} />
      </LeftContainer>
      <RightContainer>
        <TypographyContainer>
          <Typography variant="h6">
            {subCategory?.name} {name && `(${String(name).trim()})`}
          </Typography>
          <DescriptionContainer>
            <Typography variant="subtitle2">{description}</Typography>
            {comment && (
              <Typography variant="subtitle2">Obs: {comment}</Typography>
            )}
          </DescriptionContainer>
        </TypographyContainer>
        <CardFooter>
          {caregiverName ? (
            <CaregiverTypography>{caregiverName}</CaregiverTypography>
          ) : (
            <div></div>
          )}
          <StatusContainer>
            {status == 'accomplished' ? (
              <img src={DoneIcon} title={'done-icon'} />
            ) : (
              <img src={NotDoneIcon} title={'not-done-icon'} />
            )}
          </StatusContainer>
        </CardFooter>
      </RightContainer>
    </CardBox>
  );
};

export default RoutineCard;
