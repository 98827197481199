import { PatientModel } from '@cuidador/database';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import * as Yup from 'yup';
import StyledSelect from '../Forms/FormikSelect';
import StyledMenuItem from '../StyledMenuItem';
import StyledSimpleModal from '../StyledSimpleModal';
import { Button, Form, StyledOptions } from './styles';

interface ModalProps {
  patients: PatientModel[];
  onSubmit: (data: FormValues) => void;
  onClose: () => void;
  open: boolean;
}

export interface FormValues {
  patient: number | string;
}

const initialValues: FormValues = {
  patient: '',
};

const validationSchema = yup.object().shape({
  patient: Yup.string().required('Por favor, selecione um paciente.'),
});

const SelectPatientModal: React.FC<ModalProps> = ({
  patients,
  onSubmit,
  onClose,
  open,
}) => {
  return (
    <StyledSimpleModal open={open} title={'Selecione o paciente'}>
      <Formik
        validateOnChange={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {() => {
          return (
            <Form noValidate={true}>
              <StyledSelect
                name="patient"
                label="Paciente"
                SelectDisplayProps={{
                  'data-testid': 'select-patient',
                }}
                color="white"
              >
                {patients.map((patient) => (
                  <StyledMenuItem
                    key={patient.id}
                    value={patient.id}
                    color="primary"
                  >
                    {patient.name}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
              <StyledOptions>
                <Button size="medium" color="inherit" onClick={onClose}>
                  Cancelar
                </Button>
                <Button type="submit" size="medium" color="inherit">
                  Continuar
                </Button>
              </StyledOptions>
            </Form>
          );
        }}
      </Formik>
    </StyledSimpleModal>
  );
};

export default SelectPatientModal;
