import React from 'react';
import { handleFormatDate, handleFormatTime } from '../utils';
import {
  CardTitle,
  CardItem,
  StyledSubtitle,
  StyledCreatedBy,
  StyledTypography,
} from '../styles';
import { ShiftAnnullation } from '@cuidador/database';

interface AnnulledCardProps {
  annullation: ShiftAnnullation;
}

const AnnulledCard: React.FC<AnnulledCardProps> = ({ annullation }) => {
  const dateCreatedAt = handleFormatDate(annullation.createdAt!);
  const timeCreatedAt = handleFormatTime(annullation.createdAt!);

  const executionType = annullation?.isAnnulled ? 'anulada' : 'restaurada';

  return (
    <>
      <CardTitle data-testid="annulled-restored-execution">
        Execução {executionType} ({dateCreatedAt} - {timeCreatedAt})
      </CardTitle>
      <CardItem>
        <StyledSubtitle>Responsável</StyledSubtitle>
        <StyledCreatedBy>{annullation.annulledBy?.name}</StyledCreatedBy>
      </CardItem>
      {!!annullation?.isAnnulled && (
        <CardItem>
          <StyledSubtitle>Justificativa</StyledSubtitle>
          <StyledTypography>{annullation.description}</StyledTypography>
        </CardItem>
      )}
    </>
  );
};

export default AnnulledCard;
