import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import FormikTextField from '../../../Forms/FormikTextField';
import StyledSimpleModal from '../../../StyledSimpleModal';
import { Button, Form, LargeButton, StyledOptions } from './styles';

export type ModalProps = {
  handleEventComment: (values: FormValues) => void;
  onClose: () => void;
  readonly?: boolean;
  eventId: number;
  opened: boolean;
  initialValues?: FormValues;
  allowRemoveButton?: boolean;
};

export type FormValues = {
  comment: string;
};

const validationSchema = yup.object().shape({
  comment: yup
    .string()
    .required('Por favor, insira uma observação')
    .max(500, 'A observação deve conter no máximo 500 caracteres.'),
});

const defaultValues = {
  comment: '',
};

const CommentModal: React.FC<ModalProps> = ({
  handleEventComment,
  onClose,
  readonly = false,
  opened,
  initialValues,
  allowRemoveButton,
}) => {
  return (
    <StyledSimpleModal open={opened} title={'Adicionar observação'}>
      <Formik
        validateOnChange={false}
        initialValues={initialValues || defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleEventComment}
      >
        {({ isSubmitting }) => {
          return (
            <Form noValidate={true}>
              <FormikTextField
                id="description"
                color="white"
                multiline
                rows={4}
                inputProps={{ 'data-testid': 'comment', maxlength: 255 }}
                label="Observações"
                name="comment"
                margin="normal"
                autoComplete="off"
              />
              <StyledOptions>
                <Button size="medium" color="inherit" onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  onClick={() => {
                    handleEventComment({ comment: '' });
                  }}
                  size="medium"
                  color="inherit"
                  disabled={!allowRemoveButton || readonly}
                >
                  Remover
                </Button>
              </StyledOptions>
              <LargeButton
                type="submit"
                size="medium"
                color="inherit"
                disabled={isSubmitting || readonly}
              >
                Salvar
              </LargeButton>
            </Form>
          );
        }}
      </Formik>
    </StyledSimpleModal>
  );
};

export default CommentModal;
