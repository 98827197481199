import { EventSubCategoryModel, MeasurementModel } from '@cuidador/database';
import React from 'react';
import BloodGlycemiaInput from './BloodGlycemiaInput';
import BloodPressureMeasurementInput from './BloodPressureMeasurementInput';
import BodyTemperatureInput from './BodyTemperatureInput';
import BreathRateInput from './BreathRateInput';
import HeartRateInput from './HeartRateInput';
import OximetryRateInput from './OximetryRateInput';
import WeightMeasurementInput from './WeightMeasurementInput';
import { subCategoryIdMapper } from './utils';
import { NewMeasurementValue } from '../CurrentShiftEventList/CurrentShiftCards/ScheduledMeasurementCard/Measurement';

interface VitalSignsMeasurementInputProps {
  subCategory: EventSubCategoryModel;
  patchEvent: (
    newMeasurementValue: NewMeasurementValue,
    status: 'awaiting' | 'accomplished' | 'not_accomplished'
  ) => void;
  value?: string;
  id?: string;
  disabled?: boolean;
  measurement?: MeasurementModel;
}

const VitalSignsMeasurementInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  VitalSignsMeasurementInputProps
> = ({ subCategory, ...props }, ref) => {
  const childrenProps = { ...props, ref };

  if (subCategory.id === subCategoryIdMapper.BLOOD_PRESSURE)
    return <BloodPressureMeasurementInput {...childrenProps} />;
  else if (subCategory.id === subCategoryIdMapper.BODY_TEMPERATURE)
    return <BodyTemperatureInput {...childrenProps} />;
  else if (subCategory.id === subCategoryIdMapper.BLOOD_GLYCEMIA)
    return <BloodGlycemiaInput {...childrenProps} />;
  else if (subCategory.id === subCategoryIdMapper.BREATH_RATE)
    return <BreathRateInput {...childrenProps} />;
  else if (subCategory.id === subCategoryIdMapper.HEART_RATE)
    return <HeartRateInput {...childrenProps} />;
  else if (subCategory.id === subCategoryIdMapper.OXIMETRY_RATE)
    return <OximetryRateInput {...childrenProps} />;
  else if (subCategory.id === subCategoryIdMapper.WEIGHT_MEASURMENT)
    return <WeightMeasurementInput {...childrenProps} />;

  return <></>;
};

export default React.forwardRef(VitalSignsMeasurementInput);
