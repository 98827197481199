export const SUB_CATEGORY_ENUM = {
  GLICEMY: 35230,
  BLOOD_PRESSURE: 35231,
  OXIMETRY: 35232,
  WEIGHT: 35233,
  HEIGHT: 35234,
  HEART_RATE: 35235,
  BREATH_RATE: 35236,
  TEMPERATURE: 35237,
  PAIN: 35238,
  URINARY_ELIMINATION: 35297,
  EVACUATION: 35298,
  HIDRIC_INGESTION: 35299,
  ABDOMINAL_CIRCUMFERENCE: 35300,
};

export default SUB_CATEGORY_ENUM;
