import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import FormikTextField from '../../../components/Forms/FormikTextField';
import ProfilePicture from '../../../components/ProfilePicture';
import StyledButton from '../../../components/StyledButton';
import { AuthContext } from '../../../contexts/auth';
import useCanAccess from '../../../hooks/useCanAccess';
import useCaregiver from '../../../hooks/useCaregiver';
import useUser from '../../../hooks/useUser';
import { resolveErrorMessage } from '../../../utils/error';
import { cpfMask, phoneMask } from '../../../utils/inputs';
import { Backdrop, BackdropCircularProgress } from '../styles';
import { Container, FooterContainer } from './styles';
import {
  formDataToCaregiverModel,
  FormValues,
  userModelToFormData,
  validationSchema,
} from './utils';

const Personal: React.FC = () => {
  const [imageUrl, setImageUrl] = useState('');
  const { userInfo, loading, refreshUserInfo } = useContext(AuthContext);
  const { patch } = useCaregiver();
  const { getProfilePicture, uploadProfilePicture } = useUser();
  const formikRef = useRef<FormikProps<FormValues> | null>();
  const { isAllowedToUpdate: isAllowedToUpdateCaregiver } = useCanAccess(
    'user/caregiver'
  );
  const { isAllowedToUpdate: isAllowedToUpdateProfilePicture } = useCanAccess(
    'media/profile-picture'
  );

  const handleGetProfilePicture = async (id: number) => {
    try {
      return await getProfilePicture(id).then(({ data }) => data?.signedUrl);
    } catch (err) {
      const displayMessage = resolveErrorMessage(err);
      if (displayMessage !== 'Arquivo não encontrado.') {
        toast.error(displayMessage);
      }
    }
  };

  useEffect(() => {
    async function fetchPatientProfilePicture() {
      const result = await handleGetProfilePicture(
        parseInt(`${userInfo!.user?.id}`)
      );
      if (result) {
        setImageUrl(result);
      }
    }

    fetchPatientProfilePicture();
    refreshUserInfo();
  }, []);

  const userData = userInfo?.user;
  const formInitialValue = userModelToFormData(userData!);

  const handleSubmit = (values: FormValues) => {
    return Promise.all([
      isAllowedToUpdateProfilePicture && values.picture
        ? uploadProfilePicture(values.picture, userInfo!.id!)
        : undefined,
      patch(formDataToCaregiverModel(values)),
    ])
      .then(() => {
        // reset picture field
        formikRef.current?.setFieldValue('picture', undefined);
        toast.success('Dados alterados com sucesso!');
        return refreshUserInfo();
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  if (loading) {
    return (
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    );
  }

  return (
    <>
      <Container>
        <Formik
          innerRef={(ref) => (formikRef.current = ref)}
          validationSchema={validationSchema}
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
          validateOnChange={false}
        >
          {(formik) => (
            <Form noValidate={true}>
              <ProfilePicture
                onChange={(file) => formik.setFieldValue('picture', file)}
                initialImage={imageUrl}
              />
              <FormikTextField
                color="primary"
                name="name"
                label="Nome completo"
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'name' }}
              />
              <FormikTextField
                color="primary"
                name="cpf"
                label="CPF"
                type="tel" // numeric keyboard without parsing to number
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'cpf' }}
                value={cpfMask(formik.values.cpf)}
                disabled
              />
              <FormikTextField
                color="primary"
                name="email"
                type="email"
                label="E-mail"
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'email' }}
              />
              <FormikTextField
                color="primary"
                name="phoneNumber"
                label="Celular"
                type="tel" // numeric keyboard without parsing to number
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'phoneNumber' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue(
                    'phoneNumber',
                    phoneMask(e.target.value)
                  );
                }}
              />
              <FooterContainer>
                <StyledButton
                  disabled={!isAllowedToUpdateCaregiver || formik.isSubmitting}
                  type="submit"
                  size="large"
                  color="primary"
                >
                  Salvar
                </StyledButton>
              </FooterContainer>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default Personal;
