import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import MadeBy from '../../components/MadeBy';
import Button from '../../components/StyledButton';
import { AuthContext } from '../../contexts/auth';
import { resolveErrorMessage } from '../../utils/error';
import { cpfMask, nonAlphanumericString } from '../../utils/inputs';

import LoadingBackdrop from '../../components/LoadingBackdrop';
import {
  Background,
  BackgroundOverlay,
  Container,
  Logo,
  OutterContainer,
  StyledTextField,
  TypographyContainer,
} from './styles';
import { formInitialValues, FormValues, validationSchema } from './utils';

const Login: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const formikRef = useRef<FormikProps<FormValues> | null>();
  const history = useHistory()

  const { signIn, error, loading } = useContext(AuthContext);

  useEffect(() => {
    if (error) {
      if (error.response?.data.message === 'Wrong credentials') {
        toast.dismiss();
        toast.error(
          'Identificação e/ou senha não reconhecidos. Se você for gestor, verifique se o link está correto.'
        );
        formikRef.current?.setFieldError(
          'password',
          'Senha incorreta ou usuário não encontrado'
        );
      } else {
        toast.error(resolveErrorMessage(error));
      }
    }
  }, [error]);

  useEffect(() => {
    if (!error && redirect) {
      history.replace('/gestao/pacientes')
    }
  }, [redirect])

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    const formattedCpf = nonAlphanumericString(values.cpf);
    setSubmitting(true);
    await signIn(formattedCpf, values.password).then(() => setRedirect(true))
  };

  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <OutterContainer>
      <LoadingBackdrop loading={Boolean(loading)} />
      <Container>
        <Logo />
        <TypographyContainer>
          <Typography>Insira as informações para acessar sua conta!</Typography>
        </TypographyContainer>
        <Formik
          innerRef={(ref) => (formikRef.current = ref)}
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              <StyledTextField
                name="cpf"
                label="CPF"
                type="tel" // numeric keyboard without parsing to number
                value={cpfMask(formik.values.cpf)}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.cpf)}
                helperText={formik.errors.cpf}
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'cpf' }}
              />
              <StyledTextField
                name="password"
                type={showPassword ? 'text' : 'password'}
                label="Senha"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={Boolean(formik.errors.password)}
                helperText={formik.errors.password}
                margin="normal"
                fullWidth
                inputProps={{ 'data-testid': 'password' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                data-testid="submit"
                size="large"
                color="inherit"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Entrar
              </Button>
            </Form>
          )}
        </Formik>
        <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
        <MadeBy mainColor="white" />
      </Container>
      <BackgroundOverlay>
        <Background />
      </BackgroundOverlay>
    </OutterContainer>
  );
};

export default Login;
