import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/MoreVert';
import { Menu, MenuProps, withStyles } from '@material-ui/core';
import {
  MenuButton,
  ButtonContainer,
  Container,
  MenuSection,
  StyledMenuItem,
  Text,
  TextSection,
  Title,
  ClockIcon,
} from './styles';
import { ShiftExecutionModel, ShiftModel } from '@cuidador/database';
import {
  getShiftExecutionRealTime,
  isCaregiverExecutionOwner,
  isExecutionContested,
} from '../utils';
import { AuthContext } from '../../../contexts/auth';
import { addDays, endOfMonth, isBefore } from 'date-fns';

interface ShiftExecutionCardTypes {
  index: number;
  execution: ShiftExecutionModel;
  caregiverId: ShiftExecutionModel['caregiverId'];
  shiftId: ShiftModel['id'];
}

const StyledMenu = withStyles({
  paper: {
    border: 'none',
    backgroundColor: 'transparent',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

export const ShiftExecutionCard: React.FC<ShiftExecutionCardTypes> = ({
  index,
  execution,
  caregiverId,
  shiftId,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { userInfo } = useContext(AuthContext);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsExpanded(true);
  };

  const history = useHistory();

  const handleClose = () => {
    setAnchorEl(null);
    setIsExpanded(false);
  };

  const handleRedirectToExecutionHistoryContestation = (
    shiftId: number,
    executionId: number
  ) => {
    history.push(`/correcao-de-horarios/historico/${shiftId}/${executionId}`);
  };

  const handleRedirectToCreateContestation = (
    shiftId: number,
    executionId: number
  ) => {
    history.push(
      `/correcao-de-horarios/plantao/${shiftId}/execucao/${executionId}/novo`
    );
  };

  const shouldBeAbleToContestTime = useMemo(() => {
    const { timeContestations, createdAt, caregiverId, isAnnulled } = execution;

    if (!createdAt) return false;

    const hasPreviouslyContested =
      !!timeContestations &&
      timeContestations.length > 0 &&
      timeContestations.find(({ createdById }) => createdById === userInfo?.id);

    const endOfContestPeriod = addDays(endOfMonth(new Date(createdAt)), 1);

    const now = new Date();

    return (
      !hasPreviouslyContested &&
      !isAnnulled &&
      caregiverId === userInfo?.id &&
      isBefore(now, endOfContestPeriod)
    );
  }, [execution, userInfo]);

  return (
    <Container data-testid={`shift-execution-card-${index}`}>
      <TextSection>
        <Title data-testid="shift-execution-card-title">
          Execução {index + 1}{' '}
          {isExecutionContested(execution) && (
            <ClockIcon data-testid="shift-execution-card-clock-icon" />
          )}
        </Title>
        <Text
          data-testid="shift-execution-card-text"
          strikethrough={!!execution.isAnnulled}
        >
          <b>{getShiftExecutionRealTime(execution)}</b>
        </Text>
      </TextSection>
      {isCaregiverExecutionOwner(execution, caregiverId) && (
        <MenuSection>
          <ButtonContainer $isExpanded={isExpanded}>
            <MenuButton
              aria-controls="button-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              onClick={handleClick}
              data-testid={'shift-execution-card-menu-button'}
            >
              <MenuIcon fontSize="small" />
            </MenuButton>
            <StyledMenu
              id="button-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              data-testId="shift-execution-menu-list"
            >
              {!!shouldBeAbleToContestTime && (
                <StyledMenuItem
                  onClick={() => {
                    handleRedirectToCreateContestation(
                      Number(shiftId),
                      Number(execution.id)
                    );
                  }}
                >
                  Corrigir horários
                </StyledMenuItem>
              )}
              <StyledMenuItem
                onClick={() =>
                  handleRedirectToExecutionHistoryContestation(
                    Number(shiftId),
                    Number(execution.id)
                  )
                }
              >
                Histórico
              </StyledMenuItem>
            </StyledMenu>
          </ButtonContainer>
        </MenuSection>
      )}
    </Container>
  );
};
