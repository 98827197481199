import styled from 'styled-components';
import StyledButton from '../../components/StyledButton';
import { IconButton, Typography } from '@material-ui/core';
import { ChevronLeftRounded, History } from '@material-ui/icons';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline: ${({ theme }) => theme.spacing(4)}px;
`;

export const HeaderTitle = styled(Typography)`
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Title = styled(Typography)`
  margin-block: ${({ theme }) => theme.spacing(1)}px;
`;

export const ShiftHistoryButton = styled(StyledButton)`
  color: ${({ theme }) => theme.palette.info.dark};
  font-weight: bold;
  align-self: flex-start;
  padding-inline: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)}px;
`;

export const HistoryIcon = styled(History)`
  margin-right: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const StyledBackIcon = styled(ChevronLeftRounded)`
  font-size: 40px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  margin: 0;
`;

export const IconBackground = styled(Link)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  width: ${({ theme }) => theme.spacing(3.5)}px;
  height: ${({ theme }) => theme.spacing(3.5)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0.5px 1.5px;
`;

export const PhotoElement = styled.div`
  display: flex;
  justify-content: center;
`;

export const CenterContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 4fr auto;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-grow: 1;
  margin-inline: ${({ theme }) => theme.spacing(1.55)}px;
  max-width: 350px;
  width: 100%;

  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr 4fr 0.9fr;
  }
`;
