import * as yup from 'yup';
import { validateCpf } from '../../utils/inputs'

export const validationSchema = yup.object().shape({
  cpf: yup.string().required('CPF é obrigatório')
  .test('isCpfValid', 'Por favor, insira um CPF válido', (value) =>
    validateCpf(value || '')
  ),
  password: yup
    .string()
    .min(8, 'A senha deve conter pelo menos 8 caracteres')
    .required('Senha é obrigatória'),
});

export const formInitialValues = {
  cpf: '',
  password: '',
};

export interface FormValues {
  cpf: string;
  password: string;
}
