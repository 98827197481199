import { AxiosInstance } from 'axios';
import { createOfflineProxy } from '../../config/axios/offlineProxy';

export const createOfflinePostCaregiver = (axios: AxiosInstance) =>
  createOfflineProxy(axios, 'post', async () => {
    return {
      data: {},
      statusCode: 200,
    };
  });
