import React from 'react';
import { Typography } from '@material-ui/core';
import { Container, StyledOldManIllustration } from './styles';

const UnregistredShiftsMessage: React.FC = () => {
  return (
    <Container>
      <StyledOldManIllustration data-testid="old-man-illustration" />
      <Typography>Não há nenhum plantão agendado</Typography>
    </Container>
  );
};

export default UnregistredShiftsMessage;
