import React from 'react'
import PscIcon from '../Icons/PscIcon'
import { useHistory } from 'react-router-dom'
import { IconButtonStyled } from '../IconButtonStyled'

export function PscButton () {
  const history = useHistory()

  return (
    <IconButtonStyled
      icon={<PscIcon />}
      onClick={() => history.push('/pessoas-sob-cuidado')}
      backgroundColor='white'
    />
  )
}
