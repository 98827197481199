import { ShiftModel } from '@cuidador/database';
import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/MoreVert';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../contexts/auth';
import { ShiftInProgressContext } from '../../../contexts/ShiftInProgress';
import useCanAccess from '../../../hooks/useCanAccess';
import useShift from '../../../hooks/useShift';
import {
  caregiverOwnAtLeastOneShiftExecution,
  isShiftDetailsVisible,
} from '../utils';
import {
  ButtonContainer,
  StyledMenuItem,
  ShiftDetailsIcon,
  TimeCorrectionIcon,
} from './styles';

const StyledMenu = withStyles({
  paper: {
    border: 'none',
    backgroundColor: 'transparent',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const MenuButton: React.FC<{ shift: ShiftModel }> = ({ shift }) => {
  const history = useHistory();
  const { showContestationPageItem } = useShift();
  const { userInfo } = useContext(AuthContext);
  const { shiftInProgress } = useContext(ShiftInProgressContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const caregiverId = Number(userInfo?.user?.id);
  const {
    isAllowedToRead: isAllowedToReadShiftTimeContestation,
  } = useCanAccess('care/shift/execution/time-contestation');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsExpanded(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsExpanded(false);
  };

  const redirectToShiftDetails = () =>
    history.push(`/historico/plantao/${shift.id}`);

  const redirectToShiftExecutionsContestation = () =>
    history.push(`/correcao-de-horarios/plantao/${shift.id}`);

  const showShiftDetailsItem = isShiftDetailsVisible(
    shift,
    userInfo!.user!,
    shiftInProgress
  );

  const showTimeContestationReadItem =
    !!caregiverOwnAtLeastOneShiftExecution(shift, caregiverId) &&
    isAllowedToReadShiftTimeContestation;

  if (
    !showShiftDetailsItem &&
    !showTimeContestationReadItem &&
    !showContestationPageItem(shift)
  ) {
    // If no item is visible, don't show menu button
    return null;
  }

  return (
    <ButtonContainer
      $isExpanded={isExpanded}
      data-testid={`menu-button-${shift.id}`}
    >
      <Button
        aria-controls="button-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <MenuIcon fontSize="small" />
      </Button>
      <StyledMenu
        id="button-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {showShiftDetailsItem && (
          <StyledMenuItem onClick={redirectToShiftDetails}>
            <ShiftDetailsIcon />
            Detalhes do plantão
          </StyledMenuItem>
        )}
        {showContestationPageItem(shift) && (
          <StyledMenuItem onClick={redirectToShiftExecutionsContestation}>
            <TimeCorrectionIcon />
            Correção de horários
          </StyledMenuItem>
        )}
      </StyledMenu>
    </ButtonContainer>
  );
};

export default MenuButton;
