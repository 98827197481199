import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { CollapsibleCardContentProps } from '.';

type BackgroundVariant = Pick<CollapsibleCardContentProps, 'backgroundVariant'>;

type Content = BackgroundVariant & {
  maxLines?: number;
};

export const CardActionAreaCentered = styled(Button)<BackgroundVariant>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  color: ${({ theme, backgroundVariant }) =>
    backgroundVariant === 'primary'
      ? theme.palette.background.paper
      : theme.palette.grey[400]};
  background-color: ${({ theme, backgroundVariant }) =>
    backgroundVariant === 'primary'
      ? theme.palette.secondary.dark
      : 'transparent'};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  height: ${({ theme }) => theme.spacing(2)}px;
  font-size: 12px;
`;

export const CollapseCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const Divider = styled.div<BackgroundVariant>`
  border-top: 1px solid
    ${({ theme, backgroundVariant }) =>
      backgroundVariant === 'primary'
        ? theme.palette.background.paper
        : theme.palette.grey[400]};
  width: 90%;
  height: 0;
  margin: ${({ theme }) => theme.spacing(2)}px 0
    ${({ theme }) => theme.spacing(1)}px 5%;
`;

export const CardContentColorVariant = styled.div<Content>`
  color: ${({ theme, backgroundVariant }) =>
    backgroundVariant === 'primary'
      ? theme.palette.background.paper
      : theme.palette.text.primary};
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  ${({ maxLines }) => (!maxLines ? '' : `-webkit-line-clamp: ${maxLines};`)}
`;
