import { Patient } from '@cuidador/database'
import * as Yup from 'yup'
import { FormValues } from '.'
import {
  bloodGlycemiaAdditionalDetails,
  BloodGlycemiaAdditionalDetailsType,
  staticCareLineIdMapper,
  subCategoryIdMapper,
  validateBloodGlycemia,
  validateBloodPressure,
  validateBodyTemperature,
  validateBreathRate,
  validateHeartRate,
  validateOxymetryInput,
} from '../../VitalSignsMeasurementInput/utils'

export const validationSchema = Yup.object().shape({
  measurementValue: Yup.string().required(
    'Por favor, insira um valor para a medição.'
  ),
  subCategoryId: Yup.string().required('Por favor, insira o tipo.'),
  description: Yup.string().max(
    2000,
    'O comentário deve conter no máximo 2000 caracteres.'
  ),
  additionalDetails: Yup.string()
    .ensure()
    .when('subCategoryId', {
      is: String(subCategoryIdMapper.BLOOD_GLYCEMIA),
      then: Yup.string()
        .oneOf(
          Object.keys(bloodGlycemiaAdditionalDetails),
          'Por favor, preencha os detalhes adicionais.'
        )
        .required(),
    }),
})

export const validateSubCategory = (
  formValues: FormValues,
  patientData: Patient | undefined
) => {
  const subCategoryId = Number(formValues.subCategoryId)

  if (subCategoryId === subCategoryIdMapper.BLOOD_GLYCEMIA) {
    const hasDiabetes = !!patientData?.staticCareLines?.some(
      (scl) =>
        ['dysfunctional', 'severe_dysfunctional'].includes(String(scl.classification)) &&
        [staticCareLineIdMapper.DIABETES_TYPE1, staticCareLineIdMapper.DIABETES_TYPE2].includes(scl.careLineId!),
    )
    return validateBloodGlycemia(
      formValues.measurementValue,
      patientData?.dateOfBirth,
      hasDiabetes,
      formValues.additionalDetails as BloodGlycemiaAdditionalDetailsType,
    )
  } else if (subCategoryId === subCategoryIdMapper.BODY_TEMPERATURE) {
    return validateBodyTemperature(formValues.measurementValue)
  } else if (subCategoryId === subCategoryIdMapper.BREATH_RATE) {
    return validateBreathRate(formValues.measurementValue)
  } else if (subCategoryId === subCategoryIdMapper.HEART_RATE) {
    return validateHeartRate(formValues.measurementValue)
  } else if (subCategoryId === subCategoryIdMapper.OXIMETRY_RATE) {
    return validateOxymetryInput(formValues.measurementValue)
  } else if (subCategoryId === subCategoryIdMapper.BLOOD_PRESSURE) {
    return validateBloodPressure(formValues.measurementValue, formValues.symptomList?.length || 0)
  } else if (subCategoryId === subCategoryIdMapper.WEIGHT_MEASURMENT) {
    return undefined
  }

  return undefined
}
