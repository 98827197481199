import React from 'react';
import InternetConnectionErrorBanner from '../../InternetConnectionCheck';

import {
  Container,
  StyledDiv,
  StyledElipseBlueBot,
  StyledElipseBlueTop,
  StyledElipseGreenBot,
  StyledElipseGreenTop,
  Toolbar,
} from './styles';

export type HeaderProps = {
  leftContent?: React.ReactNode;
  centerContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  withBackgroundShapes?: boolean;
};

const HeaderBase: React.FC<HeaderProps> = ({
  leftContent,
  centerContent,
  rightContent,
  withBackgroundShapes = true,
  children,
}) => {
  return (
    <Container>
      <Toolbar>
        {leftContent}
        {centerContent}
        {rightContent}
      </Toolbar>
      {withBackgroundShapes && (
        <StyledDiv>
          <StyledElipseBlueTop />
          <StyledElipseGreenTop />
          <StyledElipseBlueBot />
          <StyledElipseGreenBot />
        </StyledDiv>
      )}
      <InternetConnectionErrorBanner />
      {children}
    </Container>
  );
};

export default HeaderBase;
