import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const StepDot = styled.div<{ $active: boolean }>`
  height: 6px;
  width: 6px;
  margin: 3px;
  border-radius: 50%;
  background-color: ${({ $active, theme }) =>
    $active ? theme.palette.common.white : 'transparent'};
  border: solid 1px ${({ theme }) => theme.palette.common.white};
`;
