import React, { useContext } from 'react';
import {
  Route,
  Redirect,
  RouteComponentProps,
  RouteProps,
  useLocation,
} from 'react-router-dom';

import { AuthContext } from '../contexts/auth';

interface UnauthenticatedRouteProps extends RouteProps {
  component: React.FC<RouteComponentProps>;
}

interface LocationType {
  from: { pathname: string };
}

const UnauthenticatedRoute = ({
  component: Component,
  ...rest
}: UnauthenticatedRouteProps): JSX.Element => {
  const { user } = useContext(AuthContext);
  const { state } = useLocation<LocationType>();
  return (
    <Route
      {...rest}
      render={(props) =>
        !Boolean(user) ? (
          <Component {...props} />
        ) : (
          <Redirect to={state?.from?.pathname || '/'} />
        )
      }
    />
  );
};

export default UnauthenticatedRoute;
