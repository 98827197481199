import styled, { css } from 'styled-components';
import { Typography } from '@material-ui/core';
import { WatchLater, DateRange } from '@material-ui/icons';

const iconBaseStyles = css`
  color: ${({ theme }) => theme.palette.primary.dark};
  height: 20px;
  width: 20px;
`;

export const CardContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-inline: ${({ theme }) => theme.spacing(2)}px;
  width: 100%;
  max-width: 800px;
`;

export const HeaderContainer = styled(CardContainer)`
  margin-block: ${({ theme }) => theme.spacing(2, 1)};
`;

export const OriginalSchedulesContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[100]};
  padding-block: ${({ theme }) => theme.spacing(2)}px;
`;

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const CardItemJustify = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const CardTimeText = styled(Typography)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const CardTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};

  & ~ div {
    margin-left: ${({ theme }) => theme.spacing(1.5)}px;
  }
`;

export const StyledTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.dark};
  text-align: center;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const StyledTypography = styled(Typography)`
  font-size: 0.95rem;
`;

export const StyledCreatedBy = styled(StyledTypography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Divider = styled.hr`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  border: 1px solid ${({ theme }) => theme.palette.primary.dark};
  margin: ${({ theme }) => theme.spacing(0.5)}px 0;
  height: 0.2px;
`;

export const NoRegistrationsText = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  text-align: center;
  margin-block: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledClockIcon = styled(WatchLater)`
  ${iconBaseStyles}
`;

export const StyledCalendarIcon = styled(DateRange)`
  ${iconBaseStyles}
`;
