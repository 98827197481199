import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().optional(),
  subCategoryId: Yup.string().required('Por favor, insira o tipo.'),
  description: Yup.string().max(
    2000,
    'A descrição deve conter no máximo 2000 caracteres.'
  ),
});
