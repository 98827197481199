import { ShiftExecutionModel, ShiftModel } from '@cuidador/database';
import { addMinutes } from 'date-fns';
import React, { createContext, useContext, useEffect, useState } from 'react';
import useShift from '../hooks/useShift';
import { AuthContext } from './auth';
import { CurrentShiftDataProvider } from './CurrentShiftData';

export interface ContextProps {
  shiftInProgress?: ShiftModel | null;
  executionInProgress?: ShiftExecutionModel | null;
  refreshShiftInProgress: () => Promise<void>;
  setExecutionInProgress: (value: ShiftExecutionModel | null) => void;
  loading?: boolean;
  minShiftLimitDate?: Date;
  maxShiftLimitDate?: Date;
}

const TOLERANCE_LIMIT_BEFORE_SHIFT = -5;
const TOLERANCE_LIMIT_AFTER_SHIFT = 10;

export const ShiftInProgressContext = createContext<ContextProps>({
  loading: true,
} as ContextProps);

export const ShiftInProgressProvider: React.FC = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { getShiftInProgressWithExecutionInProgress } = useShift();
  const [loading, setLoading] = useState(true);
  const [shiftInProgress, setShiftInProgress] = useState<ShiftModel | null>(
    null
  );
  const [
    executionInProgress,
    setExecutionInProgress,
  ] = useState<ShiftExecutionModel | null>(null);

  const minShiftLimitDate = shiftInProgress?.plannedToStartAt
    ? addMinutes(
        new Date(shiftInProgress.plannedToStartAt),
        TOLERANCE_LIMIT_BEFORE_SHIFT
      )
    : undefined;

  const maxShiftLimitDate = shiftInProgress?.plannedToEndAt
    ? addMinutes(
        new Date(shiftInProgress.plannedToEndAt),
        TOLERANCE_LIMIT_AFTER_SHIFT
      )
    : undefined;

  useEffect(() => {
    if (user) refreshShiftInProgress();
  }, [user]);

  const refreshShiftInProgress = () => {
    return getShiftInProgressWithExecutionInProgress()
      .then((shiftInProgress) => {
        if (shiftInProgress && shiftInProgress.executionInProgress) {
          setShiftInProgress(shiftInProgress);
          setExecutionInProgress(shiftInProgress.executionInProgress);
        } else {
          setShiftInProgress(null);
          setExecutionInProgress(null);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ShiftInProgressContext.Provider
      value={{
        shiftInProgress,
        executionInProgress,
        refreshShiftInProgress,
        setExecutionInProgress,
        loading,
        minShiftLimitDate,
        maxShiftLimitDate,
      }}
    >
      {shiftInProgress && executionInProgress ? (
        <CurrentShiftDataProvider>{children}</CurrentShiftDataProvider>
      ) : (
        children
      )}
    </ShiftInProgressContext.Provider>
  );
};

export default ShiftInProgressProvider;
