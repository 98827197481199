import Typography from '@material-ui/core/Typography';
import { Language, Launch, Phone, WhatsApp } from '@material-ui/icons';
import React from 'react';
import { useTheme } from 'styled-components';
import { phoneMask } from '../../utils/inputs';
import {
  CardBox,
  Contact,
  ContactText,
  Instruction,
  LinksContainer,
  StyledOpenLinkContainer,
  StyledOpenWhatsappContainer,
  StyledPhoneContainer,
  SubTitle,
  Title,
  TypographyContainer,
} from './styles';

interface CardProps {
  id?: string | number;
  name?: string;
  relevantInformation?: string;
  callingCondition?: string;
  warning?: boolean;
  support?: boolean;
  phoneNumber?: string;
  link?: string;
  isWhatsapp?: boolean;
}

const ContactsCard: React.FC<CardProps> = ({
  id,
  name,
  relevantInformation,
  callingCondition,
  warning,
  support,
  phoneNumber,
  link,
  isWhatsapp,
}) => {
  const theme = useTheme();
  const color = warning
    ? `${theme.palette.warning.light}`
    : support
    ? `${theme.palette.extra.color.grey.light}`
    : `${theme.palette.common.white}`;

  const maskedPhoneNumber = warning ? phoneNumber : phoneMask(`${phoneNumber}`);

  return (
    <CardBox
      data-testid={`contactsCard-${id}`}
      style={{ backgroundColor: color }}
    >
      <TypographyContainer>
        <Title variant="subtitle1">{name}</Title>

        {callingCondition && (
          <div>
            <SubTitle variant="subtitle2">
              Em qual caso entrar em contato?
            </SubTitle>
            <Instruction variant="body2">{callingCondition}</Instruction>
          </div>
        )}

        {relevantInformation && (
          <div>
            <SubTitle variant="subtitle2">Observação:</SubTitle>
            <Instruction variant="body2">{relevantInformation}</Instruction>
          </div>
        )}

        {phoneNumber && (
          <Contact>
            <Phone />
            <ContactText variant="body1">{maskedPhoneNumber}</ContactText>
          </Contact>
        )}
        {link && (
          <Contact>
            <Language />
            <ContactText variant="body1">
              {link.replace(/https?:\/\//, '') + '/consulta'}
            </ContactText>
          </Contact>
        )}
      </TypographyContainer>
      <LinksContainer>
        {phoneNumber && (
          <>
            <StyledPhoneContainer href={`tel:${phoneNumber}`}>
              <Phone />
              <Typography variant="body1">{'Ligar'}</Typography>
            </StyledPhoneContainer>

            {!!isWhatsapp && (
              <StyledOpenWhatsappContainer
                onClick={() =>
                  window.open(
                    `https://api.whatsapp.com/send/?phone=${phoneNumber}`,
                    '_blank'
                  )
                }
              >
                <WhatsApp />
                Whatsapp
              </StyledOpenWhatsappContainer>
            )}
          </>
        )}
        {link && (
          <StyledOpenLinkContainer onClick={() => window.open(link, '_blank')}>
            <Launch />
            Abrir Link
          </StyledOpenLinkContainer>
        )}
      </LinksContainer>
    </CardBox>
  );
};

export default ContactsCard;
