import {
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(2)}px;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.primary.dark};
  padding-bottom: ${({ theme }) => theme.spacing(11)}px;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const TitleContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  text-align: left;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};

  div {
    margin-top: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const StyledTypographyTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  font-weight: bold;
`;

export const StyledTypographyAvatar = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledTypographySubTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
`;

export const TypographyWithIcon = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  display: flex;
  align-items: center;

  svg {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const ShiftPatientContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;

export const StyledTypographyAdditionalCoverage = styled(Typography)`
  color: ${({ theme }) => theme.palette.warning.dark};
`;
