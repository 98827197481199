import styled from 'styled-components';
import { Grid, Typography, Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

export const Container = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-block: ${({ theme }) => theme.spacing(1)}px;
`;

export const TextSection = styled.section`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  display: flex;
  align-items: center;
`;

export const ClockIcon = styled(AccessTimeIcon)`
  max-width: 16px;
  max-height: 16px;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const Text = styled(Typography)<{ strikethrough?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ strikethrough }) => !!strikethrough && 'text-decoration: line-through;'}

  b {
    margin-right: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;

export const MenuSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 60px;
`;

export const ButtonContainer = styled.div<{ $isExpanded?: boolean }>`
  ${({ $isExpanded }) =>
    $isExpanded
      ? `
          &::before {
            content: '';
            position: fixed;
            top: 0; right: 0; bottom: 0; left: 0;
            background-color: black;
            opacity: 0.4; 
          }
          `
      : ''}
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const MenuButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing(0.5, 0.1)};
  min-width: 38px;
  min-height: 38px;
  background-image: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.palette.primary.main},
    ${({ theme }) => theme.palette.secondary.dark}
  );
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
  background-image: linear-gradient(
    to bottom right,
    ${({ theme }) => theme.palette.primary.main},
    ${({ theme }) => theme.palette.secondary.dark}
  );
  margin-bottom: ${({ theme }) => theme.spacing(0.75)}px;
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  font-weight: bold;
  min-height: initial;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.7;
  }
`;
