import styled from 'styled-components';
import LinearProgress from '@material-ui/core/LinearProgress';

const StyledLinearProgress = styled(LinearProgress)`
  position: fixed;
  width: 100%;
  z-index: 1;
`;

export default StyledLinearProgress;
