import { EventModel, EventSubCategoryModel } from '@cuidador/database';
import { Typography } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import { AuthContext } from '../../contexts/auth';
import { isSelfCare } from '../../contexts/permission';
import AppointmentCard from './CurrentShiftCards/AppointmentCard';
import ComplicationCard from './CurrentShiftCards/ComplicationCard';
import MeasurementCard from './CurrentShiftCards/MeasurementCard';
import MedicationCard from './CurrentShiftCards/MedicationCard';
import RoutineCard from './CurrentShiftCards/RoutineCard';
import ScheduledMeasurementCard from './CurrentShiftCards/ScheduledMeasurementCard';
import { formatEvents } from './CurrentShiftCards/ScheduledMeasurementCard/utils';
import { CardDiv, Container, TypographyContainer } from './styles';

// todo: export from @cuidador/database
export const categoryIdMapper = {
  GENERAL: 1,
  MEDICATION: 2,
  COMPLICATION: 3,
  MEASUREMENT: 4,
};

export type CurrentShiftEventListProps = {
  events: EventModel[];
  onChangeStatus: (id: number, newStatus: EventModel['status']) => void;
  onChangeComment: (id: number, newComment: string) => void;
  onChangeTime: (id: number, updatedTimeHappensAt: string | null) => void;
};

export type FormattedEvents = EventModel & {
  scheduledMeasurements?: EventModel[];
};

const CurrentShiftEventList: React.FC<CurrentShiftEventListProps> = ({
  events,
  onChangeStatus,
  onChangeComment,
  onChangeTime,
}) => {
  const { userInfo } = useContext(AuthContext);
  const renderActivityCardByEvent = (event: FormattedEvents) => {
    const subCategory = event.subCategory as EventSubCategoryModel;
    switch (subCategory?.categoryId) {
      case categoryIdMapper['MEDICATION']:
        return (
          <CardDiv key={event.id}>
            <MedicationCard //[TODO] Add status and eventNotifiedAt
              {...event}
              name={subCategory.name}
              dosageFormat={event.medication?.dosageFormat}
              dosageQuantity={event.medication?.dosageQuantity}
              administeredBy={event.medication?.administeredBy}
              medicalNotes={event.medication?.medicalNotes}
              caregiverName={event.caregiver?.user?.name}
              onChangeStatus={onChangeStatus}
              onChangeComment={onChangeComment}
              onChangeTime={onChangeTime}
            />
          </CardDiv>
        );
      case categoryIdMapper['COMPLICATION']:
        return (
          <CardDiv key={event.id}>
            <ComplicationCard
              {...event}
              caregiverName={event.caregiver?.user?.name}
              onChangeComment={onChangeComment}
            />
          </CardDiv>
        );
      case categoryIdMapper['GENERAL']:
        if (subCategory.isRoutine) {
          return (
            <CardDiv key={event.id}>
              <RoutineCard
                {...event}
                caregiverName={event.caregiver?.user?.name}
                onChangeStatus={onChangeStatus}
                onChangeComment={onChangeComment}
                onChangeTime={onChangeTime}
              />
            </CardDiv>
          );
        } else
          return (
            <CardDiv key={event.id}>
              <AppointmentCard
                {...event}
                caregiverName={event.caregiver?.user?.name}
                onChangeStatus={onChangeStatus}
                onChangeComment={onChangeComment}
                onChangeTime={onChangeTime}
              />
            </CardDiv>
          );
      case categoryIdMapper['MEASUREMENT']:
        if (event.scheduledMeasurements) {
          const key = event.scheduledMeasurements[0]?.eventScheduleId;
          return (
            <CardDiv key={key}>
              <ScheduledMeasurementCard
                scheduledMeasurements={event.scheduledMeasurements}
                onChangeComment={(id, { comment }) =>
                  onChangeComment(id, comment)
                }
              />
            </CardDiv>
          );
        } else {
          return (
            <CardDiv key={event.id}>
              <MeasurementCard
                {...event}
                caregiverName={event.caregiver?.user?.name}
                onChangeComment={onChangeComment}
              />
            </CardDiv>
          );
        }
      default:
        return <></>;
    }
  };

  const formattedEvents = useMemo(() => formatEvents(events), [events]);

  const emptyMessage = `Não existe nenhum evento cadastrado para o ${
    isSelfCare(userInfo?.user) ? 'dia' : 'plantão'
  } de hoje.`;

  return (
    <Container>
      {events.length > 0 ? (
        <>
          {formattedEvents.map((event: FormattedEvents) =>
            renderActivityCardByEvent(event)
          )}
        </>
      ) : (
        <TypographyContainer>
          <Typography variant="subtitle1">{emptyMessage}</Typography>
        </TypographyContainer>
      )}
    </Container>
  );
};

export default CurrentShiftEventList;
