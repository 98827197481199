import styled from 'styled-components';
import MuiFab from '@material-ui/core/Fab';
import AvatarEditor from 'react-avatar-editor';

import { hexToRGBArray } from '../../styles/colorMapping';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: center;

  img {
    height: 92px;
    width: 92px;
    border-radius: 50%;
    border: solid 1px ${({ theme }) => theme.palette.common.white};
  }
`;

export const Fab = styled(MuiFab)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  min-height: auto;
  border: solid 1px ${({ theme }) => theme.palette.common.white};
  svg {
    font-size: 18px;
  }
`;

export const StyledEditor = styled(AvatarEditor).attrs((props) => ({
  width: 230,
  height: 230,
  borderRadius: 115, // totally round
  color: [...hexToRGBArray(props.theme.palette.primary.main), 0.8],
}))``;
