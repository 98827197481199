import React from 'react';
import { Typography } from '@material-ui/core';
import { WifiOff } from '@material-ui/icons';

import { Container } from './styles';

const OfflineWarning: React.FC = () => {
  return (
    <Container>
      <WifiOff />
      <Typography variant="h5">Sem conexão</Typography>
      <Typography>Fique online para ter acesso a esse recurso</Typography>
    </Container>
  );
};

export default OfflineWarning;
