import styled, { css } from 'styled-components';
import { ExecutionTimelineSlot } from '..';
import red from '@material-ui/core/colors/red';

export const TimelineContainer = styled.div<{ hasTimestamps: boolean }>`
  padding: ${({ theme, hasTimestamps }) =>
    theme.spacing(0, hasTimestamps ? 4 : 1, 2)};
`;

export const ParentTimeline = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.grey[300]};
  height: 30px;
  width: 100%;
  overflow: hidden;
`;

export const BoundaryTimestamps = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(0, 1, 1)};
`;

export const Timestamp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;

  p {
    margin: 0;
  }
`;

export const ExecutionTimelineSlotBlock = styled.div<{
  timeslot: ExecutionTimelineSlot;
}>`
  position: absolute;
  height: 100%;
  min-width: 2px;
  top: 0;
  ${({ theme, timeslot }) => {
    return timeslot.status === 'occupied'
      ? css`
          background-color: ${theme.palette.primary.main};
          z-index: 1;
        `
      : css`
          background-color: ${red[500]};
          z-index: 2;
        `;
  }}

  left: ${({ timeslot }) => timeslot.startsAtPercentage}%;
  width: ${({ timeslot }) => timeslot.durationPercentage}%;
`;

export const LegendContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: ${({ theme }) => theme.spacing(1.5)}px;
  column-gap: ${({ theme }) => theme.spacing(2)}px;
`;

export const Legend = styled.div`
  display: flex;
  align-items: center;
`;

export const LegendLabel = styled.span``;

export const LegendColor = styled.div<{ color: string }>`
  width: 40px;
  min-width: 40px;
  height: 20px;
  background-color: ${({ color }) => color};
  margin-right: ${({ theme }) => theme.spacing(1)}px;
`;
