import styled from 'styled-components';
import StyledPaper from '../../../StyledPaper';

export const CardBox = styled(StyledPaper)`
  width: 100%;
  display: flex;
  margin: 0;
  min-height: 120px;
  background-color: ${({ theme }) => theme.palette.info.main};
  padding: 0;
`;
export const StatusContainer = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: initial;
  margin: ${({ theme }) => theme.spacing(0, 2, 1, 0)};
  & img {
    margin-left: ${({ theme }) => theme.spacing(2)}px;
  }
`;
