import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ButtonContainer,
  Container,
  PreviousButton,
  ShiftDataContainer,
} from './styles';

interface PreviousOrNextShiftProps {
  startedAt?: string;
  plannedToStartAt?: string;
  shiftIds: number[];
}

const PreviousOrNextShift: React.FC<PreviousOrNextShiftProps> = ({
  startedAt,
  plannedToStartAt,
  shiftIds,
}) => {
  const params = useParams<{ shiftId: string }>();
  const currentShiftId = parseInt(params.shiftId);
  const history = useHistory();
  const getShiftStartTime = () => {
    const startTime = startedAt
      ? format(new Date(startedAt), 'HH:mm')
      : '--:--';
    return startTime;
  };
  const getShiftPlannedStartDate = () => {
    const shiftPlannedStartDate = plannedToStartAt
      ? format(new Date(plannedToStartAt), 'dd/MM/yyyy')
      : '--/--/----';
    return shiftPlannedStartDate;
  };

  const goToPreviousShift = () => {
    const previousShiftId = shiftIds[shiftIds.indexOf(currentShiftId) + 1];
    history.replace(`/historico/plantao/${previousShiftId}`);
  };

  const goToNextShift = () => {
    const nextShiftId = shiftIds[shiftIds.indexOf(currentShiftId) - 1];
    history.replace(`/historico/plantao/${nextShiftId}`);
  };

  const isPreviousShiftButtonDisabled = useMemo(() => {
    const previousShiftId = shiftIds[shiftIds.indexOf(currentShiftId) + 1];
    if (!previousShiftId) {
      return true;
    }
    return false;
  }, [shiftIds, currentShiftId]);

  const isNextShiftButtonDisabled = useMemo(() => {
    const nextShiftId = shiftIds[shiftIds.indexOf(currentShiftId) - 1];
    if (!nextShiftId) {
      return true;
    }
    return false;
  }, [shiftIds, currentShiftId]);

  return (
    <Container>
      <ButtonContainer>
        <PreviousButton
          onClick={goToPreviousShift}
          disabled={isPreviousShiftButtonDisabled}
          data-testid="previous-shift-button"
        >
          <ChevronLeft />
        </PreviousButton>
      </ButtonContainer>

      <ShiftDataContainer>
        <span>{getShiftPlannedStartDate()}</span>
        <span>{getShiftStartTime()}</span>
      </ShiftDataContainer>

      <ButtonContainer>
        <PreviousButton
          onClick={goToNextShift}
          disabled={isNextShiftButtonDisabled}
        >
          <ChevronRight />
        </PreviousButton>
      </ButtonContainer>
    </Container>
  );
};

export default PreviousOrNextShift;
