import React from 'react';
import { ListItemIcon } from '@material-ui/core';
import {
  TextContainer,
  StyledListItem,
  AppItemTitle,
  AppItemLabel,
} from '../styles';

interface AppShortcutProps {
  label: string;
  icon: JSX.Element;
}

const AppShortcut: React.FC<AppShortcutProps> = ({ label, icon }) => {
  return (
    <StyledListItem button>
      <ListItemIcon>{icon}</ListItemIcon>
      <TextContainer>
        <AppItemTitle>Trocar de app</AppItemTitle>
        <AppItemLabel>{label}</AppItemLabel>
      </TextContainer>
    </StyledListItem>
  );
};

export default AppShortcut;
