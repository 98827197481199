import { useContext } from 'react';
import * as Yup from 'yup';
import { AuthContext } from '../../contexts/auth';
import { isSelfCare } from '../../contexts/permission';

export interface FormValues {
  message: string;
}

export interface FormProps {
  subTitle?: string;
  label?: string;
  placeholder?: string;
  buttonLabel?: string;
  // eslint-disable-next-line
  handleChange?: (value: any) => void;
  hiddenButton?: boolean;
}

export const formInitialValues = {
  message: '',
};

export const validationSchema = Yup.object().shape({
  message: Yup.string().max(
    5000,
    'A anotação deve conter no máximo 5000 caracteres.'
  ),
});

export const defaultFormProps = (): FormProps => {
  const { userInfo } = useContext(AuthContext);
  return {
    buttonLabel: 'Adicionar',
    subTitle: 'Anotações e evolução do plantão',
    label: isSelfCare(userInfo?.user)
      ? 'Anotações do dia'
      : 'Anotações do plantão',
    placeholder: isSelfCare(userInfo?.user)
      ? 'Digite todas as informações relevantes deste dia.'
      : 'Digite todas as informações relevantes deste plantão.',
  };
};
