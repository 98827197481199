import { Paper } from '@material-ui/core';
import styled from 'styled-components';

const StyledPaper = styled(Paper).attrs({ elevation: 0 })`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: ${({ theme }) => theme.spacing(2)}px;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000042;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export default StyledPaper;
