import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { oximetryRateMask } from '../../../utils/inputs';
import { Row, StyledInput } from '../styles';
import { validateOxymetryInput } from '../utils';
import { NewMeasurementValue } from '../../CurrentShiftEventList/CurrentShiftCards/ScheduledMeasurementCard/Measurement';
import { parse } from 'path';

interface OximetryRateProps {
  value?: string;
  patchEvent: (
    newMeasurementValue: NewMeasurementValue,
    status: 'awaiting' | 'accomplished' | 'not_accomplished'
  ) => void;
  id?: string;
  disabled?: boolean;
}

const OximetryRate: React.ForwardRefRenderFunction<
  HTMLInputElement,
  OximetryRateProps
> = ({ value, patchEvent, id, disabled }, ref) => {
  const [oximetryRate, setOximetryRate] = useState(value || '');

  const onChangeAnyValue = useDebouncedCallback(
    (newMeasurementValue: NewMeasurementValue) => {
      const newStatus = newMeasurementValue.measurementValue
        ? 'accomplished'
        : 'awaiting';
      patchEvent(newMeasurementValue, newStatus);
    },
    400
  );

  return (
    <Row>
      <StyledInput
        type="text"
        value={oximetryRate}
        onChange={(e) => {
          const formattedValue = oximetryRateMask(
            e.target.value === '0'
              ? ''
              : parseInt(e.target.value) > 100
              ? '100'
              : parseInt(e.target.value).toString()
          );
          const measurementComplication = validateOxymetryInput(formattedValue);
          onChangeAnyValue.callback({
            ...measurementComplication,
            measurementValue: formattedValue,
          });
          setOximetryRate(formattedValue);
        }}
        disabled={disabled}
        ref={ref}
        id={`${id}-oximetry`}
        // The bellow attribute makes the input keyboard be set to only numbers on smartphones.
        pattern="\d*"
      />
      <span>%SaO2</span>
    </Row>
  );
};

export default React.forwardRef(OximetryRate);
