import styled, { css } from 'styled-components';
import { Button, Checkbox, Input, Modal, Typography } from '@material-ui/core';

const makeBackgroundColorWithHover = (color: string) => css`
  background-color: ${color};
  &:hover {
    background-color: ${color};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MultiselectContainer = styled.div`
  max-height: 80vh;
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 10px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 9px;
  background-color: ${({ theme }) => theme.palette.extra.color.yellow};
  color: ${({ theme }) => theme.palette.background.paper};
`;

export const HeaderLeftIcon = styled.div`
  display: flex;
  width: 60px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 36px;
  }
`;

export const HeaderTitle = styled.div`
  display: flex;
  width: 100%;
  margin-right: 60px;
  height: 60px;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  width: 85%;
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledTypography = styled(Typography)`
  text-overflow: clip;
  overflow-x: hidden;
`;

export const StyledCheckbox = styled(Checkbox)`
  padding: 0;
`;

export const StyledInput = styled(Input)`
  width: 100%;
  font-size: ${({ theme }) => theme.typography.fontSize}px;
`;

export const FooterButton = styled(Button)`
  width: 100%;
  ${({ theme }) => makeBackgroundColorWithHover(theme.palette.primary.main)};
  color: ${({ theme }) => theme.palette.background.paper};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
`;
