import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import React, { useContext } from 'react';

import { AuthContext } from '../../contexts/auth';
import { CurrentShiftDataContext } from '../../contexts/CurrentShiftData';
import { isSelfCare } from '../../contexts/permission';
import Button from '../StyledButton';
import { CompletedTasks, Container, LeftContent, Message } from './styles';
import { getTasksSummary } from './utils';

const ShiftHeaderNotification: React.FC<{ onFinishClick: () => void }> = ({
  onFinishClick,
}) => {
  const { userInfo } = useContext(AuthContext);
  const { useEvent, useShiftQuestions } = useContext(CurrentShiftDataContext);
  const { byId: eventsById } = useEvent();
  const { byId: questionsById } = useShiftQuestions();

  const { total, completed } = getTasksSummary(eventsById, questionsById);
  const completedTasksInPercentage = Math.round((completed / total) * 100);

  return (
    <Container $isAlmostComplete={completedTasksInPercentage >= 80}>
      <LeftContent>
        <Message>
          O {isSelfCare(userInfo?.user) ? 'seu dia' : 'plantão'} termina em
          menos de 1 hora.
        </Message>
        {total ? (
          <CompletedTasks>{`${completed}/${total} (${completedTasksInPercentage}%) tarefas completas`}</CompletedTasks>
        ) : null}
      </LeftContent>
      <Button
        data-testid="notification-finish-button"
        color="inherit"
        variant="text"
        size="small"
        onClick={onFinishClick}
        endIcon={<ChevronRightRoundedIcon />}
      >
        FINALIZAR
      </Button>
    </Container>
  );
};

export default React.memo(ShiftHeaderNotification);
