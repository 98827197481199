import { EventModel, EventSubCategoryModel } from '@cuidador/database';
import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { categoryIdMapper } from '../..';
import { resolveErrorMessage } from '../../../../utils/error';
import { subCategoryIdMapper } from '../../../VitalSignsMeasurementInput/utils';

type GroupedMeasurements = {
  scheduledMeasurements: EventModel[];
  subCategory: EventSubCategoryModel | undefined;
};

type FormattedEvent = GroupedMeasurements | EventModel;

export const formatEvents = (events: EventModel[]) => {
  const measurements = events.filter(
    (event) =>
      event.subCategory?.categoryId === categoryIdMapper['MEASUREMENT'] &&
      event.eventScheduleId
  );
  const filteredEvents = events.filter(
    (event) =>
      event.subCategory?.categoryId !== categoryIdMapper['MEASUREMENT'] ||
      !event.eventScheduleId
  );

  const groupedMeasurements = Object.values(
    _.groupBy(measurements, 'eventHappensAt')
  );

  const formattedMeasurements = groupedMeasurements.map((group) => {
    return {
      scheduledMeasurements: group,
      subCategory: group[0].subCategory,
    };
  });

  const formattedEvents: FormattedEvent[] = filteredEvents.concat(
    formattedMeasurements
  );

  const formattedSortedEvents = formattedEvents.sort((event1, event2) => {
    const dateEvent1 =
      'scheduledMeasurements' in event1
        ? event1.scheduledMeasurements[0].eventHappensAt
        : event1.eventHappensAt;

    const dateEvent2 =
      'scheduledMeasurements' in event2
        ? event2.scheduledMeasurements[0].eventHappensAt
        : event2.eventHappensAt;

    return new Date(dateEvent1 as string) > new Date(dateEvent2 as string)
      ? 1
      : -1;
  });

  return formattedSortedEvents;
};

export const toastErrorRedirect = async (
  err: AxiosError<APIError>,
  beforeOpen: () => Promise<void>
) => {
  const responseDataMessage = err.response?.data.displayMessage || '';
  const messages = [
    'O plantão foi finalizado automaticamente.',
    'Você foi removido do plantão.',
    'Você já finalizou esta execução.',
  ];
  if (messages.includes(responseDataMessage)) {
    await beforeOpen();
    return toast.error(responseDataMessage);
  }
  const displayMessage = resolveErrorMessage(err);
  toast.error(displayMessage);
};

export const subCategoryHasCustomInput = (
  subCategory: EventSubCategoryModel
): boolean => {
  return Boolean(
    Object.values(subCategoryIdMapper).find((id) => id === subCategory.id)
  );
};
