import React, { useContext, useEffect } from 'react';
import { Container } from './styles';

import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { ShiftInProgressContext } from '../../contexts/ShiftInProgress';
import { resolveErrorMessage } from '../../utils/error';

import LoadingBackdrop from '../../components/LoadingBackdrop';

import { Typography } from '@material-ui/core';
import PatientHeader from '../../components/Headers/PatientHeader';
import ShoppingListUpsertForm from '../../components/ShoppingListUpsertForm';
import { FormValues } from '../../components/ShoppingListUpsertForm/utils';
import { StyledFieldset } from '../../components/StyledFieldset';
import useCanAccess from '../../hooks/useCanAccess';
import useShoppingList from '../../hooks/useShoppingList';

const ShoppingList: React.FC = () => {
  const {
    getOneByPatientId,
    ids,
    byId,
    loading,
    patch,
    postBuyShoppingListById,
  } = useShoppingList();
  const { shiftInProgress } = useContext(ShiftInProgressContext);
  const { isAllowedToUpdate: isAllowedToUpdateShoppingList } = useCanAccess(
    'care/shopping-list'
  );
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const patientId = shiftInProgress?.patientId!;

  useEffect(() => {
    getOneByPatientId(patientId);
  }, [patientId]);

  const shoppingList = byId[ids[0]];
  const listId = shoppingList?.id;
  const item = {
    shoppingText: shoppingList?.shoppingText ? shoppingList?.shoppingText : '',
  };

  if (loading) {
    return <LoadingBackdrop loading={loading} />;
  }

  const handleSubmit = (values: FormValues) => {
    return patch(listId!, values)
      .then(() => {
        toast.success('Lista alterada com sucesso!');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handleBoughtSubmit = () => {
    return postBuyShoppingListById(listId!)
      .then(() => {
        toast.success('Lista comprada com sucesso!');
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  return (
    <>
      <PatientHeader />
      <StyledFieldset disabled={!isAllowedToUpdateShoppingList}>
        <Container>
          <Typography variant="h6">Lista de compras</Typography>
          <Typography variant="body2" align="center">
            Anote aqui a lista de itens que precisam ser providenciados pela
            família.
          </Typography>
          <ShoppingListUpsertForm
            onSubmit={handleSubmit}
            handleBoughtSubmit={handleBoughtSubmit}
            initialValues={item}
          />
        </Container>
      </StyledFieldset>
    </>
  );
};

export default ShoppingList;
