/* eslint-disable @typescript-eslint/no-explicit-any */
const QUEUE_KEY = `cuidador_http_queue`;

export interface QueueData {
  httpMethod: 'post' | 'patch';
  url: string;
  data: any;
}

export const addToRequestQueue = async (
  requestQueueData: QueueData
): Promise<void> => {
  const queuedData = getQueueData();
  queuedData.push(requestQueueData);
  setQueuedData(queuedData);
};

export const getQueueData = (): QueueData[] => {
  const stringData = localStorage.getItem(QUEUE_KEY) || '[]';
  const queueData: QueueData[] = JSON.parse(stringData);
  return queueData;
};

export const setQueuedData = (data: QueueData[]) => {
  const stringData = JSON.stringify(data);
  localStorage.setItem(QUEUE_KEY, stringData);
};
