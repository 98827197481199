import { CaregiverMoodModel } from '@cuidador/database';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../contexts/auth';
import useCaregiverMood from '../../../hooks/useCaregiverMood';

import Header from '../../../components/Headers/Header';
import {
  Backdrop,
  BackdropCircularProgress,
  Container,
  HorizontalLine,
  MoodIconContainer,
  MoodIconsLine,
  NextButton,
  OutterContainer,
  TitleContainer,
} from './styles';

import HappyMoodIcon from '../../../assets/caregiver-mood-icons/happy-icon.svg';
import LittleHappyMoodIcon from '../../../assets/caregiver-mood-icons/little-happy-icon.svg';
import SadMoodIcon from '../../../assets/caregiver-mood-icons/sad-icon.svg';
import VeryHappyMoodIcon from '../../../assets/caregiver-mood-icons/very-happy-icon.svg';
import VerySadMoodIcon from '../../../assets/caregiver-mood-icons/very-sad-icon.svg';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import { ShiftInProgressContext } from '../../../contexts/ShiftInProgress';
import useCanAccess from '../../../hooks/useCanAccess';

enum MoodEnum {
  VERY_HAPPY = 5,
  HAPPY = 4,
  LITTLE_HAPPY = 3,
  SAD = 2,
  VERY_SAD = 1,
}

type RouteState = {
  shiftExecutionId: number;
};

const ShiftEnd: React.FC = () => {
  const history = useHistory();
  const { state: { shiftExecutionId } = {} } = useLocation<RouteState>();
  const { userInfo } = useContext(AuthContext);
  const {
    refreshShiftInProgress,
    loading: loadingShiftInProgress,
  } = useContext(ShiftInProgressContext);
  const [mood, setMood] = useState(0);
  const { post, loading } = useCaregiverMood();
  const { isAllowedToCreate: isAllowedToCreateCaregiverMood } = useCanAccess(
    'care/caregiver/mood'
  );

  const handleCaregiverMoodClick = () => {
    const data: CaregiverMoodModel = {
      moodLevel: mood,
      shiftExecutionId,
      shiftPeriod: 'shiftEnd',
      caregiverId: userInfo?.id,
    };
    post(data)
      .then(() => history.replace(`/`))
      .catch(() =>
        toast.error('Erro ao salvar disposição, tente novamente daqui a pouco')
      );
  };

  useEffect(() => {
    refreshShiftInProgress();
  }, []);

  if (!isAllowedToCreateCaregiverMood) {
    // If is not allowed to create mood, skip this step
    return <Redirect to={'/'} />;
  }

  if (loadingShiftInProgress) {
    return <LoadingBackdrop loading={loadingShiftInProgress} />;
  }

  return (
    <>
      <OutterContainer>
        <Header leftButtonType="goBack" title="Cuidar do cuidador" />
        <HorizontalLine />
        <TitleContainer>Como você está se sentindo?</TitleContainer>

        <Container>
          <MoodIconsLine>
            <MoodIconContainer
              alt="Muito feliz"
              src={VeryHappyMoodIcon}
              onClick={() => setMood(MoodEnum.VERY_HAPPY)}
              selected={mood === MoodEnum.VERY_HAPPY}
            />
            <MoodIconContainer
              alt="Feliz"
              src={HappyMoodIcon}
              onClick={() => setMood(MoodEnum.HAPPY)}
              selected={mood === MoodEnum.HAPPY}
            />
          </MoodIconsLine>

          <MoodIconsLine>
            <MoodIconContainer
              alt="Pouco feliz"
              src={LittleHappyMoodIcon}
              onClick={() => setMood(MoodEnum.LITTLE_HAPPY)}
              selected={mood === MoodEnum.LITTLE_HAPPY}
            />
          </MoodIconsLine>

          <MoodIconsLine>
            <MoodIconContainer
              alt="Triste"
              src={SadMoodIcon}
              onClick={() => setMood(MoodEnum.SAD)}
              selected={mood === MoodEnum.SAD}
            />
            <MoodIconContainer
              alt="Muito triste"
              src={VerySadMoodIcon}
              onClick={() => setMood(MoodEnum.VERY_SAD)}
              selected={mood === MoodEnum.VERY_SAD}
            />
          </MoodIconsLine>

          <NextButton onClick={handleCaregiverMoodClick} disabled={mood === 0}>
            Próximo
          </NextButton>
        </Container>
      </OutterContainer>
      <Backdrop open={loading}>
        {loading && (
          <BackdropCircularProgress data-testid="table-backdrop-spinner" />
        )}
      </Backdrop>
    </>
  );
};

export default ShiftEnd;
