import {
  AnswerConfig,
  AnswerType,
  CareCategoryModel,
  CareLineModel,
  CareQuestionAnswerModel,
  CareQuestionModel,
} from '@cuidador/database';

export interface NormalizedCareCategory extends CareCategoryModel {
  totalQuestions: number;
  totalAnswered: number;
  careLines: NormalizedCareLine[];
}

export interface NormalizedCareLine extends CareLineModel {
  totalQuestions: number;
  totalAnswered: number;
  careQuestions: NormalizedCareQuestion[];
}

export interface NormalizedCareQuestion extends CareQuestionModel {
  questionIndex: number;
}

export const isQuestionAnswered = (answerConfig: AnswerType): boolean => {
  if (answerConfig.type === 'single') {
    const checkedOption = answerConfig.options.find(
      (option) => option.isChecked
    );

    if (!checkedOption) return false;

    if (checkedOption.needDescription && !checkedOption.description)
      return false;

    if (checkedOption.nextAnswer)
      return isQuestionAnswered(checkedOption.nextAnswer);

    return true;
  }
  return answerConfig.options.some((option) => option.isChecked);
};

// normalizes care category by adding total questions and answered questions considering its care lines
export const normalizeCareCategory = (
  careCategory: CareCategoryModel,
  questionIndex = { index: 0 }
): NormalizedCareCategory => {
  const normalizedCareLines =
    careCategory.careLines?.map((careLine) =>
      normalizeCareLine(careLine, questionIndex)
    ) || [];

  return {
    ...careCategory,
    id: careCategory.id!,
    careLines: normalizedCareLines,
    totalQuestions: normalizedCareLines.reduce(
      (totalQuestions: number, careLine) =>
        (totalQuestions += careLine?.careQuestions?.length || 0),
      0
    ),
    totalAnswered: normalizedCareLines.reduce(
      (totalAnswered: number, careLine) =>
        (totalAnswered += careLine?.totalAnswered || 0),
      0
    ),
  };
};

// normalizes care line by adding total questions and answered questions
export const normalizeCareLine = (
  careLine: CareLineModel,
  questionIndex = { index: 0 }
): NormalizedCareLine => {
  const normalizedCareQuestions =
    careLine.careQuestions?.map((question) =>
      normalizeCareQuestion(question, questionIndex)
    ) || [];

  return {
    ...careLine,
    careQuestions: normalizedCareQuestions,
    totalQuestions: normalizedCareQuestions.length || 0,
    totalAnswered:
      normalizedCareQuestions.filter((question) =>
        isQuestionAnswered(question.answerConfig as AnswerConfig)
      ).length || 0,
  };
};

export const normalizeCareQuestion = (
  careQuestion: CareQuestionModel,
  questionIndex = { index: 0 }
): NormalizedCareQuestion => {
  const question: NormalizedCareQuestion = {
    ...careQuestion,
    // used to identify the question position in relation to the shift (considering all care categories and care lines)
    questionIndex: questionIndex.index,
  };

  // increments index for next question
  questionIndex.index += 1;
  return question;
};

export const normalizeAnswersToApi = (careCategories: CareCategoryModel[]) => {
  const answers: CareQuestionAnswerModel[] = [];

  careCategories.forEach((careCategory) => {
    careCategory?.careLines?.forEach((careLine) => {
      const normalizedAnswers = (careLine.careQuestions || []).map(
        normalizeAnswerToApi
      );
      answers.push(...normalizedAnswers);
    });
  });

  return answers.filter((cqa) =>
    isQuestionAnswered(cqa.questionAnswerData as AnswerType)
  );
};

export const normalizeAnswerToApi = (question: CareQuestionModel) => ({
  careQuestionId: question.id,
  questionAnswerData: question.answerConfig,
});
