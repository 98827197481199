import { ShiftModel } from '@cuidador/database';
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import Card from './Card';
import { CardDiv, Container, StyledSection, TitleHeader } from './styles';
import { getCareCategoryCardStatusMessage } from './utils';

interface CareCategoriesViewerProps {
  shift: ShiftModel;
}

const CareCategoriesViewer: React.FC<CareCategoriesViewerProps> = (
  props: CareCategoriesViewerProps
) => {
  const questionsGroupedByCareCategory = _.groupBy(
    props.shift.shiftCareQuestions || [],
    'careQuestion.careLine.categoryId'
  );
  return (
    <StyledSection>
      <TitleHeader variant="body1">Cuidados</TitleHeader>
      <Container>
        {Object.entries(questionsGroupedByCareCategory).map(
          ([careCategoryId, shiftCareQuestionList]) => {
            const currentCareCategory = shiftCareQuestionList
              .map((scq) => scq.careQuestion?.careLine?.careCategory)
              .find((cq) => String(cq?.id) === careCategoryId);

            const filteredCareQuestions = (
              props.shift.careQuestionAnswers || []
            ).filter((cqa) => {
              return shiftCareQuestionList
                .map((scq) => scq.careQuestionId)
                .includes(cqa.careQuestionId);
            });

            return (
              <CardDiv
                key={careCategoryId}
                color={currentCareCategory?.colorHexa}
              >
                <Typography variant="subtitle1">
                  {currentCareCategory?.name}
                </Typography>
                <Typography variant="subtitle2">
                  {getCareCategoryCardStatusMessage(
                    filteredCareQuestions,
                    shiftCareQuestionList.length
                  )}
                </Typography>
                {shiftCareQuestionList.map((shiftCareQuestion) => {
                  const currentCareQuestionAnswer = props.shift.careQuestionAnswers?.find(
                    (cqa) =>
                      cqa.careQuestionId === shiftCareQuestion.careQuestionId
                  );

                  const currentCareQuestion =
                    shiftCareQuestion.careQuestion?.careQuestionText;

                  return (
                    <Card
                      key={currentCareQuestionAnswer?.id}
                      title={shiftCareQuestion.careQuestion?.careLine?.name}
                      careQuestion={currentCareQuestion}
                      careQuestionAnswer={currentCareQuestionAnswer}
                    />
                  );
                })}
              </CardDiv>
            );
          }
        )}
      </Container>
    </StyledSection>
  );
};

export default CareCategoriesViewer;
