import { EventModel, EventSubCategoryModel } from '@cuidador/database';
import { Typography } from '@material-ui/core';
import { Message } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import React from 'react';
import CalendarIcon from '../../../../assets/Icon-coloredCalendar.svg';
import TimeColorIcon from '../../../../assets/Icon-time-color.svg';
import TimeIcon from '../../../../assets/Icon-time.svg';
import useCanAccess from '../../../../hooks/useCanAccess';
import useShiftEventHandling from '../../../../hooks/useShiftEventHandling';
import CommentModal, {
  FormValues as CommentModalFormValues,
} from '../CommentModal';
import {
  ButtonContainer,
  CaregiverTypography,
  DescriptionContainer,
  LeftContainer,
  LineThroughTypography,
  RealTimeContainer,
  RightContainer,
  StyledButton,
  StyledChangeStatus,
  StyledCommentButton,
  StyledDoneIcon,
  StyledNotDoneIcon,
  TimerContainer,
  TypographyContainer,
} from '../styles';
import UpdateTimeModal from '../UpdateTimeModal';
import { CardBox } from './styles';

const UnselectedNotDoneIcon = <CloseIcon />;
const UnselectedDoneIcon = <CheckIcon />;
const SelectedNotDoneIcon = <StyledNotDoneIcon />;
const SelectedDoneIcon = <StyledDoneIcon />;
const CommentIcon = <Message />;
const UnselectedTimeIcon = <img src={TimeIcon} />;
const SelectedTimeIcon = <img src={TimeColorIcon} />;

interface CardProps {
  id?: number;
  name?: string;
  subCategory?: EventSubCategoryModel;
  eventHappensAt?: string;
  description?: string;
  status?: EventModel['status'];
  updatedTimeHappensAt?: string | null;
  comment?: string;
  caregiverName?: string;
  onChangeStatus: (id: number, newStatus: EventModel['status']) => void;
  onChangeComment: (id: number, newComment: string) => void;
  onChangeTime: (id: number, updatedTimeHappensAt: string | null) => void;
}

const AppointmentCard: React.FC<CardProps> = (event) => {
  const {
    id,
    name,
    subCategory,
    description,
    onChangeStatus,
    onChangeComment,
    onChangeTime,
    caregiverName,
  } = event;

  const {
    date,
    minShiftLimitDate,
    maxShiftLimitDate,
    currentStatus,
    currentComment,
    handleStatusToggle,
    handleCommentChange,
    handleUpdatedTime,
    handleAllowedPatchEvent,
    isStatusToggleTimeAllowed,
  } = useShiftEventHandling(event, {
    onChangeStatus,
    onChangeComment,
    onChangeTime,
  });

  const { isAllowedToUpdate: isAllowedToUpdateComment } = useCanAccess(
    'care/shift/event.comment'
  );
  const { isAllowedToUpdate: isAllowedToUpdateDoneStatus } = useCanAccess(
    'care/shift/event.status'
  );
  const { isAllowedToUpdate: isAllowedToUpdateRealTime } = useCanAccess(
    'care/shift/event.updated-time'
  );

  const [commentModalVisible, setCommentModalVisible] = React.useState(false);
  const [visibleModal, setVisibleModal] = React.useState(false);

  return (
    <CardBox data-testid={`appointmentCard-${id}`}>
      {event.updatedTimeHappensAt && (
        <RealTimeContainer
          data-testid={`realTimeContainer-${id}`}
        >{`Alterado para ${format(
          new Date(event.updatedTimeHappensAt),
          'HH:mm'
        )}`}</RealTimeContainer>
      )}
      <LeftContainer>
        <TimerContainer>
          {event.updatedTimeHappensAt ? (
            <LineThroughTypography variant="h6">{date}</LineThroughTypography>
          ) : (
            <Typography variant="h6">{date}</Typography>
          )}
        </TimerContainer>
        <img src={CalendarIcon} title={`appointment-image-${id}`} />
      </LeftContainer>
      <RightContainer>
        <TypographyContainer>
          <Typography variant="h6">
            {subCategory?.name} {name && `(${String(name).trim()})`}
          </Typography>
          <DescriptionContainer>
            <Typography variant="subtitle2">{description}</Typography>
            {currentComment && (
              <Typography variant="subtitle2">Obs: {currentComment}</Typography>
            )}
          </DescriptionContainer>
          {caregiverName && (
            <CaregiverTypography>{caregiverName}</CaregiverTypography>
          )}
        </TypographyContainer>
        <StyledChangeStatus>
          {id! > 0 && (
            <ButtonContainer>
              {isAllowedToUpdateComment && (
                <StyledCommentButton
                  data-testid={`comment-set-${id}`}
                  onClick={() =>
                    handleAllowedPatchEvent(() => setCommentModalVisible(true))
                  }
                >
                  {CommentIcon}
                </StyledCommentButton>
              )}
              {isAllowedToUpdateRealTime && (
                <StyledButton
                  data-testid={'timer-set-appointment'}
                  onClick={() =>
                    handleAllowedPatchEvent(() => setVisibleModal(true))
                  }
                >
                  {event.updatedTimeHappensAt
                    ? SelectedTimeIcon
                    : UnselectedTimeIcon}
                </StyledButton>
              )}
              {isAllowedToUpdateDoneStatus && (
                <>
                  <StyledButton
                    data-testid={`notDoneButton-${id}`}
                    $disabled={!isStatusToggleTimeAllowed}
                    onClick={() => {
                      handleAllowedPatchEvent(() =>
                        handleStatusToggle('not_accomplished')
                      );
                    }}
                  >
                    {currentStatus === 'not_accomplished'
                      ? SelectedNotDoneIcon
                      : UnselectedNotDoneIcon}
                  </StyledButton>
                  <StyledButton
                    data-testid={`doneButton-${id}`}
                    $disabled={!isStatusToggleTimeAllowed}
                    onClick={() => {
                      handleAllowedPatchEvent(() =>
                        handleStatusToggle('accomplished')
                      );
                    }}
                  >
                    {currentStatus === 'accomplished'
                      ? SelectedDoneIcon
                      : UnselectedDoneIcon}
                  </StyledButton>
                </>
              )}
            </ButtonContainer>
          )}
        </StyledChangeStatus>
      </RightContainer>
      {visibleModal && id && (
        <UpdateTimeModal
          eventId={id}
          handleUpdatedTime={(values) =>
            handleUpdatedTime(values.updatedTimeHappens || null)
          }
          onClose={() => setVisibleModal(false)}
          opened={visibleModal}
          allowRemoveButton={!!event.updatedTimeHappensAt}
          minDate={minShiftLimitDate?.toISOString()}
          maxDate={maxShiftLimitDate?.toISOString()}
          initialFormValues={
            event.updatedTimeHappensAt
              ? {
                  updatedTimeHappens: new Date(event.updatedTimeHappensAt),
                }
              : undefined
          }
        />
      )}
      {commentModalVisible && id && (
        <CommentModal
          handleEventComment={(values: CommentModalFormValues) => {
            handleCommentChange(values.comment);
            setCommentModalVisible(false);
          }}
          onClose={() => setCommentModalVisible(false)}
          opened={commentModalVisible}
          eventId={id}
          allowRemoveButton={!!currentComment}
          initialValues={
            currentComment ? { comment: currentComment } : undefined
          }
        />
      )}
    </CardBox>
  );
};

export default AppointmentCard;
