import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { Favorite } from '@material-ui/icons';
import { MadeByProps } from '.';
import { ReactComponent as LogoInline } from '../../assets/logo-cuidador-inline.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const Text = styled(Typography)<MadeByProps>`
  color: ${(props) =>
    props.mainColor === 'white'
      ? ({ theme }) => theme.palette.common.white
      : ({ theme }) => theme.palette.common.black};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledFavorite = styled(Favorite)`
  margin-inline: 5px;
  height: 15px;
  width: 15px;
`;

export const StyledLogoInline = styled(LogoInline)<MadeByProps>`
  & .c {
    fill: ${(props) =>
      props.mainColor === 'white'
        ? ({ theme }) => theme.palette.common.white
        : ({ theme }) => theme.palette.common.black};
  }
  margin-top: 2.5px;
  margin-inline: 5px;
  height: 15px;
  width: auto;
`;
