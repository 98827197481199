import { FormControlLabel } from '@material-ui/core';
import MuiFab from '@material-ui/core/Fab';
import styled from 'styled-components';

export const CreateEventDialogContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(1.5)}px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  color: white;
  & .MuiRadio-root {
    color: white;
  }
`;

export const CreateDialogBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: ${({ theme }) => theme.spacing(2)}px 0;
`;

export const Fab = styled(MuiFab)`
  bottom: ${({ theme }) => theme.spacing(3)}px;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.common.white};
  svg {
    font-size: ${({ theme }) => theme.typography.h3.fontSize};
  }
`;
