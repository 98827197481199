/**
 *
 *  This is a copy of familia/src/hooks/useCanAccess
 *  Any change here might need be reflected there
 *
 */

import { useMemo } from 'react';
import { PermissionAction, PermissionResource } from '@cuidador/database';
import { usePermission } from '../contexts/permission';

const useCanAccess: any = (resource: PermissionResource, userInfo: any = null) => {

  if (resource === 'care/shift' && userInfo) {
    const organizationId: number | undefined = userInfo?.user?.organizationId;
    const prohibitedOrganizations: string | undefined = process.env.REACT_APP_VIEW_CARE_SHIFT_PROHIBITED_ORGANIZATIONS;      

    const prohibitedOrganizationsArray: number[] = prohibitedOrganizations
      ? prohibitedOrganizations.split(',').map(Number)
      : [];    

    if (organizationId !== undefined && prohibitedOrganizationsArray.includes(organizationId)) {
      return false;
    }
  }

  const { loading, permissions } = usePermission();

  const allowedActions = useMemo(() => {
    if (!permissions) {
      return [];
    }

    const userCanAccessResource = Boolean(permissions[resource]);

    if (!userCanAccessResource) {
      return [];
    }

    const allowedActions = permissions[resource].map(
      ({ action }) => action as PermissionAction
    );
    return allowedActions;
  }, [permissions]);

  const isAllowedToCreate = useMemo(() => {
    return allowedActions.includes('create');
  }, allowedActions);

  const isAllowedToDelete = useMemo(() => {
    return allowedActions.includes('delete');
  }, allowedActions);

  const isAllowedToInvoke = useMemo(() => {
    return allowedActions.includes('invoke');
  }, allowedActions);

  const isAllowedToRead = useMemo(() => {
    return allowedActions.includes('read');
  }, allowedActions);

  const isAllowedToUpdate = useMemo(() => {
    return allowedActions.includes('update');
  }, allowedActions);

  return {
    loading,
    allowedActions,
    isAllowedToCreate,
    isAllowedToDelete,
    isAllowedToInvoke,
    isAllowedToRead,
    isAllowedToUpdate,
  };
};

export default useCanAccess;
