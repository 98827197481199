import {
  EventSubCategoryModel,
  ProfessionalReportModel,
} from '@cuidador/database';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBr from 'date-fns/locale/pt-BR';
import { Formik, setNestedObjectValues } from 'formik';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/auth';
import StyledSelect from '../Forms/FormikSelect';
import FormikTextField from '../Forms/FormikTextField';
import StyledKeyboardDatePicker from '../StyledKeyboardDatePicker';
import StyledMenuItem from '../StyledMenuItem';
import { Centralizer, Form, FormContainer, OptionsButton } from './styles';
import {
  handleTitleByPatientOccupation,
  titleOptions,
  validationSchema,
} from './utils';

export interface Props {
  submit: (data: ProfessionalReportModel) => Promise<void | string | number>;
  cancel: () => void;
  subCategories: EventSubCategoryModel[];
  patientId: number;
  formValues?: FormValues;
  readOnly?: boolean;
}

export interface FormValues {
  title: string;
  description: string;
  subCategoryId?: number | string;
  date: string;
  caregiver: string;
}

const CreateProfessionalReportForm: React.FC<Props> = ({
  submit,
  cancel,
  subCategories,
  patientId,
  formValues,
  readOnly,
}) => {
  const { userInfo } = useContext(AuthContext);

  const initialValues: FormValues = {
    title: handleTitleByPatientOccupation(userInfo?.supporter?.occupation),
    description: '',
    date: new Date().toISOString(),
    subCategoryId: '',
    caregiver: userInfo?.user?.name || '',
  };

  const formatAndSubmit = async (formValues: FormValues, isDraft: boolean) => {
    /* should never happen subCaregoryId with id 0 */

    const data: ProfessionalReportModel = {
      title: formValues.title,
      description: formValues.description,
      eventSubCategoryId:
        Number(formValues.subCategoryId) === 0
          ? undefined
          : Number(formValues.subCategoryId),
      date: new Date(formValues.date).toISOString(),
      patientId,
      isDraft,
    };
    await submit(data);
  };

  return (
    <FormContainer>
      <Formik
        validationSchema={validationSchema}
        initialValues={formValues || initialValues}
        onSubmit={(values) => formatAndSubmit(values, true)}
        validateOnChange={false}
      >
        {({
          isSubmitting,
          values,
          setTouched,
          validateForm,
          handleBlur,
          setFieldValue,
        }) => {
          const validateAndSubmit = async () => {
            const now = new Date();
            const date = new Date(values.date);
            let isFormValid = true;

            const validateFutureDate = () => {
              if (date > now) {
                isFormValid = false;
                toast.error(
                  'Por favor, escolha a data de hoje ou um dia anterior'
                );
                return;
              }
            };

            const triggerCustomValidation = async () => {
              const validationErrors = await validateForm();
              setTouched(setNestedObjectValues(validationErrors, true));
              validateFutureDate();
              if (Object.keys(validationErrors).length > 0) {
                isFormValid = false;
              }
            };

            await triggerCustomValidation();

            const isDraft = false;
            if (isFormValid) {
              formatAndSubmit(values, isDraft);
            }
          };

          return (
            <Form>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
                <StyledKeyboardDatePicker
                  name="date"
                  label="Data"
                  cancelLabel="Cancelar"
                  value={values.date}
                  onChange={(date: Date | null) => {
                    setFieldValue('date', date, true);
                  }}
                  placeholder="dd/mm/aaaa"
                  color="black"
                  margin="normal"
                  size="medium"
                  inputProps={{
                    'data-testid': 'date',
                    disabled: readOnly,
                  }}
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true }}
                  readOnly={readOnly}
                  disabled={readOnly}
                />
              </MuiPickersUtilsProvider>
              <StyledSelect
                name="title"
                label="Título"
                SelectDisplayProps={{
                  'data-testid': 'title',
                }}
                color="black"
                disabled={readOnly}
              >
                {titleOptions.map((item) => (
                  <StyledMenuItem
                    key={item.value}
                    value={item.value}
                    color="black"
                  >
                    {item.label}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
              <FormikTextField
                color="black"
                id="caregiver"
                inputProps={{ 'data-testid': 'caregiver' }}
                label="Profissional Responsável"
                name="caregiver"
                margin="normal"
                autoComplete="off"
                disabled
              />
              <StyledSelect
                name="subCategoryId"
                label="Intercorrência relacionada"
                SelectDisplayProps={{
                  'data-testid': 'subCategoryId',
                }}
                color="black"
                value={values.subCategoryId}
                disabled={readOnly}
              >
                {subCategories.map((item) => (
                  <StyledMenuItem key={item.id} value={item.id} color="black">
                    {item.name}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
              <FormikTextField
                color="black"
                id="description"
                multiline
                rows={4}
                inputProps={{ 'data-testid': 'description', maxlength: 5000 }}
                label="Descrição"
                name="description"
                margin="normal"
                autoComplete="off"
                disabled={readOnly}
              />
              {!readOnly && (
                <Centralizer>
                  <OptionsButton
                    data-testid="draft-submit"
                    type="submit"
                    size="large"
                    color="inherit"
                  >
                    Salvar como rascunho
                  </OptionsButton>
                  <OptionsButton
                    data-testid="submit"
                    size="large"
                    color="inherit"
                    disabled={isSubmitting}
                    onClick={validateAndSubmit}
                  >
                    Publicar
                  </OptionsButton>
                  <OptionsButton
                    data-testid="cancel"
                    onClick={cancel}
                    size="large"
                    color="inherit"
                  >
                    Cancelar
                  </OptionsButton>
                </Centralizer>
              )}
            </Form>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

export default CreateProfessionalReportForm;
