import { ListItemIcon } from '@material-ui/core';
import React from 'react';
import { StyledListItem, StyledListItemText } from '../styles';

interface MenuItemProps {
  label: string;
  icon: JSX.Element;
  onClick?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ label, icon, onClick }) => {
  return (
    <StyledListItem button onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <StyledListItemText>{label}</StyledListItemText>
    </StyledListItem>
  );
};

export default MenuItem;
