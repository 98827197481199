import { ShiftModel, UserModel } from '@cuidador/database';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { isCaregiver } from '../../contexts/permission';

export function getShiftCardBackgroundColor(shift: ShiftModel) {
  if (shift.status === 'not_done') return '#C0C0C0';
  if (shift.isAdditionalCoverage) return '#EFC1401A';
  return 'white';
}

export function getDayGroup(date: Date) {
  const weekday = format(date, 'EEE', { locale: ptBrLocale });
  const capitalizedWeekday = weekday.charAt(0).toUpperCase() + weekday.slice(1);
  const day = format(date, 'dd', { locale: ptBrLocale });
  const month = format(date, 'MMMM', { locale: ptBrLocale });
  return `${capitalizedWeekday} ${day} de ${month}`;
}

export function getShiftRealTime(shift: ShiftModel) {
  if (
    (shift.status !== 'done' && shift.status !== 'done_automatically') ||
    !shift.startedAt
  )
    return;
  const start = format(new Date(shift.startedAt), 'HH:mm');
  return `Horário: ${start}`;
}

export const isShiftMenuVisible = (
  shift: ShiftModel,
  user: UserModel,
  shiftInProgress?: ShiftModel | null
): boolean => {
  const caregiverId = Number(user?.id);
  return !!(
    !isCaregiver(user) ||
    isCaregiverWithInProgressShift(user, shiftInProgress) ||
    caregiverOwnAtLeastOneShiftExecution(shift, caregiverId)
  );
};

export const isShiftDetailsVisible = (
  shift: ShiftModel,
  user: UserModel,
  shiftInProgress?: ShiftModel | null
): boolean => {
  const shiftStatusDiferentOfNotDone = shift.status !== 'not_done';
  return !!(
    shiftStatusDiferentOfNotDone &&
    (!isCaregiver(user) ||
      isCaregiverWithInProgressShift(user, shiftInProgress))
  );
};

const isCaregiverWithInProgressShift = (
  user: UserModel,
  shiftInProgress?: ShiftModel | null
): boolean => {
  return !!(isCaregiver(user) && shiftInProgress);
};

export const caregiverOwnAtLeastOneShiftExecution = (
  shift: ShiftModel,
  caregiverId: number
) => {
  if (!shift.executions || shift.executions.length < 1) return false;

  return shift.executions.some(
    (execution) => execution.caregiverId == caregiverId
  );
};
