import { PatientModel, ProfessionalReportModel } from '@cuidador/database';
import AddIcon from '@material-ui/icons/AddRounded';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Fab from '../../components/Fab';
import Header from '../../components/Headers/Header';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import ProfessionalReportList from '../../components/ProfessionalReportList';
import StyledButton from '../../components/StyledButton';
import { AuthContext } from '../../contexts/auth';
import useCanAccess from '../../hooks/useCanAccess';
import useProfessionalReport from '../../hooks/useProfessionalReport/indexs';
import { Container } from './styles';

const ProfessionalReport: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const { getByPatientId, loading, total } = useProfessionalReport();
  const params = useParams<{ patientId: string }>();
  const patientId = parseInt(params.patientId);
  const [patient, setPatient] = useState<PatientModel>();
  const [page, setPage] = useState(0);
  const [professionalReports, setProfessionalReports] = useState<
    ProfessionalReportModel[]
  >([]);
  const {
    isAllowedToCreate: isAllowedToCreateProfessionalReport,
  } = useCanAccess('report/professional');

  useEffect(() => {
    handlePatientName();
  }, []);

  useEffect(() => {
    loadProfessionalReports();
  }, [page]);

  const loadProfessionalReports = async () => {
    if (patientId) {
      getByPatientId(patientId, {
        page,
        limit: 10,
        orderBy: 'date',
        order: 'DESC',
      })
        .then((data) => {
          const loadedReports = data.results;
          if (page === 0) {
            setProfessionalReports(loadedReports);
          } else {
            setProfessionalReports([...professionalReports, ...loadedReports]);
          }
        })
        .catch(() =>
          toast.error('Erro ao carregar avaliações multiprofissional.')
        );
    }
  };

  const pageIncrement = () => {
    setPage(page + 1);
  };

  const handlePatientName = () => {
    const caregiverPatients = userInfo?.user?.patients;
    const patient = caregiverPatients?.find(
      (patient) => patient.id === patientId
    );
    setPatient(patient);
  };

  return (
    <>
      <Header
        title={`Avaliação Multiprofissional ${patient?.name || ''}`}
        leftButtonType="goBack"
      />
      <Container>
        <ProfessionalReportList reports={professionalReports} />
        {loading && <LoadingBackdrop loading />}
        {professionalReports.length < total && (
          <StyledButton
            data-testid="show-more"
            size="medium"
            color="default"
            onClick={pageIncrement}
          >
            Ver mais
          </StyledButton>
        )}
        {isAllowedToCreateProfessionalReport && (
          <Fab to={`/avaliacao/adicionar/paciente/${patientId}`}>
            <AddIcon />
          </Fab>
        )}
      </Container>
    </>
  );
};

export default ProfessionalReport;
