import DateFnsUtils from '@date-io/date-fns';
import { Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBr from 'date-fns/locale/pt-BR';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import StyledSimpleModal from '../../../StyledSimpleModal';
import {
  Button,
  Form,
  LargeButton,
  StyledDatePicker,
  StyledOptions,
} from './styles';

export type ModalProps = {
  handleUpdatedTime: (values: FormValues) => void;
  onClose: () => void;
  eventId: number;
  opened: boolean;
  allowRemoveButton?: boolean;
  initialFormValues?: FormValues;
  maxDate?: string;
  minDate?: string;
};

export type FormValues = {
  updatedTimeHappens: Date | null | undefined;
};

const validationSchema = yup.object().shape({
  updatedTimeHappens: yup
    .date()
    .required('Por favor, insira uma data')
    .nullable()
    .typeError('Por favor, insira uma data válida'),
});

const UpdateTimeModal: React.FC<ModalProps> = ({
  handleUpdatedTime,
  onClose,
  opened,
  allowRemoveButton,
  initialFormValues,
  maxDate,
  minDate,
}) => {
  return (
    <StyledSimpleModal open={opened} title={'Alteração de horário'}>
      <Formik
        validateOnChange={false}
        initialValues={
          initialFormValues || {
            updatedTimeHappens: new Date(),
          }
        }
        validationSchema={validationSchema}
        onSubmit={(formValues) => {
          handleUpdatedTime(formValues);
          onClose();
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          return (
            <Form noValidate={true}>
              <Typography>Para qual horário gostaria de alterar?</Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
                <StyledDatePicker
                  data-testid={'data-late-input'}
                  inputVariant="outlined"
                  format="dd/MM/yyyy HH:mm"
                  name="updatedTimeHappens"
                  value={values.updatedTimeHappens}
                  cancelLabel="Cancelar"
                  maxDate={maxDate}
                  minDate={minDate}
                  ampm={false}
                  onChange={(date: Date | null) => {
                    setFieldValue('updatedTimeHappens', date, true);
                  }}
                />
              </MuiPickersUtilsProvider>
              <StyledOptions>
                <Button size="medium" color="inherit" onClick={onClose}>
                  Cancelar
                </Button>
                <Button
                  onClick={() => {
                    handleUpdatedTime({ updatedTimeHappens: null });
                    onClose();
                  }}
                  size="medium"
                  color="inherit"
                  disabled={!allowRemoveButton}
                >
                  Remover
                </Button>
              </StyledOptions>
              <LargeButton
                type="submit"
                size="medium"
                color="inherit"
                disabled={isSubmitting}
              >
                Salvar
              </LargeButton>
            </Form>
          );
        }}
      </Formik>
    </StyledSimpleModal>
  );
};

export default UpdateTimeModal;
