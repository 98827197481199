import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { breathRateMask } from '../../../utils/inputs';
import { Row, StyledInput } from '../styles';
import { validateBreathRate } from '../utils';
import { NewMeasurementValue } from '../../CurrentShiftEventList/CurrentShiftCards/ScheduledMeasurementCard/Measurement';

interface BreathRateInputProps {
  value?: string;
  patchEvent: (
    newMeasurementValue: NewMeasurementValue,
    status: 'awaiting' | 'accomplished' | 'not_accomplished'
  ) => void;
  id?: string;
  disabled?: boolean;
}

const BreathRateInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  BreathRateInputProps
> = ({ value, patchEvent, id, disabled }, ref) => {
  const [frequencyValue, setFrequencyValue] = useState(value || '');

  const onChangeAnyValue = useDebouncedCallback(
    (newMeasurementValue: NewMeasurementValue) => {
      const newStatus = newMeasurementValue.measurementValue
        ? 'accomplished'
        : 'awaiting';
      patchEvent(newMeasurementValue, newStatus);
    },
    400
  );

  return (
    <Row>
      <StyledInput
        type="text"
        value={frequencyValue}
        onChange={(e) => {
          const formattedValue = breathRateMask(e.target.value === '0' ? '' : parseInt(e.target.value).toString());
          const complicationMeasurement = validateBreathRate(formattedValue);
          onChangeAnyValue.callback({
            ...complicationMeasurement,
            measurementValue: formattedValue,
          });
          setFrequencyValue(formattedValue);
        }}
        disabled={disabled}
        ref={ref}
        id={`${id}-breathing-frequency`}
        // The bellow attribute makes the input keyboard be set to only numbers on smartphones.
        pattern="\d*"
      />
      <span>mrm</span>
    </Row>
  );
};

export default React.forwardRef(BreathRateInput);
