import React from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';

import StyledDialog from '../../../components/StyledDialog';
import StyledButton from '../../../components/StyledButton';

interface ConfirmSaveDialogProps extends DialogProps {
  onConfirm: (() => void) | undefined;
  onClose: () => void;
}

const ConfirmSaveDialog: React.FC<ConfirmSaveDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <StyledDialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogTitle>Tem certeza que deseja salvar?</DialogTitle>
        <DialogContentText align="center">
          Você não poderá fazer outra alteração nesse plantão.
        </DialogContentText>
        <DialogActions>
          <StyledButton onClick={onClose} color="inherit" variant="text">
            Voltar
          </StyledButton>
          <StyledButton
            data-testid="confirm-time-contestation-submit-button"
            onClick={onConfirm}
            color="inherit"
          >
            Confirmar
          </StyledButton>
        </DialogActions>
      </DialogContent>
    </StyledDialog>
  );
};

export default ConfirmSaveDialog;
