import { getMessaging, onMessage } from 'firebase/messaging';
import React, { useContext, useEffect } from 'react';
import { getFCMToken } from '../config/firebase';
import useUser from '../hooks/useUser';
import { AuthContext } from './auth';

export const PushNotificationContext = React.createContext({});

PushNotificationContext.displayName = 'PushNotificationContext';

export const usePushNotification = () => useContext(PushNotificationContext);

export const PushNotificationProvider: React.FC = ({ children }) => {
  const { putUserFCMToken } = useUser();
  const { user } = useContext(AuthContext);

  const handlePermissionAndTokenRequest = async () => {
    if (!('Notification' in window)) {
      // Browser does not support Push Notifications. Ignore
    } else if (Notification.permission === 'denied') {
      // Browser supports Push Notification, but user has already denied it's permission
    } else {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          handlePutToken();
        }
      });
    }
  };

  const handlePutToken = async () => {
    const currentToken = await getFCMToken();
    if (currentToken) {
      await putUserFCMToken({ fcmRegistrationToken: currentToken });
    }
  };

  useEffect(() => {
    if (user) {
      handlePermissionAndTokenRequest();
    }

    // Foreground listnener will show notifications when app is in foreground
    const messaging = getMessaging();
    const fcmOnMessageUnsubscribe = onMessage(messaging, (payload) => {
      const notificationTitle = payload.notification?.title;
      if (notificationTitle) {
        const notificationOptions = {
          body: payload.notification?.body,
        };

        new Notification(notificationTitle, notificationOptions);
      }
    });

    return () => {
      fcmOnMessageUnsubscribe;
    };
  }, [user]);

  return (
    <PushNotificationContext.Provider value={{}}>
      {children}
    </PushNotificationContext.Provider>
  );
};
