import React from 'react';
import { ReactComponent as ElderlyIcon } from '../../assets/elderlyIcon.svg';
import {
  Balloon,
  BalloonContainer,
  Balloons,
  PointBalloon,
  StyledText,
} from './styles';

interface Props {
  text?: string | string[];
}

const MedicBalloon: React.FC<Props> = ({ text, children }) => {
  const isTextArray = Array.isArray(text);
  const balloonContainerAlignItems = isTextArray ? 'flex-start' : 'center';
  const pointBalloonMarginTop = isTextArray ? '35px' : '0';

  return (
    <BalloonContainer
      data-testid="medic-ballon"
      alignItems={balloonContainerAlignItems}
    >
      <ElderlyIcon />
      <PointBalloon marginTop={pointBalloonMarginTop} />
      <Balloons>
        {Array.isArray(text) ? (
          text.map((t) => (
            <Balloon key={t}>
              <StyledText variant="subtitle1">{t}</StyledText>
            </Balloon>
          ))
        ) : (
          <Balloon>
            <StyledText variant="subtitle1">{text}</StyledText>
            {children}
          </Balloon>
        )}
      </Balloons>
    </BalloonContainer>
  );
};

export default MedicBalloon;
