import { clientSideScheme } from '@cuidador/whitelabel'
import { Drawer, IconButton, Link, List, ListItem, ListItemText } from '@material-ui/core'
import { AccessibilityNewRounded, Assignment, Close, Facebook, History, InsertDriveFile, Person, Phone } from '@material-ui/icons'
import { Link as defaultLink } from 'react-router-dom'
import styled from 'styled-components'

export const StyledDrawer = styled(Drawer)`
  display: flex;

  .MuiDrawer-paper {
    max-width: 300px;
    width: 100%;
  };
`

export const StyledCloseIcon = styled(Close)`
  color: ${({ theme }) => theme.palette.common.white};
  height: 1em;
  width: 1em;
`

export const StyledPersonIcon = styled(Person)`
  color: ${({ theme }) => theme.palette.primary.dark};
  height: 1em;
  width: 1em;
`

export const StyledPscIcon = styled(AccessibilityNewRounded)`
  color: ${({ theme }) => theme.palette.primary.dark};
  height: 1em;
  width: 1em;
`

export const StyledAssessment = styled(Assignment)`
  color: ${({ theme }) => theme.palette.primary.dark};
  height: 1.3em;
  width: 1.3em;
`

export const StyledComunityIcon = styled(Facebook)`
  color: ${({ theme }) => theme.palette.primary.dark};
  height: 1.1em;
  width: 1.1em;
`

export const StyledFileIcon = styled(InsertDriveFile)`
  color: ${({ theme }) => theme.palette.primary.dark};
  height: 1em;
  width: 1em;
`

export const StyledHistoryIcon = styled(History)`
  color: ${({ theme }) => theme.palette.primary.main};
  height: 1.1em;
  width: 1.1em;
`

export const StyledPhoneIcon = styled(Phone)`
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(0.75)}px;
  padding: ${({ theme }) => theme.spacing(0.25)}px;
  margin-left: ${({ theme }) => theme.spacing(0.25)}px;
  width: 0.7em;
  height: 0.7em;
`

export const StyledLogoBlock = styled.img.attrs(() => {
  const { logoLightUrl } = clientSideScheme()
  return { src: logoLightUrl }
})`
  max-width: 200px;
  max-height: 20px;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(0.75)}px;
  margin-bottom: ${({ theme }) => theme.spacing(0.75)}px;
`

export const DrawerHeader = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: flex;
  align-items: center;
  max-height: 72px;
`

export const StyledList = styled(List)`
  padding: 0;
`

export const StyledListItem = styled(ListItem)`
  &:hover {
    background-color: ${({ theme }) => theme.palette.extra.color.grey.light};
  }
`

export const StyledListItemText = styled(ListItemText)`
  color: ${({ theme }) => theme.palette.primary.dark};
`

export const StyledLink = styled(defaultLink)`
  text-decoration: none;
`

export const StyledHrefLink = styled(Link)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  };
`

export const AppItemTitle = styled.span`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 16px;
  font-weight: 600;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)}px;
`

export const AppItemLabel = styled.span`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: 12px;
`

export const AppIcon = styled.img`
  height: 25px;
  width: 25px;
  margin-left: ${({ theme }) => theme.spacing(0.25)}px;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing(0.25)}px;
`

export const DrawerBody = styled.div`
  flex: 1
`

export const DrawerFooter = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding-inline: ${({ theme }) => theme.spacing(2)}px;
`

export const DrawerButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: 0;
  font-size: 16px;
  display: flex;
  align-items: center;
`

export const DrawerFooterText = styled.span`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`
