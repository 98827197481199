import React from 'react';
import { breathRateMask } from '../../../../../utils/inputs';
import FormikTextField from '../../../../Forms/FormikTextField';
import { InputContainer } from '../styles';

interface BreathRateInputProps {
  value?: string;
  onChange: (value: string) => void;
}

const BreathRateInput: React.FC<
  BreathRateInputProps & Partial<HTMLInputElement>
> = ({ value, onChange, ...props }) => {
  return (
    <InputContainer>
      <FormikTextField
        color="primary"
        inputProps={{
          'data-testid': 'measurementInput',
          maxlength: 255,
        }}
        label="Valor"
        margin="normal"
        autoComplete="off"
        placeholder="16"
        value={value}
        onChange={(e) => {
          const formattedValue = breathRateMask(e.target.value);
          onChange(formattedValue);
        }}
        {...props}
      />
      {'mrm'}
    </InputContainer>
  );
};

export default BreathRateInput;
