import defaultAxios from 'axios';
import { useCallback } from 'react';

import axios, { getCachedAxios } from '../config/axios';

const useMedia = () => {
  // Gets PUT signed url
  const getSignedUrl = useCallback(
    (endpoint: string, contentType: string) =>
      axios({
        method: 'put',
        url: endpoint,
        data: null,
        headers: {
          'Content-Type': contentType,
        },
      }),
    []
  );

  // Puts file into Storage bucket
  const uploadMedia = useCallback(async (endpoint: string, file: File) => {
    const {
      data: { signedUrl },
    } = await getSignedUrl(endpoint, file.type);
    return defaultAxios({
      method: 'put',
      data: file,
      url: signedUrl,
      headers: {
        'Content-Type': file.type,
      },
    });
  }, []);

  const getMedia = useCallback(
    (endpoint: string) =>
      getCachedAxios()({
        method: 'get',
        url: endpoint,
        data: null,
      }),
    []
  );

  return { uploadMedia, getMedia };
};

export default useMedia;
