import { EventModel, ExecutionMessage, Shift } from '@cuidador/database'
import { APIError } from '@cuidador/lib'
import { Backdrop, CircularProgress, Typography } from '@material-ui/core'
import { AxiosError } from 'axios'
import { format } from 'date-fns'
import ptBrLocale from 'date-fns/locale/pt-BR'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce/lib'
import CareCategoryList from '../../components/CareCategoriesList'
import CurrentShiftEventList from '../../components/CurrentShiftEventList'
import { toastErrorRedirect } from '../../components/CurrentShiftEventList/CurrentShiftCards/ScheduledMeasurementCard/utils'
import ExecutionMessageCard from '../../components/ExecutionMessageCard'
import ExecutionMessageForm from '../../components/ExecutionMessageForm'
import FinishShiftConfirmDialog from '../../components/FinishShiftConfirmDialog'
import MadeBy from '../../components/MadeBy'
import { AuthContext } from '../../contexts/auth'
import { CurrentShiftDataContext } from '../../contexts/CurrentShiftData'
import { isSelfCare } from '../../contexts/permission'
import { ShiftInProgressContext } from '../../contexts/ShiftInProgress'
import useCanAccess from '../../hooks/useCanAccess'
import useExecution from '../../hooks/useExecution'
import {
  CircleContainer,
  Container,
  DateContainer,
  DateInside,
  DateOutside,
  FinishShiftButton,
  SubTitle,
  Title,
} from './styles'
import { formatFullWeekdayName, getExecutionMessages } from './utils'
import SymptomsListContextProvider from '../../contexts/SymptomsList'
import { useTheme } from 'styled-components'
import { PageTitle } from '../../components/PageTitle'
import { Header } from '../../components/Header'
import { PscButton } from '../../components/PscButton'
import { SideMenu } from '../../components/SideMenu'
import { MenuButton } from '../../components/MenuButton'
import Notiflix from 'notiflix';
import { QueueData } from '../../config/axios/offlineProxy/offlineQueue';
//import { hotjar } from 'react-hotjar';


/**
 * This component needs to act as a middle man because when the user logs out
 *  on a shift execution in progress and log back after it ended,
 *  an exception will be caused.
 */
export function CurrentShift() {
  const theme = useTheme()
  const history = useHistory()
  const QUEUE_KEY = `cuidador_http_queue`;
  const dev = process.env.REACT_APP_ENV !== 'production';
  // const hotjarUsers = [330]

  useEffect(() => {
    const unblock = history.block((location, action) => {

      if (hasQueueItems()) {
        Notiflix.Confirm.show(
          '⚠️ Sem conexão',
          `Ao sair dessa tela as marcações serão perdidas. 
           Permanecer e manter as marcações?`, // Texto
          'Sair',
          'Sim',
          () => {
            unblock(); // Permite a navegação
            history.push(location.pathname); // Realiza a navegação para o novo caminho
          },
          () => {},
          {
            width: '320px',
            borderRadius: '8px',
            cssAnimationDuration: 300,
            okButtonBackground: '#FF0000', // Cor do botão "Sair"
            cancelButtonBackground: theme.palette.secondary.main, // Cor do botão "Cancelar"
            fontFamily: theme.typography.fontFamily, // Fonte do projeto
            titleColor: theme.palette.text.primary, // Cor do título
            messageColor: theme.palette.text.secondary, // Cor da mensagem
            backgroundColor: theme.palette.background.paper // Cor do fundo
          }
        );

        setTimeout(() => {
          const titleElement = document.querySelector('.notiflix-confirm .notiflix-title');
          if (titleElement) {
            titleElement.innerHTML = '<img src="path/to/your-icon.png" alt="Ícone" style="width:24px; vertical-align:middle; margin-right:8px;">' + titleElement.innerHTML;
          }
        }, 10); 

        return false;
      }
    });

    // Limpa o bloqueio ao desmontar o componente
    return () => {
      unblock();
    };
  }, [history]);


  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isOpenSideMenu, setIsOpenSideMenu] = useState<boolean>(false)
  const [isFinishShiftDialogOpened, setIsFinishShiftDialogOpened] = useState(false)

  const hasQueueItems = (): boolean => {
    const stringData = localStorage.getItem(QUEUE_KEY) || '[]';
    const queueData: QueueData[] = JSON.parse(stringData);
    return queueData.length > 0;
  };

  const refreshAndEnsurePageLoads = async () => {
    setIsLoading(true)
    await refreshShiftInProgress()
    setIsLoading(false)
  }

  useEffect(() => {
    refreshAndEnsurePageLoads()
  }, [])

  const { userInfo } = useContext(AuthContext)
  const { useEvent } = useContext(CurrentShiftDataContext)
  const { shiftInProgress, executionInProgress } = useContext(ShiftInProgressContext)
  const { refreshShiftInProgress, setExecutionInProgress } = useContext(ShiftInProgressContext)
  const {
    getEventsByShiftId,
    ids,
    byId,
    patchEventStatus,
    patchEventComment,
    setUpdatedTime,
  } = useEvent()
  const { patchExecutionMessage } = useExecution()
  const { isAllowedToInvoke: isAllowedToFinishShift } = useCanAccess('care/shift.finish')
  const { isAllowedToRead: isAllowedToReadCareQuestions } = useCanAccess('care/shift/care-question')
  const shiftExecutionId = executionInProgress?.id

  const showError = () =>
    toast.error(
      `Erro ao carregar eventos ${isSelfCare(userInfo?.user) ? 'da rotina' : 'do plantão'}. Tente novamente daqui a pouco`,
    )



  async function loadPageData() {

    // if (userInfo?.id && hotjarUsers.includes(userInfo.id)) {
    //   console.log('Hotjar inicializado');
    //   hotjar.initialize(2488113, 6);
    // }

    try {
      setIsLoading(true)
      const shiftId = Number(shiftInProgress!.id)
      await getEventsByShiftId(shiftId)
    } catch (err) {
      console.log(err)
      showError()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadPageData()
  }, [])

  const currentShiftEvents = useMemo(() => {
    if (!ids) {
      return null
    }

    return ids
      .map((id) => byId[id])
      // Undefined events could happen on shiftId transition (id still not matching byId)
      // Events with non-null shiftId are the ones belonging to current shift
      .filter((event) => event && event.shiftId)
  }, [ids])

  const showToastOnError = useCallback((err) => {
    toastErrorRedirect(err, async () => {
      history.replace('/pessoas-sob-cuidado')
      await refreshShiftInProgress()
    })
  }, [])

  const patchNewEventStatus = (id: number, newStatus: EventModel['status']) => {
    patchEventStatus(id, {
      status: newStatus,
      shiftExecutionId,
      caregiverId: userInfo?.id,
    }).catch((err: AxiosError<APIError>) => {
      showToastOnError(err)
    })
  }

  const patchNewEventComment = (id: number, newComment: string) => {
    patchEventComment(id, {
      comment: newComment,
      shiftExecutionId,
      caregiverId: userInfo?.id,
    }).catch((err: AxiosError<APIError>) => {
      showToastOnError(err)
    })
  }

  const patchEventTime = (id: number, updatedTimeHappensAt: string | null) => {
    setUpdatedTime(id, {
      updatedTimeHappensAt,
      shiftExecutionId,
      caregiverId: userInfo?.id,
    }).catch((err) => {
      showToastOnError(err)
    })
  }

  const handleExecutionMessage = (newMessage: string) => {
    patchExecutionMessageDebounced.callback(newMessage)
  }

  const patchExecutionMessageDebounced = useDebouncedCallback(
    async (newMessage: string) => {
      setExecutionInProgress({
        ...executionInProgress,
        message: {
          ...executionInProgress?.message,
          message: newMessage,
        } as ExecutionMessage,
      })

      await patchExecutionMessage(Number(executionInProgress?.id), {
        shiftId: shiftInProgress?.id,
        message: newMessage,
      }).catch((err) => {
        showToastOnError(err)
      })
    },
    500,
  )

  const dateDay = format(new Date(`${shiftInProgress?.plannedToStartAt}`), 'd')
  const dateMonth = format(new Date(`${shiftInProgress?.plannedToStartAt}`), 'MMM', { locale: ptBrLocale }).toLowerCase()

  const dateWeekDay = format(new Date(`${shiftInProgress?.plannedToStartAt}`),'EEEE')

  const executionMessages = useMemo(() => getExecutionMessages(
      shiftInProgress as Shift,
      executionInProgress,
    ),
    [shiftInProgress, shiftInProgress?.executions, executionInProgress],
  )

  if (!executionInProgress) {
    return <Redirect to='/pessoas-sob-cuidado' />
  }

  return (
    <>
      {isLoading && (
        <Backdrop
          open={isLoading}
          style={{
            zIndex: theme.zIndex.drawer + 1,
            color: theme.palette.extra.color.grey.light,
            position: 'absolute',
          }}
        >
          <CircularProgress color='inherit' data-testid='table-backdrop-spinner' />
        </Backdrop>
      )}
      <Header
        leftContent={
          <MenuButton onClick={() => setIsOpenSideMenu(true)} />
        }
        centerContent={
          <PageTitle
            title={shiftInProgress ? `${shiftInProgress?.patient?.name}` : 'Menu'}
            patientId={shiftInProgress?.patientId}
          />
        }
        rightContent={
          <PscButton />
        }
      />
      <SideMenu
        isSideMenuOpen={isOpenSideMenu}
        handleToggleSideMenu={() => setIsOpenSideMenu(false)}
      />
      <Container>
        <Title>
          Roteiro do {isSelfCare(userInfo?.user) ? 'meu dia' : 'plantão'}
        </Title>
        <SubTitle>Rotinas</SubTitle>
        <DateContainer>
          <CircleContainer>
            <DateInside>
              <Typography variant='subtitle2'>
                {dateDay}/{dateMonth}
              </Typography>
            </DateInside>
          </CircleContainer>
          <DateOutside>
            <Typography variant='subtitle2'>
              {formatFullWeekdayName(dateWeekDay)}
            </Typography>
          </DateOutside>
        </DateContainer>
        <SymptomsListContextProvider currentShiftEvents={currentShiftEvents}>
          <CurrentShiftEventList
            onChangeStatus={patchNewEventStatus}
            onChangeComment={patchNewEventComment}
            onChangeTime={patchEventTime}
            events={currentShiftEvents || []}
          />
        </SymptomsListContextProvider>
        {executionMessages.length > 0 && (
          <>
            <SubTitle>Anotações e evolução do plantão</SubTitle>
            {executionMessages.map(
              (message, index) =>
                !!message && (
                  <>
                    {index !== 0 && <br />}
                    <ExecutionMessageCard
                      executionMessage={message}
                      key={`execution-message-card-${index}`}
                      data-testid={`execution-message-card-${index}`}
                    />
                  </>
                ),
            )}
          </>
        )}
        <ExecutionMessageForm
          onSubmit={() => {
            return
          }}
          initialValues={{
            message: executionInProgress?.message?.message || '',
          }}
          formProps={{
            subTitle: ' ',
            handleChange: handleExecutionMessage,
            hiddenButton: true,
          }}
        />
        {isAllowedToReadCareQuestions && (
          <>
            <SubTitle>Cuidados</SubTitle>
            <CareCategoryList />
          </>
        )}
        {isAllowedToFinishShift && (
          <FinishShiftButton
            onClick={() => setIsFinishShiftDialogOpened(true)}
            size='small'
          >
            Finalizar
          </FinishShiftButton>
        )}
        <MadeBy mainColor='black' />
      </Container>
      <FinishShiftConfirmDialog
        opened={isFinishShiftDialogOpened}
        close={() => setIsFinishShiftDialogOpened(false)}
      />
    </>
  )
}
