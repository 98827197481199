import { addMinutes, subHours } from 'date-fns';
import { format } from 'date-fns-tz';

/**
 * Receives a ISODateTime, and format to a time
 * with America/Sao_Paulo timezone (GMT -3),
 * e.g, 2022-06-06T13:00:00.000Z returns 10:00
 */
export const formatTimeToAmericaSaoPauloTimezone = (date: string): string => {
  /**
   * It removes 3 hours because the database holds the datetime as America/Sao_Paulo timezone.
   */
  const utcDatePtBr = subHours(new Date(date), 3);
  const formattedDate = format(
    // Removes timezone of the local machine that was introduced in Date instance above.
    timezoneUnawareDate(utcDatePtBr),
    'HH:mm'
  );
  return formattedDate;
};

/**
 * Receives a ISODateTime, and format to a Date
 * with America/Sao_Paulo timezone (GMT -3),
 * e.g, 2022-06-06T12:24:45.255Z returns 06/06/2022
 */
export const formatShortDateToAmericaSaoPauloTimezone = (
  date: string
): string => {
  /**
   * It removes 3 hours because the database holds the datetime as America/Sao_Paulo timezone.
   */
  const utcDatePtBr = subHours(new Date(date), 3);
  const formattedDate = format(
    // Removes timezone of the local machine that was introduced in Date instance above.
    timezoneUnawareDate(utcDatePtBr),
    'dd/MM/yy'
  );
  return formattedDate;
};

/**
 * This method will ignore time and timezone from a date, an use only the date
 * part, no matter the current local timezone for the machine running the code
 * Useful for "fixed" dates, like birthdates
 */
export const timezoneUnawareDate = (date: number | string | Date) =>
  addMinutes(new Date(date), new Date(date).getTimezoneOffset());
