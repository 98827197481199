import {
  EventSubCategoryModel,
  PatientModel,
  ProfessionalReportModel,
} from '@cuidador/database';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CreateProfessionalReportForm from '../../components/CreateProfessionalReportForm';
import Header from '../../components/Headers/Header';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import usePatient from '../../hooks/usePatient';
import useProfessionalReport from '../../hooks/useProfessionalReport/indexs';
import useSubCategory from '../../hooks/useSubCategory';
import { Container } from './styles';

const CreateProfessionalReport: React.FC = () => {
  const { getAllComplications, loading } = useSubCategory();
  const { post } = useProfessionalReport();
  const { getById } = usePatient();
  const params = useParams<{ patientId: string }>();
  const patientId = parseInt(params.patientId);
  const history = useHistory();
  const [patient, setPatient] = useState<PatientModel>();
  const [subCategories, setSubCategories] = useState<EventSubCategoryModel[]>(
    []
  );

  useEffect(() => {
    loadComplications();
    loadPatient();
  }, []);

  const loadComplications = async () => {
    const subCategories = (await getAllComplications().catch(() =>
      toast.error('Erro ao carregar lista de intercorrências')
    )) as EventSubCategoryModel[];
    const noneOption: EventSubCategoryModel = {
      id: 0,
      name: 'Nenhuma',
    };
    const subCategoriesWithNone = [noneOption, ...subCategories];
    setSubCategories(subCategoriesWithNone);
  };

  const loadPatient = async () => {
    const patient = await getById(patientId).catch(() =>
      toast.error('Erro ao carregar dados do paciente.')
    );
    setPatient(patient as PatientModel);
  };

  const submitForm = async (data: ProfessionalReportModel) => {
    try {
      await post(data);
      toast.success('Avaliação multiprofissional adicionada!');
      history.goBack();
    } catch (error) {
      console.log(error);
      toast.error(
        'Erro ao criar avaliação multiprofissional, tente novamente daqui a pouco.'
      );
    }
  };

  return (
    <>
      <Header leftButtonType="goBack" title={`${patient?.name}`} />
      <Container>
        <CreateProfessionalReportForm
          patientId={patientId}
          subCategories={subCategories}
          cancel={history.goBack}
          submit={submitForm}
        />
      </Container>
      <LoadingBackdrop loading={loading} />
    </>
  );
};

export default CreateProfessionalReport;
