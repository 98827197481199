import { ListItemIcon } from '@material-ui/core'
import React from 'react'
import { StyledListItem, StyledListItemText } from '../styles'

interface MenuItemProps {
  label: string
  icon: JSX.Element
  onClick?: () => void
}

export function MenuItem (props: MenuItemProps) {
  const { label, icon, onClick } = props

  return (
    <StyledListItem button onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <StyledListItemText>{label}</StyledListItemText>
    </StyledListItem>
  )
}
