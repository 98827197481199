import styled, { css } from 'styled-components';
import { CircularProgress, Typography } from '@material-ui/core';

export const StyledInput = styled.input`
  border-radius: 4px;
  width: 35px;
  border: 1px solid ${({ theme }) => theme.palette.extra.color.grey.main};
  padding: ${({ theme }) => theme.spacing(1)}px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div<{ clickable?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)}px;
  margin: ${({ theme }) => theme.spacing(1, 0, 0, 1)};
  ${({ clickable }) => {
    if (!!clickable)
      return css`
        &:hover {
          cursor: pointer;
        }
      `;
  }}
`;

export const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.yellow};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)}px;
  & > svg {
    color: ${({ theme }) => theme.palette.text.secondary};
    width: ${({ theme }) => theme.spacing(2)}px;
    height: ${({ theme }) => theme.spacing(2)}px;
  }
`;

export const SymptomsDescription = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const LoadingSpinner = styled(CircularProgress)`
  max-width: 10px;
  max-height: 10px;
  color: ${({ theme }) => theme.palette.text.primary};
`;
