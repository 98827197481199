import styled from 'styled-components';
import { ButtonContainerBaseStyles } from '../styles';

export const ButtonContainer = styled.div`
  button,
  button:hover,
  path[fill-rule='evenodd'],
  button:hover path[fill-rule='evenodd'] {
    background-color: ${({ theme }) => theme.palette.primary.light};
    stroke: none;
    ${ButtonContainerBaseStyles}
    min-width: 70px;

    span {
      padding: ${({ theme }) => theme.spacing(0, 0.7)};
      padding-left: ${({ theme }) => theme.spacing(0.35)}px;
      font-weight: bold;
    }

    svg {
      padding: ${({ theme }) => theme.spacing(0, 0.7)};
      padding-right: ${({ theme }) => theme.spacing(0.35)}px;
    }
  }
`;
