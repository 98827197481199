import React from 'react';
import { Backdrop, BackdropCircularProgress } from './styles';

const LoadingBackdrop: React.FC<{ loading: boolean }> = ({ loading }) => {
  return (
    <Backdrop open={loading}>
      {loading && (
        <BackdropCircularProgress data-testid="loading-backdrop-spinner" />
      )}
    </Backdrop>
  );
};

export default LoadingBackdrop;
