import { useCallback } from 'react';
import axios, { getCachedAxios } from '../../config/axios';
import { ActionType, Item, ReducerData } from '../../utils/store/index';
import { createOfflineReducer } from '../../utils/store/offlineStore';
import useReducerWithLocalStorage from '../localstorage/useReducerWithLocalStorage';

import { AnswerType, CareCategoryModel } from '@cuidador/database';
import { useDebouncedCallback } from 'use-debounce/lib';
import {
  isQuestionAnswered,
  normalizeAnswersToApi,
  normalizeAnswerToApi,
  normalizeCareCategory,
  NormalizedCareCategory,
} from '../../utils/shiftQuestion';
import { removeLocalStorageItemsByKeySubstring } from '../localstorage/utils';
import { createOfflinePostAnswer } from './postAnswerOffline';

export const getEndpoint = (shiftId: number) =>
  `/care/shift/${shiftId}/care-question`;

export const SHIFT_QUESTIONS_LOCAL_KEY = 'caregiver-shift-questions';

const initialData: ReducerData<NormalizedCareCategory> = {
  byId: {} as Record<string, Item<NormalizedCareCategory>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useShiftQuestions = (shiftId?: number) => {
  const [state, dispatch] = useReducerWithLocalStorage<
    ReducerData<NormalizedCareCategory>,
    ActionType<NormalizedCareCategory>
  >({
    initializerArg: initialData,
    key: `${SHIFT_QUESTIONS_LOCAL_KEY}-${shiftId}`,
    reducer: createOfflineReducer<NormalizedCareCategory>(),
  });

  const clearAllLocalShiftQuestions = useCallback(() => {
    removeLocalStorageItemsByKeySubstring(SHIFT_QUESTIONS_LOCAL_KEY);
  }, []);

  const getQuestionsByShiftId = useCallback(
    async (shiftId: number) => {
      // fetch questions once and then get from cache
      if (state.total) return;
      try {
        dispatch({ type: 'LOADING' });
        const response = await getCachedAxios().get(`${getEndpoint(shiftId)}`);
        const questionIndex = { index: 0 }; // initial question index. it will mutate while normalizing questions
        dispatch({
          type: 'GET_ALL',
          payload: response.data.careCategories.map(
            (careCategory: CareCategoryModel) =>
              normalizeCareCategory(
                // fill care question answer config (careQuestion.answerConfig) with related care question answer (careQuestionAnswer.questionAnswerData) before normalize
                {
                  ...careCategory,
                  careLines: careCategory.careLines?.map((careline) => ({
                    ...careline,
                    careQuestions: careline.careQuestions?.map(
                      (careQuestion) => ({
                        ...careQuestion,
                        answerConfig:
                          careQuestion.careQuestionAnswers?.find(
                            (careQuestionAnswer) =>
                              careQuestionAnswer.careQuestionId ===
                              careQuestion.id
                          )?.questionAnswerData || careQuestion.answerConfig,
                      })
                    ),
                  })),
                },
                questionIndex
              )
          ),
        });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        throw err;
      }
    },
    [state.total]
  );

  const saveAnswer = useCallback(
    (questionId, answer, { careCategoryIndex, careLineIndex }) => {
      const careCategory = state.byId[state.ids[careCategoryIndex]];

      // index of first question of this category. it will mutate while normalizing questions
      const firstQuestionIndex = {
        index: careCategory.careLines[0].careQuestions[0].questionIndex,
      };

      // update counters and save answer locally
      const updatedCategory = normalizeCareCategory(
        {
          ...careCategory,
          careLines: careCategory.careLines.map((careLine, index) =>
            index !== careLineIndex
              ? careLine
              : {
                  ...careLine,
                  careQuestions: careLine.careQuestions?.map((question) =>
                    questionId !== question.id
                      ? question
                      : { ...question, answerConfig: answer }
                  ),
                }
          ),
        },
        firstQuestionIndex
      );

      dispatch({
        type: 'UPDATE',
        payload: { ...updatedCategory, id: updatedCategory.id as number },
      });

      // if questions is answered, send answer to api (or send to offline queue)
      if (isQuestionAnswered(answer)) {
        sendAnswerDebounce.callback(questionId, answer);
      }
    },
    [state]
  );

  const buildNormalizedAnswersToApi = useCallback(() => {
    return normalizeAnswersToApi(state.ids.map((id) => state.byId[id]));
  }, [state]);

  const sendAnswer = useCallback(
    async (questionId: number, answer: AnswerType) => {
      const normalizedAnswer = normalizeAnswerToApi({
        id: questionId,
        answerConfig: answer,
      });
      const postOfflineAnswer = createOfflinePostAnswer(
        axios,
        normalizedAnswer
      );
      await postOfflineAnswer(getEndpoint(shiftId!), normalizedAnswer);
    },
    []
  );

  const sendAnswerDebounce = useDebouncedCallback(sendAnswer, 500);

  return {
    ...state,
    clearAllLocalShiftQuestions,
    getQuestionsByShiftId,
    saveAnswer,
    buildNormalizedAnswersToApi,
    sendAnswer,
  };
};

export default useShiftQuestions;
