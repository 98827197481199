import React, { useEffect } from 'react';
import { Container, TextWrap, TitleWrap } from './styles';

import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
import useContent from '../../../hooks/useContent';

import { Typography } from '@material-ui/core';
import Header from '../../../components/Headers/Header';
import LoadingBackdrop from '../../../components/LoadingBackdrop';

const ContentItem: React.FC = () => {
  const { getById, byId, loading } = useContent();
  const params = useParams<{ id: string }>();

  const itemId = parseInt(params.id);

  useEffect(() => {
    getById(itemId);
  }, [itemId]);

  const contentItem = byId[itemId];

  if (loading) {
    return <LoadingBackdrop loading={loading} />;
  }

  return (
    <>
      <Header title="Leitura de conteúdo" leftButtonType="goBack" />
      <Container>
        <TitleWrap>
          <Typography variant="h6">{contentItem?.title}</Typography>
        </TitleWrap>
        <TextWrap>
          <Typography variant="subtitle2">
            {parse(contentItem?.text || '')}
          </Typography>
        </TextWrap>
      </Container>
    </>
  );
};

export default ContentItem;
