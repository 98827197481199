import React, { createContext, useContext, useEffect } from 'react';
import useEmergencyContacts from '../hooks/useEmergencyContacts';
import useEvent from '../hooks/useEvent';
import useMedication from '../hooks/useMedication';
import useShiftQuestions from '../hooks/useShiftQuestions';
import useShoppingList from '../hooks/useShoppingList';
import useSubCategory from '../hooks/useSubCategory';
import { ShiftInProgressContext } from './ShiftInProgress';

export type ContextProps = {
  useEvent: () => ReturnType<typeof useEvent>;
  useShiftQuestions: () => ReturnType<typeof useShiftQuestions>;
};

export const CurrentShiftDataContext = createContext<ContextProps>(
  {} as ContextProps
);

export const useCurrentShift = () => {
  const context = useContext(CurrentShiftDataContext);
  return context;
};

export const CurrentShiftDataProvider: React.FC = ({ children }) => {
  const { shiftInProgress } = useContext(ShiftInProgressContext);
  const eventHookData = useEvent(shiftInProgress!.id!);
  const questionHookData = useShiftQuestions(shiftInProgress!.id!);
  const {
    getAllByPatientId: getEmergencyContactsByPatientId,
  } = useEmergencyContacts();
  const {
    getAllAppointments,
    getAllRoutines,
    getAllComplications,
    getAllMeasurements,
  } = useSubCategory();
  const { getAllMedicationsIfNecessaryByPatientId } = useMedication();
  const { getOneByPatientId: getShoppingListByPatientId } = useShoppingList();

  useEffect(() => {
    if (shiftInProgress) {
      // cache events
      eventHookData.getEventsByShiftId(shiftInProgress!.id!);
      // cache careQuestions
      questionHookData.getQuestionsByShiftId(shiftInProgress!.id!);
      // cache emergency contacts
      getEmergencyContactsByPatientId(shiftInProgress.patientId!);
      // cache event subcategories
      getAllAppointments();
      getAllRoutines();
      getAllComplications();
      getAllMeasurements();
      // cache event medications
      getAllMedicationsIfNecessaryByPatientId(shiftInProgress.patientId!);
      // cache shopping list
      getShoppingListByPatientId(shiftInProgress.patientId!);
    }
  }, [shiftInProgress]);

  return (
    <CurrentShiftDataContext.Provider
      value={{
        useEvent: () => eventHookData,
        useShiftQuestions: () => questionHookData,
      }}
    >
      {children}
    </CurrentShiftDataContext.Provider>
  );
};

export default CurrentShiftDataProvider;
