import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import HeaderWithSignOut from '../../../components/Headers/HeaderWithSignOut';
import { AuthContext } from '../../../contexts/auth';
import {
  Container,
  CardContainer,
  CardText,
  StyledWarningIcon,
} from '../styles';

const NoOrganizationIdBlock: React.FC = () => {
  const { userInfo, refreshUserInfo } = useContext(AuthContext);
  const history = useHistory();

  const userHasOrganizationId = userInfo?.user?.organizationId;

  const handleRefreshUserInfo = async () => {
    try {
      await refreshUserInfo();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleRefreshUserInfo();

    if (userHasOrganizationId) {
      history.push('/');
    }
  }, []);

  return (
    <>
      <HeaderWithSignOut title="" />
      <Container>
        <CardContainer>
          <StyledWarningIcon color="primary" />
          <CardText color="primary" data-testid="first-block">
            Você <b>não está vinculado(a)</b> a nenhuma rede de cuidado.
          </CardText>
          <CardText color="primary" data-testid="second-block">
            Para voltar a usar o aplicativo, solicite o vínculo a um paciente.
          </CardText>
        </CardContainer>
      </Container>
    </>
  );
};

export default NoOrganizationIdBlock;
