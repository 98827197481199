import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useCanAccess from '../../../hooks/useCanAccess';
import PscIcon from '../../Icons/PscIcon';
import HeaderBase, { HeaderProps } from '../HeaderBase';
import Drawer from './Drawer';
import { IconBackground, StyledMenuButton, StyledMenuIcon } from './styles';

const HeaderWithDrawer: React.FC<HeaderProps> = ({ ...props }) => {
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { isAllowedToRead: isAllowedToReadPatients } = useCanAccess(
    'user/patient'
  );

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <HeaderBase
        leftContent={
          <StyledMenuButton
            onClick={() => {
              handleToggleDrawer();
            }}
          >
            <StyledMenuIcon />
          </StyledMenuButton>
        }
        rightContent={
          isAllowedToReadPatients &&
          location.pathname !== '/' && (
            <IconBackground to="/pessoas-sob-cuidado">
              <PscIcon />
            </IconBackground>
          )
        }
        {...props}
      />
      <Drawer
        isDrawerOpen={isDrawerOpen}
        handleToggleDrawer={handleToggleDrawer}
      />
    </>
  );
};

export default HeaderWithDrawer;
