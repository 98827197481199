import React from 'react';
import { subCategoryIdMapper } from '../../../VitalSignsMeasurementInput/utils';
import BloodGlycemiaInput from './BloodGlycemiaInput';
import BloodPressureInput from './BloodPressureInput';
import BodyTemperatureInput from './BodyTemperatureInput';
import BreathRateInput from './BreathRateInput';
import DefaultInput from './DefaultInput';
import HeartRateInput from './HeartRateInput';
import OximetryRateInput from './OximetryRateInput';
import WeightInput from './WeightInput';

interface MeasurementInputProps {
  subCategoryId?: number | string;
  onChange: (value: string) => void;
  value?: string;
}

const MeasurementInput: React.FC<
  MeasurementInputProps & Partial<HTMLInputElement>
> = ({ subCategoryId, ...props }) => {
  if (subCategoryId === subCategoryIdMapper.BLOOD_GLYCEMIA)
    return <BloodGlycemiaInput {...props} />;
  else if (subCategoryId === subCategoryIdMapper.BODY_TEMPERATURE)
    return <BodyTemperatureInput {...props} />;
  else if (subCategoryId === subCategoryIdMapper.BREATH_RATE)
    return <BreathRateInput {...props} />;
  else if (subCategoryId === subCategoryIdMapper.HEART_RATE)
    return <HeartRateInput {...props} />;
  else if (subCategoryId === subCategoryIdMapper.OXIMETRY_RATE)
    return <OximetryRateInput {...props} />;
  else if (subCategoryId === subCategoryIdMapper.BLOOD_PRESSURE)
    return <BloodPressureInput {...props} />;
  else if (subCategoryId === subCategoryIdMapper.WEIGHT_MEASURMENT)
    return <WeightInput {...props} />;

  return <DefaultInput {...props} />;
};

export default MeasurementInput;
