import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { StyledFormControl, StyledSelect } from './styles';
import { ColorsType } from '../../styles/colorMapping';

interface SelectDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-testid'?: string;
}

export interface SelectProps {
  name?: string;
  label?: string;
  value?: string;
  id: string;
  error?: boolean;
  disabled?: boolean;
  color?: ColorsType;
  formControlVariant?: 'standard' | 'outlined' | 'filled';
  onChange?: (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => void;
  SelectDisplayProps?: SelectDisplayProps;
}

const StyledSelectField: React.FC<SelectProps> = ({
  id,
  label,
  children,
  error,
  disabled,
  color = 'primary',
  formControlVariant = 'outlined',
  ...props
}) => {
  return (
    <StyledFormControl
      $color={color}
      fullWidth
      error={Boolean(error)}
      disabled={disabled}
      variant={formControlVariant}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <StyledSelect
        variant="outlined"
        label={label}
        labelId={id}
        disabled={disabled}
        {...props}
      >
        {children}
      </StyledSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </StyledFormControl>
  );
};

export default StyledSelectField;
