import { CaregiverModel, UserModel } from '@cuidador/database';
import * as Yup from 'yup';
import { numberMask, phoneMask } from '../../../utils/inputs';

export interface FormValues {
  picture?: File;
  name: string;
  cpf: string;
  email: string;
  phoneNumber: string;
}

export const userModelToFormData = (user: UserModel): FormValues => ({
  name: user?.name || '',
  cpf: user?.cpf || '',
  email: user?.email || '',
  phoneNumber: user?.phoneNumber ? phoneMask(user.phoneNumber) : '',
});

export const formDataToCaregiverModel = (values: FormValues) =>
  ({
    user: {
      name: values.name,
      email: values.email,
      phoneNumber: numberMask(`55${values.phoneNumber}`),
    },
  } as CaregiverModel);

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Por favor, insira o nome completo')
    .min(3, 'O nome deve conter ao menos 3 caracteres')
    .test(
      'isFullName',
      'Por favor, insira o nome completo',
      (value) => (value || '').split(' ').length > 1
    ),
  email: Yup.string()
    .required('Por favor, insira um email')
    .email('Por favor, insira um e-mail válido'),
  phoneNumber: Yup.string()
    .required('Por favor, insira um celular')
    .min(15, 'Por favor, insira um celular válido')
    .max(15, 'Por favor, insira um celular válido'),
});
