import React, { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce/lib'
import { heartRateMask } from '../../../utils/inputs'
import { Row, StyledInput } from '../styles'
import { NewMeasurementValue } from '../../CurrentShiftEventList/CurrentShiftCards/ScheduledMeasurementCard/Measurement'
import { validateHeartRate } from '../utils'

interface HeartRateInputProps {
  value?: string
  patchEvent: (
    newMeasurementValue: NewMeasurementValue,
    status: 'awaiting' | 'accomplished' | 'not_accomplished'
  ) => void
  id?: string
  disabled?: boolean
}

const HeartRateInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  HeartRateInputProps
> = ({ value, patchEvent, id, disabled }, ref) => {
  const [heartRate, setHeartRate] = useState(value || '')

  const onChangeAnyValue = useDebouncedCallback(
    (newMeasurementValue: NewMeasurementValue) => {
      const newStatus = newMeasurementValue.measurementValue
        ? 'accomplished'
        : 'awaiting'
      patchEvent(newMeasurementValue, newStatus)
    },
    400,
  )

  return (
    <Row>
      <StyledInput
        type='text'
        value={heartRate}
        onChange={(e) => {
          const formattedValue = heartRateMask(e.target.value === '0' ? '' : parseInt(e.target.value).toString())
          const complicationMeasurement = validateHeartRate(formattedValue)
          onChangeAnyValue.callback({
            ...complicationMeasurement,
            measurementValue: formattedValue,
          })
          setHeartRate(formattedValue)
        }}
        disabled={disabled}
        ref={ref}
        id={`${id}-rate`}
        // The bellow attribute makes the input keyboard be set to only numbers on smartphones.
        pattern='\d*'
      />
      <span>bpm</span>
    </Row>
  )
}

export default React.forwardRef(HeartRateInput)
