import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useHistory } from 'react-router-dom';
import HeaderWithTitle from '../HeaderWithTitle';
import PscIcon from '../../Icons/PscIcon';
import {
  IconBackground,
  StyledBackIcon,
  StyledBlankDiv,
  StyledIconButton,
  StyledPhoneIcon,
} from './styles';

export type HeaderProps = {
  title: string;
  rightButtonType?: 'phone' | 'remove' | 'psc';
  leftButtonType?: 'goBack';
  rightIconClick?: () => void;
  leftIconClick?: () => void;
};

const Header: React.FC<HeaderProps> = ({
  title,
  rightButtonType,
  rightIconClick,
  leftIconClick,
}: HeaderProps) => {
  const history = useHistory();

  const actions = {
    phone: (
      <IconBackground to="/ajuda">
        <StyledPhoneIcon fontSize="small" />
      </IconBackground>
    ),
    remove: <DeleteIcon fontSize="large" />,
    psc: (
      <IconBackground to="/pessoas-sob-cuidado">
        <PscIcon />
      </IconBackground>
    ),
  };

  return (
    <>
      <HeaderWithTitle
        leftContent={
          <StyledIconButton
            edge="end"
            color="inherit"
            data-testid="back-button"
            onClick={!leftIconClick ? () => history.goBack() : leftIconClick}
          >
            <StyledBackIcon />
          </StyledIconButton>
        }
        title={title}
        rightContent={
          rightButtonType ? (
            <StyledIconButton
              edge="end"
              color="inherit"
              data-testid={rightButtonType}
              onClick={rightIconClick}
            >
              {actions[rightButtonType]}
            </StyledIconButton>
          ) : (
            <StyledBlankDiv />
          )
        }
      />
    </>
  );
};

export default Header;
