import { ShiftModel } from '@cuidador/database';
import format from 'date-fns/format';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../components/Headers/Header';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import ShiftsHistory from '../../components/ShiftsHistory';
import StyledButton from '../../components/StyledButton';
import useShift from '../../hooks/useShift';
import { Container } from './styles';

const ShiftHistory: React.FC = () => {
  const [page, setPage] = useState(0);
  const [shifts, setShifts] = useState<ShiftModel[]>([]);
  const [patientName, setPatientName] = useState('');
  const params = useParams<{ patientId: string }>();
  const history = useHistory();
  const { getByPatientIdPaginated, total, loading } = useShift();
  const patientId = parseInt(params.patientId);

  /**
   * groupBy year + month, then group by day
   * Map<'YYYYMM', Map<'DD', ShiftModel>>
   */
  const [groupedShifts, setGroupedShifts] = useState<
    Map<string, Map<string, ShiftModel[]>>
  >(new Map<string, Map<string, ShiftModel[]>>());

  const fetchData = () => {
    const params = qs.parse(history.location.search);
    if (patientId) {
      getByPatientIdPaginated(patientId, {
        ...params,
        page,
        limit: 10,
        orderBy: 'plannedToStartAt',
        order: 'DESC',
      })
        .then((response) => {
          const loadedShifts = response.data.results;
          if (page === 0) {
            setShifts(loadedShifts);
          } else {
            setShifts([...shifts, ...loadedShifts]);
          }
          setPatientName(getPatientName(response.data.results));
        })
        .catch((e) => {
          toast.error(e);
        });
    }
  };

  const handleShiftGroup = () => {
    const map = new Map(groupedShifts);
    for (const shift of shifts) {
      const mapKeyYYYYMM = format(
        new Date(String(shift.plannedToStartAt)),
        'yyyyMM'
      );
      const mapKeyDD = format(new Date(String(shift.plannedToStartAt)), 'dd');

      if (map.has(mapKeyYYYYMM)) {
        const innerMap = map.get(mapKeyYYYYMM)!;
        if (innerMap.has(mapKeyDD)) {
          const shifts = innerMap.get(mapKeyDD)!;
          if (!shifts.find((s) => s.id === shift.id)) {
            innerMap.set(mapKeyDD, [...shifts, shift]);
          }
        } else {
          innerMap.set(mapKeyDD, [shift]);
        }
      } else {
        map.set(
          mapKeyYYYYMM,
          new Map<string, ShiftModel[]>([[mapKeyDD, [shift]]])
        );
      }
    }
    setGroupedShifts(map);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    handleShiftGroup();
  }, [shifts]);

  const pageIncrement = () => {
    setPage(page + 1);
  };

  const getPatientName = (shifts: ShiftModel[]) =>
    shifts[0].patient?.name || '';

  return (
    <>
      <Header
        title={`Histórico de Plantões - ${patientName}`}
        leftIconClick={() => history.goBack()}
      />
      <Container>
        <ShiftsHistory shifts={groupedShifts} />
        {loading && <LoadingBackdrop loading />}
        {shifts.length < total && (
          <StyledButton
            data-testid="show-more"
            size="medium"
            color="secondary"
            onClick={pageIncrement}
          >
            Ver mais
          </StyledButton>
        )}
      </Container>
    </>
  );
};

export default ShiftHistory;
