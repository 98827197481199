import React, { useContext, useEffect, useState } from 'react';
import { ShiftModel } from '@cuidador/database';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sortBy } from 'lodash';
import Header from '../../components/Headers/Header';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import { AuthContext } from '../../contexts/auth';
import useShift from '../../hooks/useShift';
import { ShiftExecutionCard } from './ShiftExecutionCard';
import { Container, Divider, ExecutionList } from './styles';
import { formatShift } from './utils';
import ShiftExecutionTimeline from './ExecutionTimeline';

export interface ExecutionTimelineSlot {
  status: 'occupied' | 'in_another_shift';
  startsAtPercentage: number;
  durationPercentage: number;
}

export type ExecutionTimeline = ExecutionTimelineSlot[];

const ShiftContestation: React.FC = () => {
  const history = useHistory();
  const params = useParams<{ shiftId: string }>();
  const { userInfo } = useContext(AuthContext);
  const shiftId = parseInt(params.shiftId);

  const [shift, setShift] = useState<ShiftModel>();
  const [executionTimeline, setExecutionTimeline] = useState<
    ExecutionTimeline | undefined
  >(undefined);
  const { getById, getTimeline, loading } = useShift();

  const fetchData = () => {
    if (shiftId) {
      getById(shiftId)
        .then((response) => {
          setShift(formatShift(response as ShiftModel));
        })
        .catch((e) => {
          toast.error(e);
        });
    }
  };

  useEffect(() => {
    fetchData();
    getTimeline(shiftId).then((data) => {
      setExecutionTimeline(data);
    });
  }, [shiftId]);

  return (
    <>
      <Header
        title={'Correção de horários'}
        leftIconClick={() => history.goBack()}
      />
      <Container>
        {loading && <LoadingBackdrop loading />}
        {!!shift && !!executionTimeline && (
          <ShiftExecutionTimeline shift={shift} timeline={executionTimeline} />
        )}
        <ExecutionList>
          {sortBy(shift?.executions, ({ startedAt }) => startedAt)?.map(
            (execution, index) => (
              <>
                {index !== 0 && <Divider />}
                <ShiftExecutionCard
                  shiftId={shiftId}
                  caregiverId={userInfo?.id}
                  execution={execution}
                  index={index}
                  key={`shift-execution-card-${index}`}
                />
              </>
            )
          )}
        </ExecutionList>
      </Container>
    </>
  );
};

export default ShiftContestation;
