import { isEqual } from 'date-fns';
import { ActionType, Item, ReducerData, ReducerProps } from './';

type WithCreatedAt<T> = {
  createdAt: string;
} & Item<T>;

function getIdsAndContent<T>(data: Item<T>[], state: ReducerData<T>) {
  const payloadIds: Array<number> = [];
  const payloadById = data.reduce<Record<string, Item<T>>>((prev, cur) => {
    if (cur.id) {
      payloadIds.push(cur.id);
      prev[cur.id] = cur;
    }
    return prev;
  }, {});

  /* check for state data that isn't on payload with the same ID,
   * then checks if createdAt field has the same date, so it can be
   * replaced from an "without ID" state to a "backend payload" state
   */
  const localStorageIds: Array<number> = [];
  const localStorageById = state.ids
    .map((id) => state.byId[id])
    .reduce<Record<string, Item<T>>>((prev, cur) => {
      const currentItem = cur as WithCreatedAt<T>;
      if (cur?.id) {
        const existsOnPayloadById = data.find((item) => item.id == cur.id);

        // if it exists on payload by ID, use payload data
        if (existsOnPayloadById) return prev;
        const currentItemDate = new Date(String(currentItem?.createdAt));
        const existsOnPayloadByCreatedAt = (data as WithCreatedAt<T>[])
          .map((item) => new Date(String(item.createdAt)))
          .find((itemDate) => isEqual(itemDate, currentItemDate));

        // if createdAt from payload and state is the same, use payload data
        if (existsOnPayloadByCreatedAt) return prev;

        // if ID and createdAt is not equal from payload, add item to state
        localStorageIds.push(cur.id);
        prev[cur.id] = cur;
      }
      return prev;
    }, {});

  return {
    byId: { ...payloadById, ...localStorageById },
    ids: [...payloadIds, ...localStorageIds],
    total: payloadIds.length + localStorageIds.length,
  };
}

// returns the current date in ms as a negative value, just to have a valid number as fake ID
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function createOfflineResolvedId<T>(payload: WithCreatedAt<T>): number {
  return -Date.now();
}

export const createOfflineReducer = <T>() => (
  state: ReducerData<T>,
  action: ActionType<T>
): ReducerProps<T> => {
  switch (action.type) {
    case 'GET_ALL':
      return {
        ...state,
        ...getIdsAndContent<T>(action.payload, state),
        loading: false,
      };
    case 'GET_BY_ID':
      return {
        ...state,
        ...getIdsAndContent<T>([action.payload as Item<T>], state),
        loading: false,
      };
    case 'CREATE':
      // if ID is not present on payload, generates one for localStorage
      if (!action.payload?.id) {
        action.payload.id = createOfflineResolvedId<T>(
          action.payload as WithCreatedAt<T>
        );
      }
      return {
        ...state,
        ids: Array.from(new Set([...state.ids, action.payload.id])),
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload,
          },
        },
        total: state.total + 1,
        loading: false,
      };
    case 'UPDATE':
      return {
        ...state,
        ids: Array.from(new Set([...state.ids, action.payload.id])),
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload,
          },
        },
        loading: false,
      };
    case 'REMOVE':
      return {
        ...state,
        ids: state.ids.filter((id: number) => id !== action.payload.id),
        byId: { ...state.byId, [action.payload.id]: undefined },
        total: state.total - 1,
        loading: false,
      };
    case 'PAGINATION':
      return {
        ...state,
        ...getIdsAndContent<T>(action.payload.results, state),
        total: action.payload.total,
        loading: false,
      };
    case 'ERROR':
      return { ...state, error: action.payload, loading: false };
    case 'LOADING':
      return { ...state, loading: true };
    default:
      return state;
  }
};
