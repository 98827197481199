/**
 * Clear all data that includes `substring` string
 */
export const removeLocalStorageItemsByKeySubstring = (substring: string) => {
  const keysToDelete = Object.keys(localStorage).filter((key) =>
    key.includes(substring)
  );
  keysToDelete.forEach((key) => {
    localStorage.removeItem(key);
  });
};
