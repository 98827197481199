import { useCallback } from 'react';
import axios from '../config/axios';

const useShiftTimeContestation = () => {
  const post = useCallback(
    async (shiftExecutionId: number, { endedAt, startedAt, justification }) => {
      try {
        await axios.post(
          `/care/shift/execution/${shiftExecutionId}/time-contestation`,
          {
            endedAt,
            startedAt,
            justification,
          }
        );
      } catch (err) {
        return Promise.reject(err);
      }
    },
    []
  );

  return { post };
};

export default useShiftTimeContestation;
