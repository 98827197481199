import { subCategoryIdMapper } from '../../../VitalSignsMeasurementInput/utils';

export const getMeasurementUnit = (subCategoryId?: number): string => {
  switch (subCategoryId) {
    case subCategoryIdMapper.BLOOD_GLYCEMIA:
      return 'mg/dL';
    case subCategoryIdMapper.BODY_TEMPERATURE:
      return '°C';
    case subCategoryIdMapper.BREATH_RATE:
      return 'mrm';
    case subCategoryIdMapper.HEART_RATE:
      return 'bpm';
    case subCategoryIdMapper.OXIMETRY_RATE:
      return '%SaO2';
    case subCategoryIdMapper.BLOOD_PRESSURE:
      return 'mmHg';
    case subCategoryIdMapper.WEIGHT_MEASURMENT:
      return 'Kg';
    default:
      return '';
  }
};
