import {
  EventModel,
  EventSubCategoryModel,
  MedicationModel,
} from '@cuidador/database';
import * as Sentry from '@sentry/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CreateAppointmentEventForm from '../../components/CreateShiftEvent/CreateAppointmentEventForm';
import CreateComplicationEventForm from '../../components/CreateShiftEvent/CreateComplicationEventForm';
import CreateMeasurementEventForm from '../../components/CreateShiftEvent/CreateMeasurementEventForm';
import CreateMedicationEventForm from '../../components/CreateShiftEvent/CreateMedicationEventForm';
import CreateRoutineEventForm from '../../components/CreateShiftEvent/CreateRoutineEventForm';
import ExecutionMessageForm from '../../components/ExecutionMessageForm';
import { FormValues } from '../../components/ExecutionMessageForm/utils';
import Header from '../../components/Headers/Header';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import { CurrentShiftDataContext } from '../../contexts/CurrentShiftData';
import { ShiftInProgressContext } from '../../contexts/ShiftInProgress';
import useExecution from '../../hooks/useExecution/index';
import useMedication from '../../hooks/useMedication';
import useSubCategory from '../../hooks/useSubCategory';
import { resolveErrorMessage } from '../../utils/error';
import { Container, OutterContainer } from './styles';
import {
  resolveEventCreationBody,
  resolveParamToProperEventType,
} from './utils';

const resolveTitleText = (text: string) => {
  switch (text) {
    case 'rotina':
      return 'rotina';
    case 'compromisso':
      return 'compromisso';
    case 'intercorrencia':
      return 'intercorrência';
    case 'medicamento':
      return 'medicamento';
    case 'medicao':
      return 'controle';
    case 'anotacoes':
      return 'anotações';
    default:
      return '';
  }
};

const CreateShiftEvent: React.FC = () => {
  const [subCategories, setSubCategories] = useState<EventSubCategoryModel[]>(
    []
  );
  const [medications, setMedications] = useState<MedicationModel[]>([]);
  const {
    shiftInProgress,
    executionInProgress,
    minShiftLimitDate,
    maxShiftLimitDate,
    refreshShiftInProgress,
  } = useContext(ShiftInProgressContext);
  const { useEvent } = useContext(CurrentShiftDataContext);
  const params = useParams<{ eventType: string }>();
  const history = useHistory();
  const titleText = resolveTitleText(String(params.eventType));
  const {
    getAllAppointments,
    getAllRoutines,
    getAllComplications,
    getAllMeasurements,
    loading,
  } = useSubCategory();
  const {
    getAllMedicationsIfNecessaryByPatientId,
    loading: loadingMedication,
  } = useMedication();
  const { createShiftEvent } = useEvent();
  const { patchExecutionMessage } = useExecution();
  const [maxDateTime, setMaxDateTime] = useState<string>();
  const [minDateTime, setMinDateTime] = useState<string>();

  useEffect(() => {
    if (maxShiftLimitDate) {
      setMaxDateTime(maxShiftLimitDate.toISOString());
    }
    if (minShiftLimitDate) {
      setMinDateTime(minShiftLimitDate.toISOString());
    }
  }, [maxShiftLimitDate, minShiftLimitDate]);

  useEffect(() => {
    resolveLoad();
  }, [params.eventType]);

  const resolveLoad = () => {
    switch (params.eventType) {
      case 'medicamento':
        return loadMedications();
      case 'anotacoes':
        break;
      default:
        return loadSubCategories();
    }
  };

  const loadMedications = async () => {
    const medications = await getAllMedicationsIfNecessaryByPatientId(
      Number(shiftInProgress?.patientId)
    ).catch(() => toast.error('Erro ao carregar medicamentos'));
    setMedications(medications as MedicationModel[]);
  };

  const loadSubCategories = async () => {
    const subCategories = await resolveGetAllSubCategories().catch(() =>
      toast.error('Erro ao carregar tipos')
    );
    setSubCategories(subCategories as EventSubCategoryModel[]);
  };

  const resolveGetAllSubCategories = () => {
    switch (params.eventType) {
      case 'rotina':
        return getAllRoutines();
      case 'compromisso':
        return getAllAppointments();
      case 'intercorrencia':
        return getAllComplications();
      case 'medicao':
        return getAllMeasurements();
      default:
        throw new Error('Invalid event type');
    }
  };

  const handleCreateEvent = async (
    eventData: EventModel,
    continuousAdd?: boolean
  ) => {
    const eventType = resolveParamToProperEventType(params.eventType);
    const dataToSend = resolveEventCreationBody({
      eventType,
      eventData,
      medications,
      subCategories,
      minShiftLimitDate,
      maxShiftLimitDate,
      shiftInProgress,
      executionInProgress,
    });

    try {
      await createShiftEvent(eventType, dataToSend);
      toast.success('Adicionado com sucesso!');
      if (!continuousAdd) history.goBack();
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      const displayMessage = resolveErrorMessage(error);
      if (displayMessage) {
        toast.error(displayMessage);
      }
    }
  };

  const handleExecutionMessage = async (values: FormValues) => {
    try {
      await patchExecutionMessage(Number(executionInProgress?.id), {
        shiftId: shiftInProgress?.id,
        message: values.message,
      });
      refreshShiftInProgress();
      toast.success('Anotação adicionada com sucesso!');
      history.goBack();
    } catch (error) {
      console.log(error);
      toast.error('Erro ao salvar anotação, tente novamente daqui a pouco.');
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const renderCreateEventShiftForms = ({
    eventType,
  }: {
    eventType: string;
  }) => {
    switch (eventType) {
      case 'rotina':
        return (
          subCategories && (
            <CreateRoutineEventForm
              submit={handleCreateEvent}
              cancel={handleCancel}
              subCategories={subCategories}
              maxDateTime={maxDateTime}
              minDateTime={minDateTime}
            />
          )
        );
      case 'compromisso':
        return (
          subCategories && (
            <CreateAppointmentEventForm
              submit={handleCreateEvent}
              cancel={handleCancel}
              subCategories={subCategories}
              minDateTime={minDateTime}
            />
          )
        );
      case 'intercorrencia':
        return (
          subCategories && (
            <CreateComplicationEventForm
              submit={handleCreateEvent}
              cancel={handleCancel}
              subCategories={subCategories}
              maxDateTime={maxDateTime}
              minDateTime={minDateTime}
            />
          )
        );
      case 'medicamento':
        return (
          medications && (
            <CreateMedicationEventForm
              submit={handleCreateEvent}
              cancel={handleCancel}
              medications={medications}
              maxDateTime={maxDateTime}
              minDateTime={minDateTime}
            />
          )
        );
      case 'medicao':
        return (
          subCategories && (
            <CreateMeasurementEventForm
              submit={handleCreateEvent}
              cancel={handleCancel}
              subCategories={subCategories}
              maxDateTime={maxDateTime}
              minDateTime={minDateTime}
            />
          )
        );
      case 'anotacoes':
        return (
          <ExecutionMessageForm
            onSubmit={handleExecutionMessage}
            initialValues={{
              message: executionInProgress?.message?.message || '',
            }}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <OutterContainer>
        {loading ? (
          <LoadingBackdrop loading={loading || loadingMedication} />
        ) : (
          <>
            <Header leftButtonType="goBack" title={`Adicionar ${titleText}`} />
            <Container>
              {renderCreateEventShiftForms({
                eventType: String(params.eventType),
              })}
            </Container>
          </>
        )}
      </OutterContainer>
    </>
  );
};

export default CreateShiftEvent;
