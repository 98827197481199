import React from 'react';
import { weightMeasurementMask } from '../../../../../utils/inputs';
import FormikTextField from '../../../../Forms/FormikTextField';
import { InputContainer } from '../styles';

interface WeightInputProps {
  value?: string;
  onChange: (value: string) => void;
}

const WeightInput: React.FC<WeightInputProps & Partial<HTMLInputElement>> = ({
  value,
  onChange,
  ...props
}) => {
  return (
    <InputContainer>
      <FormikTextField
        color="primary"
        inputProps={{
          'data-testid': 'measurementInput',
          maxlength: 255,
        }}
        label="Valor"
        margin="normal"
        autoComplete="off"
        placeholder="62"
        value={value}
        onChange={(e) => {
          const formattedValue = weightMeasurementMask(e.target.value);
          onChange(formattedValue);
        }}
        {...props}
      />
      {'Kg'}
    </InputContainer>
  );
};

export default WeightInput;
