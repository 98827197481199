import React from 'react';
import { ShiftModel } from '@cuidador/database';
import {
  BoundaryTimestamps,
  ExecutionTimelineSlotBlock,
  Legend,
  LegendColor,
  LegendContainer,
  LegendLabel,
  ParentTimeline,
  TimelineContainer,
  Timestamp,
} from './styles';
import { useTheme } from 'styled-components';
import {
  formatShortDateToAmericaSaoPauloTimezone,
  formatTimeToAmericaSaoPauloTimezone,
} from '../../../utils/dates';
import red from '@material-ui/core/colors/red';
import { ExecutionTimeline } from '..';

interface ShiftExecutionTimelineProps {
  timeline: ExecutionTimeline | undefined;
  shift: ShiftModel;
}

const ShiftExecutionTimeline: React.FC<ShiftExecutionTimelineProps> = ({
  timeline,
  shift,
}) => {
  const theme = useTheme();

  if (!shift || !timeline) return null;

  return (
    <>
      <BoundaryTimestamps>
        {!!shift.plannedToStartAt && !!shift.plannedToEndAt && (
          <>
            <Timestamp>
              <p>
                {formatShortDateToAmericaSaoPauloTimezone(
                  shift.plannedToStartAt
                )}
              </p>
              <p>
                {formatTimeToAmericaSaoPauloTimezone(shift.plannedToStartAt)}
              </p>
            </Timestamp>
            <Timestamp>
              <p>
                {formatShortDateToAmericaSaoPauloTimezone(shift.plannedToEndAt)}
              </p>
              <p>{formatTimeToAmericaSaoPauloTimezone(shift.plannedToEndAt)}</p>
            </Timestamp>
          </>
        )}
      </BoundaryTimestamps>
      <TimelineContainer
        hasTimestamps={!!shift.plannedToStartAt && !!shift.plannedToEndAt}
        data-testid="timeline"
      >
        <ParentTimeline>
          {timeline.map((timeslot, index) => (
            <ExecutionTimelineSlotBlock
              key={index}
              data-testid="timeline-execution"
              timeslot={timeslot}
            />
          ))}
        </ParentTimeline>
        <LegendContainer>
          <Legend>
            <LegendColor color={theme.palette.grey[300]} />
            <LegendLabel>Livre</LegendLabel>
          </Legend>
          <Legend>
            <LegendColor color={theme.palette.primary.main} />
            <LegendLabel>Ocupado</LegendLabel>
          </Legend>
          {timeline.some(({ status }) => status === 'in_another_shift') && (
            <Legend>
              <LegendColor color={red[500]} />
              <LegendLabel>Ocupado em outro paciente</LegendLabel>
            </Legend>
          )}
        </LegendContainer>
      </TimelineContainer>
    </>
  );
};

export default ShiftExecutionTimeline;
