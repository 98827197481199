/**
 * https://medium.com/trainingcenter/mascara-de-cpf-com-react-javascript-a07719345c93
 * Will be of type ddd.ddd.ddd-dd
 */
export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const numberMask = (value: string) => {
  return value.replace(/\D/g, ''); // substitui qualquer caracter que nao seja numero por nada
};

// Will be of type ddddd-ddd
export const cepMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{5})(\d{1,3})/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

// Will be of type (dd) ddddd-dddd* or (dd) ddddd-dddd*
// Or type 0800 ddd dddd
// Warning: If first 2 digits are "55", they will be removed (brazil international code)
export const phoneMask = (value: string) => {
  let numberOnly = value.replace(/\D/g, ''); // substitui qualquer caracter que nao seja numero por nada

  // Removes "55" if present
  if (value.startsWith('55') && value.length > 10) {
    numberOnly = removeDDI(numberOnly);
  }

  // Format 0800 xxx xxxx
  if (value.startsWith('08')) {
    return numberOnly.replace(/(\d{4})(\d{3})(\d{1,4})(\d*)/, '$1 $2 $3');
  } else if (value.length > 4) {
    return numberOnly
      .replace(/(\d{2})(\d{1,5})/, '($1) $2')
      .replace(/(\d{4,5})(\d{4})(\d*)/, '$1-$2');
  }
  return numberOnly;
};

export const removeDDI = (value: string) => {
  return value.slice(2);
};

export const timeMask = (value: string) => {
  return value.replace(/\D/g, '').replace(/^(\d*):(?:[012345]\d)$/, '');
};

export const bodyTemperatureMask = (value: string) => {
  return value.replace(/\D/g, '').replace(/^(\d{2})(\d)\d*?$/g, '$1,$2');
};

export const bloodGlycemiaMask = (value: string) => {
  return value.replace(/\D/g, '').replace(/^(\d{3})\d+?$/, '$1');
};

export const breathRateMask = (value: string) => {
  return value.replace(/\D/g, '').replace(/^(\d{2})\d*?$/g, '$1');
};

export const heartRateMask = (value: string) => {
  return value.replace(/\D/g, '').replace(/(\d{3})\d+?$/, '$1');
};

export const oximetryRateMask = (value: string) => {
  return value.replace(/\D/g, '').replace(/(\d{3})\d+?$/, '$1');
};

export const weightMeasurementMask = (value: string) => {
  const formatted = value
    .replace(/[^\,\.0-9*]/g, '')
    .replace('.', ',')
    .replace(/^(\d{3})(\d)\d*?$/g, '$1,$2');

  /*
   This part limits the amount of digits (formatted.indexOf(',') + 2) after the comma,
    and the limit of the input (3 = 3 digits then the previous lines of code inserts a comma).
   */
  return formatted.substring(
    0,
    formatted.indexOf(',') > 0 ? formatted.indexOf(',') + 2 : 3
  );
};

/**
 * https://www.geradorcpf.com/javascript-validar-cpf.htm
 */
export function validateCpf(rawCpf = '') {
  const cpf = rawCpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
}

export const nonAlphanumericString = (value: string) => {
  return value.replace(/[-|. ]/g, '');
};

export const validatePasswordStrength = (value: string) => {
  // at least 8 characters
  // at least 1 number
  // at least 1 special character
  // at least 1 letter uppercase and 1 letter lowercase
  const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&?.,;/\* -])[\da-zA-Z!@#$%&?.,;/\* -]{8,}/;
  return regex.test(value);
};

// Will be of type "d minutos"
export const durationMask = (value: string) => {
  // Resolve for exception when value is 1
  if (value === '1') {
    return '1 minuto';
  }
  // Resolve for other values
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d*)/, '$1 minutos');
};

export const UFoptions = [
  { value: 'AC', label: 'AC' },
  { value: 'AL', label: 'AL' },
  { value: 'AP', label: 'AP' },
  { value: 'AM', label: 'AM' },
  { value: 'BA', label: 'BA' },
  { value: 'CE', label: 'CE' },
  { value: 'DF', label: 'DF' },
  { value: 'ES', label: 'ES' },
  { value: 'GO', label: 'GO' },
  { value: 'MA', label: 'MA' },
  { value: 'MT', label: 'MT' },
  { value: 'MS', label: 'MS' },
  { value: 'MG', label: 'MG' },
  { value: 'PA', label: 'PA' },
  { value: 'PB', label: 'PB' },
  { value: 'PR', label: 'PR' },
  { value: 'PE', label: 'PE' },
  { value: 'PI', label: 'PI' },
  { value: 'RJ', label: 'RJ' },
  { value: 'RN', label: 'RN' },
  { value: 'RS', label: 'RS' },
  { value: 'RO', label: 'RO' },
  { value: 'RR', label: 'RR' },
  { value: 'SC', label: 'SC' },
  { value: 'SP', label: 'SP' },
  { value: 'SE', label: 'SE' },
  { value: 'TO', label: 'TO' },
];
