import { ProfessionalReportModel } from '@cuidador/database';
import { CardActionArea, CardHeader, Typography } from '@material-ui/core';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { format } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  ReportStatus,
  ReportCard,
  TypographyContainer,
} from './styles';

interface ProfessionalReportListProps {
  reports: ProfessionalReportModel[];
}

const ProfessionalReportList: React.FC<ProfessionalReportListProps> = ({
  reports,
}) => {
  const history = useHistory();

  const renderReportCard = (report: ProfessionalReportModel) => {
    const formattedDate = format(new Date(report.date || ''), 'dd/MM/yy');
    const subTitle = `${formattedDate} ${report.caregiver?.user?.name}`;
    return (
      <ReportCard data-testid={`report-card-${report.id}`} key={report.id}>
        <ReportStatus isDraft={Boolean(report.isDraft)}>
          <Typography variant="caption">
            {report.isDraft ? 'Rascunho' : 'Publicada'}
          </Typography>
        </ReportStatus>
        <CardActionArea
          data-testid={`card-action-${report.id}`}
          onClick={() => {
            history.push(`/avaliacao/${report.id}`);
          }}
        >
          <CardHeader
            titleTypographyProps={{
              variant: 'body1',
            }}
            subheaderTypographyProps={{
              variant: 'caption',
              color: 'inherit',
            }}
            avatar={<LibraryBooksIcon />}
            action={<ChevronRightRoundedIcon />}
            title={report.title}
            subheader={subTitle}
          />
        </CardActionArea>
      </ReportCard>
    );
  };

  return (
    <Container>
      {reports.length > 0 ? (
        <>{reports.map((report) => renderReportCard(report))}</>
      ) : (
        <TypographyContainer>
          <Typography variant="subtitle1">
            Nenhuma avaliação multiprofissional encontrada.
          </Typography>
        </TypographyContainer>
      )}
    </Container>
  );
};

export default ProfessionalReportList;
