import { CaregiverModel, UserModel } from '@cuidador/database'
import { APIError } from '@cuidador/lib'
import { clientSideScheme } from '@cuidador/whitelabel'
import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'
import qs from 'query-string'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from '../config/axios'
import firebase, { auth, getFCMToken } from '../config/firebase'
import useUser from '../hooks/useUser'
import { resolveErrorMessage } from '../utils/error'
import { GlobalLoadingContext } from './RequestInterceptor'
import jwt from 'jsonwebtoken'
import { differenceInHours } from 'date-fns'

export interface ContextProps {
  user?: firebase.User | null
  userInfo?: CaregiverModel | null
  loading?: boolean
  error?: AxiosError<APIError> | null
  signIn (email: string, password: string): Promise<void>
  signOut?(): void
  setUserInfo: React.Dispatch<React.SetStateAction<CaregiverModel | undefined>>
  refreshUserInfo (): void
  setReloadUser: (value: boolean) => void
}

export interface Props {
  children?: React.ReactNode
}

export interface FirebaseErrorType {
  code: string
  message: string
}

export const sendPasswordResetEmail = async (email: string) => {
  const response = await axios.post(`/auth/password/recover-request`, {
    email,
    userProfile: 'caregiver', // to guarantee the redirection after changing the password
  })
  return response
}

export const changePassword = async (
  currentPassword: string,
  newPassword: string
) => {
  try {
    const response = await axios.post(`auth/password/change`, {
      currentPassword,
      newPassword,
    })
    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

const getUserInfo = async () => axios.get<UserModel>('/user/me')

export const AuthContext = createContext<ContextProps>({} as ContextProps)

export const AuthProvider: React.FC = ({ children }: Props) => {
  const [user, setUser] = useState<firebase.User | null>(null)
  const [error, setError] = useState<AxiosError<APIError> | null>(null)
  const [loading, setLoading] = useState(true)
  const [reloadUser, setReloadUser] = useState(false)
  const [userInfo, setUserInfo] = useState<CaregiverModel>()
  const { appFUrl } = clientSideScheme()
  const location = useLocation()
  const { incrementLoading, decrementLoading } = useContext(
    GlobalLoadingContext
  )
  const { deleteUserFCMToken } = useUser()

  const refreshUserInfo = async () => {
    const response = await getUserInfo()
    setUserInfo(response.data.caregiverAccount)
  }

  const requestSignIn = async (cpf: string, password: string) => {
    const response = await axios.post(`/auth/login`, {
      cpf,
      password,
      userProfile: 'caregiver',
    })
    return auth().signInWithCustomToken(response.data.token)
  }

  const signIn = async (cpf: string, password: string) => {
    try {
      incrementLoading()
      await requestSignIn(cpf, password)
      setError(null)
    } catch (err) {
      setError(err)
    } finally {
      decrementLoading()
    }
  }

  const signOut = () => {
    getFCMToken()
      .then((fcmToken) => {
        if (fcmToken) {
          deleteUserFCMToken(fcmToken)
        }
      })
      .finally(() => {
        auth().signOut()
      })
  }

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        const hasCompletedSignUp = (await axios.get(`/user/check-signup-status/${firebaseUser?.uid}`)).data.hasCompletedSignup
        if (!hasCompletedSignUp) return
      }
      const token = await firebaseUser?.getIdToken()
      axios.defaults.headers.Authorization = firebaseUser
        ? `Bearer ${token}`
        : undefined

      if (firebaseUser) {
        try {
          const { claims } = await firebaseUser.getIdTokenResult()
          if (claims.auth !== 'caregiver') {
            // On this case, user has logged in with a token registered with wrong role
            toast.error(
              'As credenciais utilizadas não são válidas para este aplicativo. Entre em contato com o suporte'
            )
            Sentry.captureException(
              new Error(
                `User with firebaseUser.uid=${firebaseUser.uid} has logged in on appC using a token signed with role=${claims.auth}. This should never happen.`
              )
            )
            signOut()
            return
          }

          const response = await getUserInfo()
          const userRole = response.data?.role
          if (!userRole) {
            // On this case, user has no role and should not be allowed to use the app
            toast.error(
              `Você não possui um perfil de acesso configurado! Por favor, entre em contato com o suporte`
            )
            Sentry.captureException(
              new Error(
                `User with firebaseUser.uid=${firebaseUser.uid} has no assigned role. This should never happen.`
              )
            )
            signOut()
            return
          }
          if (!userRole.caregiverAppAccess && userRole.guardianAppAccess) {
            // On this case, user role doesnt have access to caregiver app, but have access to guardian app; will be redirect to guardian app
            signOut()
            return window.location.replace(`${appFUrl}?token=${token}`)
          }
          if (!userRole.caregiverAppAccess) {
            // On this case, user role doesnt have access to caregiver app and should not be allowed to use the app
            const roleTitle = userRole.title
            toast.error(
              `Você possui o perfil de acesso ${roleTitle}, que não te dá acesso ao aplicativo do Cuidador`
            )
            signOut()
            return
          }
          setUserInfo(response.data.caregiverAccount)
        } catch (err) {
          toast.error(resolveErrorMessage(err))
        }
      }

      setUser(firebaseUser)
      setLoading(false)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    const unsubscribe = auth().onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        const hasCompletedSignUp = (await axios.get(`/user/check-signup-status/${firebaseUser?.uid}`)).data.hasCompletedSignup
        if (!hasCompletedSignUp) return
      }
      const token = await firebaseUser?.getIdToken()
      axios.defaults.headers.Authorization = firebaseUser
        ? `Bearer ${token}`
        : undefined

      if (firebaseUser) {
        try {
          const { claims } = await firebaseUser.getIdTokenResult()
          if (claims.auth !== 'caregiver') {
            // On this case, user has logged in with a token registered with wrong role
            toast.error(
              'As credenciais utilizadas não são válidas para este aplicativo. Entre em contato com o suporte'
            )
            Sentry.captureException(
              new Error(
                `User with firebaseUser.uid=${firebaseUser.uid} has logged in on appC using a token signed with role=${claims.auth}. This should never happen.`
              )
            )
            signOut()
            return
          }

          const response = await getUserInfo()
          const userRole = response.data?.role
          if (!userRole) {
            // On this case, user has no role and should not be allowed to use the app
            toast.error(
              `Você não possui um perfil de acesso configurado! Por favor, entre em contato com o suporte`
            )
            Sentry.captureException(
              new Error(
                `User with firebaseUser.uid=${firebaseUser.uid} has no assigned role. This should never happen.`
              )
            )
            signOut()
            return
          }
          if (!userRole.caregiverAppAccess && userRole.guardianAppAccess) {
            // On this case, user role doesnt have access to caregiver app, but have access to guardian app; will be redirect to guardian app
            signOut()
            return window.location.replace(`${appFUrl}?token=${token}`)
          }
          if (!userRole.caregiverAppAccess) {
            // On this case, user role doesnt have access to caregiver app and should not be allowed to use the app
            const roleTitle = userRole.title
            toast.error(
              `Você possui o perfil de acesso ${roleTitle}, que não te dá acesso ao aplicativo do Cuidador`
            )
            signOut()
            return
          }
          setUserInfo(response.data.caregiverAccount)
        } catch (err) {
          toast.error(resolveErrorMessage(err))
        }
      }

      setUser(firebaseUser)
      setLoading(false)
      setReloadUser(false)
    })

    return () => {
      unsubscribe()
    }
  }, [reloadUser])

  useEffect(() => {
    (async () => {
      const { token: apiAuthToken } = qs.parse(location.search)
      if (apiAuthToken) {
        try {
          setLoading(true)
          const response = await axios.post(
            '/auth/custom-token',
            { userProfile: 'caregiver' },
            { headers: { Authorization: `Bearer ${apiAuthToken}` } }
          )
          const decodedAuthToken = jwt.decode(apiAuthToken as string) as any
          const localToken = window.localStorage.getItem('completeSignUp')

          if (localToken) {
            const decodedLocalToken = JSON.parse(localToken)
            console.log('decodedLocal', decodedAuthToken)

            const isSameUser = decodedLocalToken.userId === decodedAuthToken.id
            const isRecent = differenceInHours(new Date(), new Date(decodedLocalToken.date)) <= 1

            if (decodedAuthToken && isSameUser && isRecent) {
              await auth().signInWithCustomToken(response.data.token)
            }
          }
        } catch (err) {
          console.error(err)
          Sentry.captureException(err)
          toast.error(resolveErrorMessage(err as AxiosError<APIError>))
        } finally {
          setLoading(false)
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (userInfo) {
      Sentry.setUser({
        id: String(userInfo?.id),
        email: userInfo?.user?.email,
        username: userInfo?.user?.name,
      })
    } else {
      Sentry.setUser(null)
    }
  }, [userInfo])

  if (loading) return null

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        error,
        signIn,
        signOut,
        userInfo,
        setUserInfo,
        refreshUserInfo,
        setReloadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
