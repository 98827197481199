import React, { useContext } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBackdrop from '../components/LoadingBackdrop';
import { AuthContext } from '../contexts/auth';
import { isSelfCare } from '../contexts/permission';
import { ShiftInProgressContext } from '../contexts/ShiftInProgress';

interface AuthenticatedRouteProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<RouteComponentProps<any, any, unknown>>;
  executionInProgressRequired?: boolean;
  executionInProgressForbidden?: boolean;
}

const ExecutionInProgressRedirects: React.FC<{
  renderComponent: () => JSX.Element;
  executionInProgressRequired?: boolean;
  executionInProgressForbidden?: boolean;
}> = ({
  renderComponent,
  executionInProgressRequired,
  executionInProgressForbidden,
}) => {
  const { executionInProgress, loading } = useContext(ShiftInProgressContext);
  const { userInfo } = useContext(AuthContext);

  // Still loading current shift
  if (loading) {
    return <LoadingBackdrop loading={loading} />;
  }

  if (executionInProgressRequired && !executionInProgress) {
    // Should have execution, but is not. Will be redirected
    toast.error(
      `Você tem que estar com ${
        isSelfCare(userInfo?.user) ? 'uma rotina ativa' : 'um plantão ativo'
      } para acessar essa página`,
      {
        toastId: 'shift-in-progress-required-toast',
      }
    );
    return <Redirect to={{ pathname: '/pessoas-sob-cuidado' }} />;
  } else if (executionInProgressForbidden && !!executionInProgress) {
    // Should not be on Shift, but is. Will be redirected
    return <Redirect to={{ pathname: `/plantao` }} />;
  } else {
    return renderComponent();
  }
};

const AuthenticatedRoute = ({
  component: Component,
  executionInProgressRequired,
  executionInProgressForbidden,
  ...rest
}: AuthenticatedRouteProps): JSX.Element => {
  const { user, userInfo } = useContext(AuthContext);
  const shouldCheckIfHasShiftInProgress = Boolean(
    executionInProgressRequired || executionInProgressForbidden
  );

  const isUserOrganizationOwner =
    userInfo?.id === userInfo?.user?.organization?.createdById;
  const userHasRelatedPatients = Number(userInfo?.user?.patients?.length) > 0;
  const userHasOrganizationId = userInfo?.user?.organizationId;

  return (
    <Route
      {...rest}
      render={(props) => {
        // redirect unauthenticated user to login
        if (!user)
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );

        // redirect users who do not own an organization and are not related to any patients
        if (
          !userHasRelatedPatients &&
          !isUserOrganizationOwner &&
          props.location.pathname !== '/usuario-sem-vinculo'
        ) {
          return <Redirect to="/usuario-sem-vinculo" exact />;
        }

        // redirect user who do not have an organization_id
        if (
          !userHasOrganizationId &&
          props.location.pathname !== '/usuario-sem-organizacao'
        ) {
          return <Redirect to="/usuario-sem-organizacao" exact />;
        }

        if (shouldCheckIfHasShiftInProgress) {
          return (
            <ExecutionInProgressRedirects
              executionInProgressRequired={executionInProgressRequired || false}
              executionInProgressForbidden={
                executionInProgressForbidden || false
              }
              renderComponent={() => <Component {...props} />}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default AuthenticatedRoute;
