import React from 'react';
import { FabProps as MuiFabProps } from '@material-ui/core/Fab';

import ButtonLink, { ButtonLinkProps } from '../ButtonLink';
import { StyledFab } from './styles';

type FabProps = MuiFabProps & ButtonLinkProps;

const ButtonLinkForwardRef = React.forwardRef<
  HTMLAnchorElement,
  ButtonLinkProps
>((props, ref) => <ButtonLink innerRef={ref} {...props} />);
ButtonLinkForwardRef.displayName = 'ButtonLinkForwardRef';

const Fab: React.FC<FabProps> = ({ children, ...rest }) => (
  // eslint-disable-next-line
  // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
  <StyledFab {...rest} component={rest.to ? ButtonLinkForwardRef : undefined}>
    {children}
  </StyledFab>
);

export default Fab;
