import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div<{ $isAlmostComplete: boolean }>`
  position: sticky;
  top: 72px; // header height
  z-index: 1;
  padding: ${({ theme }) => theme.spacing(1, 2)};
  background-color: ${({ theme, $isAlmostComplete }) => {
    const { red, green } = theme.palette.extra.color;
    return $isAlmostComplete ? green : red.light;
  }};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.common.white};

  a {
    text-decoration: underline;
    font-weight: 400;
  }
`;

export const LeftContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Message = styled(Typography).attrs({ variant: 'caption' })`
  font-weight: 300;
`;

export const CompletedTasks = styled(Typography).attrs({
  variant: 'caption',
})``;
