import {
  EventModel,
  EventSubCategoryModel,
  MeasurementModel,
} from '@cuidador/database'
import { Typography } from '@material-ui/core'
import { Message } from '@material-ui/icons'
import { format } from 'date-fns'
import React, { useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'
import SelectedTimeIcon from '../../../../../assets/Icon-time-measurement-orange.svg'
import UnSelectedTimeIcon from '../../../../../assets/Icon-time-measurement.svg'
import { CurrentShiftDataContext } from '../../../../../contexts/CurrentShiftData'
import { ShiftInProgressContext } from '../../../../../contexts/ShiftInProgress'
import { AuthContext } from '../../../../../contexts/auth'
import useCanAccess from '../../../../../hooks/useCanAccess'
import { checkIfUserIsAllowedToPatchEvent as userIsAllowedToPatchEvent } from '../../../../../hooks/useShiftEventHandling'
import { isDateAtInterval } from '../../../../CreateShiftEvent/utils'
import VitalSignsMeasurementInput from '../../../../VitalSignsMeasurementInput'
import CommentModal, {
  FormValues as CommentModalFormValues,
} from '../../CommentModal'
import UpdateTimeModal, {
  FormValues as UpdatedTimeModalFormValues,
} from '../../UpdateTimeModal'
import { subCategoryHasCustomInput, toastErrorRedirect } from '../utils'
import {
  ActionsContainer,
  BoldText,
  CaregiverTypography,
  CheckIconContainer,
  CloseIconContainer,
  Container,
  Instructions,
  MeasurementInput,
  NoteTypography,
  RealTimeContainer,
  SelectedAccessTimeButton,
  StyledBoldTitle,
  StyledCheckIcon,
  StyledCloseIcon,
  StyledCommentButton,
} from './styles'
import { BloodGlycemiaAdditionalDetailsType } from '../../../../VitalSignsMeasurementInput/utils'

interface MeasurementProps {
  id: number;
  name?: string;
  description?: string;
  status?: string;
  comment?: string;
  caregiverId?: number;
  caregiverName?: string;
  measurement?: MeasurementModel;
  readonly: boolean;
  subCategory?: EventSubCategoryModel;
  updatedTimeHappensAt?: string | null;
  onChangeComment: (id: number, values: CommentModalFormValues) => void;
  legacy?: boolean;
  additionalDetails?: string
}

export type NewMeasurementValue = Prettify<
  PartialNullable<
    Required<
      Pick<
        MeasurementModel,
        'complicationLevel' | 'isDangerousComplication' | 'measurementValue'
      >
    >,
    'complicationLevel' | 'isDangerousComplication'
  > & {
    symptomOptions?: {
      id?: number;
      label?: string;
      customLabel?: string;
      wasUserInput?: boolean;
    }[];
  } & {
    additionalDetails?: BloodGlycemiaAdditionalDetailsType
  }
>;

const Measurement = ({
  id: eventId,
  status,
  comment,
  caregiverId,
  caregiverName,
  measurement,
  readonly,
  description,
  updatedTimeHappensAt,
  subCategory,
  onChangeComment,
  legacy,
}: MeasurementProps) => {
  const history = useHistory()
  const [currentStatus, setCurrentStatus] = React.useState(
    status || 'awaiting',
  )
  const [currentCaregiverName, setCurrentCaregiverName] = React.useState(
    caregiverName || undefined,
  )
  const [currentComment, setCurrentComment] = React.useState(comment || '')
  const [
    currentUpdatedTimeHappensAt,
    setCurrentUpdatedTimeHappensAt,
  ] = React.useState(updatedTimeHappensAt)
  const [currentEvent, setCurrentEvent] = React.useState({
    status: status || 'awaiting',
    caregiverId,
    caregiverName,
  } as EventModel)

  const { isAllowedToUpdate: isAllowedToUpdateComment } = useCanAccess(
    'care/shift/event.comment',
  )
  const { isAllowedToUpdate: isAllowedToUpdateRealTime } = useCanAccess(
    'care/shift/event.updated-time',
  )
  const { isAllowedToUpdate: isAllowedToUpdateMeasurement } = useCanAccess(
    'care/event/measurement',
  )

  const initialMeasurement = measurement?.measurementValue || ''
  const [measurementValue, setMeasurementValue] = React.useState<string>(
    initialMeasurement,
  )
  const [additionalDetails, setAdditionalDetails] = React.useState<BloodGlycemiaAdditionalDetailsType | undefined>(measurement?.additionalDetails as BloodGlycemiaAdditionalDetailsType)

  const [measurementValidation, setMeasurementValidation] = React.useState({
    isDangerousComplication: measurement?.isDangerousComplication ?? null,
    complicationLevel: measurement?.complicationLevel ?? 0,
    additionalDetails: measurement?.additionalDetails ?? 'preprandial',
  })

  const [visibleTimeModal, setVisibleTimeModal] = React.useState(false)
  const [visibleCommentModal, setVisibleCommentModal] = React.useState(false)

  const { useEvent } = useContext(CurrentShiftDataContext)
  const { updateScheduledMeasurement, setUpdatedTime } = useEvent()

  const {
    shiftInProgress,
    minShiftLimitDate,
    maxShiftLimitDate,
    executionInProgress,
    refreshShiftInProgress,
  } = useContext(ShiftInProgressContext)
  const { userInfo } = useContext(AuthContext)
  const userId = useMemo(() => userInfo?.id, [userInfo])

  const timeIcon = currentUpdatedTimeHappensAt
    ? SelectedTimeIcon
    : UnSelectedTimeIcon

  const delayedUpdateScheduledMeasurement = useDebouncedCallback(
    (
      newStatus: 'awaiting' | 'accomplished' | 'not_accomplished',
      newMeasurementValue: NewMeasurementValue,
      measuredAt?: string,
    ) => {
      const patientId = Number(shiftInProgress?.patientId)

      updateScheduledMeasurement(
        eventId,
        {
          ...currentEvent,
          status: newStatus,
          measurement: {
            ...measurement,
            ...newMeasurementValue,
          },
        },
        {
          status: newStatus,
          measurementValue:
            newMeasurementValue.measurementValue ??
            measurement?.measurementValue ??
            '',
          complicationLevel: newMeasurementValue.complicationLevel,
          isDangerousComplication: newMeasurementValue.isDangerousComplication,
          symptomOptions: newMeasurementValue.symptomOptions,
          patientId,
          measuredAt,
          shiftExecutionId: Number(executionInProgress?.id),
          additionalDetails: newMeasurementValue.additionalDetails,
        },
      ).catch((err) => {
        toastErrorRedirect(err, async () => {
          history.replace('/pessoas-sob-cuidado')
          await refreshShiftInProgress()
        })
      })
    },
    500,
  )

  const measurementInputRef = React.useRef<HTMLInputElement | null>(null)

  const handleChangeStatus = (
    newMeasurementValue: NewMeasurementValue,
    status: 'awaiting' | 'accomplished' | 'not_accomplished',
    overrideStatus?: boolean,
  ) => {
    setMeasurementValue(newMeasurementValue.measurementValue)
    setAdditionalDetails(newMeasurementValue.additionalDetails)
    setCurrentCaregiverName(
      !!newMeasurementValue.measurementValue ? userInfo?.user?.name : undefined,
    )
    let newStatus = status

    if (newStatus === 'accomplished') {
      setCurrentStatus(newStatus)
      delayedUpdateScheduledMeasurement.callback(
        newStatus,
        newMeasurementValue,
        new Date().toISOString(),
      )
      return
    }

    if (newStatus === currentStatus && !overrideStatus) {
      newStatus = 'awaiting'
    }

    delayedUpdateScheduledMeasurement.callback(
      newStatus,
      newMeasurementValue,
      undefined,
    )
    setCurrentStatus(newStatus)
    setCurrentEvent({ status: newStatus, caregiverId: userId })
    return
  }

  const handleUpdatedTime = (
    eventId: number,
    values: UpdatedTimeModalFormValues,
  ) => {
    const date = values.updatedTimeHappens as Date
    if (date && !isDateAtInterval(date, minShiftLimitDate, maxShiftLimitDate)) {
      toast.error('O horário deve estar dentro do horário do plantão')
      return false
    }

    setUpdatedTime(eventId, {
      updatedTimeHappensAt: values.updatedTimeHappens?.toISOString() || null,
      shiftExecutionId: executionInProgress?.id,
    })
    setCurrentUpdatedTimeHappensAt(
      values.updatedTimeHappens?.toISOString() || undefined,
    )
    setCurrentCaregiverName(userInfo?.user?.name)
    setCurrentEvent({ ...currentEvent, caregiverId: userId })
    setVisibleTimeModal(false)
  }

  const handleChangeComment = (values: CommentModalFormValues) => {
    onChangeComment(eventId, values)
    setCurrentComment(values.comment)
    setCurrentCaregiverName(userInfo?.user?.name)
    setCurrentEvent({ ...currentEvent, caregiverId: userId })
    setVisibleCommentModal(false)
  }

  const handleAllowedPatchEvent = (callbackFn: () => void) => {
    if (userIsAllowedToPatchEvent(currentEvent, userId)) {
      return callbackFn()
    } else {
      toast.dismiss('patch-warning-toast')
      toast.warning('Apenas quem registrou esse evento pode editá-lo.', {
        toastId: 'patch-warning-toast',
      })
      return
    }
  }

  return (
    <Container
      styledHighlight={
        !!measurementValidation.isDangerousComplication
          ? 'red'
          : Math.abs(measurementValidation.complicationLevel) === 1
          ? 'orange'
          : undefined
      }
    >
      {currentUpdatedTimeHappensAt && (
        <RealTimeContainer data-testid={`realTimeContainer-${eventId}`}>
          <Typography variant='caption'>{`Atualizado para ${format(
            new Date(currentUpdatedTimeHappensAt),
            'HH:mm',
          )}`}</Typography>
        </RealTimeContainer>
      )}
      <StyledBoldTitle
        variant='subtitle1'
        styledHighlight={
          !!measurementValidation.isDangerousComplication ? 'red' : undefined
        }
        data-testid={'measurement-title'}
      >
        {subCategory?.name?.trim()}
      </StyledBoldTitle>
      <Instructions variant='caption'>{description}</Instructions>
      {!legacy && subCategory && subCategoryHasCustomInput(subCategory) ? (
        <VitalSignsMeasurementInput
          id={`measurement-input-${eventId}`}
          subCategory={subCategory}
          ref={measurementInputRef}
          value={measurementValue}
          disabled={readonly || !isAllowedToUpdateMeasurement}
          measurement={measurement}
          patchEvent={(
            newMeasurementValue: NewMeasurementValue,
            status: 'awaiting' | 'accomplished' | 'not_accomplished',
          ) => {
            !!newMeasurementValue.measurementValue
              ? setMeasurementValidation({
                  complicationLevel: newMeasurementValue.complicationLevel ?? 0,
                  isDangerousComplication:
                    newMeasurementValue.isDangerousComplication,
                  additionalDetails: newMeasurementValue.additionalDetails || 'preprandial',
                })
              : setMeasurementValidation({
                  complicationLevel: 0,
                  isDangerousComplication: false,
                  additionalDetails: 'preprandial',
                })
            handleAllowedPatchEvent(() =>
              handleChangeStatus(newMeasurementValue, status, true),
            )
          }}
        />
      ) : (
        <MeasurementInput
          id={`measurement-input-${eventId}`}
          placeholder='digite o valor aqui'
          ref={measurementInputRef}
          value={measurementValue}
          disabled={readonly || !isAllowedToUpdateMeasurement}
          onChange={(event) => {
            const { value } = event.target
            const newStatus = value ? 'accomplished' : 'awaiting'
            handleAllowedPatchEvent(() =>
              handleChangeStatus(
                {
                  measurementValue: value,
                  complicationLevel: null,
                  isDangerousComplication: null,
                },
                newStatus,
              ),
            )
          }}
        />
      )}
      {currentComment && (
        <NoteTypography variant='caption'>
          <BoldText>Obs:</BoldText> {currentComment}
        </NoteTypography>
      )}
      {currentCaregiverName && (
        <CaregiverTypography>{currentCaregiverName}</CaregiverTypography>
      )}
      <ActionsContainer>
        {!readonly && isAllowedToUpdateComment && (
          <StyledCommentButton
            data-testid={`measurementScheduleCommentButton-${eventId}`}
            onClick={() =>
              handleAllowedPatchEvent(() => setVisibleCommentModal(true))
            }
          >
            <Message />
          </StyledCommentButton>
        )}

        {!readonly && isAllowedToUpdateRealTime && (
          <SelectedAccessTimeButton
            isChecked={currentStatus === 'not_accomplished'}
            data-testid={`measurementScheduleTimerButton-${eventId}`}
            disabled={readonly}
            onClick={() => {
              if (currentStatus === 'accomplished') {
                handleAllowedPatchEvent(() => setVisibleTimeModal(true))
              } else {
                measurementInputRef?.current?.focus()
                toast.error(
                  'Digite o valor da medição antes de clicar no botão de horário.',
                  { position: 'top-right' },
                )
              }
            }}
          >
            <img src={timeIcon} />
          </SelectedAccessTimeButton>
        )}

        {(!readonly || currentStatus === 'not_accomplished') &&
          isAllowedToUpdateMeasurement && (
            <CloseIconContainer
              isChecked={currentStatus === 'not_accomplished'}
              data-testid={`notDoneIcon-${eventId}`}
              disabled={readonly || !isAllowedToUpdateMeasurement}
              onClick={() => {
                if (currentUpdatedTimeHappensAt && eventId) {
                  handleAllowedPatchEvent(() =>
                    handleUpdatedTime(eventId, { updatedTimeHappens: null }),
                  )
                }
                handleAllowedPatchEvent(() =>
                  handleChangeStatus(
                    {
                      measurementValue: '',
                      complicationLevel: null,
                      isDangerousComplication: null,
                    },
                    'not_accomplished',
                  ),
                )
              }}
            >
              <StyledCloseIcon />
            </CloseIconContainer>
          )}

        {(!readonly || currentStatus === 'accomplished') &&
          isAllowedToUpdateMeasurement && (
            <CheckIconContainer
              isChecked={currentStatus === 'accomplished'}
              data-testid={`doneIcon-${eventId}`}
              onClick={() => {
                if (currentStatus !== 'accomplished') {
                  measurementInputRef?.current?.focus()
                  toast.error('Digite o valor da medição no campo destacado.', {
                    position: 'top-right',
                  })
                }
              }}
            >
              <StyledCheckIcon />
            </CheckIconContainer>
          )}
      </ActionsContainer>
      {visibleCommentModal && eventId && (
        <CommentModal
          handleEventComment={handleChangeComment}
          readonly={readonly}
          onClose={() => setVisibleCommentModal(false)}
          opened={visibleCommentModal}
          eventId={eventId}
          allowRemoveButton={!!currentComment}
          initialValues={
            currentComment ? { comment: currentComment } : undefined
          }
        />
      )}
      {visibleTimeModal && (
        <UpdateTimeModal
          eventId={eventId}
          handleUpdatedTime={(values: UpdatedTimeModalFormValues) =>
            handleUpdatedTime(eventId, values)
          }
          onClose={() => setVisibleTimeModal(false)}
          opened={visibleTimeModal}
          allowRemoveButton={!!currentUpdatedTimeHappensAt}
          minDate={minShiftLimitDate?.toISOString()}
          maxDate={maxShiftLimitDate?.toISOString()}
          initialFormValues={
            currentUpdatedTimeHappensAt
              ? {
                  updatedTimeHappens: new Date(currentUpdatedTimeHappensAt),
                }
              : undefined
          }
        />
      )}
    </Container>
  )
}

export default Measurement
