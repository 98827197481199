import { useCallback, useReducer } from 'react';
import { getCachedAxios } from '../config/axios';
import { EventSubCategoryModel, ShiftModel } from '@cuidador/database';
import { createReducer, ReducerData, Item } from '../utils/store/index';

const endpoint = '/care/medication';

const initialData: ReducerData<ShiftModel> = {
  byId: {} as Record<string, Item<ShiftModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useMedication = () => {
  const [state, dispatch] = useReducer(
    createReducer<ShiftModel>(),
    initialData
  );

  const getAllMedicationsIfNecessaryByPatientId = useCallback(
    async (id: number) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await getCachedAxios().get(
          `${endpoint}/if-necessary/by-patient/${id}?orderBy=subCategory.name&order=asc`
        );
        dispatch({ type: 'GET_ALL', payload: response.data });
        return response.data as EventSubCategoryModel[];
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
      }
    },
    []
  );

  return {
    ...state,
    getAllMedicationsIfNecessaryByPatientId,
  };
};

export default useMedication;
