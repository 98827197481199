import { ProfessionalReportModel } from '@cuidador/database';
import { useCallback } from 'react';
import axios from '../../config/axios';
import {
  ActionType,
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../../utils/store/index';
import { createOfflineReducer } from '../../utils/store/offlineStore';
import useReducerWithLocalStorage from '../localstorage/useReducerWithLocalStorage';
import { createOfflinePostProfessionalReport } from './postOfflineProfessionalReport';

const endpoint = '/report/professional';
const PROFESSIONAL_REPORTS_LOCAL_KEY = 'professional-reports';

const initialData: ReducerData<ProfessionalReportModel> = {
  byId: {} as Record<string, Item<ProfessionalReportModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useProfessionalReport = (caregiverId?: number) => {
  const [state, dispatch] = useReducerWithLocalStorage<
    ReducerData<ProfessionalReportModel>,
    ActionType<ProfessionalReportModel>
  >({
    initializerArg: initialData,
    key: `${PROFESSIONAL_REPORTS_LOCAL_KEY}-${caregiverId}`,
    reducer: createOfflineReducer<ProfessionalReportModel>(),
  });

  const post = useCallback(async (data: ProfessionalReportModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const postOfflineEvent = createOfflinePostProfessionalReport(axios, data);
      const response = await postOfflineEvent(endpoint, data);
      dispatch({ type: 'CREATE', payload: response.data });
      return Promise.resolve(response.data as ProfessionalReportModel);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const getByPatientId = useCallback(
    async (patientId, params: PaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get<{
          results: Item<ProfessionalReportModel>[];
          total: number;
        }>(`${endpoint}/by-patient/${patientId}`, {
          params,
        });
        dispatch({ type: 'PAGINATION', payload: response.data });
        return Promise.resolve(response.data);
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const getById = useCallback(async (id: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return Promise.resolve(response.data as ProfessionalReportModel);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const deleteById = useCallback(async (id: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.delete(`${endpoint}/${id}`);
      dispatch({ type: 'REMOVE', payload: response.data });
      return Promise.resolve(response.data as number);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const patch = useCallback(
    async (id: number, data: ProfessionalReportModel) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.patch(`${endpoint}/${id}`, data);
        dispatch({ type: 'UPDATE', payload: response.data });
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  return {
    ...state,
    post,
    getByPatientId,
    getById,
    deleteById,
    patch,
  };
};

export default useProfessionalReport;
