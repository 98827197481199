import { PatientModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios, { getCachedAxios } from '../config/axios';
import {
  createReducer,
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../utils/store/index';
import useMedia from './useMedia';

const endpoint = '/user/patient';

const initialData: ReducerData<PatientModel> = {
  byId: {} as Record<string, Item<PatientModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const usePatient = () => {
  const { getMedia } = useMedia();
  const [state, dispatch] = useReducer(
    createReducer<PatientModel>(),
    initialData
  );

  const post = useCallback(async (data: PatientModel) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.post(`${endpoint}`, data);
      dispatch({ type: 'CREATE', payload: response.data });
      return Promise.resolve(response);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const getById = useCallback(async (id: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await getCachedAxios().get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return response.data as PatientModel;
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getAll = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}`);
      dispatch({ type: 'GET_ALL', payload: response.data });
      return response.data;
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getAllRelated = useCallback(async (params: PaginatedRequestParams) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/related`, { params });
      dispatch({ type: 'PAGINATION', payload: response.data });
      return response.data;
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getProfilePicture = useCallback(async (id: number) => {
    try {
      const response = await getMedia(`/media/profile-picture/patient/${id}`);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    post,
    getProfilePicture,
    getById,
    getAll,
    getAllRelated,
  };
};

export default usePatient;
