import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import analytics from '../config/googleAnalytics';

const RouteAnalytics: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const dev = process.env.REACT_APP_ENV !== 'production';
    if (!dev) {
      analytics.pageview(location.pathname);
    }
  }, [location]);

  return <></>;
};

export default RouteAnalytics;
