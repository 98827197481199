import { EventSubCategoryModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import { Message } from '@material-ui/icons';
import React from 'react';
import AlertIcon from '../../../../assets/Icon-alert.svg';
import useCanAccess from '../../../../hooks/useCanAccess';
import useShiftEventHandling from '../../../../hooks/useShiftEventHandling';
import CommentModal, {
  FormValues as CommentModalFormValues,
} from '../CommentModal';
import {
  ButtonContainer,
  CardFooter,
  CaregiverTypography,
  DescriptionContainer,
  LeftContainer,
  RightContainer,
  StyledChangeStatus,
  StyledCommentButton,
  TimerContainer,
  TypographyContainer,
} from '../styles';
import { CardBox } from './styles';

const CommentIcon = <Message />;

interface CardProps {
  id?: number;
  subCategory?: EventSubCategoryModel;
  eventHappensAt?: string;
  description?: string;
  comment?: string;
  caregiverName?: string;
  onChangeComment: (id: number, newComment: string) => void;
}

const ComplicationCard: React.FC<CardProps> = (event) => {
  const {
    id,
    subCategory,
    description,
    onChangeComment,
    caregiverName,
  } = event;
  const [visibleCommentModal, setVisibleCommentModal] = React.useState(false);

  const { isAllowedToUpdate: isAllowedToUpdateComment } = useCanAccess(
    'care/shift/event.comment'
  );

  const {
    date,
    currentComment,
    handleCommentChange,
    handleAllowedPatchEvent,
  } = useShiftEventHandling(event, {
    onChangeComment,
  });

  return (
    <CardBox data-testid={`complicationCard-${id}`}>
      <LeftContainer>
        <TimerContainer>
          <Typography variant="h6">{date}</Typography>
        </TimerContainer>
        <img src={AlertIcon} title={`complication-image-${id}`} />
      </LeftContainer>
      <RightContainer>
        <TypographyContainer>
          <Typography variant="h6">{subCategory?.name}</Typography>
          <DescriptionContainer>
            <Typography variant="subtitle2">{description}</Typography>
            {currentComment && (
              <Typography variant="subtitle2">Obs: {currentComment}</Typography>
            )}
          </DescriptionContainer>
        </TypographyContainer>
        <CardFooter>
          {caregiverName && (
            <CaregiverTypography>{caregiverName}</CaregiverTypography>
          )}
          {isAllowedToUpdateComment && (
            <StyledChangeStatus>
              {id! > 0 && (
                <ButtonContainer>
                  <StyledCommentButton
                    data-testid={`comment-set-${id}`}
                    onClick={() =>
                      handleAllowedPatchEvent(() =>
                        setVisibleCommentModal(true)
                      )
                    }
                  >
                    {CommentIcon}
                  </StyledCommentButton>
                </ButtonContainer>
              )}
            </StyledChangeStatus>
          )}
        </CardFooter>
      </RightContainer>
      {visibleCommentModal && id && (
        <CommentModal
          handleEventComment={(values: CommentModalFormValues) => {
            handleCommentChange(values.comment);
            setVisibleCommentModal(false);
          }}
          onClose={() => setVisibleCommentModal(false)}
          opened={visibleCommentModal}
          eventId={id}
          allowRemoveButton={!!currentComment}
          initialValues={
            currentComment ? { comment: currentComment } : undefined
          }
        />
      )}
    </CardBox>
  );
};

export default ComplicationCard;
