import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.spacing(2, 2)};
  color: black;
`;

export const TitleHeader = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  font-weight: bold;
`;

export const StyledSection = styled.div`
  background-color: ${({ theme }) => theme.palette.extra.color.grey.light};
  margin: ${({ theme }) => theme.spacing(1, -2)};
  padding: ${({ theme }) => theme.spacing(1, 2)};
`;

export const CardDiv = styled.div<{ color?: string }>`
  margin-top: ${({ theme }) => theme.spacing(-1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  background-color: ${(props) => `#${props.color}` || 'inherit'};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
`;
