import MuiFab from '@material-ui/core/Fab';
import styled from 'styled-components';

export const StyledFab = styled(MuiFab)`
  position: fixed;
  bottom: ${({ theme }) => theme.spacing(8)}px;
  right: ${({ theme }) => theme.spacing(2)}px;
  background-color: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.primary.dark};
  border: solid 1px;
  svg {
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
  }
`;
