import { ShiftModel } from '@cuidador/database';
import { Avatar, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CareCategoriesViewer from '../../../components/CareCategoriesViewer';
import { formatEvents } from '../../../components/CurrentShiftEventList/CurrentShiftCards/ScheduledMeasurementCard/utils';
import ExecutionMessageCard from '../../../components/ExecutionMessageCard';
import Header from '../../../components/Headers/Header';
import LoadingBackdrop from '../../../components/LoadingBackdrop';
import ShiftEventList from '../../../components/ShiftEventList';
import { separateComplicationsFromActivities } from '../../../components/ShiftEventList/utils';
import { getShiftRealTime } from '../../../components/ShiftsHistory/utils';
import { isCaregiver } from '../../../contexts/permission';
import { ShiftInProgressContext } from '../../../contexts/ShiftInProgress';
import usePatient from '../../../hooks/usePatient';
import useShift from '../../../hooks/useShift';
import PreviousOrNextShift from './PreviousOrNextShift';
import {
  Container,
  ShiftPatientContainer,
  StyledTypographyAdditionalCoverage,
  StyledTypographyAvatar,
  StyledTypographyTitle,
  TitleContainer,
  TypographyWithIcon,
} from './styles';

const FollowShift: React.FC = () => {
  const [patientPictureURL, setPatientPictureURL] = useState('');
  const [shiftIds, setShiftIds] = useState<number[]>([]);
  const [shift, setShift] = useState<ShiftModel>();
  const { getById, loading, getByPatientIdPaginated } = useShift();
  const { getProfilePicture } = usePatient();
  const { refreshShiftInProgress, executionInProgress } = useContext(
    ShiftInProgressContext
  );

  const params = useParams<{ shiftId: string }>();
  const id = parseInt(params.shiftId);
  const history = useHistory();

  useEffect(() => {
    if (isCaregiver()) {
      refreshShiftInProgress();

      if (!executionInProgress) {
        toast.error(
          'Como cuidador você só pode ter acesso a essa tela com um plantão em andamento'
        );
        return history.push('/');
      }
    }
  }, []);

  useEffect(() => {
    if (id) {
      getById(id).then((shift) => {
        setShift(shift as ShiftModel);

        if (shift?.patientId && shiftIds.length === 0)
          fetchShiftList(shift.patientId);
      });
    }
  }, [id]);

  useEffect(() => {
    if (shift) {
      handleGetProfilePictureURL(Number(shift?.patient?.id)).then((url) => {
        if (!url) return;
        setPatientPictureURL(url);
      });
    }
  }, [shift]);

  const handleGetProfilePictureURL = async (id: number) => {
    try {
      return await getProfilePicture(id).then(
        ({ data: { signedUrl } }) => signedUrl
      );
    } catch (err) {
      // ignore patient without profile picture error
      if (!String(err).includes('404')) toast.error(err);
    }
  };

  const fetchShiftList = async (patientId: number) => {
    getByPatientIdPaginated(patientId, {
      page: 0,
      limit: 100,
      orderBy: 'plannedToStartAt',
      order: 'DESC',
    }).then((response) => {
      const shifts = response.data.results;
      const startedShifts = shifts.filter((shift) => shift.startedAt);
      const shiftIds = startedShifts.map((shift) => shift.id);
      setShiftIds(shiftIds);
    });
  };

  const {
    complicationEvents,
    eventsWithoutComplications,
  } = separateComplicationsFromActivities(shift?.events);

  const formattedEventsWithoutComplications = useMemo(
    () => formatEvents(eventsWithoutComplications),
    [shift?.events]
  );

  if (loading) {
    return <LoadingBackdrop loading />;
  }

  if (!shift) {
    return null;
  }

  return (
    <>
      <Header
        title="Histórico de plantão"
        leftIconClick={() => history.goBack()}
      />
      <Container>
        <TitleContainer>
          <StyledTypographyTitle variant="h6">
            {format(new Date(shift.plannedToStartAt || ''), 'dd/MM/yyyy')}
            {' - '}
            {format(new Date(shift.plannedToStartAt || ''), 'EEEE', {
              locale: ptBR,
            })}
          </StyledTypographyTitle>
          <StyledTypographyTitle variant="h6">
            {shift.name || shift?.shiftSchedule?.name}
          </StyledTypographyTitle>
          <ShiftPatientContainer>
            <Avatar
              src={patientPictureURL}
              alt={shift.patient?.name}
              data-testid="avatar"
            />
            <StyledTypographyAvatar variant="subtitle1">
              {shift.patient?.name}
            </StyledTypographyAvatar>
          </ShiftPatientContainer>
          <TypographyWithIcon variant="subtitle1">
            {getShiftRealTime(shift)}
          </TypographyWithIcon>
          {!!shift.isAdditionalCoverage && (
            <StyledTypographyAdditionalCoverage>
              *Essa é uma hora-extra / cobertura.
            </StyledTypographyAdditionalCoverage>
          )}
        </TitleContainer>

        {!!complicationEvents && complicationEvents.length > 0 && (
          <ShiftEventList
            events={complicationEvents || []}
            title="Intercorrências"
          />
        )}
        {shift?.messages && shift?.messages.length > 0 && (
          <>
            <TitleContainer>
              <Typography variant="h6">
                Anotações e evolução do plantão
              </Typography>
              {shift.messages.map((message, index) => (
                <ExecutionMessageCard
                  executionMessage={message}
                  key={`execution-message-card-${index}`}
                  data-testid={`execution-message-card-${index}`}
                />
              ))}
            </TitleContainer>
          </>
        )}
        {!!formattedEventsWithoutComplications &&
        formattedEventsWithoutComplications.length > 0 ? (
          <ShiftEventList
            events={formattedEventsWithoutComplications || []}
            title="Atividades"
          />
        ) : (
          <TitleContainer>
            <Typography variant="h6">
              Nenhum evento registrado no plantão.
            </Typography>
          </TitleContainer>
        )}

        {shift.careQuestionAnswers && shift.careQuestionAnswers.length > 0 ? (
          <CareCategoriesViewer shift={shift} />
        ) : (
          <TitleContainer>
            <Typography variant="h6">
              Nenhuma pergunta de cuidado relacionada ao plantão.
            </Typography>
          </TitleContainer>
        )}
      </Container>
      {shiftIds?.length > 0 && (
        <PreviousOrNextShift
          plannedToStartAt={String(shift?.plannedToStartAt)}
          startedAt={shift?.startedAt}
          shiftIds={shiftIds}
        />
      )}
    </>
  );
};

export default FollowShift;
