import { toNumber } from 'lodash';
import React, { useEffect, useState } from 'react';
import { numberMask } from '../../../../../utils/inputs';
import FormikTextField from '../../../../Forms/FormikTextField';
import { InputContainer } from '../styles';

interface BloodPressureInputProps {
  value?: string;
  onChange: (value: string) => void;
}

const BloodPressureInput: React.FC<
  BloodPressureInputProps & Partial<HTMLInputElement>
> = ({ value, onChange, ...props }) => {
  const inputLength = 3;

  const [systolicValue, setSystolicValue] = useState('');
  const [diastolicValue, setDiastolicValue] = useState('');

  useEffect(() => {
    if (!value) return;

    const [systolic, diastolic] = value.split('x');

    const sanitizedSystolic = toNumber(numberMask(systolic));
    setSystolicValue(String(sanitizedSystolic).substring(0, inputLength));

    if (typeof diastolic === 'undefined') return;

    const sanitizedDiastolic = toNumber(numberMask(diastolic));
    setDiastolicValue(String(sanitizedDiastolic).substring(0, inputLength));
  }, []);

  const onChangeAnyValue = (systolicValue: string, diastolicValue: string) => {
    const systolicNumber = toNumber(systolicValue);
    const diastolicNumber = toNumber(diastolicValue);

    const systolicWithLeftZeros = String(systolicNumber).padStart(
      inputLength,
      '0'
    );
    const diastolicWithLeftZeros = String(diastolicNumber).padStart(
      inputLength,
      '0'
    );

    const systolic = systolicWithLeftZeros.substring(
      systolicWithLeftZeros.length - 1 - inputLength
    );

    const diastolic = diastolicWithLeftZeros.substring(
      diastolicWithLeftZeros.length - 1 - inputLength
    );

    onChange(`${systolic}x${diastolic}`);
  };

  const validateBloodPressure = (value: string) => {
    if (value.length < 2) return 'Pressão arterial requer ao menos 2 dígitos.';
    if (toNumber(value) <= 0) return 'Valor deve ser maior que zero.';
  };

  return (
    <InputContainer>
      <FormikTextField
        type="text"
        color="primary"
        placeholder="120"
        autoComplete="off"
        margin="normal"
        value={systolicValue}
        onChange={(e) => {
          const value = toNumber(numberMask(e.target.value));
          const sanitizedValue = String(value).substring(0, inputLength);
          setSystolicValue(sanitizedValue);
          onChangeAnyValue(sanitizedValue, diastolicValue);
        }}
        validate={() => validateBloodPressure(systolicValue)}
        inputProps={{
          'data-testid': 'measurementInput-systolic',
          maxlength: 255,
        }}
        {...props}
      />
      <p>{' x '}</p>
      <FormikTextField
        type="text"
        color="primary"
        placeholder="80"
        autoComplete="off"
        margin="normal"
        value={diastolicValue}
        onChange={(e) => {
          const value = toNumber(numberMask(e.target.value));
          const sanitizedValue = String(value).substring(0, inputLength);
          setDiastolicValue(sanitizedValue);
          onChangeAnyValue(systolicValue, sanitizedValue);
        }}
        validate={() => validateBloodPressure(diastolicValue)}
        inputProps={{
          'data-testid': 'measurementInput-diastolic',
          maxlength: 255,
        }}
        {...props}
      />
      {'mmHg'}
    </InputContainer>
  );
};

export default BloodPressureInput;
