import { EventSubCategoryModel, MeasurementModel } from '@cuidador/database'
import { Typography } from '@material-ui/core'
import { format } from 'date-fns'
import React from 'react'
import { CardFooter, CaregiverTypography } from '../styles'

import {
  BoldText,
  CheckIconContainer,
  CloseIconContainer,
  Container,
  InformationContainer,
  Instructions,
  MeasurementInput,
  NoteTypography,
  RealTimeContainer,
  StyledBoldTitle,
  StyledCheckIcon,
  StyledCloseIcon,
} from './styles'
import { getConditionWithDetail, getFormattedSymptoms } from '..'

export const subCategoryIdMapper = {
  BLOOD_PRESSURE: 35231,
  BODY_TEMPERATURE: 35237,
  BLOOD_GLYCEMIA: 35230,
  BREATH_RATE: 35236,
  HEART_RATE: 35235,
  OXIMETRY_RATE: 35232,
  WEIGHT_MEASURMENT: 35233,
};

export const getMeasurementUnit = (subCategoryId?: number): string => {
  switch (subCategoryId) {
    case subCategoryIdMapper.BLOOD_GLYCEMIA:
      return 'mg/dL';
    case subCategoryIdMapper.BODY_TEMPERATURE:
      return '°C';
    case subCategoryIdMapper.BREATH_RATE:
      return 'mrm';
    case subCategoryIdMapper.HEART_RATE:
      return 'bpm';
    case subCategoryIdMapper.OXIMETRY_RATE:
      return '%SaO2';
    case subCategoryIdMapper.BLOOD_PRESSURE:
      return 'mmHg';
    case subCategoryIdMapper.WEIGHT_MEASURMENT:
      return 'Kg';
    default:
      return '';
  }
};

interface MeasurementProps {
  id: number
  name?: string
  description?: string
  status?: string
  comment?: string
  measurement?: MeasurementModel
  updatedTimeHappensAt?: string | null
  subCategory?: EventSubCategoryModel
  caregiverName?: string
}

const Measurement = ({
  id: eventId,
  status,
  comment,
  measurement,
  description,
  updatedTimeHappensAt,
  subCategory,
  caregiverName,
}: MeasurementProps) => {
  const measurementValue = measurement?.measurementValue
    ? measurement?.measurementValue + ' ' + getMeasurementUnit(subCategory?.id)
    : ''

  return (
    <Container
      style={{
        borderColor: measurement?.isDangerousComplication
          ? '#f36161'
          : Math.abs(measurement?.complicationLevel || 0) === 1
            ? '#fdb62e'
            : undefined,
        borderStyle: measurement?.isDangerousComplication ? 'solid' : undefined,
        borderWidth: measurement?.isDangerousComplication ? '5px' : undefined
      }}
    >
      {updatedTimeHappensAt && (
        <RealTimeContainer data-testid={`realTimeContainer-${eventId}`}>
          <Typography variant="caption">{`Atualizado para ${format(
            new Date(updatedTimeHappensAt),
            'HH:mm'
          )}`}</Typography>
        </RealTimeContainer>
      )}
      <StyledBoldTitle
        variant="subtitle1"
        style={{ color: measurement?.isDangerousComplication ? '#f36161' : undefined }}>
        {subCategory?.name?.trim()}
      </StyledBoldTitle>
      <Instructions variant="caption">{description}</Instructions>
      <MeasurementInput
        id={`measurement-input-${eventId}`}
        placeholder="sem registro"
        value={measurementValue}
        disabled={true}
      />
      <CardFooter>
        <Typography variant='subtitle2'>
          {getConditionWithDetail(measurement?.additionalDetails || '', subCategory)}
        </Typography>
        {measurement?.symptoms && measurement?.symptoms?.length > 0 && (
          <Typography variant='subtitle2'>{getFormattedSymptoms(measurement?.symptoms)}</Typography>
        )}
        {comment && (
          <NoteTypography variant="caption">
            <BoldText>Obs:</BoldText> {comment}
          </NoteTypography>
        )}
        <InformationContainer>
          {caregiverName ? (
            <CaregiverTypography>{caregiverName}</CaregiverTypography>
          ) : (
            <div></div>
          )}
          {status === 'not_accomplished' && (
            <CloseIconContainer isChecked={true} disabled={true}>
              <StyledCloseIcon />
            </CloseIconContainer>
          )}

          {status === 'accomplished' && (
            <CheckIconContainer
              isChecked={true}
              data-testid={`doneIcon-${eventId}`}
            >
              <StyledCheckIcon />
            </CheckIconContainer>
          )}
        </InformationContainer>
      </CardFooter>
    </Container>
  )
}

export default Measurement
