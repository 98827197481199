import React from 'react';
import { clientSideScheme } from '@cuidador/whitelabel';
import { Text, StyledFavorite, StyledLogoInline, Container } from './styles';

export interface MadeByProps {
  mainColor: 'white' | 'black';
}

const MadeBy: React.FC<MadeByProps> = ({ mainColor }) => {
  const { schemeId } = clientSideScheme();

  if (schemeId === 'default') return null;

  return (
    <Container>
      <Text mainColor={mainColor}>
        Feito com <StyledFavorite /> por
        <StyledLogoInline mainColor={mainColor} />
      </Text>
    </Container>
  );
};

export default MadeBy;
