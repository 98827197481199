import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/auth';
import Header from '../Header';
import LogoutDialog from '../../LogoutDialog';

interface HeaderWithSignOutProps {
  title: string;
}

const HeaderWithSignOut: React.FC<HeaderWithSignOutProps> = ({ title }) => {
  const [dialogStatus, setDialogStatus] = useState(false);

  const { signOut } = useContext(AuthContext);

  const handleToggleDialog = () => {
    setDialogStatus(!dialogStatus);
  };

  return (
    <>
      <Header title={title} leftIconClick={handleToggleDialog} />
      <LogoutDialog
        open={dialogStatus}
        onClose={handleToggleDialog}
        onConfirm={signOut}
      />
    </>
  );
};

export default HeaderWithSignOut;
