import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const Title = styled(Typography).attrs({
  variant: 'h5',
  align: 'center',
})`
  color: ${({ theme }) => theme.palette.primary.dark};
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button {
    color: ${({ theme }) => theme.palette.primary.dark};
    margin-top: ${({ theme }) => theme.spacing(2)}px;
    width: fit-content;
  }

  & > div {
    display: flex;
    justify-content: center;
    margin-top: ${({ theme }) => theme.spacing(2)}px;

    button {
      margin: ${({ theme }) => theme.spacing(0, 1)};
    }
  }
`;

export const FinishingShiftHeaderDiv = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;
