import { DatePickerProps, TimePickerProps } from '@material-ui/pickers';
import { ErrorMessage } from 'formik';
import React from 'react';
import { StyledDatePicker, StyledTimePicker } from '../styles';

export const DatePicker: React.FC<
  Omit<DatePickerProps, 'value' | 'onChange'> & {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
    setFieldValue: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void;
  }
> = ({ name, values, setFieldValue, ...rest }) => {
  return (
    <StyledDatePicker
      {...rest}
      data-testid={`data-${name}`}
      name={name}
      value={values[name]}
      onChange={(date: Date | null) => {
        // Since it's a Date only picker, lets set hours, minutes, seconds and milliseconds to 0
        date?.setHours(0, 0, 0, 0);
        setFieldValue(name, date, true);
      }}
      inputVariant="outlined"
      cancelLabel="Cancelar"
      helperText={<ErrorMessage name={name} />}
    />
  );
};

export const TimePicker: React.FC<
  Omit<TimePickerProps, 'value' | 'onChange'> & {
    name: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: any;
    setFieldValue: (
      field: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      shouldValidate?: boolean | undefined
    ) => void;
  }
> = ({ name, values, setFieldValue, ...rest }) => {
  return (
    <StyledTimePicker
      {...rest}
      data-testid={`data-${name}`}
      name={name}
      value={values[name]}
      onChange={(date: Date | null) => {
        setFieldValue(name, date, true);
      }}
      inputVariant="outlined"
      cancelLabel="Cancelar"
      helperText={<ErrorMessage name={name} />}
    />
  );
};
