import { PatientModel } from '@cuidador/database';
import { Avatar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import userBaseImage from '../../assets/user.png';
import useCanAccess from '../../hooks/useCanAccess';
import usePatient from '../../hooks/usePatient';
import { resolveErrorMessage } from '../../utils/error';
import ShiftCalendarButton from './Buttons/ShiftCalendarButton';
import { CardBox, Info, InfoContainer, PatientInfoContainer } from './styles';
import { formatDifferenceInYears } from './utils';

interface Props {
  patient: PatientModel;
}

const PatientCardItem: React.FC<Props> = ({ patient }: Props) => {
  const history = useHistory();
  const [imageUrl, setImageUrl] = useState('');
  const { getProfilePicture } = usePatient();

  const { isAllowedToRead: isAllowedToReadProfilePicture } = useCanAccess(
    'media/profile-picture'
  );

  const handleRedirectToPatientShiftCalendar = () => {
    history.push(`/agenda-de-plantoes/${patient.id}`);
  };

  const handleGetProfilePicture = async (id: number) => {
    if (isAllowedToReadProfilePicture) {
      try {
        return await getProfilePicture(id).then(({ data }) => data?.signedUrl);
      } catch (err) {
        const displayMessage = resolveErrorMessage(err);
        if (displayMessage !== 'Arquivo não encontrado.') {
          toast.error(displayMessage);
        }
      }
    }
  };

  const fetchPatientProfilePicture = async () => {
    const result = await handleGetProfilePicture(parseInt(`${patient.id}`));
    if (result) {
      setImageUrl(result);
    } else {
      setImageUrl(userBaseImage);
    }
  };

  useEffect(() => {
    fetchPatientProfilePicture();
  }, []);

  return (
    <CardBox>
      <PatientInfoContainer data-testid={`patientCardItem-${patient.id}`}>
        <Avatar src={imageUrl} alt={patient.name} data-testid="avatar" />
        <InfoContainer>
          <Info>{patient.name}</Info>
          <Info>{formatDifferenceInYears(patient.dateOfBirth)} anos</Info>
        </InfoContainer>
      </PatientInfoContainer>
      <ShiftCalendarButton onClick={handleRedirectToPatientShiftCalendar} />
    </CardBox>
  );
};

export default PatientCardItem;
