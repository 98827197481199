import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const CardBox = styled.div`
  background-color: ${(props) => props.theme.palette.common.white};
  display: grid;
  grid-template-columns: 2fr repeat(2, auto);
  align-items: center;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.spacing(8)}px;
  box-sizing: border-box;
  width: 100%;
`;

export const PatientInfoContainer = styled.div`
  max-width: 400px;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing(1.5)}px;
  margin-right: ${({ theme }) => theme.spacing(1.5)}px;
  width: 100%;
`;

export const Info = styled(Typography)`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  font-size: clamp(0.95rem, 75%, 2rem);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
