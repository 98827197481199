import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { ButtonContainer, Container, StyledTabItem } from './styles';

import Header from '../../components/Headers/Header';
import { StyledFieldset } from '../../components/StyledFieldset';
import StyledTabs from '../../components/StyledTabs';
import useCanAccess from '../../hooks/useCanAccess';
import ChangePassword from './ChangePassword';
import Personal from './Personal';

const Account: React.FC<RouteComponentProps<{ tabId: string }>> = (props) => {
  const currentTab = props.match.params.tabId;

  const { isAllowedToUpdate } = useCanAccess('user/caregiver');

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    props.history.replace(`/minha-conta/${newValue}`);
  };

  return (
    <>
      <Header leftButtonType="goBack" title="Meus dados" />
      <Container>
        <ButtonContainer>
          <StyledTabs value={currentTab} onChange={handleChange}>
            <StyledTabItem value="pessoais" label="Pessoais" />
            <StyledTabItem value="senha" label="Senha" />
          </StyledTabs>
        </ButtonContainer>
        <StyledFieldset disabled={!isAllowedToUpdate}>
          {currentTab === 'pessoais' && <Personal />}
        </StyledFieldset>
        {currentTab === 'senha' && <ChangePassword />}
      </Container>
    </>
  );
};

export default Account;
