import {
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import StyledButton from '../../components/StyledButton';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 1)};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: ${({ theme }) => theme.spacing(10)}px;
  margin: ${({ theme }) => theme.spacing(4, 0, 0, 0)};
`;

export const ShiftHeader = styled.div`
  margin: ${({ theme }) => theme.spacing(0, 0, 4, 0)};
  text-align: center;
`;

export const Title = styled(Typography).attrs({
  variant: 'h5',
  align: 'center',
})`
  color: ${({ theme }) => theme.palette.primary.dark};
  margin: ${({ theme }) => theme.spacing(3)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const TitleContainer = styled.div`
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  text-align: left;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.primary.main};

  div {
    margin-top: ${({ theme }) => theme.spacing(1)}px;
  }
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const ButtonBottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ theme }) => theme.spacing(9)}px;
  position: fixed;
  bottom: 0;
`;

export const Button = styled(StyledButton)`
  color: ${({ theme }) => theme.palette.info.dark};
  background-color: ${({ theme }) => theme.palette.common.white};
  border-color: ${({ theme }) => theme.palette.info.dark};
  font-size: ${({ theme }) => theme.typography.h6.fontSize};
  font-weight: 400;
  transition: 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
    color: ${({ theme }) => theme.palette.info.dark};
    opacity: 85%;
  }
`;
