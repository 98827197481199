import React from 'react';
import { FormControlLabelProps } from '@material-ui/core/FormControlLabel';

import { ColorsType } from '../../styles/colorMapping';
import { FormControlLabel } from './styles';

type StyledFormControlLabelProps = {
  color?: ColorsType;
} & FormControlLabelProps;

const StyledFormControlLabel: React.FC<StyledFormControlLabelProps> = ({
  color = 'primary',
  ...props
}) => <FormControlLabel $color={color} {...props} />;

export default StyledFormControlLabel;
