// https://gist.github.com/mattiaerre/8dbd2d8efca3f242c7085a9ce82ecbde

import { useReducer } from 'react';
import useLocalStorage from './useLocalStorage';

interface ReducerWithLocalStorage<T, P> {
  initializerArg: T;
  key: string;
  reducer: (state: T, action: P) => T;
}

const useReducerWithLocalStorage = <T, P>({
  initializerArg,
  key,
  reducer,
}: ReducerWithLocalStorage<T, P>) => {
  const [storedValue, setValue] = useLocalStorage<T>(key, initializerArg);

  return useReducer((state: T, action: P): T => {
    const newState = reducer(state, action);
    setValue(newState);
    return newState;
  }, storedValue);
};

export default useReducerWithLocalStorage;
