import styled, { css } from 'styled-components';
import StyledPaper from '../../../StyledPaper';

export type HighlightVariants = 'red' | 'yellow' | undefined;

export const CardBox = styled(StyledPaper)<{
  styledHighlight?: HighlightVariants;
}>`
  width: 100%;
  display: flex;
  margin: 0;
  min-height: 120px;
  background-color: ${({ theme }) => theme.palette.extra.color.yellow};
  border-bottom-left-radius: 10px;
  padding: 0;
  outline-offset: 3px;

  ${({ theme, styledHighlight }) => {
    switch (styledHighlight) {
      case 'red':
        return css`
          outline: solid 3px ${theme.palette.extra.color.red.light};
        `;
      case 'yellow':
        return css`
          outline: solid 3px ${theme.palette.extra.color.yellow};
        `;
    }
  }}
`;
