import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const BalloonContainer = styled.div<{ alignItems?: string }>`
  width: 100%;
  min-height: ${({ theme }) => theme.spacing(15)}px;
  display: flex;
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: center;
  flex-direction: row;
`;

export const Balloon = styled.div`
  width: ${({ theme }) => theme.spacing(25)}px;
  min-height: ${({ theme }) => theme.spacing(11)}px;
  background-color: ${({ theme }) => theme.palette.extra.color.grey.light};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1.5)}px;
  word-break: break-word;
  margin-bottom: 10px;
`;

export const Balloons = styled.div`
  flex-direction: column;
`;

export const PointBalloon = styled.div<{ marginTop?: string }>`
  width: 0;
  height: 0;
  border-top: ${({ theme }) => theme.spacing(1)}px solid transparent;
  border-bottom: ${({ theme }) => theme.spacing(1)}px solid transparent;
  border-right: ${({ theme }) => theme.spacing(1)}px solid
    ${({ theme }) => theme.palette.extra.color.grey.light};
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${(props) => props.marginTop || '0'};
`;

export const StyledText = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.black};
  line-height: 1.2;
  white-space: pre-wrap;
`;
