import { IconButton } from '@material-ui/core';
import ChevronLeftRounded from '@material-ui/icons/ChevronLeftRounded';
import PhoneIcon from '@material-ui/icons/Phone';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledBackIcon = styled(ChevronLeftRounded)`
  font-size: 40px;
`;

export const StyledBlankDiv = styled.div`
  height: 40px;
  width: 40px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  margin: 0;
`;

export const IconBackground = styled(Link)`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  width: ${({ theme }) => theme.spacing(3.5)}px;
  height: ${({ theme }) => theme.spacing(3.5)}px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-right: 0.5px;
`;

export const StyledPhoneIcon = styled(PhoneIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
`;
