import { EventModel, SymptomModel } from '@cuidador/database';
import React, { createContext, useContext, useEffect, useState } from 'react';
import SUB_CATEGORY_ENUM from '../utils/subCategoryEnum';
import { CurrentShiftDataContext } from './CurrentShiftData';
import { Item } from '../utils/store';

interface SymptomsListContextProviderProps {
  currentShiftEvents: Item<EventModel>[] | null;
}

export const SymptomsListContext = createContext<SymptomModel[]>([]);

export const useSymptomsListContext = () => {
  const symptomsList = useContext(SymptomsListContext);
  return symptomsList;
};

const SymptomsListContextProvider: React.FC<SymptomsListContextProviderProps> = ({
  children,
  currentShiftEvents,
}) => {
  const [symptomsList, setSymptomsList] = useState<SymptomModel[]>([]);
  const { useEvent } = useContext(CurrentShiftDataContext);
  const { getSymptomsList } = useEvent();

  useEffect(() => {
    const anyBloodPressureEvent = currentShiftEvents?.find(
      (event) => event.subCategoryId === SUB_CATEGORY_ENUM.BLOOD_PRESSURE
    );

    if (!anyBloodPressureEvent) return;

    getSymptomsList().then((list) => !!list && setSymptomsList(list));
  }, [currentShiftEvents]);

  return (
    <SymptomsListContext.Provider value={symptomsList}>
      {children}
    </SymptomsListContext.Provider>
  );
};

export default SymptomsListContextProvider;
