import styled from 'styled-components';

export const ContainerFlat = styled.div`
  overflow: scroll;
  flex: 1;
`;

export const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  justify-content: center;
`;
