import { DialogContentText } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import styled from 'styled-components';
import StyledDialog from '../StyledDialog';

export const StyledDialogContainer = styled(StyledDialog)`
  .MuiDialogContent-root {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledWarningIcon = styled(ReportProblemOutlined)`
  height: 50px;
  width: 50px;
`;

export const StyledDialogContentText = styled(DialogContentText)`
  max-width: 380px;
  width: 100%;
`;
