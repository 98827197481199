const sendPageView = (pagePath: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).gtag('event', 'page_view', {
    page_path: pagePath,
  });
};

const analytics = {
  pageview: sendPageView,
};

export default analytics;
