import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { Link } from 'react-router-dom';

import useCanAccess from '../../hooks/useCanAccess';
import { NormalizedCareCategory } from '../../utils/shiftQuestion';
import {
  Accordion,
  CareLineCard,
  CareLineInfo,
  CareLineName,
  CareLineStatus,
} from './styles';

const CareCategoryAccordion: React.FC<
  NormalizedCareCategory & { careCategoryIndex: number }
> = ({
  careCategoryIndex,
  colorHexa,
  name,
  careLines,
  totalAnswered,
  totalQuestions,
}) => {
  const { isAllowedToCreate: isAllowedToCreateCareQuestions } = useCanAccess(
    'care/shift/care-question'
  );
  return (
    <Accordion $backgroundColor={colorHexa}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{name}</Typography>

        <Typography variant="caption">{`${totalAnswered}/${totalQuestions} perguntas respondidas`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {careLines.map((careLine, index) => {
          const answered = careLine.totalQuestions === careLine.totalAnswered;
          return (
            <CareLineCard key={careLine.id}>
              <CareLineInfo>
                <Typography>
                  {careLine.careQuestions[0].careQuestionText}
                </Typography>
                <CareLineName>{careLine.name}</CareLineName>
                <CareLineStatus $answered={answered}>
                  {answered ? 'Respondida' : 'Não respondida'}
                </CareLineStatus>
              </CareLineInfo>
              {isAllowedToCreateCareQuestions &&
                careLine?.careQuestions?.length && (
                  <Link
                    data-testid={`answer[${careCategoryIndex}-${index}-${0}]`}
                    to={{
                      pathname: '/perguntas',
                      state: {
                        careCategoryIndex: careCategoryIndex,
                        careLineIndex: index as number,
                        careQuestionIndex: 0,
                      },
                    }}
                  >
                    Responder
                  </Link>
                )}
            </CareLineCard>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default CareCategoryAccordion;
