import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  subCategoryId: Yup.string().required(
    'Por favor, insira o tipo de intercorrência.'
  ),
  description: Yup.string().max(
    2000,
    'A descrição deve conter no máximo 2000 caracteres.'
  ),
});
