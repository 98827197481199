import Symptom, { SymptomModel } from '@cuidador/database/src/models/Symptom';
import { MultiselectModalOptionsType } from './MultiselectModal';
import { NewMeasurementValue } from '../../CurrentShiftEventList/CurrentShiftCards/ScheduledMeasurementCard/Measurement';

export const formatSymptomsOptions = (
  symptoms: SymptomModel[],
  checkedIds?: SymptomModel[]
) =>
  symptoms.map((symptom) =>
    formatSingleSymptomIntoOption(
      symptom,
      !!symptom.id && !!checkedIds?.find(({ id }) => symptom.id === id)
    )
  );

export const formatSingleSymptomIntoOption = (
  symptom: Symptom | SymptomModel,
  checked?: boolean
) => ({
  id: symptom.id!,
  label: symptom.symptomName!,
  checked: checked ?? false,
});

export const formatOptionIntoSymptomOption = (
  options: MultiselectModalOptionsType[]
): NonNullable<NewMeasurementValue['symptomOptions']> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return options.map(({ checked, check, id, isCustomInput, ...option }) => ({
    ...option,
    id: Number.isNaN(Number(id)) ? undefined : Number(id),
    wasUserInput: isCustomInput,
  }));
};

export const shouldShowSymptoms = (systolic: number) =>
  !Number.isNaN(systolic) && systolic > 0 && (systolic < 90 || systolic >= 130);
