import Typography from '@material-ui/core/Typography';
import * as Sentry from '@sentry/react';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ExecutionMessageForm from '../../components/ExecutionMessageForm';
import { FormValues } from '../../components/ExecutionMessageForm/utils';
import Header from '../../components/Headers/Header';
import { AuthContext } from '../../contexts/auth';
import { CurrentShiftDataContext } from '../../contexts/CurrentShiftData';
import { isSelfCare } from '../../contexts/permission';
import { ShiftInProgressContext } from '../../contexts/ShiftInProgress';
import useShift from '../../hooks/useShift';
import { resolveErrorMessage } from '../../utils/error';
import { OutterContainer, TitleContainer } from './styles';

const ShiftFinish: React.FC = () => {
  const { shiftInProgress, executionInProgress } = useContext(
    ShiftInProgressContext
  );
  const { useEvent, useShiftQuestions } = useContext(CurrentShiftDataContext);
  const { finishShift } = useShift();
  const { clearAllLocalShiftEvents } = useEvent();
  const {
    buildNormalizedAnswersToApi,
    clearAllLocalShiftQuestions,
  } = useShiftQuestions();
  const history = useHistory();
  const { userInfo } = useContext(AuthContext);

  const handleShiftFinish = async (values: FormValues) => {
    const shiftId = shiftInProgress!.id!;
    const shiftExecutionId = executionInProgress!.id!;
    return finishShift(shiftId, {
      message: values.message,
      careQuestionAnswers: buildNormalizedAnswersToApi(),
    })
      .then(() => {
        clearAllLocalShiftEvents();
        clearAllLocalShiftQuestions();
        history.replace(`/plantao/cuidar-do-cuidador/fim`, {
          shiftExecutionId,
        });
      })
      .catch((err) => {
        Sentry.captureException(err);
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const headerTitle = `Finalização de ${
    isSelfCare(userInfo?.user) ? 'rotina' : 'plantão'
  }`;
  const title = isSelfCare(userInfo?.user) ? '' : 'Passagem de plantão';

  return (
    <OutterContainer>
      <Header title={headerTitle} leftButtonType="goBack" />
      <TitleContainer>
        <Typography variant="h6">{title}</Typography>
      </TitleContainer>
      <ExecutionMessageForm
        onSubmit={handleShiftFinish}
        formProps={{ buttonLabel: 'Finalizar' }}
        initialValues={{
          message: executionInProgress?.message?.message || '',
        }}
      />
    </OutterContainer>
  );
};

export default ShiftFinish;
