import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export interface ContextProps {
  internetStatus?: 'online' | 'offline';
  checkInternetConnection: () => Promise<boolean>;
}

export const InternetConnectionContext = createContext<ContextProps>({
  internetStatus: 'online',
} as ContextProps);

/**
 *  Check for internet every 30 seconds
 */
const INTERNET_CONNECTION_CHECK_FREQUENCY = 30 * 1000;
// Primary DNS servers for:
const EXTERNAL_INTERNET_TESTING_SERVER_URIS = [
  '8.8.8.8', // Google
  '1.1.1.1', // Cloudflare
  '208.67.222.222', // OpenDNS
];

export const InternetConnectionProvider: React.FC = ({ children }) => {
  const intervalRef = useRef<NodeJS.Timeout>();
  const [internetStatus, setInternetStatus] = useState<
    ContextProps['internetStatus']
  >();

  useEffect(() => {
    checkInternetConnection().finally(() => {
      intervalRef.current = setInterval(
        checkInternetConnection,
        INTERNET_CONNECTION_CHECK_FREQUENCY
      );
    });
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  const checkInternetConnection = useCallback(async () => {
    const pings = await Promise.all(
      EXTERNAL_INTERNET_TESTING_SERVER_URIS.map(async (uri) => {
        // If server is unreachable, fetch throws an error.
        // By doing this, we count how many errors occured,
        // and therefore how many pings were unreachable
        try {
          await fetch(uri);
          return true;
        } catch {
          return false;
        }
      })
    );

    if (pings.some((ping) => ping)) {
      setInternetStatus('online');
      return true;
    } else {
      setInternetStatus('offline');
      return false;
    }
  }, []);

  return (
    <InternetConnectionContext.Provider
      value={{ checkInternetConnection, internetStatus }}
    >
      {children}
    </InternetConnectionContext.Provider>
  );
};

export default InternetConnectionProvider;
