import {
  CircularProgress as MaterialCircularProgress,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import StyledPaper from '../../components/StyledPaper';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 2)};
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.palette.primary.dark};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  text-align: center;
`;

export const TypographyContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: ${({ theme }) => theme.palette.primary.dark};
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const CardBox = styled(StyledPaper)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  border-radius: 10px;
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  display: flex;
`;

export const TextWrap = styled.div`
  max-width: 70vw;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const ContainerFlat = styled.div`
  overflow: scroll;
  flex: 1;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;

export const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  justify-content: center;
`;

export const HeaderTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  text-align: center;
  margin-left: ${({ theme }) => theme.spacing(1)}px;
  flex-grow: 1;
`;
