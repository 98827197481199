import React, { useContext, useState } from 'react';
import { InternetConnectionContext } from './InternetConnectionContext';
import {
  CircularProgress,
  Container,
  Label,
  RefreshIcon,
  StyledIconButton,
} from './style';

/**
 * This component will render a warning banner if user's internet connection
 * is not active.
 */
const InternetConnectionErrorBanner: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { checkInternetConnection, internetStatus } = useContext(
    InternetConnectionContext
  );

  const handleRefresh = () => {
    setLoading(true);

    return checkInternetConnection().finally(() => {
      setLoading(false);
    });
  };

  if (internetStatus === 'online') {
    return null;
  }

  if (internetStatus === 'offline') {
    return (
      <>
        <Container onClick={() => handleRefresh()}>
          <Label>Você está sem conexão no momento!</Label>
          <StyledIconButton>
            {loading ? <CircularProgress /> : <RefreshIcon />}
          </StyledIconButton>          
        </Container>        
      </>
    );
  }

  return null;
};


export default InternetConnectionErrorBanner;
