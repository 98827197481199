import { ProfessionalReportModel } from '@cuidador/database';
import { FormValues } from '../../../components/ViewProfessionalReportForm';

export const ProfessionalReportModelToFormValues = (
  report: ProfessionalReportModel
): FormValues => {
  return {
    caregiver: report?.caregiver?.user?.name || '',
    title: report?.title || '',
    description: report?.description || '',
    date: report?.date || '',
    subCategoryId: report?.eventSubCategory?.id || 0,
    isDraft: report?.isDraft || false,
  };
};
