import styled, { css } from 'styled-components';
import { Typography } from '@material-ui/core';
import { Done, AlarmOff } from '@material-ui/icons';
import StyledButton from '../../../components/StyledButton';
import { ReactComponent as NotStartedIcon } from '../../../assets/not-started.svg';
import { ReactComponent as ListIcon } from '../../../assets/list.svg';

interface CardHeaderProps {
  backgroundColorByStatusTitle?: string;
}

const iconBaseStyles = css`
  width: 25px;
  height: 25px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`;

export const CardContainer = styled.div`
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 ${({ theme }) => theme.palette.grey[400]};
  margin-block: ${({ theme }) => theme.spacing(1.5)}px;
  width: 100%;
`;

export const CardHeader = styled.div<CardHeaderProps>`
  background-color: ${({ backgroundColorByStatusTitle }) =>
    backgroundColorByStatusTitle};
  color: ${({ theme }) => theme.palette.common.white};
  font-weight: 500;
  border-radius: 10px 10px 0 0;
  display: flex;
  align-items: center;
  padding-block: ${({ theme }) => theme.spacing(1.5)}px;
  padding-inline: ${({ theme }) => theme.spacing(2)}px;
`;

export const CardBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-block: ${({ theme }) => theme.spacing(1.5)}px;
  padding-inline: ${({ theme }) => theme.spacing(2)}px;

  & + div {
    width: 60%;
  }
`;

export const CardTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.info.dark};
  font-weight: 500;
  max-width: 50vw;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  white-space: nowrap;
`;

export const CardTime = styled.div`
  color: ${({ theme }) => theme.palette.info.dark};
  font-size: clamp(0.7rem, 2vw, 1rem);

  span {
    font-weight: 500;
  }
`;

export const CardButton = styled(StyledButton)`
  color: ${({ theme }) => theme.palette.info.dark};
  font-size: clamp(12px, 1vw, 0.8rem);
`;

export const StyledDoneIcon = styled(Done)`
  ${iconBaseStyles}
`;

export const StyledAvailableIcon = styled(NotStartedIcon)`
  ${iconBaseStyles}
`;

export const StyledUnavailableIcon = styled(AlarmOff)`
  ${iconBaseStyles}
`;

export const StyledInProgressIcon = styled(ListIcon)`
  ${iconBaseStyles}
`;
