import styled from 'styled-components';

export const StyledFieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
  // !important flag is necessary to override color from StyledTextField and StyledSelectField
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme, disabled }) =>
      disabled ? theme.palette.extra.color.grey.main + '!important' : ''};
  }
  & input {
    color: ${({ theme, disabled }) =>
      disabled ? theme.palette.extra.color.grey.main + '!important' : ''};
  }
  & label {
    color: ${({ theme, disabled }) =>
      disabled ? theme.palette.extra.color.grey.main + '!important' : ''};
  }
`;
