import React, { useContext, useEffect, useRef, useState } from 'react';
import { CenterContainer, PhotoElement, Title } from './styles';

import { Avatar } from '@material-ui/core';
import getDiffInMs from 'date-fns/differenceInMilliseconds';

import { ShiftInProgressContext } from '../../../contexts/ShiftInProgress';
import usePatient from '../../../hooks/usePatient';
import FinishShiftConfirmDialog from '../../FinishShiftConfirmDialog';
import ShiftHeaderNotification from '../../ShiftHeaderNotification';
import { shouldShowNotification, TIMER_IN_MS } from './utils';

import HeaderWithDrawer from '../HeaderWithDrawer';

const PatientHeader: React.FC = () => {
  const [imageUrl, setImageUrl] = useState('');
  const { shiftInProgress } = useContext(ShiftInProgressContext);
  const shiftEndingTimer = useRef<NodeJS.Timeout>();
  const [isFinishShiftDialogOpened, setIsFinishShiftDialogOpened] = useState(
    false
  );
  const [isShiftNotificationVisible, setIsShiftNotificationVisible] = useState(
    shouldShowNotification(shiftInProgress?.plannedToEndAt)
  );

  const name = shiftInProgress ? `${shiftInProgress?.patient?.name}` : 'Menu';
  const id = shiftInProgress?.patientId;

  const { getProfilePicture } = usePatient();

  const handleGetProfilePicture = async (id: number) => {
    try {
      return await getProfilePicture(id).then(({ data }) => data?.signedUrl);
    } catch (err) {}
  };

  useEffect(() => {
    async function fetchPatientProfilePicture() {
      const result = await handleGetProfilePicture(parseInt(`${id}`));
      if (result) {
        setImageUrl(result);
      }
    }

    fetchPatientProfilePicture();
  }, []);

  useEffect(
    function handleNotification() {
      if (shiftInProgress) {
        const { plannedToEndAt } = shiftInProgress;
        const diffInMs = getDiffInMs(new Date(plannedToEndAt!), Date.now());

        shiftEndingTimer.current = setTimeout(() => {
          setIsShiftNotificationVisible(true);
        }, diffInMs - TIMER_IN_MS);
      }
      return () => {
        if (shiftEndingTimer.current) {
          clearTimeout(shiftEndingTimer.current);
          setIsShiftNotificationVisible(false);
        }
      };
    },
    [shiftInProgress]
  );

  return (
    <>
      <HeaderWithDrawer
        centerContent={
          <CenterContainer>
            <PhotoElement>
              {<Avatar src={shiftInProgress ? imageUrl : ''} />}
            </PhotoElement>
            <Title align="center" variant="h6">
              {name}
            </Title>
          </CenterContainer>
        }
      />
      {isShiftNotificationVisible && (
        <>
          <ShiftHeaderNotification
            onFinishClick={() => setIsFinishShiftDialogOpened(true)}
          />
          <FinishShiftConfirmDialog
            opened={isFinishShiftDialogOpened}
            close={() => setIsFinishShiftDialogOpened(false)}
          />
        </>
      )}
    </>
  );
};

export default PatientHeader;
