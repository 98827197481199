import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(1, 1, 2)};
  display: flex;
  flex-direction: column;
`;

export const ExecutionList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: ${({ theme }) => theme.spacing(1)}px;
`;

export const Divider = styled.hr`
  background-color: ${({ theme }) => theme.palette.common.black};
  color: ${({ theme }) => theme.palette.common.black};
  width: 100%;
  margin: 0;
`;
