import React from 'react';
import { Centralizer, Form } from './styles';

import { Formik, FormikHelpers, FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import { FormValues } from './utils';

import useCanAccess from '../../hooks/useCanAccess';
import FormikTextField from '../Forms/FormikTextField';
import StyledButton from '../StyledButton';

const ShoppingListUpsertForm: React.FC<{
  initialValues: FormValues;
  innerRef?: React.Ref<FormikProps<FormValues>>;
  handleBoughtSubmit: () => void | Promise<void>;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<void>;
}> = ({ initialValues, innerRef, onSubmit, handleBoughtSubmit }) => {
  const { isAllowedToUpdate: isAllowedToUpdateShoppingList } = useCanAccess(
    'care/shopping-list'
  );
  const { isAllowedToInvoke: isAllowedToBuyShoppingList } = useCanAccess(
    'care/shopping-list.buy'
  );
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnChange={false}
      innerRef={innerRef}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form
            // noValidate disables browser default validation
            noValidate={true}
          >
            <FormikTextField
              color="primary"
              id="shoppingText"
              inputProps={{ 'data-testid': 'shoppingText' }}
              placeholder="Exemplo: medicamentos (com dosagem e demais informações importantes), fraldas, alimentos, suplementos, equipamentos (bengala, cadeira de rodas, cadeira de banho), etc."
              name="shoppingText"
              margin="normal"
              autoComplete="off"
              multiline
              rows={10}
            />
            <Centralizer>
              {isAllowedToUpdateShoppingList && (
                <StyledButton
                  data-testid="submit-list"
                  type="submit"
                  size="large"
                  color="inherit"
                  disabled={isSubmitting || isEqual(initialValues, values)}
                >
                  Salvar
                </StyledButton>
              )}
              {isAllowedToBuyShoppingList && (
                <StyledButton
                  data-testid="bought-list"
                  onClick={() => handleBoughtSubmit()}
                  size="large"
                  color="inherit"
                  disabled={isSubmitting || isEqual('', values.shoppingText)}
                >
                  Comprado
                </StyledButton>
              )}
            </Centralizer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ShoppingListUpsertForm;
