import { ContentModel } from '@cuidador/database';
import { Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import qs from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { APIError } from '@cuidador/lib';
import { AxiosError } from 'axios';
import ContentSearchTextField from '../../components/ContentSearchTextField';
import HeaderWithDrawer from '../../components/Headers/HeaderWithDrawer';
import { InternetConnectionContext } from '../../components/InternetConnectionCheck/InternetConnectionContext';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import LoadMoreContainer from '../../components/LoadMoreContainer';
import OfflineWarning from '../../components/OfflineWarning';
import useContent from '../../hooks/useContent';
import { resolveErrorMessage } from '../../utils/error';
import {
  CardBox,
  Container,
  HeaderTitle,
  MessageContainer,
  StyledLink,
  TextWrap,
  TypographyContainer,
} from './styles';

const Content: React.FC = () => {
  const { internetStatus } = useContext(InternetConnectionContext);
  const [recommendedData, setRecommendedData] = useState<ContentModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const [contents, setContents] = useState<ContentModel[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchValue, setSearchValue] = useState('');

  const {
    getPaginated,
    loading: loadingContent,
    getAllRecommendedContent,
  } = useContent();

  const limit = 10;
  const history = useHistory();

  const checkHasMoreItems = (totalActual: number) => {
    return totalActual > limit * (page + 1) ? true : false;
  };

  useEffect(() => {
    if (internetStatus === 'online') {
      getAllRecommendedContent().then((data) => {
        setRecommendedData(data);
      });
    }
  }, [internetStatus]);

  useEffect(() => {
    if (internetStatus === 'online') {
      fetchData();
    }
  }, [internetStatus, page]);

  const fetchData = async () => {
    const params = qs.parse(history.location.search);
    if (params) {
      const searchParam = qs.parse(history.location.search);
      const paramToString = searchParam[`content.title`]?.toString();
      setSearchValue(paramToString?.slice(1, -1) || ''); // using this to set the searchfield value if it comes back from a content item
    }
    getPaginated({
      ...params,
      page,
    })
      .then((data) => {
        if (page === 0) {
          setContents(data.results);
        } else {
          setContents([...contents, ...data.results]);
        }
        setTotal(data.total);
        setHasMoreItems(checkHasMoreItems(data.total));
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onChangeParams = () => {
    if (page === 0) {
      fetchData();
    } else {
      setPage(0);
    }
  };

  const handlePagination = () => {
    if (checkHasMoreItems(total)) {
      setLoading(true);
      setPage(page + 1);
    }
  };

  const renderContentItem = (item: ContentModel) => {
    return (
      <StyledLink to={`/conteudo/${item.id}`}>
        <CardBox>
          <TextWrap>
            <Typography variant="subtitle2">{item.title}</Typography>
          </TextWrap>
          <ChevronRightIcon />
        </CardBox>
      </StyledLink>
    );
  };

  const renderOnlineContent = () => (
    <>
      <ContentSearchTextField
        fieldName="content.title"
        onChangeDebounced={onChangeParams}
        defaultValue={searchValue}
      />
      {recommendedData?.length > 0 && (
        <>
          <TypographyContainer>
            <Typography variant="h6">Conteúdos recomendados</Typography>
          </TypographyContainer>
          {recommendedData.map((item) => (
            <div key={item.id}>{renderContentItem(item)}</div>
          ))}
        </>
      )}
      <TypographyContainer>
        <Typography variant="h6">Lista de conteúdos</Typography>
      </TypographyContainer>
      {contents?.length <= 0 ? (
        <MessageContainer>
          <Typography variant="subtitle1">
            Não há nenhum conteúdo disponível
          </Typography>
        </MessageContainer>
      ) : (
        <LoadMoreContainer
          data={contents}
          loading={loading}
          keyExtractor={({ item }) => String(item!.id)}
          loadMoreText={'Carregar mais conteúdos'}
          hasMoreItems={hasMoreItems}
          renderItem={renderContentItem}
          handlePagination={handlePagination}
        />
      )}
    </>
  );

  return (
    <>
      {loadingContent && !loading && (
        <LoadingBackdrop loading={loadingContent} />
      )}
      <HeaderWithDrawer
        centerContent={
          <HeaderTitle align="center" variant="h6">
            Conteúdos de A a Z
          </HeaderTitle>
        }
      />
      <Container>
        {internetStatus === 'online' ? (
          renderOnlineContent()
        ) : (
          <OfflineWarning />
        )}
      </Container>
    </>
  );
};

export default Content;
