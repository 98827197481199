import styled from 'styled-components';
import StyledPaper from '../../../StyledPaper';

export const CardBox = styled(StyledPaper)`
  display: flex;
  min-height: 120px;
  padding: 0;
  color: ${({ theme }) => theme.palette.extra.color.grey.main};
  background-color: ${({ theme }) => theme.palette.extra.color.grey.light};
  margin-top: 0px;
`;

export const LeftContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.extra.color.yellow};
  display: flex;
  padding: ${({ theme }) => theme.spacing(2, 0, 0, 0)};
  flex-direction: column;

  img {
    width: 75px;
    height: 75px;
  }
  border-radius: 4px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  padding: ${({ theme }) => theme.spacing(1, 1, 0, 1)};
`;

export const CardFooter = styled.footer`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing(1, 1, 0, 1)};
`;

export const CaregiverTypography = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 600;
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &::before {
    content: 'por ';
    font-weight: 400;
  }
`;
