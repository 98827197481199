import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 1)};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CardDiv = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const TypographyContainer = styled.div`
  width: 100%;
  text-align: center;
  color: black;
  margin-top: ${({ theme }) => theme.spacing(6)}px;
`;
