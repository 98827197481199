import React, { useMemo, useContext } from 'react';

import Tabbar from '../Tabbar';
import Routes from '../../routes/Routes';
import { Container, Content } from './styles';
import { AuthContext } from '../../contexts/auth';
import { useRouteMatch } from 'react-router-dom';

export interface ContentProps {
  withTabs: boolean;
}

const routesWithMenu = [
  '/conteudo',
  '/compras',
  '/menu',
  '/plantao',
  '/agenda-de-plantoes/:id',
  '/pessoas-sob-cuidado',
  '/historico/plantao/paciente/:patientId',
  '/avaliacao/paciente/:patientId',
  '/ajuda',
  '/',
];

const Layout: React.FC = () => {
  const { user } = useContext(AuthContext);
  const match = useRouteMatch(routesWithMenu);

  const shouldRenderTabs = useMemo(() => {
    return Boolean(user) && Boolean(match?.isExact);
  }, [user, match]);

  return (
    <Container>
      <Content withTabs={shouldRenderTabs}>
        <Routes />
      </Content>
      {shouldRenderTabs && <Tabbar />}
    </Container>
  );
};

export default Layout;
