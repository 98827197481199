import { APIError } from '@cuidador/lib'
import { Checkbox, FormHelperText } from '@material-ui/core'
import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'
import { format } from 'date-fns'
import { Form, Formik, FormikErrors, FormikProps } from 'formik'
import qs from 'query-string'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  FormCardContainer,
  StyledBoldTitle,
  StyledFormikSelect,
  StyledFormikTextField,
  StyledFormikTextPassword,
} from '../../components/FormCardContainer'
import FormikSelectField from '../../components/Forms/FormikSelect'
import FormikTextField from '../../components/Forms/FormikTextField'
import HeaderBase from '../../components/Headers/HeaderBase'
import MedicBalloon from '../../components/MedicBalloon'
import PasswordRules from '../../components/PasswordRules'
import ProfilePicture from '../../components/ProfilePicture'
import StyledButton from '../../components/StyledButton'
import StyledKeyboardDatePicker from '../../components/StyledKeyboardDatePicker'
import StyledMenuItem from '../../components/StyledMenuItem'
import { AuthContext } from '../../contexts/auth'
import useUser from '../../hooks/useUser'
import { resolveErrorMessage } from '../../utils/error'
import { cpfMask, numberMask, phoneMask, UFoptions } from '../../utils/inputs'
import {
  ButtonContainer,
  Container,
  ContentContainer,
  FooterContainer,
  Logo,
  LogoContainer,
  MessageContainer,
  ProfileContainer,
  Screen,
  StyledFormControlLabel,
  Subtitle,
  TabbedContainer,
  VisibilityContainer,
} from './styles'
import {
  educationLevelOptions,
  formDataToCompleteSignupRequest,
  formInitialValues,
  FormValues,
  genderOptions,
  hasTakenProfessionalCourseOptions,
  occupationOptions,
  resolveFormTypeByRole,
  resolvePendingSignupJWTData,
  resolveValidationSchema,
  worksAsCaregiverForTimeOptions,
} from './utils'

const CompleteSignup: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const formikFormRef = useRef<FormikProps<FormValues> | null>(null)

  const { completeSignup, checkSignupStatus, uploadProfilePicture } = useUser()
  const { signIn, error: signInError, refreshUserInfo, userInfo, setReloadUser } = useContext(AuthContext)
  const [validationError, setValidationError] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const { token: completeSignupToken } = qs.parse(location.search)

  useEffect(() => {
    if (userInfo && submitted) {
      setReloadUser(true)
      history.push('/')
    }
  }, [userInfo, submitted])

  const completeSignupTokenPayload = useMemo(() => {
    // avoids access without valid token
    if (completeSignupToken == null) {
      redirectToHome()
      return
    }

    const payload = resolvePendingSignupJWTData(String(completeSignupToken))

    if (!payload) {
      // Invalid token
    }

    return payload
  }, [completeSignupToken])

  const formType = useMemo(() => {
    if (!completeSignupTokenPayload) {
      return undefined
    }
    return resolveFormTypeByRole(completeSignupTokenPayload?.roleId)
  }, [completeSignupTokenPayload?.roleId])

  function redirectToHome () {
    history.replace('/')
  }

  const userIdFromTokenPayload = Number(completeSignupTokenPayload?.id)

  useEffect(() => {
    checkSignupStatus(userIdFromTokenPayload).then(({ hasCompletedSignup }) => {
      if (hasCompletedSignup) {
        history.push('/login')
      }
    })
  }, [userIdFromTokenPayload])

  useEffect(
    function removeLoadingOnSignInError () {
      if (signInError) {
        toast.error(resolveErrorMessage(signInError))
      }
    },
    [signInError]
  )

  const handleSignIn = () => {
    const { cpf } = formikFormRef.current?.values as FormValues
    const { password } = formikFormRef.current?.values as FormValues
    return signIn(numberMask(cpf || ''), password)
  }

  const handleUploadProfilePicture = (picture: File, caregiverId: number) => {
    return new Promise<void>((resolve) => {
      setTimeout(async () => {
        try {
          await uploadProfilePicture(picture, caregiverId)
          resolve()
        } catch (err) {
          toast.error(
            'Houve um erro durante o envio de sua imagem, tente novamente acessando seu perfil'
          )
          Sentry.captureException(err)
        }
      }, 0)
    })
  }

  const handleCompleteSignupSubmit = async () => {
    const formData = formikFormRef.current?.values as FormValues
    setLoading(true)

    if (!formType) return

    window.localStorage.setItem('completeSignUp', JSON.stringify({ userId: userIdFromTokenPayload, date: (new Date()).toUTCString() }))

    return completeSignup(
      formDataToCompleteSignupRequest(
        {
          ...formData,
        },
        formType
      ),
      String(completeSignupToken)
    )
      .then(async (response) => {
        await handleSignIn()
        return response
      })
      .then(async ({ id }) => {
        if (formData?.picture != null) {
          await handleUploadProfilePicture(formData.picture, id)
        }
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err)
        const fieldError = err.response?.data.context?.key
        if (fieldError) {
          if (Object.keys(formInitialValues).includes(fieldError)) {
            formikFormRef.current?.setFieldError(
              fieldError,
              displayMessage || ''
            )
          }
        } else {
          toast.error(displayMessage)
        }
      })
      .finally(async () => {
        await refreshUserInfo()
        setSubmitted(true)
        setLoading(false)
      })
  }

  if (!formType) {
    return null
  }

  return (
    <>
      <HeaderBase
        centerContent={
          <LogoContainer>
            <Logo />
          </LogoContainer>
        }
      />
      <Screen>
        <MessageContainer>
          <MedicBalloon
            text={
              'Olá, eu sou a Augusta!\nSeja bem-vindo ao Cuidador de Confiança.\n\nPara começar, crie sua conta! '
            }
          />
        </MessageContainer>
        <Container>
          <Formik
            validateOnChange={false}
            innerRef={(ref) => (formikFormRef.current = ref)}
            initialValues={{
              ...formInitialValues,
              ...completeSignupTokenPayload,
            }}
            validationSchema={resolveValidationSchema(formType)}
            onSubmit={handleCompleteSignupSubmit}
          >
            {({ values, setFieldValue, errors, handleBlur }) => {
              return (
                <Form>
                  <ContentContainer>
                    <PersonalDataStepContainer
                      setFieldValue={setFieldValue}
                      values={values}
                      handleBlur={handleBlur}
                      errors={errors}
                    />
                    <VisibilityContainer $active={formType === 'selfCare'}>
                      <SelfCareStepContainer />
                    </VisibilityContainer>
                    <VisibilityContainer $active={formType === 'caregiver'}>
                      <CaregiverStepContainer values={values} />
                    </VisibilityContainer>
                    <VisibilityContainer $active={formType === 'supporter'}>
                      <SupporterStepContainer values={values} />
                    </VisibilityContainer>

                    <PasswordStepContainer
                      values={values}
                      validationError={validationError}
                    />

                    <FooterContainer>
                      <div>
                        <StyledFormControlLabel
                          control={
                            <Checkbox
                              name="termsAccepted"
                              value={values.termsAccepted}
                              onChange={() =>
                                setFieldValue(
                                  'termsAccepted',
                                  !values.termsAccepted
                                )
                              }
                            />
                          }
                          label={
                            <a
                              href="https://www.cuidadordeconfianca.com.br/termos-e-politicas"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Eu li e aceito os Termos de Uso e Políticas de
                              Privacidade
                            </a>
                          }
                        />
                        {errors.termsAccepted && (
                          <FormHelperText error>
                            {errors.termsAccepted}
                          </FormHelperText>
                        )}
                      </div>

                      <ButtonContainer>
                        <StyledButton
                          disabled={loading}
                          type="submit"
                          size="large"
                          color="inherit"
                          data-testid="submit"
                          onClick={() => setValidationError(true)}
                        >
                          Finalizar
                        </StyledButton>
                      </ButtonContainer>
                    </FooterContainer>
                  </ContentContainer>
                </Form>
              )
            }}
          </Formik>
        </Container>
      </Screen>
    </>
  )
}

const PersonalDataStepContainer: React.FC<{
  setFieldValue: (field: string, value: string | File | Date | null) => void
  values: FormValues
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleBlur: any
  errors: FormikErrors<FormValues>
}> = ({ values, setFieldValue, handleBlur }) => {
  const finalDate = format(new Date('12/31/9999'), 'yyyy-MM-dd')

  return (
    <>
      <ProfileContainer>
        <ProfilePicture
          onChange={(file) => setFieldValue('picture', file)}
          isCreation={true}
        />
      </ProfileContainer>
      <FormCardContainer>
        <StyledBoldTitle variant="subtitle2">
          Informações pessoais
        </StyledBoldTitle>

        <FormikTextField
          as={StyledFormikTextField}
          name="name"
          color="primary"
          label="Nome completo"
          margin="normal"
          inputProps={{ 'data-testid': 'name' }}
        />
        <FormikTextField
          as={StyledFormikTextField}
          name="cpf"
          color="primary"
          label="CPF"
          type="tel" // numeric keyboard without parsing to number
          margin="normal"
          inputProps={{ 'data-testid': 'cpf' }}
          value={cpfMask(values.cpf)}
        />
        <FormikTextField
          as={StyledFormikTextField}
          name="email"
          color="primary"
          type="email"
          label="E-mail"
          margin="normal"
          inputProps={{ 'data-testid': 'email' }}
        />
        <FormikTextField
          as={StyledFormikTextField}
          name="phoneNumber"
          color="primary"
          label="Telefone"
          type="tel"
          margin="normal"
          inputProps={{ 'data-testid': 'phoneNumber' }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setFieldValue('phoneNumber', phoneMask(e.target.value))
          }}
        />
        <FormikSelectField
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          as={(props: any) => <StyledFormikSelect {...props} />}
          name="gender"
          label="Sexo"
          color="primary"
          SelectDisplayProps={{
            'data-testid': 'gender',
          }}
        >
          {genderOptions.map((item) => (
            <StyledMenuItem key={item.value} value={item.value} color="primary">
              {item.label}
            </StyledMenuItem>
          ))}
        </FormikSelectField>
        <StyledKeyboardDatePicker
          name="dateOfBirth"
          label="Data de nascimento"
          color="primary"
          value={values.dateOfBirth}
          onChange={(date: Date | null) => {
            setFieldValue('dateOfBirth', date)
          }}
          placeholder="dd/mm/aaaa"
          margin="normal"
          size="medium"
          inputProps={{
            'data-testid': 'dateOfBirth',
            max: finalDate,
          }}
          onBlur={handleBlur}
          InputLabelProps={{ shrink: true }}
          borderType="flat"
        />
      </FormCardContainer>
    </>
  )
}

const CaregiverStepContainer: React.FC<{
  values: FormValues
}> = ({ values }) => {
  return (
    <>
      <StyledBoldTitle variant="subtitle2" data-testid="secondStepTitle">
        Outras informações
      </StyledBoldTitle>

      <TabbedContainer>
        <Subtitle>Há quanto tempo trabalha como cuidador?</Subtitle>
        <FormikSelectField
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          as={(props: any) => <StyledFormikSelect {...props} />}
          name="worksAsCaregiverForTime"
          color="primary"
          label="Selecione a resposta"
          SelectDisplayProps={{
            'data-testid': 'worksAsCaregiverForTime',
          }}
        >
          {worksAsCaregiverForTimeOptions.map((item) => (
            <StyledMenuItem key={item.value} value={item.value} color="primary">
              {item.label}
            </StyledMenuItem>
          ))}
        </FormikSelectField>

        <Subtitle>Já realizou algum curso profissionalizante?</Subtitle>
        <FormikTextField
          as={StyledFormikTextField}
          name="hasTakenProfessionalCourse"
          color="primary"
          label="Descreva o que já realizou"
          margin="normal"
          multiline
          rows={4}
          inputProps={{ 'data-testid': 'hasTakenProfessionalCourse' }}
        />

        <Subtitle>Faz parte de alguma agência de cuidadores?</Subtitle>
        <FormikSelectField
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          as={(props: any) => <StyledFormikSelect {...props} />}
          name="belongsToCaregiverAgency"
          color="primary"
          label="Selecione a resposta"
          SelectDisplayProps={{
            'data-testid': 'belongsToCaregiverAgency',
          }}
        >
          {hasTakenProfessionalCourseOptions.map((item) => (
            <StyledMenuItem key={item.value} value={item.value} color="primary">
              {item.label}
            </StyledMenuItem>
          ))}
        </FormikSelectField>
        {!!values.belongsToCaregiverAgency && (
          <FormikTextField
            as={StyledFormikTextField}
            name="caregiverAgencyName"
            color="primary"
            label="Qual agência?"
            margin="normal"
            inputProps={{ 'data-testid': 'caregiverAgencyName' }}
          />
        )}
      </TabbedContainer>
    </>
  )
}

const SelfCareStepContainer: React.FC = () => {
  return (
    <>
      <StyledBoldTitle variant="subtitle2" data-testid="secondStepTitle">
        Outras informações
      </StyledBoldTitle>

      <FormikSelectField
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        as={(props: any) => <StyledFormikSelect {...props} />}
        name="educationLevel"
        color="primary"
        label="Qual a sua escolaridade?"
        SelectDisplayProps={{
          'data-testid': 'educationLevel',
        }}
      >
        {educationLevelOptions.map((educationLevelOption) => (
          <StyledMenuItem
            value={educationLevelOption.value}
            key={educationLevelOption.value}
          >
            {educationLevelOption.label}
          </StyledMenuItem>
        ))}
      </FormikSelectField>
    </>
  )
}

const SupporterStepContainer: React.FC<{
  values: FormValues
}> = ({ values }) => {
  return (
    <>
      <StyledBoldTitle variant="subtitle2" data-testid="supporterStepTitle">
        Outras informações
      </StyledBoldTitle>

      <FormikSelectField
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        as={(props: any) => <StyledFormikSelect {...props} />}
        name="occupation"
        color="primary"
        label="Profissão"
        SelectDisplayProps={{
          'data-testid': 'occupation',
        }}
      >
        {occupationOptions.map((item) => (
          <StyledMenuItem key={item.value} value={item.value}>
            {item.label}
          </StyledMenuItem>
        ))}
      </FormikSelectField>

      <FormikTextField
        as={StyledFormikTextField}
        name="document"
        color="primary"
        label="Número do Conselho"
        margin="normal"
        type="tel" // numeric keyboard without parsing to number
        inputProps={{ 'data-testid': 'document' }}
        value={numberMask(values.document || '')}
      />

      <FormikSelectField
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        as={(props: any) => <StyledFormikSelect {...props} />}
        name="documentProvince"
        color="primary"
        label="UF"
        SelectDisplayProps={{
          'data-testid': 'documentProvince',
        }}
      >
        {UFoptions.map((item) => (
          <StyledMenuItem key={item.value} value={item.value}>
            {item.label}
          </StyledMenuItem>
        ))}
      </FormikSelectField>

      <FormikTextField
        as={StyledFormikTextField}
        name="specialty"
        color="primary"
        label="Especialidade"
        margin="normal"
        inputProps={{ 'data-testid': 'specialty' }}
      />
    </>
  )
}

const PasswordStepContainer: React.FC<{
  values: FormValues
  validationError: boolean
}> = ({ values, validationError }) => {
  return (
    <FormCardContainer>
      <StyledBoldTitle variant="subtitle2">Crie sua senha</StyledBoldTitle>

      <FormikTextField
        as={StyledFormikTextPassword}
        color="primary"
        name="password"
        label="Senha"
        margin="normal"
        autoComplete="off"
        inputProps={{
          'data-testid': 'password',
          autocomplete: 'new-password',
          form: {
            autocomplete: 'off',
          },
        }}
      />
      <FormikTextField
        as={StyledFormikTextPassword}
        color="primary"
        name="passwordConfirmation"
        label="Confirmar senha"
        margin="normal"
        autoComplete="off"
        inputProps={{
          'data-testid': 'passwordConfirmation',
          autocomplete: 'new-password',
          form: {
            autocomplete: 'off',
          },
        }}
      />
      <PasswordRules
        password={values.password}
        validationError={validationError}
      />
    </FormCardContainer>
  )
}

export default CompleteSignup
