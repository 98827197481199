import {
  ExecutionMessage,
  Shift,
  ShiftExecutionModel,
} from '@cuidador/database';
import { sortBy } from 'lodash';

export function formatFullWeekdayName(day: string) {
  switch (day) {
    case 'Monday':
      return 'Segunda-feira';
    case 'Tuesday':
      return 'Terça-Feira';
    case 'Wednesday':
      return 'Quarta-Feira';
    case 'Thursday':
      return 'Quinta-Feira';
    case 'Friday':
      return 'Sexta-feira';
    case 'Saturday':
      return 'Sabado';
    case 'Sunday':
      return 'Domingo';
    default:
      return '';
  }
}

export interface FormValues {
  id: number;
  status: string;
  updatedTime?: string;
}

export const getExecutionMessages = (
  shift: Shift | null | undefined,
  executionInProgress: ShiftExecutionModel | null | undefined
) => {
  if (!shift || !shift.executions || shift.executions.length === 0) return [];

  const filteredAndOrderedExecutions = sortBy(
    shift.executions.filter(
      (execution) =>
        !!execution.message &&
        (!executionInProgress || execution.id !== executionInProgress.id)
    ),
    'startedAt'
  );

  const messages = filteredAndOrderedExecutions.map(
    ({ message }) => message
  ) as ExecutionMessage[];

  return messages;
};
