import { FormControl, Select } from '@material-ui/core';
import styled from 'styled-components';

import { colorMapping, ColorsType } from '../../styles/colorMapping';

export const StyledFormControl = styled(FormControl).attrs(({ variant }) => ({
  variant: variant || 'outlined',
}))<{
  $color: ColorsType;
}>`
  & .MuiInputBase-root {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & label:not(.Mui-error) {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & label.Mui-disabled {
    color: ${({ $color }) => colorMapping[$color]};
    opacity: 65%;
  }
  & input:not(.Mui-disabled) {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & textarea:not(.Mui-disabled) {
    color: ${({ $color }) => colorMapping[$color]};
  }
  &
    .MuiInputAdornment-root:not(.Mui-disabled)
    .MuiIconButton-label:not(.Mui-disabled) {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & .MuiOutlinedInput-root:not(.Mui-error, .Mui-disabled) {
    & fieldset {
      border-color: ${({ $color }) => colorMapping[$color]};
      color: ${({ $color }) => colorMapping[$color]};
    }
    &:hover fieldset {
      border-color: ${({ $color }) => colorMapping[$color]};
      color: ${({ $color }) => colorMapping[$color]};
    }
    &.Mui-focused fieldset {
      border-color: ${({ $color }) => colorMapping[$color]};
      color: ${({ $color }) => colorMapping[$color]};
    }
  }
  & .MuiOutlinedInput-root.Mui-disabled {
    color: ${({ $color }) => colorMapping[$color]};
    opacity: 65%;
    cursor: default;

    & input:disabled {
      color: ${({ $color }) => colorMapping[$color]};
      opacity: 65%;
    }

    & fieldset {
      border-color: ${({ $color }) => colorMapping[$color]};
      opacity: 65%;
    }
  }

  width: fit-content;
`;

export const StyledSelect = styled(Select)`
  border-radius: 4px;

  & .MuiSelect-select {
    padding: ${({ theme }) => theme.spacing(1, 4, 1, 2)};
  }
`;
