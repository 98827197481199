import { Typography } from '@material-ui/core';
import { Form as FormikForm } from 'formik';
import styled from 'styled-components';
import StyledButton from '../StyledButton';

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #000000;
`;

export const FormContainer = styled.div`
  margin: 0 ${({ theme }) => theme.spacing(3)}px; ;
`;

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
`;

export const OptionsButton = styled(StyledButton)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  width: 100%;
  max-width: 220px;
`;

export const StyledError = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 1em;
`;
