import { clientSideScheme } from '@cuidador/whitelabel';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlined from '@material-ui/icons/CheckBoxOutlined';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { RuleContainer, RuleText, Title } from './styles';

interface passwordRule {
  text: string;
  isValid: (value: string) => boolean;
}

const passwordRules: passwordRule[] = [
  {
    text: 'Pelo menos 8 caracteres',
    isValid: (password) => password.length >= 8,
  },
  {
    text: 'Uma letra maiúscula',
    isValid: (password) => /[A-Z]/.test(password),
  },
  {
    text: 'Uma letra minúscula',
    isValid: (password) => /[a-z]/.test(password),
  },
  {
    text: 'Um número',
    isValid: (password) => /[0-9]/.test(password),
  },
  {
    text: 'Um caractere especial (!, *, -, ?, @, /)',
    isValid: (password) => /[!@#$%&?.,;/\* -]/.test(password),
  },
];

interface PasswordRequirementsProps {
  password: string;
  validationError?: boolean;
}

const PasswordRequirements = ({
  password,
  validationError = false,
}: PasswordRequirementsProps) => {
  const { appCPalette } = clientSideScheme();
  return (
    <div>
      <Title variant="caption">Critérios para criar sua senha</Title>
      {passwordRules.map((rule, index) => {
        const isValid = rule.isValid(password);
        return (
          <RuleContainer key={index}>
            {isValid ? (
              <CheckBoxIcon htmlColor={appCPalette.extra.color.green} />
            ) : validationError ? (
              <CloseIcon htmlColor={appCPalette.extra.color.red.main} />
            ) : (
              <CheckBoxOutlined htmlColor={appCPalette.extra.color.grey.main} />
            )}
            <RuleText
              variant="subtitle2"
              isValid={isValid}
              submitError={validationError}
            >
              {rule.text}
            </RuleText>
          </RuleContainer>
        );
      })}
    </div>
  );
};

export default PasswordRequirements;
