import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.spacing(2, 2)};
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const EmptyDiv = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-right: auto;
  margin-left: auto;
  color: ${({ theme }) => theme.palette.primary.dark};
  text-align: center;
`;
