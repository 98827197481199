import styled from 'styled-components';

export const HeaderContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  padding: 0 8px ${({ theme }) => theme.spacing(1)}px 8px;
  background-color: ${({ theme }) => theme.palette.extra.color.grey.light};

  h6 {
    color: ${({ theme }) => theme.palette.secondary.main};
    text-align: center;
    font-family: 'Work Sans';
    font-weight: bold;
  }
`;

export const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const TimerBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing(2, 2, 0, 2)};

  h6 {
    color: ${({ theme }) => theme.palette.primary.main};
    font-family: 'Work Sans';
    font-weight: bold;
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.spacing(-0.1)}px;

    svg {
      margin-right: ${({ theme }) => theme.spacing(1)}px;
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }
`;

export const HeaderSubtitle = styled.div`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;

  h6 {
    text-align: left;
    color: ${({ theme }) => theme.palette.primary.main};
  }

  p {
    margin: 0;
  }
`;
