import { clientSideScheme } from '@cuidador/whitelabel';
import styled from 'styled-components';
import BackgroundImage from '../../assets/senior-background.png';
import TextField from '../../components/StyledTextField';

export const Background = styled.div`
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  background-image: ${`url(${BackgroundImage})`};
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.13;
`;

export const BackgroundOverlay = styled.section`
  height: 100vh;
  background: linear-gradient(
    180deg,
    ${(props) => props.theme.palette.primary.main} 0%,
    ${(props) => props.theme.palette.secondary.main} 100%
  );
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 9;
`;

export const OutterContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-height: 675px) {
    form {
      .MuiButton-root {
        margin-top: ${({ theme }) => theme.spacing(2)}px;
      }
    }

    a {
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }

    img {
      max-height: 95px;
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }

    p:last-child {
      position: inherit;
    }

    section {
      height: 200%;
    }

    section > div {
      height: 100%;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  max-width: 500px;
  color: white;
  padding: 0px ${({ theme }) => theme.spacing(2)}px;

  z-index: 10;

  img {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing(6)}px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;

    .MuiButton-root {
      margin-top: ${({ theme }) => theme.spacing(4)}px;
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
    }
  }

  a {
    font-size: 14px;
    color: white;
    margin-bottom: ${({ theme }) => theme.spacing(6)}px;
  }
`;

export const StyledTextField = styled(TextField).attrs({ variant: 'outlined' })`
  & label:not(.Mui-error) {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & input {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & textarea {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiInputAdornment-root .MuiIconButton-label {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiOutlinedInput-root:not(.Mui-error) {
    & fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

export const TypographyContainer = styled.div`
  max-width: 100%;
  text-align: center;
`;

export const Logo = styled.img.attrs(() => {
  const { logoLightUrl } = clientSideScheme();
  return { src: logoLightUrl };
})`
  margin: ${({ theme }) => theme.spacing(0, 2, 0)};
`;
