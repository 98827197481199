import styled from 'styled-components';
import Cached from '@material-ui/icons/Cached';
import IconButton from '@material-ui/core/IconButton';
import MaterialCircularProgress from '@material-ui/core/CircularProgress';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.warning.light};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const CircularProgress = styled(MaterialCircularProgress).attrs({
  size: 16,
  color: 'inherit',
})`
  padding: 4px;
`;

export const Label = styled.span`
  color: ${({ theme }) => theme.palette.primary.dark};
  fontsize: ${({ theme }) => theme.typography.body2.fontSize}px;
`;

export const RefreshIcon = styled(Cached)`
  width: 24px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  margin: ${({ theme }) => theme.spacing(0, 0, 0, 1)};
`;
