import { InputBase } from '@material-ui/core';
import styled from 'styled-components';

export const StyledInput = styled(InputBase)`
  margin-bottom: ${({ theme }) => theme.spacing(2.5)}px;
  margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(1, 1)};
  border-radius: 9px;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  max-width: 100%;

  input {
    font-size: ${({ theme }) => theme.spacing(2)}px;
    padding: ${({ theme }) => theme.spacing(0, 1)};
  }

  .MuiInputAdornment-positionEnd {
    margin: 0;
  }
`;
