import styled from 'styled-components';
import { Typography } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-block: ${({ theme }) => theme.spacing(1)}px;
`;

export const ContainerText = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const Text = styled(Typography)`
  display: inline;
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const Label = styled(Text)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;
