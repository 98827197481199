import { PatientModel } from '@cuidador/database';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeaderWithDrawer from '../../components/Headers/HeaderWithDrawer';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import PatientCardItem from '../../components/PatientCardItem';
import SearchTextField from '../../components/SearchTextField';
import StyledButton from '../../components/StyledButton';
import useCanAccess from '../../hooks/useCanAccess';
import usePatient from '../../hooks/usePatient';
import { HeaderTitle } from '../Content/styles';
import { Container, EmptyDiv } from './styles';

const Patients: React.FC = () => {
  const [patients, setPatients] = useState<PatientModel[]>([]);
  const [page, setPage] = useState(0);
  const { getAllRelated, loading, total } = usePatient();

  const { isAllowedToRead: isAllowedToReadRelatedPatients } = useCanAccess(
    'user/patient'
  );

  const history = useHistory();
  const historyLocationSearch = history.location.search;

  useEffect(() => {
    if (!isAllowedToReadRelatedPatients) {
      toast.error('Você não tem permissão para visualizar essa página');
      return history.goBack();
    }
  }, [isAllowedToReadRelatedPatients]);

  useEffect(() => {
    handleGetPatients();
  }, [page, historyLocationSearch, isAllowedToReadRelatedPatients]);

  const handleGetPatients = () => {
    const params = qs.parse(historyLocationSearch);
    getAllRelated({
      ...params,
      page,
      limit: 10,
      orderBy: 'name',
      order: 'asc',
    })
      .then((data) => {
        const patientsToInsert = data.results;
        if (page === 0) {
          setPatients(patientsToInsert);
        } else {
          setPatients([...patients, ...patientsToInsert]);
        }
      })
      .catch((e) => {
        toast.error(e);
      });
  };

  const pageIncrement = () => {
    setPage(page + 1);
  };

  const onChangeParams = () => {
    if (page !== 0) {
      setPage(0);
    }
  };

  return (
    <>
      <HeaderWithDrawer
        centerContent={
          <HeaderTitle align="center" variant="h6">
            Pessoas sob cuidado
          </HeaderTitle>
        }
      />
      <LoadingBackdrop loading={loading} />
      <Container>
        <SearchTextField
          fieldName="patient.name"
          onChangeDebounced={onChangeParams}
        />
        {patients.length > 0 ? (
          patients.map((patient) => (
            <PatientCardItem patient={patient} key={patient.id} />
          ))
        ) : (
          <EmptyDiv>Não existem pessoas sob o seu cuidado.</EmptyDiv>
        )}
        {patients.length < total && (
          <EmptyDiv>
            <StyledButton size="medium" color="primary" onClick={pageIncrement}>
              Ver mais
            </StyledButton>
          </EmptyDiv>
        )}
      </Container>
    </>
  );
};

export default Patients;
