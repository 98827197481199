import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AccessibleSharpIcon from '@material-ui/icons/AccessibleSharp';
import styled, { css } from 'styled-components';
import { ReactComponent as DoneIcon } from '../../../assets/Icon-done.svg';
import { ReactComponent as NotDoneIcon } from '../../../assets/Icon-notdone.svg';

export const TypographyContainer = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  margin: ${({ theme }) => theme.spacing(0, 2, 2, 2)};
  min-height: 60px;
`;

export const StyledNotDoneIcon = styled(NotDoneIcon)`
  width: 30px;
  height: 30px;
`;

export const StyledDoneIcon = styled(DoneIcon)`
  width: 30px;
  height: 30px;
`;

export const StyledAccessTimeIcon = styled(AccessibleSharpIcon)`
  width: 30px;
  height: 30px;
`;

export const TimerContainer = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  align-self: center;
`;

export const RealTimeContainer = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(1)}px;
  background-color: ${({ theme }) => theme.palette.warning.light};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  color: black;
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  img {
    width: 75px;
    height: 75px;
  }
`;

export const DescriptionContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
  padding: ${({ theme }) => theme.spacing(0, 2, 1, 0)};
`;

export const StyledButton = styled(Button)<{ $disabled?: boolean }>`
  color: ${({ theme }) => theme.palette.common.white};
  border: 2px solid ${({ theme }) => theme.palette.common.white};
  border-radius: 100%;
  padding: 0;
  min-width: 0px;
  width: 30px;
  height: 30px;
  align-self: flex-end;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  ${(props) =>
    props.$disabled
      ? css`
          opacity: 0.3;
        `
      : ''}
`;

export const StyledCommentButton = styled(StyledButton)`
  border: none;
`;

export const StyledChangeStatus = styled.div`
  display: flex;
  align-self: flex-end;
`;

export const LineThroughTypography = styled(Typography)`
  text-decoration: line-through;
`;

export const CardFooter = styled.footer`
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(1, 1, 0, 2)};
`;

export const CaregiverTypography = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 15px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &::before {
    content: 'por ';
    font-weight: 400;
  }
`;
