// https://gist.github.com/mattiaerre/8dbd2d8efca3f242c7085a9ce82ecbde

import { useState } from 'react';

const useLocalStorage = <T>(
  key: string,
  initialValue: T
): [T, (state: T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  function setValue(value: T) {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  }

  return [storedValue, setValue];
};

export default useLocalStorage;
