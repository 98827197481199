import React, { ForwardRefRenderFunction } from 'react';
import MuiMenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import styled from 'styled-components';

import { ColorsType, colorMapping } from '../styles/colorMapping';

type StyledMenuItemProps = {
  color?: ColorsType;
} & MenuItemProps;

const MenuItem = styled(MuiMenuItem)<{
  $color: ColorsType;
}>`
  color: ${({ $color }) => colorMapping[$color]};
` as React.FC<{
  $color: ColorsType;
}>;

const StyledMenuItem: ForwardRefRenderFunction<unknown, StyledMenuItemProps> = (
  { color = 'primary', children, ...rest },
  ref
) => (
  <MenuItem ref={ref} $color={color} {...rest}>
    {children}
  </MenuItem>
);

export default React.forwardRef(StyledMenuItem);
