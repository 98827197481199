import { useCallback, useReducer } from 'react';
import { getCachedAxios } from '../config/axios';
import { EventSubCategoryModel, ShiftModel } from '@cuidador/database';
import { createReducer, ReducerData, Item } from '../utils/store/index';

const endpoint = '/care/event/category/sub';

const initialData: ReducerData<ShiftModel> = {
  byId: {} as Record<string, Item<ShiftModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

export enum EventCategoryEnum {
  GENERAL = 1,
  MEDICATION = 2,
  COMPLICATION = 3,
  MESUREMENT = 4,
}

const useSubCategory = () => {
  const [state, dispatch] = useReducer(
    createReducer<ShiftModel>(),
    initialData
  );

  const getAllAppointments = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await getCachedAxios().get(
        `${endpoint}?categoryId=${EventCategoryEnum['GENERAL']}&isAppointment=1&orderBy=eventSubCategory.name&order=asc`
      );
      dispatch({ type: 'GET_ALL', payload: response.data });
      return response.data as EventSubCategoryModel[];
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getAllRoutines = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await getCachedAxios().get(
        `${endpoint}?categoryId=${EventCategoryEnum['GENERAL']}&isRoutine=1&orderBy=eventSubCategory.name&order=asc`
      );
      dispatch({ type: 'GET_ALL', payload: response.data });
      return response.data as EventSubCategoryModel[];
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getAllComplications = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await getCachedAxios().get(
        `${endpoint}?categoryId=${EventCategoryEnum['COMPLICATION']}&orderBy=eventSubCategory.name&order=asc`
      );
      dispatch({ type: 'GET_ALL', payload: response.data });
      return response.data as EventSubCategoryModel[];
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getAllMeasurements = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await getCachedAxios().get(
        `${endpoint}?categoryId=${EventCategoryEnum['MESUREMENT']}&orderBy=eventSubCategory.name&order=asc`
      );
      dispatch({ type: 'GET_ALL', payload: response.data });
      return response.data as EventSubCategoryModel[];
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  return {
    ...state,
    getAllAppointments,
    getAllRoutines,
    getAllComplications,
    getAllMeasurements,
  };
};

export default useSubCategory;
