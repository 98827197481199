import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { weightMeasurementMask } from '../../../utils/inputs';
import { Row, StyledInput } from '../styles';
import { NewMeasurementValue } from '../../CurrentShiftEventList/CurrentShiftCards/ScheduledMeasurementCard/Measurement';

interface WeightMeasurementInputProps {
  value?: string;
  patchEvent: (
    newMeasurementValue: NewMeasurementValue,
    status: 'awaiting' | 'accomplished' | 'not_accomplished'
  ) => void;
  id?: string;
  disabled?: boolean;
}

const WeightMeasurementInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  WeightMeasurementInputProps
> = ({ value, patchEvent, id, disabled }, ref) => {
  const [weightValue, setWeightValue] = useState(value || '');

  const onChangeAnyValue = useDebouncedCallback(
    (newMeasurementValue: NewMeasurementValue) => {
      const newStatus = newMeasurementValue.measurementValue
        ? 'accomplished'
        : 'awaiting';
      patchEvent(newMeasurementValue, newStatus);
    },
    400
  );

  return (
    <Row>
      <StyledInput
        type="text"
        value={weightValue}
        onChange={(e) => {
          const formattedValue = weightMeasurementMask(e.target.value === '0' ? '' : parseInt(e.target.value).toString());
          const complicationMeasurement = {};
          setWeightValue(formattedValue);
          onChangeAnyValue.callback({
            ...complicationMeasurement,
            measurementValue: formattedValue,
          });
        }}
        disabled={disabled}
        ref={ref}
        id={`${id}-weight`}
      />
      <span>Kg</span>
    </Row>
  );
};

export default React.forwardRef(WeightMeasurementInput);
