import {
  ShiftExecutionModel,
  ShiftModel,
  ShiftTimeContestationModel,
} from '@cuidador/database';
import { addDays, endOfMonth, format } from 'date-fns';

export const formatShift = (shift: ShiftModel): ShiftModel => {
  return {
    ...shift,
    timeContestations: sortDescShiftContestations(shift?.timeContestations),
  } as ShiftModel;
};

export const sortDescShiftContestations = (
  timeContestations?: ShiftTimeContestationModel[]
): ShiftTimeContestationModel[] | undefined => {
  return timeContestations?.sort((a, b) =>
    getTimeContestationCreatedAt(a) < getTimeContestationCreatedAt(b) ? 1 : -1
  );
};

const getTimeContestationCreatedAt = (
  timeContestation: ShiftTimeContestationModel
): string => timeContestation?.createdAt || '';

export const isContestedOrHaveBeenAnnulledShift = (
  shift?: ShiftModel
): boolean => {
  return !!(
    (shift?.timeContestations && shift?.timeContestations.length > 0) ||
    (shift?.annullations && shift?.annullations.length > 0)
  );
};

export const isContestationCreationEnabled = (shift?: ShiftModel) => {
  const now = new Date();
  const endOfMonthDateTime = endOfMonth(
    new Date(shift?.plannedToStartAt || '')
  );
  const maxContestationDateTime = addDays(endOfMonthDateTime, 1);
  return now < maxContestationDateTime && !shift?.isAnnulled;
};

export const getShiftExecutionRealTime = (execution: ShiftExecutionModel) => {
  if (!execution.startedAt) return '';

  const startedAt = new Date(execution.startedAt);

  const start = format(startedAt, 'HH:mm');

  if (!execution.endedAt) return start;

  const endedAt = new Date(execution.endedAt);

  const end = format(endedAt, 'HH:mm');

  return `${start} até ${end}`;
};

export const isCaregiverExecutionOwner = (
  execution: ShiftExecutionModel,
  caregiverId: ShiftExecutionModel['caregiverId']
) => {
  if (!execution || !caregiverId) return false;

  return execution.caregiverId === caregiverId;
};

export const isExecutionContested = ({
  timeContestations,
}: ShiftExecutionModel) => !!timeContestations && timeContestations.length > 0;
