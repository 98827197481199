import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import StyledPaper from '../StyledPaper';

export const CardBox = styled(StyledPaper)`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: ${({ theme }) => theme.spacing(0, 0, 0, 2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  overflow: hidden;
  width: 100%;
`;

export const TypographyContainer = styled.div`
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const Title = styled(Typography)`
  margin: ${({ theme }) => theme.spacing(1, 0, 0.5, 0)};
  font-weight: bold;
  line-height: 1;
`;

export const SubTitle = styled(Typography)`
  color: #696969;
  font-weight: bold;
  line-height: 1;
`;

export const Instruction = styled(Typography)`
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const ContactText = styled(Typography)`
  line-height: 1;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

export const StyledPhoneContainer = styled.a`
  display: flex;
  flex: 1;
  min-height: 0;
  flex-direction: column;
  text-decoration: none;
  background-color: ${({ theme }) => theme.palette.info.main};
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 1)};
  &:hover {
    cursor: pointer;
  }
  min-width: ${({ theme }) => theme.spacing(10)}px;
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  text-decoration: none;
`;

export const StyledOpenWhatsappContainer = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 0;
  min-height: 0;
  background-color: ${({ theme }) => theme.palette.extra.color.green};
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(2, 1)};
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  &:hover {
    cursor: pointer;
  }
  min-width: ${({ theme }) => theme.spacing(10)}px;
`;

export const StyledOpenLinkContainer = styled.div`
  display: flex;
  flex: 1;
  flex-basis: 0;
  min-height: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.primary.dark};
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  padding: ${({ theme }) => theme.spacing(2, 1)};
  white-space: nowrap;

  &:hover {
    cursor: pointer;
  }
  min-width: ${({ theme }) => theme.spacing(10)}px;
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    margin-right: ${({ theme }) => theme.spacing(0.5)}px;
  }
`;
