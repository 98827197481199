import { useCallback, useState, useRef, useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';

export function useOverflowDetector<T extends HTMLElement>(
  onChange?: (overflow: boolean) => void
) {
  const [overflow, setOverflow] = useState<boolean>(false);
  const ref = useRef<T>(null);

  const updateState = useCallback(() => {
    if (ref.current == undefined) {
      return;
    }

    const newState =
      ref.current.offsetWidth < ref.current.scrollWidth ||
      ref.current.offsetHeight < ref.current.scrollHeight;

    if (newState === overflow) {
      return;
    }

    setOverflow(newState);

    if (onChange) {
      onChange(newState);
    }
  }, [ref.current, onChange, setOverflow, overflow]);

  useResizeDetector({
    targetRef: ref as React.MutableRefObject<HTMLElement>,
    onResize: updateState,
  });

  useEffect(() => {
    updateState();
  });

  return { overflow, ref };
}
