import styled from 'styled-components';
import { Badge } from '@material-ui/core';

interface StyledBadgeProps {
  backgroundColor: string;
  visible: boolean;
}

export const Container = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: ${({ theme }) => theme.spacing(7)}px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
`;

export const LogoContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(8)}px
    ${({ theme }) => theme.spacing(4)}px;
  img {
    width: 100%;
  }
`;

export const LogoutContainer = styled.div`
  margin: ${({ theme }) => theme.spacing(4)}px;
`;

export const StyledBadge = styled(Badge)<StyledBadgeProps>`
  .MuiBadge-anchorOriginTopRightRectangle {
    background-color: ${({ backgroundColor }) => backgroundColor};
    transform: ${({ visible }) => (visible ? 'scale(1)' : 'scale(0)')}
      translate(15%, -15%);
    border-radius: 50%;
    height: 10px;
    width: 10px;
  }
`;
