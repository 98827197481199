import React from 'react';
import { ShiftTimeContestation } from '@cuidador/database';
import { handleFormatDate, handleFormatTime } from '../utils';
import {
  CardItem,
  CardItemJustify,
  CardTimeText,
  CardTitle,
  StyledSubtitle,
  StyledTypography,
  StyledCreatedBy,
  StyledCalendarIcon,
  StyledClockIcon,
} from '../styles';

interface TimeContestationCardProps {
  timeContestation: ShiftTimeContestation;
  contestationNumber: number;
}

const TimeContestationCard: React.FC<TimeContestationCardProps> = ({
  timeContestation,
  contestationNumber,
}) => {
  const dateStartedAt = handleFormatDate(timeContestation.startedAt!);
  const timeStartedAt = handleFormatTime(timeContestation.startedAt!);

  const dateEndedAt = handleFormatDate(timeContestation.endedAt!);
  const timeEndedAt = handleFormatTime(timeContestation.endedAt!);

  const dateCreatedAt = handleFormatDate(timeContestation.createdAt!);
  const timeCreatedAt = handleFormatTime(timeContestation.createdAt!);

  return (
    <>
      <CardTitle>
        Correção {contestationNumber} - ({dateCreatedAt} - {timeCreatedAt})
      </CardTitle>
      <CardItem>
        <StyledSubtitle>Responsável</StyledSubtitle>
        <StyledCreatedBy>{timeContestation.createdBy?.name}</StyledCreatedBy>
      </CardItem>
      <CardItemJustify>
        <CardItem>
          <StyledSubtitle>Início</StyledSubtitle>
          <CardTimeText data-testid="date-started-at">
            <StyledCalendarIcon /> {dateStartedAt}
          </CardTimeText>
          <CardTimeText data-testid="time-started-at">
            <StyledClockIcon /> {timeStartedAt}
          </CardTimeText>
        </CardItem>
        <CardItem>
          <StyledSubtitle>Fim</StyledSubtitle>
          <CardTimeText data-testid="date-ended-at">
            <StyledCalendarIcon />
            {dateEndedAt}
          </CardTimeText>
          <CardTimeText data-testid="time-ended-at">
            <StyledClockIcon /> {timeEndedAt}
          </CardTimeText>
        </CardItem>
      </CardItemJustify>
      <CardItem>
        <StyledSubtitle>Justificativa</StyledSubtitle>
        <StyledTypography>{timeContestation.justification}</StyledTypography>
      </CardItem>
    </>
  );
};

export default TimeContestationCard;
