import { MedicationModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import React from 'react';
import DoneIcon from '../../../../assets/Icon-done.svg';
import MedicationIcon from '../../../../assets/Icon-medication.svg';
import NotDoneIcon from '../../../../assets/Icon-notdone.svg';
import TimeColorIcon from '../../../../assets/Icon-time-color.svg';
import {
  handleFormatAdministeredBy,
  handleFormatDose,
} from '../../../../utils/medication';
import {
  CardFooter,
  CaregiverTypography,
  DescriptionContainer,
  LeftContainer,
  RightContainer,
  TimerContainer,
  TypographyContainer,
} from '../styles';
import { CardBox, StatusContainer } from './styles';

interface CardProps {
  id?: number;
  name?: string;
  dosageQuantity?: MedicationModel['dosageQuantity'];
  dosageFormat?: MedicationModel['dosageFormat'];
  administeredBy?: MedicationModel['administeredBy'];
  medicalNotes?: MedicationModel['medicalNotes'];
  eventNotifiedAt?: string;
  eventHappensAt?: string;
  status?: string;
  updatedTimeHappensAt?: string | null;
  comment?: string;
  caregiverName?: string;
}

const MedicationCard: React.FC<CardProps> = ({
  id,
  name,
  dosageQuantity,
  dosageFormat,
  administeredBy,
  medicalNotes,
  eventHappensAt,
  status,
  updatedTimeHappensAt,
  comment,
  caregiverName,
}) => {
  const eventDate = updatedTimeHappensAt || eventHappensAt;
  const formattedDate = format(new Date(`${eventDate}`), 'HH:mm');

  return (
    <CardBox data-testid={`medicationCard-${id}`}>
      <LeftContainer>
        <TimerContainer>
          <Typography variant="h6">{formattedDate}</Typography>
        </TimerContainer>
        <img src={MedicationIcon} title={`medication-image-${id}`} />
      </LeftContainer>
      <RightContainer>
        <TypographyContainer>
          <Typography variant="h6">{name}</Typography>
          <Typography variant="subtitle2">
            {dosageQuantity} {handleFormatDose(dosageFormat, dosageQuantity)}
          </Typography>
          <Typography variant="subtitle2">
            Via: {handleFormatAdministeredBy(administeredBy)}
          </Typography>
          <DescriptionContainer>
            <Typography variant="subtitle2">{medicalNotes}</Typography>
            {comment && (
              <Typography variant="subtitle2">Obs: {comment}</Typography>
            )}
          </DescriptionContainer>
        </TypographyContainer>
        <CardFooter>
          {caregiverName ? (
            <CaregiverTypography>{caregiverName}</CaregiverTypography>
          ) : (
            <div></div>
          )}
          <StatusContainer>
            {updatedTimeHappensAt && status === 'accomplished' && (
              <img src={TimeColorIcon} data-testid={`delayedIcon-${id}`} />
            )}
            {status == 'accomplished' ? (
              <img src={DoneIcon} title={'done-icon'} />
            ) : (
              <img src={NotDoneIcon} title={'not-done-icon'} />
            )}
          </StatusContainer>
        </CardFooter>
      </RightContainer>
    </CardBox>
  );
};

export default MedicationCard;
