import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';

const StyledTextFieldWhite = styled(TextField).attrs({
  variant: 'outlined',
})`
  & label:not(.Mui-error) {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & input {
    color: ${({ theme }) => theme.palette.common.white};
    ::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
  }
  & textarea {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiInputAdornment-root .MuiIconButton-label {
    color: ${({ theme }) => theme.palette.common.white};
  }
  & .MuiOutlinedInput-root:not(.Mui-error) {
    & fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &:hover fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.palette.common.white};
      color: ${({ theme }) => theme.palette.common.white};
    }
  }
`;

export default StyledTextFieldWhite;
