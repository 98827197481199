import { EventModel } from '@cuidador/database';
import { NormalizedCareCategory } from '../../utils/shiftQuestion';

export const getTasksSummary = (
  events: Record<number | string, Partial<EventModel>>,
  questions: Record<number | string, Partial<NormalizedCareCategory>>
) => {
  const data = { total: 0, completed: 0 };

  Object.values(events).reduce((prev, event) => {
    // ignores events which is not general and medication
    if (
      event?.subCategory?.categoryId !== 1 &&
      event?.subCategory?.categoryId !== 2
    ) {
      return prev;
    }
    prev.total += 1;
    // accomplished and not accomplished
    prev.completed += event.status !== 'awaiting' ? 1 : 0;
    return prev;
  }, data);

  Object.values(questions).reduce((prev, question) => {
    prev.total += question?.totalQuestions || 0;
    prev.completed += question?.totalAnswered || 0;
    return prev;
  }, data);

  return data;
};
