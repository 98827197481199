import React from 'react';
import { DialogProps } from '@material-ui/core/Dialog';

import elipseBlue from '../../assets/Elipseblue.svg';
import elipseGreen from '../../assets/Elipsegreen.svg';

import {
  StyledMuiDialog,
  StyledElipseBlueTop,
  StyledElipseGreenTop,
  StyledElipseBlueBot,
  StyledElipseGreenBot,
  StyledDiv,
} from './styles';

const StyledDialog: React.FC<DialogProps> = ({ children, ...dialogProps }) => {
  return (
    <StyledMuiDialog {...dialogProps}>
      <StyledDiv>
        <StyledElipseBlueTop src={elipseBlue} />
        <StyledElipseGreenTop src={elipseGreen} />
        <StyledElipseBlueBot src={elipseBlue} />
        <StyledElipseGreenBot src={elipseGreen} />
      </StyledDiv>
      {children}
    </StyledMuiDialog>
  );
};

export default StyledDialog;
