import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 3)};
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${({ theme }) => theme.palette.primary.dark};
  align-items: center;
`;

export const TextWrap = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  width: 100%;
  word-wrap: break-word;
  text-align: justify;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
`;

export const TitleWrap = styled.div`
  max-width: 100%;
  word-wrap: break-word;
  text-align: justify;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
`;
