import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.spacing(2, 2)};
  color: black;
`;

export const HeaderContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  margin-top: ${({ theme }) => theme.spacing(-2.5)}px;
`;
