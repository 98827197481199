import { Form as FormikForm } from 'formik';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 2, 0, 2)};
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
