import { numberMask } from '@cuidador/frontend-caregiver/src/utils/inputs';
import { toNumber } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import {
  Column,
  LoadingSpinner,
  Row,
  StyledInput,
  Subtitle,
  SymptomsDescription,
} from '../styles';
import { MeasurementModel } from '@cuidador/database';
import { NewMeasurementValue } from '../../CurrentShiftEventList/CurrentShiftCards/ScheduledMeasurementCard/Measurement';
import { Create } from '@material-ui/icons';
import {
  formatOptionIntoSymptomOption,
  formatSymptomsOptions,
  shouldShowSymptoms,
} from './utils';
import {
  MultiselectModal,
  MultiselectModalOptionsType,
} from './MultiselectModal';
import { useSymptomsListContext } from '../../../contexts/SymptomsList';
import { CurrentShiftDataContext } from '../../../contexts/CurrentShiftData';
import { validateBloodPressure } from '../utils';

interface BloodPressureMeasurementInputProps {
  value?: string;
  patchEvent: (
    newMeasurementValue: NewMeasurementValue,
    status: 'awaiting' | 'accomplished' | 'not_accomplished'
  ) => void;
  id?: string;
  disabled?: boolean;
  measurement?: MeasurementModel;
}

const BloodPressureMeasurementInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  BloodPressureMeasurementInputProps
> = ({ value, patchEvent, id, disabled, measurement }, ref) => {

  const [systolicValue, setSystolicValue] = useState('');
  const [diastolicValue, setDiastolicValue] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [symptomOptionsToPut, setSymptomOptionsToPut] = useState<
    NonNullable<NewMeasurementValue['symptomOptions']>
  >([]);

  const { useEvent } = useContext(CurrentShiftDataContext);
  const { loading } = useEvent();

  const symptomOptionsList = useSymptomsListContext();

  useEffect(() => {
    if (!value) return;

    const [systolic, diastolic] = value.split('x');

    const sanitizedSystolic = toNumber(numberMask(systolic));
    setSystolicValue(String(sanitizedSystolic).substring(0));

    if (typeof diastolic === 'undefined') return;

    const sanitizedDiastolic = toNumber(numberMask(diastolic));
    setDiastolicValue(String(sanitizedDiastolic).substring(0));
  }, []);

  const onChangeAnyValue = useDebouncedCallback(
    (
      systolicValue: string,
      diastolicValue: string,
      measurementComplication: Nullable<
        Required<
          Pick<
            MeasurementModel,
            'complicationLevel' | 'isDangerousComplication'
          >
        >
      >,
      symptomOptions: NonNullable<NewMeasurementValue['symptomOptions']>
    ) => {
      const systolicNumber = toNumber(systolicValue);
      const diastolicNumber = toNumber(diastolicValue);


      const systolic = String(systolicNumber)
      const diastolic = String(diastolicNumber)

      const status = (() => {
        if (systolicNumber > 0 && diastolicNumber > 0) return 'accomplished';

        if (systolicNumber > 0 || diastolicNumber > 0)
          return 'not_accomplished';

        return 'awaiting';
      })();

      if (systolicNumber === 0 && diastolicNumber === 0) {
        setSystolicValue('');
        setDiastolicValue('');
        return patchEvent(
          {
            measurementValue: '',
            ...measurementComplication,
            symptomOptions,
          },
          status
        );
      }

      return patchEvent(
        {
          measurementValue: `${systolic} x ${diastolic}`,
          ...measurementComplication,
          symptomOptions,
        },
        status
      );
    },
    400,
  );

  const symptomList = useMemo(
    () =>
      symptomOptionsToPut.map(
        ({ wasUserInput, label, customLabel }) =>
          (wasUserInput ? customLabel : label) ?? ''
      ),
    [symptomOptionsToPut]
  );

  const customOptionInitialValue = useMemo(
    () => measurement?.symptoms?.find((symptom) => !!symptom.wasUserInput),
    [measurement?.symptoms]
  );

  const symptomOptions = useMemo(() => {
    const formattedSymptomOptions = formatSymptomsOptions(
      symptomOptionsList,
      measurement?.symptoms ?? []
    );

    const customOption = measurement?.symptoms?.find(
      (symptom) => !!symptom.wasUserInput
    );

    const formattedCheckedOptions = formattedSymptomOptions.filter(
      (option) => option.checked === true
    );

    if (!customOption) {
      setSymptomOptionsToPut(formattedCheckedOptions);
    } else {
      const formattedCustomOption = {
        id: customOption?.id,
        customLabel: customOption?.symptomName,
        label: 'Outro',
        wasUserInput: true,
      };

      setSymptomOptionsToPut([
        ...formattedCheckedOptions,
        formattedCustomOption,
      ]);
    }

    return formattedSymptomOptions;
  }, [symptomOptionsList, measurement?.symptoms]);

  const onCloseWithoutSelection = useCallback(() => {
    const complicationMeasurement = validateBloodPressure(
      systolicValue,
      symptomOptionsToPut.length
    );
    onChangeAnyValue.callback(
      systolicValue,
      diastolicValue,
      complicationMeasurement,
      symptomOptionsToPut
    );
  }, [systolicValue, diastolicValue, symptomOptionsToPut]);

  return (
    <Column>
      <Row>
        <StyledInput
          type="text"
          value={systolicValue}
          onChange={(e) => {
            const value = e.target.value === '0' ? '' : toNumber(numberMask(e.target.value));
            const sanitizedValue = String(value);
            const complicationMeasurement = validateBloodPressure(
              sanitizedValue,
              symptomOptionsToPut.length
            );
            setSystolicValue(sanitizedValue);
            onChangeAnyValue.callback(
              sanitizedValue,
              diastolicValue,
              complicationMeasurement,
              symptomOptionsToPut
            );
          }}
          disabled={disabled}
          ref={ref}
          id={`${id}-systolic`}
          // The bellow attribute makes the input keyboard be set to only numbers on smartphones.
          pattern="\d*"
        />
        <span>x</span>
        <StyledInput
          type="text"
          value={diastolicValue}
          onChange={(e) => {
            const value = e.target.value === '0' ? '' : toNumber(numberMask(e.target.value));
            const sanitizedValue = String(value);
            const complicationMeasurement = validateBloodPressure(
              systolicValue,
              symptomOptionsToPut.length
            );
            setDiastolicValue(sanitizedValue);
            onChangeAnyValue.callback(
              systolicValue,
              sanitizedValue,
              complicationMeasurement,
              symptomOptionsToPut
            );
          }}
          disabled={disabled}
          id={`${id}-diastolic`}
          // The bellow attribute makes the input keyboard be set to only numbers on smartphones.
          pattern="\d*"
        />
        <span>mmHg</span>
      </Row>
      {shouldShowSymptoms(Number(systolicValue)) && (
        <Row
          clickable={true}
          onClick={() => {
            if (!loading) setIsModalOpen(true);
          }}
          data-testid="open-symptom-multiselect-modal"
        >
          <Column>
            <Subtitle variant="body2">
              Sintomas
              {!loading ? <Create width={10} /> : <LoadingSpinner />}
            </Subtitle>
            <SymptomsDescription variant="body2">
              {symptomList.length === 0 && 'Nenhum sintoma registado.'}
              {symptomList.length > 0 && `• ${symptomList.join(', ')}.`}
            </SymptomsDescription>
          </Column>
        </Row>
      )}
      <MultiselectModal
        close={() => setIsModalOpen(false)}
        onClose={onCloseWithoutSelection}
        isOpen={isModalOpen}
        loading={loading}
        title="Escolha os sintomas"
        options={symptomOptions}
        hasCustomUserInput
        customOptionInitialValue={{
          id: customOptionInitialValue?.id,
          checked: typeof customOptionInitialValue !== 'undefined',
          customLabel: customOptionInitialValue?.symptomName,
        }}
        onFinishOptionsSelection={(options: MultiselectModalOptionsType[]) => {
          const symptomsToPut = formatOptionIntoSymptomOption(options);
          setSymptomOptionsToPut(symptomsToPut);
          const complicationMeasurement = validateBloodPressure(
            systolicValue,
            symptomsToPut.length
          );
          onChangeAnyValue.callback(
            systolicValue,
            diastolicValue,
            complicationMeasurement,
            symptomsToPut
          );
        }}
      />
    </Column>
  );
};

export default React.forwardRef(BloodPressureMeasurementInput);
