import { clientSideScheme } from '@cuidador/whitelabel'
import { IconButton } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { ReactComponent as SignOutIcon } from '../../assets/sign-out-icon.svg'
import {
  AppIcon,
  DrawerBody,
  DrawerButton,
  DrawerFooter,
  DrawerFooterText,
  DrawerHeader,
  StyledAssessment,
  StyledCloseIcon,
  StyledComunityIcon,
  StyledDrawer,
  StyledFileIcon,
  StyledHistoryIcon,
  StyledHrefLink,
  StyledLink,
  StyledList,
  StyledLogoBlock,
  StyledPersonIcon,
  StyledPhoneIcon,
} from './styles'
import { AuthContext } from '../../contexts/auth'
import LogoutDialog from '../LogoutDialog'
import useCanAccess from '../../hooks/useCanAccess'
import usePatient from '../../hooks/usePatient'
import { useHistory } from 'react-router-dom'
import { PatientModel } from '@cuidador/database'
import { toast } from 'react-toastify'
import PscIcon from '../Icons/PscIcon'
import SelectPatientModal, { FormValues } from '../SelectPatientModal'
import { MenuItem } from './MenuItem'
import { AppShortcut } from './AppSchortcut'

export type SideMenuProps = {
  handleToggleSideMenu: () => void
  isSideMenuOpen: boolean
}

enum PatientModalUrl {
  HYSTORY = '/historico/plantao/paciente',
  PROFESSIONAL_REPORT = '/avaliacao/paciente',
}

export function SideMenu(props: SideMenuProps) {
  const { handleToggleSideMenu, isSideMenuOpen } = props

  const { signOut, userInfo } = useContext(AuthContext)
  const { getAll } = usePatient()
  const history = useHistory()

  const canAccessGuardianApp = userInfo?.user?.role?.guardianAppAccess
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false)
  const [isPatientModalVisible, setIsPatientModalVisible] = useState(false)
  const [patients, setPatients] = useState<PatientModel[]>([])
  const [patientModalUrl, setPatientModalUrl] = useState(PatientModalUrl.HYSTORY)
  const { isAllowedToRead: isAllowedToReadShifts } = useCanAccess('care/shift', userInfo);
  const { isAllowedToRead: isAllowedToReadProfessionalReport } = useCanAccess('report/professional')
  const { isAllowedToRead: isAllowedToReadPatients } = useCanAccess('user/patient')
  const { appFShortcutIconUrl, appFUrl } = clientSideScheme()

  const handlePatientDataModal = (modalUrl: PatientModalUrl) => {
    getAll()
      .then((data) => {
        setPatientModalUrl(modalUrl)
        setPatients(data)
        setIsPatientModalVisible(true)
      })
      .catch(() => {
        toast.error('Erro ao recuperar lista de pacientes. Tente novamente daqui a pouco')
      })
  }

  const handleToggleLogoutDialog = () => {
    setIsLogoutDialogOpen(!isLogoutDialogOpen)
  }

  const handleClosePatientModal = () => {
    setIsPatientModalVisible(false)
  }

  const handlePatientSelect = (data: FormValues) => {
    const url = `${patientModalUrl}/${data.patient}`
    history.push(url)
  }

  return (
    <>
      <StyledDrawer
        open={isSideMenuOpen}
        onClose={() => {
          handleToggleSideMenu()
        }}
      >
        <DrawerHeader>
          <IconButton
            onClick={() => {
              handleToggleSideMenu()
            }}
          >
            <StyledCloseIcon fontSize='large' />
          </IconButton>
          <StyledLogoBlock />
        </DrawerHeader>
        <DrawerBody>
          <StyledList>
            <StyledLink to={'/minha-conta/pessoais'}>
              <MenuItem icon={<StyledPersonIcon />} label={'Minha conta'} />
            </StyledLink>
            {isAllowedToReadPatients && (
              <StyledLink to={'/pessoas-sob-cuidado'}>
                <MenuItem
                  icon={<PscIcon variant='dark' />}
                  label={'Pessoas sob cuidado'}
                />
              </StyledLink>
            )}
            <StyledLink to={'/ajuda'}>
              <MenuItem icon={<StyledPhoneIcon />} label={'Me ajuda'} />
            </StyledLink>
            {isAllowedToReadShifts && (
              <MenuItem
                icon={<StyledHistoryIcon />}
                label='Histórico de plantões'
                onClick={() => handlePatientDataModal(PatientModalUrl.HYSTORY)}
              />
            )}
            {isAllowedToReadProfessionalReport && (
              <MenuItem
                icon={<StyledAssessment />}
                label='Avaliação multiprofissional'
                onClick={() =>
                  handlePatientDataModal(PatientModalUrl.PROFESSIONAL_REPORT)
                }
              />
            )}
            <StyledHrefLink
              href={'https://www.facebook.com/groups/cuidadordeconfianca'}
            >
              <MenuItem icon={<StyledComunityIcon />} label={'Comunidade'} />
            </StyledHrefLink>
            <StyledHrefLink
              href={'https://www.cuidadordeconfianca.com.br/termos-e-politicas'}
            >
              <MenuItem icon={<StyledFileIcon />} label={'Termos de uso'} />
            </StyledHrefLink>
            {Boolean(canAccessGuardianApp) && (
              <StyledHrefLink href={appFUrl}>
                <AppShortcut
                  icon={<AppIcon src={appFShortcutIconUrl} />}
                  label={'Ir para o app de Gestão'}
                />
              </StyledHrefLink>
            )}
          </StyledList>
        </DrawerBody>
        <DrawerFooter>
          <DrawerButton
            onClick={() => {
              handleToggleLogoutDialog()
            }}
          >
            <SignOutIcon />
            <DrawerFooterText>Sair da conta</DrawerFooterText>
          </DrawerButton>
        </DrawerFooter>
      </StyledDrawer>
      <LogoutDialog
        open={isLogoutDialogOpen}
        onConfirm={signOut}
        onClose={handleToggleLogoutDialog}
      />
      <SelectPatientModal
        open={isPatientModalVisible}
        onClose={handleClosePatientModal}
        patients={patients}
        onSubmit={handlePatientSelect}
      />
    </>
  )
}
