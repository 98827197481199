import { useCallback, useReducer } from 'react';
import { getCachedAxios } from '../config/axios';
import { EmergencyContactModel } from '@cuidador/database';
import { createReducer, ReducerData, Item } from '../utils/store/index';

const endpoint = 'care/emergency-contact';

const initialData: ReducerData<EmergencyContactModel> = {
  byId: {} as Record<string, Item<EmergencyContactModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useEmergencyContacts = () => {
  const [state, dispatch] = useReducer(
    createReducer<EmergencyContactModel>(),
    initialData
  );

  const getAllByPatientId = useCallback(async (id: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await getCachedAxios().get(
        `${endpoint}/by-patient/${id}`
      );
      dispatch({ type: 'GET_ALL', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  return {
    ...state,
    getAllByPatientId,
  };
};

export default useEmergencyContacts;
