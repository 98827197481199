import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import Account from '../pages/Account'
import CaregiverMoodShiftEnd from '../pages/CaregiverMood/ShiftEnd'
import CaregiverMoodShiftStart from '../pages/CaregiverMood/ShiftStart'
import CompleteSignup from '../pages/CompleteSignup'
import Content from '../pages/Content'
import ContentItem from '../pages/Content/ContentItem'
import CreateProfessionalReport from '../pages/CreateProfessionalReport'
import CreateShiftEvent from '../pages/CreateShiftEvent'
import CreateTimeContestation from '../pages/CreateShiftExecutionTimeContestation'
import { CurrentShift } from '../pages/CurrentShift'
import EmergencyContacts from '../pages/EmergencyContacts'
import ForgotPassword from '../pages/ForgotPassword'
import Home from '../pages/Home'
import LastShiftEvents from '../pages/LastShiftEvents'
import Login from '../pages/Login'
import MotivationalMessage from '../pages/MotivationalMessage'
import Patients from '../pages/Patients'
import ProfessionalReport from '../pages/ProfessionalReport'
import ProfessionalReportItem from '../pages/ProfessionalReport/ProfessionalReportItem'
import ShiftContestation from '../pages/ShiftContestation'
import ExecutionHistoryContestation from '../pages/ExecutionHistoryContestation'
import ShiftFinish from '../pages/ShiftFinish'
import ShiftHistory from '../pages/ShiftHistory'
import FollowShift from '../pages/ShiftHistory/Shift'
import ShiftQuestion from '../pages/ShiftQuestion'
import ShiftCalendar from '../pages/ShiftCalendar'
import ShoppingList from '../pages/ShoppingList'
import NoPatientRelationsBlock from '../pages/BlockPages/NoPatientRelationsBlock'
import NoOrganizationIdBlock from '../pages/BlockPages/NoOrganizationIdBlock'
import AuthenticatedRoute from './AuthenticatedRoute'
import RouteAnalytics from './RoutesAnalytics'
import UnauthenticatedRoute from './UnauthenticatedRoute'

const Routes: React.FC = () => {
  return (
    <>
      <RouteAnalytics />
      <Switch>
        {/*
          TODO: Remove '/cadastro' since it's a copy of '/completar-cadastro', used only to keep
          the system backwards compatible after migration to RBAC
         */}
        <UnauthenticatedRoute
          path='/cadastro'
          component={CompleteSignup}
        />
        <UnauthenticatedRoute
          path='/completar-cadastro'
          component={CompleteSignup}
        />
        <UnauthenticatedRoute
          path='/esqueci-minha-senha'
          component={ForgotPassword}
        />
        <UnauthenticatedRoute
          path='/login'
          component={Login}
        />

        <AuthenticatedRoute
          exact
          path='/'
          component={Patients}
          executionInProgressForbidden
        />

        <AuthenticatedRoute
          exact
          path='/agenda-de-plantoes/:id'
          component={ShiftCalendar}
        />
        <AuthenticatedRoute
          exact
          path='/agendar'
          component={Home}
        />
        <AuthenticatedRoute
          exact
          path='/ajuda'
          component={EmergencyContacts}
        />
        <AuthenticatedRoute
          path='/avaliacao/adicionar/paciente/:patientId/'
          component={CreateProfessionalReport}
        />
        <AuthenticatedRoute
          path='/avaliacao/paciente/:patientId/'
          component={ProfessionalReport}
        />
        <AuthenticatedRoute
          exact
          path='/avaliacao/:id'
          component={ProfessionalReportItem}
        />
        <AuthenticatedRoute
          path='/compras'
          component={ShoppingList}
          executionInProgressRequired
        />
        <AuthenticatedRoute
          exact
          path='/conteudo'
          component={Content}
        />
        <AuthenticatedRoute
          exact
          path='/conteudo/:id'
          component={ContentItem}
        />
        <AuthenticatedRoute
          path='/correcao-de-horarios/historico/:shiftId/:executionId'
          component={ExecutionHistoryContestation}
        />
        <AuthenticatedRoute
          path='/correcao-de-horarios/plantao/:shiftId/execucao/:shiftExecutionId/novo'
          component={CreateTimeContestation}
        />
        <AuthenticatedRoute
          path='/correcao-de-horarios/plantao/:shiftId/'
          component={ShiftContestation}
        />
        <AuthenticatedRoute
          path='/historico/plantao/paciente/:patientId'
          component={ShiftHistory}
        />
        <AuthenticatedRoute
          path='/historico/plantao/:shiftId/'
          component={FollowShift}
        />
        <AuthenticatedRoute
          exact
          path='/minha-conta/:tabId'
          component={Account}
        />
        <AuthenticatedRoute
          path='/perguntas'
          component={ShiftQuestion}
          executionInProgressRequired
        />
        <AuthenticatedRoute
          exact
          path='/pessoas-sob-cuidado'
          component={Patients}
        />
        <AuthenticatedRoute
          exact
          path='/plantao'
          component={CurrentShift}
          executionInProgressRequired
        />
        <AuthenticatedRoute
          path='/plantao/adicionar-evento/:eventType'
          component={CreateShiftEvent}
          executionInProgressRequired
        />
        <AuthenticatedRoute
          path='/plantao/cuidar-do-cuidador/inicio'
          component={CaregiverMoodShiftStart}
          executionInProgressRequired
        />
        <AuthenticatedRoute
          path='/plantao/cuidar-do-cuidador/fim'
          component={CaregiverMoodShiftEnd}
        />
        <AuthenticatedRoute
          path='/plantao/finalizar'
          component={ShiftFinish}
          executionInProgressRequired
        />
        <AuthenticatedRoute
          path='/plantao/mensagem-de-motivacao'
          component={MotivationalMessage}
          executionInProgressRequired
        />
        <AuthenticatedRoute
          path='/plantao/passado'
          component={LastShiftEvents}
          executionInProgressRequired
        />
        <AuthenticatedRoute
          exact
          path='/usuario-sem-organizacao'
          component={NoOrganizationIdBlock}
        />
        <AuthenticatedRoute
          exact
          path='/usuario-sem-vinculo'
          component={NoPatientRelationsBlock}
        />

        <Redirect to='/' />
      </Switch>
    </>
  )
}

export default Routes
