import Typography from '@material-ui/core/Typography';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useContext } from 'react';
import { InternetConnectionContext } from '../InternetConnectionCheck/InternetConnectionContext';
import StyledTextFieldWhite from '../StyledTextFieldWhite';
import {
  Button,
  Card,
  CardTitleContainer,
  Container,
  FooterContent,
  StyledForm,
  StyledFormikTextField,
} from './styles';
import {
  defaultFormProps,
  FormProps,
  FormValues,
  validationSchema,
} from './utils';

const ExecutionMessageForm: React.FC<{
  initialValues: FormValues;
  innerRef?: React.Ref<FormikProps<FormValues>>;
  formProps?: FormProps;
  onSubmit: (
    values: FormValues,
    formikHelpers: FormikHelpers<FormValues>
  ) => void | Promise<void>;
}> = ({ initialValues, onSubmit, innerRef, formProps }) => {
  const { internetStatus } = useContext(InternetConnectionContext);
  const defaultProps = defaultFormProps();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      innerRef={innerRef}
    >
      {(formik) => (
        <StyledForm noValidate>
          <Container>
            <Card>
              <CardTitleContainer>
                <Typography variant="subtitle1">
                  {formProps?.subTitle || defaultProps.subTitle}
                </Typography>
              </CardTitleContainer>
              <StyledFormikTextField
                as={StyledTextFieldWhite}
                name="message"
                label={formProps?.label || defaultProps.label}
                InputLabelProps={{ shrink: true }}
                placeholder={formProps?.placeholder || defaultProps.placeholder}
                margin="normal"
                multiline
                rows={6}
                inputProps={{ 'data-testid': 'message' }}
                value={formik.values.message}
                error={Boolean(formik.errors.message)}
                helperText={formik.errors.message}
                onChange={(e) => {
                  if (formProps?.handleChange) {
                    formProps.handleChange(e.target.value);
                  }
                  formik.handleChange(e);
                }}
              />
            </Card>
            {!formProps?.hiddenButton && (
              <FooterContent>
                <Button
                  type="submit"
                  disabled={formik.isSubmitting || internetStatus !== 'online'} // Must be online
                >
                  {formProps?.buttonLabel || defaultProps.buttonLabel}
                </Button>
              </FooterContent>
            )}
          </Container>
        </StyledForm>
      )}
    </Formik>
  );
};

export default ExecutionMessageForm;
