import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  svg {
    width: 50%;
    height: unset;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
