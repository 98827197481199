import { useCallback, useReducer } from 'react';
import { getCachedAxios } from '../config/axios';
import { MotivationalMessageModel } from '@cuidador/database';
import { createReducer, ReducerData, Item } from '../utils/store/index';

const endpoint = '/care/motivational-message';

const initialData: ReducerData<MotivationalMessageModel> = {
  byId: {} as Record<string, Item<MotivationalMessageModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useMotivationalMessage = () => {
  const [state, dispatch] = useReducer(
    createReducer<MotivationalMessageModel>(),
    initialData
  );

  const getOne = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await getCachedAxios().get(`${endpoint}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return response.data;
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      throw err;
    }
  }, []);

  return {
    ...state,
    getOne,
  };
};

export default useMotivationalMessage;
