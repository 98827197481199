import { EventModel, EventSubCategoryModel, SymptomModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import React from 'react';
import MesurementIcon from '../../../../assets/Icon-measurement.svg';
import { TimerContainer } from '../styles';
import Measurement from './Measurement';

import { CardBox, LeftContainer, RightContainer } from './styles';

const foodCondition = (condition: string) => {
  switch(condition) {
    case 'preprandial':
      return 'Condição alimentar: pré-refeição'
    case 'fasting':
      return 'Condição alimentar: jejum'
    case 'postprandial':
      return 'Condição alimentar: pós-refeição'
    case 'not_available':
      return 'Condição alimentar: não informado'
    default:
      return 'não informado'
  }
}

export const getConditionWithDetail = (condition: string, subCategory?: EventSubCategoryModel) => {
  switch (subCategory?.name){
    case 'Glicemia':
      return foodCondition(condition)
    case 'Frequência cardíaca':
      return condition
    case 'Frequência respiratória':
      return condition
    case 'Oximetria':
      return condition
    case 'Pressão arterial':
      return condition
    case 'Temperatura ':
      return condition
  }
  return condition
}

export const getFormattedSymptoms = (symptoms?: SymptomModel[]) => {
  let result = 'Sintomas: '

  symptoms?.forEach((symptom, index) => {
      if (symptoms && symptoms?.length > 1 && index > 0) {
        result += '; ' + symptom.symptomName
      } else {
        result +=  symptom.symptomName
      }
    })

  return result
}

interface CardProps {
  scheduledMeasurements: EventModel[];
  readonly?: boolean;
  caregiverName?: string;
}

const ScheduledMeasurementCard: React.FC<CardProps> = ({
  scheduledMeasurements,
}) => {
  const { eventScheduleId, eventHappensAt } = scheduledMeasurements[0];

  const hour = format(new Date(`${eventHappensAt}`), 'HH:mm');

  return (
    <CardBox data-testid={`measurementCard-${eventScheduleId}`}>
      <LeftContainer>
        <img
          src={MesurementIcon}
          title={`measurement-image-${eventScheduleId}`}
        />
        <TimerContainer>
          <Typography variant="h6">{hour}</Typography>
        </TimerContainer>
      </LeftContainer>
      <RightContainer>
        {scheduledMeasurements?.map((measurement) => {
          return (
            <div key={measurement.id}>
              <Measurement
                {...measurement}
                id={measurement.id!}
                caregiverName={measurement.caregiver?.user?.name}
              />
            </div>
          );
        })}
      </RightContainer>
    </CardBox>
  );
};

export default ScheduledMeasurementCard;
