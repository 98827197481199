import {
  EventModel,
  EventSubCategoryModel,
  MeasurementModel,
  MedicationModel,
  ShiftExecutionModel,
  ShiftModel,
} from '@cuidador/database';
import { isAfter, isWithinInterval } from 'date-fns';
import { toast } from 'react-toastify';

interface ResolveEventCreationBodyParams {
  eventData: EventModel;
  eventType: AvailableEventTypes;
  shiftInProgress?: ShiftModel | null;
  executionInProgress?: ShiftExecutionModel | null;
  medications: MedicationModel[];
  subCategories: EventSubCategoryModel[];
  minShiftLimitDate?: Date | string;
  maxShiftLimitDate?: Date | string;
}

export type AvailableEventTypes =
  | 'complication'
  | 'general'
  | 'measurement'
  | 'medication';

export const resolveEventCreationBody = ({
  eventData,
  eventType,
  medications,
  subCategories,
  shiftInProgress,
  executionInProgress,
  maxShiftLimitDate,
  minShiftLimitDate,
}: ResolveEventCreationBodyParams): EventModel => {
  const createdAtDate = new Date();
  createdAtDate.setMilliseconds(0); // helps resolver accuracy
  const status = resolveEventStatus({
    eventHappensAt: eventData.eventHappensAt,
    eventType,
    maxShiftLimitDate,
    minShiftLimitDate,
  });

  return {
    ...eventData,
    status,
    shiftId: resolveEventShiftIdData(status, shiftInProgress),
    shiftExecutionId: resolveEventShiftExecutionIdData(
      status,
      executionInProgress
    ),
    patientId: shiftInProgress?.patientId,
    createdAt: createdAtDate.toISOString(),
    eventNotifiedAt: createdAtDate.toISOString(),
    // populates medication, measurement and subCategory so the offline data can return it
    medication: resolveEventMedicatonData(eventData, medications),
    measurement: resolveEventMeasurementData(eventData, shiftInProgress),
    subCategory: resolveEventSubCategoryData({
      eventData,
      eventType,
      medications,
      subCategories,
    }),
  };
};

export const resolveParamToProperEventType = (
  eventTypeParam: string
): AvailableEventTypes => {
  switch (eventTypeParam) {
    case 'rotina':
      return 'general';
    case 'compromisso':
      return 'general';
    case 'intercorrencia':
      return 'complication';
    case 'medicamento':
      return 'medication';
    case 'medicao':
      return 'measurement';
    default:
      throw new Error('Invalid event type');
  }
};

const resolveEventStatus = ({
  eventHappensAt,
  eventType,
  maxShiftLimitDate,
  minShiftLimitDate,
}: {
  eventHappensAt?: string;
  eventType: AvailableEventTypes;
  maxShiftLimitDate?: Date | string;
  minShiftLimitDate?: Date | string;
}): EventModel['status'] => {
  if (
    isDateAfterShift(eventHappensAt, maxShiftLimitDate) &&
    eventType === 'general'
  ) {
    return 'awaiting';
  } else if (
    isDateAtShiftInterval(eventHappensAt, minShiftLimitDate, maxShiftLimitDate)
  ) {
    return 'accomplished';
  } else {
    toast.error('O horário deve estar dentro do horário do plantão');
    throw new Error('Invalid event date');
  }
};

const isDateAtShiftInterval = (
  dateTimeEvent?: string,
  minDateTime?: Date | string,
  maxDateTime?: Date | string
): boolean => {
  if (!dateTimeEvent) return false;
  return !!(
    maxDateTime &&
    minDateTime &&
    isWithinInterval(new Date(dateTimeEvent), {
      start: new Date(minDateTime),
      end: new Date(maxDateTime),
    })
  );
};

const isDateAfterShift = (
  dateTimeEvent?: string,
  shiftMaxDateTime?: Date | string
): boolean => {
  if (!shiftMaxDateTime || !dateTimeEvent) return false;
  return isAfter(new Date(dateTimeEvent), new Date(shiftMaxDateTime));
};

const resolveEventShiftIdData = (
  status: EventModel['status'],
  shiftInProgress?: ShiftModel | null
): EventModel['shiftId'] => {
  return status === 'awaiting' ? undefined : shiftInProgress?.id;
};

const resolveEventShiftExecutionIdData = (
  status: EventModel['status'],
  executionInProgress?: ShiftExecutionModel | null
): EventModel['shiftId'] => {
  return status === 'awaiting' ? undefined : executionInProgress?.id;
};

const resolveEventMedicatonData = (
  eventData: EventModel,
  medications: MedicationModel[]
): MedicationModel | undefined => {
  return medications.find(
    (medication) => medication.id === eventData.medicationId
  );
};

const resolveEventMeasurementData = (
  eventData: EventModel,
  shiftInProgress?: ShiftModel | null
): MeasurementModel | undefined => {
  return eventData.measurement
    ? {
        ...eventData.measurement,
        patientId: shiftInProgress?.patientId,
        subCategoryId: eventData?.subCategoryId,
      }
    : undefined;
};

const resolveEventSubCategoryData = ({
  eventData,
  eventType,
  subCategories,
  medications,
}: {
  eventData: EventModel;
  eventType: AvailableEventTypes;
  subCategories: EventSubCategoryModel[];
  medications: MedicationModel[];
}): EventSubCategoryModel | undefined => {
  return eventType === 'medication'
    ? medications.find((medication) => medication.id === eventData.medicationId)
        ?.subCategory
    : subCategories.find(
        (subCategory) => subCategory.id === eventData.subCategoryId
      );
};
