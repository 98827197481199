import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import localforage from 'localforage';
import { setup as setupCachedAxios } from 'axios-cache-adapter';

const defaultConfig = {
  timeout: 60000,
  headers: {
    Accept: 'application/json;charset=UTF-8',
    'Content-Type': 'application/json',
    // To fix CORS problem ?
    // 'Access-Control-Allow-Origin': '*',
  },
};

export const axiosInstance = axios.create({
  ...defaultConfig,
  baseURL: `${
    process.env.AMB === 'local'
      ? process.env.LOCAL_GATEWAY_URL
      : process.env.REACT_APP_GATEWAY_URL
  }/api/v1`,
});

const forageStore = localforage.createInstance({
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
  name: 'cuidador-caregiver-cache',
});

export const createCachedAxios = () =>
  setupCachedAxios({
    ...axiosInstance?.defaults,
    cache: {
      maxAge: 1000, // 1 sec to not force cache if connection is ok
      store: forageStore,
      exclude: {
        query: false,
      },
      // on network error, get staled data
      readOnError: (error: Error) => {
        return error.message === 'Network Error';
      },
      clearOnStale: false,
    },
  });

export const cachedAxiosInstance = createCachedAxios();

export const getCachedAxios = () => cachedAxiosInstance;

export const addGlobalRequestInterceptor = (
  onFulfilled: (
    config: AxiosRequestConfig
  ) => AxiosRequestConfig | Promise<AxiosRequestConfig>,
  onRejected?: () => void
) => {
  const axiosInstanceId = axiosInstance.interceptors.request.use(
    onFulfilled,
    onRejected
  );
  const cachedAxiosInstanceId = cachedAxiosInstance.interceptors.request.use(
    onFulfilled,
    onRejected
  );

  return () => {
    axiosInstance.interceptors.request.eject(axiosInstanceId);
    cachedAxiosInstance.interceptors.request.eject(cachedAxiosInstanceId);
  };
};

export const addGlobalResponseInterceptor = (
  onFulfilled?: (config: AxiosResponse) => AxiosResponse,
  // axios onRejected default type is any
  onRejected?: (error: any) => any // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  const axiosInstanceId = axiosInstance.interceptors.response.use(
    onFulfilled,
    onRejected
  );

  const cachedAxiosInstanceId = cachedAxiosInstance.interceptors.response.use(
    onFulfilled,
    onRejected
  );

  return () => {
    axiosInstance.interceptors.response.eject(axiosInstanceId);
    cachedAxiosInstance.interceptors.response.eject(cachedAxiosInstanceId);
  };
};

export default axiosInstance;
