import { clientSideScheme } from '@cuidador/whitelabel';
import styled from 'styled-components';
import StyledButton from '../../components/StyledButton';

export const OutterContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  color: white;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(4)}px;
`;

export const MotivationalMessageCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: white;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  border-radius: 13px;
  margin: ${({ theme }) => theme.spacing(2)}px 0;
  padding: ${({ theme }) => theme.spacing(2)}px;
`;

export const MotivationalMessageLine = styled.div`
  margin: ${({ theme }) => theme.spacing(0.5)}px 0;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(StyledButton)`
  color: ${({ theme }) => theme.palette.info.dark};
  border-color: ${({ theme }) => theme.palette.info.dark};
  font-size: ${({ theme }) => theme.typography.h6.fontSize};
  font-weight: 400;
`;

export const Logo = styled.img.attrs(() => {
  const { logoDarkUrl } = clientSideScheme();
  return { src: logoDarkUrl };
})`
  padding-top: ${({ theme }) => theme.spacing(5)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  width: 100%;
  max-height: 21px;
`;
