import styled from 'styled-components';
import StyledPaper from '../../../StyledPaper';

export const CardBox = styled(StyledPaper)`
  width: 100%;
  display: flex;
  margin: 0;
  min-height: 120px;
  background-color: ${({ theme }) => theme.palette.extra.color.red.light};
  padding: 0;
`;

export const TypographyContainer = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  display: flex;
  flex-direction: column;

  width: 100%;
  word-wrap: break-word;
  margin: ${({ theme }) => theme.spacing(0, 2, 2, 2)};
`;

export const TimerContainer = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  align-self: center;
`;

export const LeftContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const DescriptionContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)}px;
`;

export const CaregiverTypography = styled.div`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 15px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: auto;

  &::before {
    content: 'por ';
    font-weight: 400;
  }
`;
