import React from 'react'
import { ListItemIcon } from '@material-ui/core'
import {
  TextContainer,
  StyledListItem,
  AppItemTitle,
  AppItemLabel,
} from '../styles'

interface AppShortcutProps {
  label: string
  icon: JSX.Element
}

export function AppShortcut (props: AppShortcutProps) {
  const { label, icon } = props

  return (
    <StyledListItem button>
      <ListItemIcon>{icon}</ListItemIcon>
      <TextContainer>
        <AppItemTitle>Trocar de app</AppItemTitle>
        <AppItemLabel>{label}</AppItemLabel>
      </TextContainer>
    </StyledListItem>
  )
}
