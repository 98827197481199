import { Form } from 'formik';
import styled from 'styled-components';
import FormikTextField from '../Forms/FormikTextField';
import StyledButton from '../StyledButton';

export const StyledFormikTextField = styled(FormikTextField)`
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: ${({ theme }) => theme.spacing(1)}px
    ${({ theme }) => theme.spacing(3)}px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: white;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.secondary.dark};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  margin: ${({ theme }) => theme.spacing(2)}px 0;
  padding: ${({ theme }) => theme.spacing(2)}px;
  box-shadow: 0px 3px 6px #00000042;
`;

export const CardTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  text-indent: ${({ theme }) => theme.spacing(2)}px;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing(2)}px;
`;

export const Button = styled(StyledButton)`
  color: ${({ theme }) => theme.palette.info.dark};
  border-color: ${({ theme }) => theme.palette.info.dark};
  font-size: ${({ theme }) => theme.typography.h6.fontSize};
  font-weight: 400;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex: 1;
`;
