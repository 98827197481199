import { ContentModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../config/axios';
import {
  createReducer,
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../utils/store/index';

const endpoint = '/care/content';

const initialData: ReducerData<ContentModel> = {
  byId: {} as Record<string, Item<ContentModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useContent = () => {
  const [state, dispatch] = useReducer(
    createReducer<ContentModel>(),
    initialData
  );

  const getPaginated = useCallback(async (params: PaginatedRequestParams) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}?orderBy=title`, {
        params: {
          ...params,
          limit: 10,
        },
      });
      dispatch({ type: 'PAGINATION', payload: response.data });
      return Promise.resolve(response.data);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const getById = useCallback(async (id: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/${id}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const getAllRecommendedContent = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/recommended`);
      return Promise.resolve(response.data);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  return {
    ...state,
    getPaginated,
    getById,
    getAllRecommendedContent,
  };
};

export default useContent;
