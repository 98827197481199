import { CareQuestionAnswer } from '@cuidador/database';
import { Typography } from '@material-ui/core';
import React from 'react';
import { CardBox } from './styles';
import { getAnswerContent } from './utils';

type CardProps = {
  title?: string;
  careQuestionAnswer?: CareQuestionAnswer;
  careQuestion?: string;
};

const Card: React.FC<CardProps> = ({
  title,
  careQuestionAnswer,
  careQuestion,
}) => {
  return (
    <CardBox>
      <Typography variant="body1">
        <b>{title}</b>
      </Typography>
      <Typography>{careQuestion}</Typography>
      {careQuestionAnswer ? (
        <Typography>
          {getAnswerContent(careQuestionAnswer.questionAnswerData)}
        </Typography>
      ) : (
        <Typography>Não respondida.</Typography>
      )}
    </CardBox>
  );
};

export default Card;
