import { CaregiverModel, SupporterModel, UserModel } from '@cuidador/database';
import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import jwt from 'jsonwebtoken';
import * as yup from 'yup';
import { CompleteSignupBody } from '../../hooks/useUser';
import {
  numberMask,
  phoneMask,
  UFoptions,
  validateCpf,
  validatePasswordStrength,
} from '../../utils/inputs';

export type PersonalDataFormValues = typeof personalDataInitialValues;

export type RegisterFormTypes =
  | 'default'
  | 'caregiver'
  | 'supporter'
  | 'selfCare';

export const resolveFormTypeByRole = (roleId: number): RegisterFormTypes => {
  if (roleId === 6) {
    return 'supporter';
  } else if (roleId === 4) {
    return 'selfCare';
  } else if (roleId === 2) {
    return 'caregiver';
  } else {
    return 'default';
  }
};

export const resolveValidationSchema = (formType: RegisterFormTypes) => {
  if (formType === 'caregiver') {
    return caregiverValidationSchema;
  } else if (formType === 'supporter') {
    return supporterValidationSchema;
  } else if (formType === 'selfCare') {
    return selfCareValidationSchema;
  } else {
    return defaultValidationSchema;
  }
};

export const personalDataInitialValues: {
  picture: File | null;
  name: UserModel['name'];
  cpf: NonNullable<UserModel['cpf']>;
  email: UserModel['email'];
  phoneNumber: UserModel['phoneNumber'];
  gender: UserModel['gender'] | '';
  dateOfBirth: UserModel['dateOfBirth'];
} = {
  picture: null,
  name: '',
  cpf: '',
  email: '',
  phoneNumber: '',
  gender: '',
  dateOfBirth: '',
};

const personalDataValidationSchema = {
  name: yup
    .string()
    .required('Por favor, insira o nome completo')
    .min(3, 'O nome deve conter ao menos 3 caracteres')
    .test(
      'isFullName',
      'Por favor, insira o nome completo',
      (value) => (value || '').split(' ').length > 1
    ),
  cpf: yup
    .string()
    .required('Por favor, insira o CPF')
    .test('isCpfValid', 'Por favor, insira um CPF válido', (value) =>
      validateCpf(value || '')
    ),
  email: yup
    .string()
    .required('Por favor, insira um email')
    .email('Por favor, insira um e-mail válido'),
  phoneNumber: yup
    .string()
    .required('Por favor, insira um telefone')
    .min(14, 'Por favor, insira um telefone válido')
    .max(15, 'Por favor, insira um telefone válido'),
  gender: yup
    .string()
    .required('Por favor, insira o sexo')
    .oneOf(['male', 'female', 'other']),
  dateOfBirth: yup
    .date()
    .required('Por favor, insira a data de nascimento')
    .max(new Date(), 'Por favor, insira uma data anterior a hoje')
    .nullable()
    .typeError('Por favor, insira uma data válida'),
};

export type CaregiverFormValues = typeof caregiverInitialValues;

export const caregiverInitialValues: {
  worksAsCaregiverForTime: CaregiverModel['worksAsCaregiverForTime'] | '';
  hasTakenProfessionalCourse: CaregiverModel['hasTakenProfessionalCourse'];
  belongsToCaregiverAgency: CaregiverModel['belongsToCaregiverAgency'] | '';
  caregiverAgencyName: CaregiverModel['caregiverAgencyName'];
} = {
  worksAsCaregiverForTime: '',
  hasTakenProfessionalCourse: '',
  belongsToCaregiverAgency: '',
  caregiverAgencyName: '',
};

export const caregiverStepValidationSchema = {
  worksAsCaregiverForTime: yup
    .string()
    .oneOf([
      'lessThan1Year',
      'lessThan3Years',
      'lessThan5Years',
      'moreThan5Years',
    ]),
  hasTakenProfessionalCourse: yup.string(),
  belongsToCaregiverAgency: yup.boolean(),
  caregiverAgencyName: yup.string(),
};

export type SupporterFormValues = typeof supporterInitialValues;

export const supporterInitialValues: {
  occupation: SupporterModel['occupation'] | '';
  document: SupporterModel['document'] | '';
  documentProvince: SupporterModel['documentProvince'] | '';
  specialty: SupporterModel['specialty'] | '';
} = {
  occupation: '',
  document: '',
  documentProvince: '',
  specialty: '',
};

export const supporterStepValidationSchema = {
  occupation: yup
    .string()
    .oneOf([
      'doctor',
      'nurse',
      'nursingTechnician',
      'physicalTherapist',
      'psychologist',
      'occupationalTherapist',
      'physicalEducator',
      'nutritionist',
      'speechTherapist',
      'dentist',
      'another',
    ])
    .required('Por favor, selecione uma profissão'),
  document: yup.string(),
  documentProvince: yup.string().oneOf(UFoptions.map((item) => item.value)),
  specialty: yup.string(),
};

export type SelfCareFormValues = typeof selfCareInitialValues;

export const selfCareInitialValues: {
  educationLevel: CaregiverModel['educationLevel'];
} = {
  educationLevel: '',
};

export const selfCareDataValidationSchema = {
  educationLevel: yup.mixed().nullable(),
};

export type PasswordFormValues = {
  password: string;
  passwordConfirmation: string;
  termsAccepted: boolean;
};

export const passwordInitialValues = {
  password: '',
  passwordConfirmation: '',
  termsAccepted: false,
};

const passwordValidationSchema = {
  password: yup
    .string()
    .required('Por favor, insira a senha')
    .test(
      'isValidPassword',
      'Por favor, insira uma senha que atenda aos critérios listados abaixo',
      (value) => validatePasswordStrength(value || '')
    ),
  passwordConfirmation: yup
    .string()
    .required('Por favor, insira a confirmação da senha')
    .oneOf([yup.ref('password'), ''], 'As senhas devem ser iguais'),
  termsAccepted: yup
    .boolean()
    .equals(
      [true],
      'Por favor, leia e aceite nossos termos e políticas de privacidade'
    ),
};

export const genderOptions = [
  { value: 'male', label: 'Masculino' },
  { value: 'female', label: 'Feminino' },
  { value: 'other', label: 'N/A' },
];

export const worksAsCaregiverForTimeOptions = [
  { value: 'lessThan1Year', label: 'Menos de um ano' },
  { value: 'lessThan3Years', label: 'Entre 1 e 3 anos' },
  { value: 'lessThan5Years', label: 'Entre 3 e 5 anos' },
  { value: 'moreThan5Years', label: 'Mais de 5 anos' },
];

export const hasTakenProfessionalCourseOptions = [
  { value: 1, label: 'Sim' },
  { value: 0, label: 'Não' },
];

export const occupationOptions = [
  { value: 'doctor', label: 'Medicina' },
  { value: 'nurse', label: 'Enfermagem' },
  { value: 'nursingTechnician', label: 'Técnico em Enfermagem' },
  { value: 'physicalTherapist', label: 'Fisioterapia' },
  { value: 'psychologist', label: 'Psicologia' },
  { value: 'occupationalTherapist', label: 'Terapia Ocupacional' },
  { value: 'physicalEducator', label: 'Educação Física' },
  { value: 'nutritionist', label: 'Nutrição' },
  { value: 'speechTherapist', label: 'Fonoaudiologia' },
  { value: 'dentist', label: 'Odontologia' },
  { value: 'another', label: 'Outra' },
];

export const educationLevelOptions = [
  { value: 'none', label: 'Nenhuma (analfabeto)' },
  { value: 'reads-and-writes', label: 'Lê e escreve' },
  { value: 'incomplete-elementary', label: 'Ensino fundamental incompleto' },
  { value: 'complete-elementary', label: 'Ensino fundamental completo' },
  { value: 'incomplete-highschool', label: 'Ensino médio incompleto' },
  { value: 'complete-highschool', label: 'Ensino médio completo' },
  { value: 'incomplete-college', label: 'Ensino superior incompleto' },
  { value: 'complete-college', label: 'Ensino superior completo' },
];

export type FormValues = PersonalDataFormValues &
  CaregiverFormValues &
  SupporterFormValues &
  SelfCareFormValues &
  PasswordFormValues;

export const formDataToCompleteSignupRequest = (
  values: FormValues,
  formType: RegisterFormTypes
): CompleteSignupBody => ({
  userData: resolveUserData(values),
  caregiverData: resolveCaregiverData(values, formType),
  supporterData: resolveSupporterData(values, formType),
});

const resolveUserData = (values: FormValues): UserModel => {
  return {
    name: values.name,
    cpf: numberMask(values.cpf),
    email: values.email,
    phoneNumber: numberMask(`55${values.phoneNumber}`),
    password: values.password,
    gender: values.gender || 'other', // [TODO] Change 'other' to 'not-informed' when exists
    dateOfBirth: format(new Date(values.dateOfBirth || ''), 'yyyy-MM-dd'),
  };
};

const resolveCaregiverData = (
  values: FormValues,
  formType: RegisterFormTypes
): CaregiverModel | undefined => {
  if (formType !== 'caregiver' && formType !== 'selfCare') {
    return undefined;
  }
  return {
    worksAsCaregiverForTime: values.worksAsCaregiverForTime || undefined,
    hasTakenProfessionalCourse: values.hasTakenProfessionalCourse || undefined,
    belongsToCaregiverAgency:
      Boolean(values.belongsToCaregiverAgency) || undefined,
    caregiverAgencyName: !!values.belongsToCaregiverAgency
      ? values.caregiverAgencyName
      : undefined,
    educationLevel: values.educationLevel || undefined,
  };
};

const resolveSupporterData = (
  values: FormValues,
  formType: RegisterFormTypes
): SupporterModel | undefined => {
  if (formType !== 'supporter') {
    return undefined;
  }
  return {
    document: values.document || undefined,
    documentProvince: values.documentProvince || undefined,
    occupation: values.occupation || undefined,
    specialty: values.specialty || undefined,
  };
};

export type CompleteSignupTokenPayload = {
  id: number;
  roleId: number;
  name: UserModel['name'];
  cpf: NonNullable<UserModel['cpf']>;
  phoneNumber: UserModel['phoneNumber'];
  email: UserModel['email'];
};

export const resolvePendingSignupJWTData = (
  token: string
): CompleteSignupTokenPayload | null => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const payload = jwt.decode(token) as any;
    return {
      id: payload.id,
      roleId: payload.roleId,
      name: payload.name || '',
      cpf: payload.cpf || '',
      phoneNumber: phoneMask(payload.phoneNumber || ''),
      email: payload.email || '',
    };
  } catch (err) {
    Sentry.captureException(err);
    return null;
  }
};

const caregiverValidationSchema = yup.object().shape({
  ...personalDataValidationSchema,
  ...caregiverStepValidationSchema,
  ...passwordValidationSchema,
});

const selfCareValidationSchema = yup.object().shape({
  ...personalDataValidationSchema,
  ...selfCareDataValidationSchema,
  ...passwordValidationSchema,
});

const supporterValidationSchema = yup.object().shape({
  ...personalDataValidationSchema,
  ...supporterStepValidationSchema,
  ...passwordValidationSchema,
});

const defaultValidationSchema = yup.object().shape({
  ...personalDataValidationSchema,
  ...passwordValidationSchema,
});

export const formInitialValues = {
  ...personalDataInitialValues,
  ...caregiverInitialValues,
  ...supporterInitialValues,
  ...selfCareInitialValues,
  ...passwordInitialValues,
};
