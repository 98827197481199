import { FormHelperText, InputLabel, Select } from '@material-ui/core';
import { Field, FieldAttributes, useFormikContext } from 'formik';
import React from 'react';
import { ColorsType } from '../../styles/colorMapping';
import { StyledFormControl } from './styles';

interface MySelectDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-testid'?: string;
}

const FormikSelect = ({
  name,
  required = false,
  formControlVariant,
  ...props
}: FieldAttributes<any> & // eslint-disable-line @typescript-eslint/no-explicit-any
  SelectProps & {
    name: string;
    required?: boolean;
    SelectDisplayProps?: MySelectDisplayProps;
    formControlVariant?: 'standard' | 'outlined' | 'filled';
  }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { errors, touched } = useFormikContext<any>();

  const hasError = touched[name] && errors[name];

  return (
    <Field
      type="select"
      id={name}
      required={required}
      as={StyledSelectField}
      name={name}
      error={hasError}
      formControlVariant={formControlVariant}
      {...props}
    />
  );
};

export default FormikSelect;

interface SelectDisplayProps extends React.HTMLAttributes<HTMLDivElement> {
  'data-testid'?: string;
}

export interface SelectProps {
  name?: string;
  label: string;
  value?: string;
  id: string;
  error?: boolean;
  disabled?: boolean;
  color?: ColorsType;
  formControlVariant?: 'standard' | 'outlined' | 'filled';
  onChange?: (
    e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => void;
  SelectDisplayProps?: SelectDisplayProps;
}

const StyledSelectField: React.FC<SelectProps> = ({
  id,
  label,
  children,
  error,
  disabled,
  color = 'primary',
  formControlVariant = 'outlined',
  ...props
}) => {
  return (
    <StyledFormControl
      $color={color}
      fullWidth
      error={Boolean(error)}
      disabled={disabled}
      variant={formControlVariant}
    >
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        variant="outlined"
        label={label}
        labelId={id}
        disabled={disabled}
        {...props}
      >
        {children}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </StyledFormControl>
  );
};
