import {
  Backdrop as MaterialBackdrop,
  CircularProgress as MaterialCircularProgress,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2, 4)};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  display: flex;
`;

export const Backdrop = styled(MaterialBackdrop)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  position: absolute;
`;

export const BackdropCircularProgress = styled(MaterialCircularProgress).attrs({
  color: 'inherit',
})``;
