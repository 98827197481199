import React, { useState, useEffect } from 'react';
import { InputAdornment } from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';

import { useDebouncedCallback } from 'use-debounce';
import qs from 'query-string';

import { StyledInput } from './styles';
import { useHistory } from 'react-router-dom';

type SearchTextFieldProps = {
  fieldName: string;
  defaultValue?: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onChangeDebounced?: (value: string) => void;
};

const SearchTextField: React.FC<SearchTextFieldProps> = ({
  fieldName,
  defaultValue,
  onChange,
  onChangeDebounced,
}) => {
  const [inputValue, setInputValue] = useState('');

  const history = useHistory();

  useEffect(() => setInputValue(defaultValue || ''), [defaultValue]);

  const debounced = useDebouncedCallback((value) => {
    const newSearch = {
      ...qs.parse(history.location.search),
      [fieldName]: value ? `%${value}%` : undefined,
    };
    const newUrl = qs.stringifyUrl({
      url: history.location.pathname,
      query: newSearch,
    });
    history.push(newUrl);
    if (onChangeDebounced) onChangeDebounced(value);
  }, 500);

  return (
    <StyledInput
      value={inputValue}
      onChange={(e) => {
        setInputValue(e.target.value);
        debounced.callback(e.target.value);
        if (onChange) onChange(e);
      }}
      placeholder="Pesquisar conteúdos"
      inputProps={{
        'data-testid': 'searchField',
      }}
      endAdornment={
        inputValue ? (
          <InputAdornment position="end">
            <Clear
              onClick={() => {
                setInputValue('');
                debounced.callback('');
              }}
            />
          </InputAdornment>
        ) : (
          <InputAdornment position="end">
            <Search />
          </InputAdornment>
        )
      }
    />
  );
};

export default SearchTextField;
