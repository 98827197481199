import SchoolIcon from '@material-ui/icons/School';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import red from '@material-ui/core/colors/red';
import React, { useContext } from 'react';
import { ShiftInProgressContext } from '../../contexts/ShiftInProgress';

import CreateEventSelectTypeDialog from '../../components/CreateEventSelectTypeDialog';
import { AuthContext } from '../../contexts/auth';
import useCanAccess from '../../hooks/useCanAccess';
import MenuItem from './MenuItem';
import { Container, StyledBadge } from './styles';

import { ReactComponent as PhoneIcon } from '../../assets/phone-icon.svg';
import { isSelfCare } from '../../contexts/permission';

const Tabbar: React.FC = () => {
  const { userInfo } = useContext(AuthContext);
  const { executionInProgress } = useContext(ShiftInProgressContext);
  const { isAllowedToRead: isAllowedToReadNextShifts } = useCanAccess(
    'care/shift.upnext'
  );
  const { isAllowedToCreate: isAllowedToCreateEvent } = useCanAccess(
    'care/shift/event'
  );
  const { isAllowedToRead: isAllowedToReadContent } = useCanAccess(
    'care/content'
  );
  const { isAllowedToRead: isAllowedToReadShoppingList } = useCanAccess(
    'care/shopping-list'
  );
  const { isAllowedToRead: isAllowedToReadEmergencyContacts } = useCanAccess(
    'care/emergency-contact'
  );
  const path = executionInProgress ? '/plantao' : '/';

  return (
    <Container>
      {(isAllowedToReadNextShifts || executionInProgress) && (
        <MenuItem exact to={path}>
          <StyledBadge
            backgroundColor={red[500]}
            variant="dot"
            visible={!!executionInProgress}
          >
            <WatchLaterIcon />
          </StyledBadge>
          {isSelfCare(userInfo?.user) ? 'Rotina' : 'Plantão'}
        </MenuItem>
      )}
      {isAllowedToReadContent && (
        <MenuItem to="/conteudo">
          <SchoolIcon /> Conteúdo
        </MenuItem>
      )}
      {isAllowedToCreateEvent && <CreateEventSelectTypeDialog />}
      {isAllowedToReadShoppingList && (
        <MenuItem to="/compras">
          <ShoppingCartIcon /> Compras
        </MenuItem>
      )}
      {isAllowedToReadEmergencyContacts && (
        <MenuItem to="/ajuda">
          <PhoneIcon /> Me ajuda
        </MenuItem>
      )}
    </Container>
  );
};

export default Tabbar;
