import {
  CareQuestionAnswerModel,
  ExecutionMessageModel,
  ShiftModel,
} from '@cuidador/database';
import * as Sentry from '@sentry/react';
import { useCallback, useContext, useReducer } from 'react';
import { caregiverOwnAtLeastOneShiftExecution } from '../../components/ShiftsHistory/utils';
import axios, { getCachedAxios } from '../../config/axios';
import { sendQueueData } from '../../config/axios/offlineProxy';
import {
  getQueueData,
  setQueuedData,
} from '../../config/axios/offlineProxy/offlineQueue';
import { AuthContext } from '../../contexts/auth';
import {
  createReducer,
  Item,
  PaginatedRequestParams,
  ReducerData,
} from '../../utils/store/index';
import useCanAccess from '../useCanAccess';

const endpoint = '/care/shift';

const initialData: ReducerData<ShiftModel> = {
  byId: {} as Record<string, Item<ShiftModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useShift = () => {
  const [state, dispatch] = useReducer(
    createReducer<ShiftModel>(),
    initialData
  );

  const getByPatientIdPaginated = useCallback(
    async (patientId: number, params: PaginatedRequestParams) => {
      try {
        dispatch({ type: 'LOADING' });
        const response = await axios.get<{
          results: Item<ShiftModel>[];
          total: number;
        }>(`${endpoint}/by-patient/${patientId}`, {
          params,
        });
        dispatch({ type: 'PAGINATION', payload: response.data });
        return Promise.resolve(response);
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  const getById = useCallback(async (shiftId: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await getCachedAxios().get(`${endpoint}/${shiftId}`);
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return response.data as ShiftModel;
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const startShift = useCallback(async (shiftId: number) => {
    try {
      const response = await axios.post(`${endpoint}/start/${shiftId}`);
      return response.data;
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      throw err;
    }
  }, []);

  const sendQueueBeforeFinishShift = useCallback(async () => {
    const queueData = getQueueData();
    setQueuedData([]); // clear queue data

    for (const queueItem of queueData) {
      try {
        await sendQueueData(axios, queueItem);
      } catch (err) {
        console.log(err);
        Sentry.captureException(err);
      }
    }
  }, []);

  const finishShift = useCallback(
    async (
      shiftId: number,
      data: {
        message: ExecutionMessageModel['message'];
        careQuestionAnswers: CareQuestionAnswerModel[];
      }
    ) => {
      try {
        // try to send queue items
        await sendQueueBeforeFinishShift();

        const response = await axios.post(
          `${endpoint}/finish/${shiftId}`,
          data
        );
        return response.data;
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        throw err;
      }
    },
    []
  );

  const getShiftInProgressWithExecutionInProgress = useCallback(async () => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await getCachedAxios().get(
        `${endpoint}?shift.status=in_progress&executions.status=in_progress`
      );
      dispatch({ type: 'GET_BY_ID', payload: response.data });
      return response.data.results[0] as ShiftModel;
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
    }
  }, []);

  const getShiftCalendarByPatientId = useCallback(async (patientId: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(
        `${endpoint}/calendar/by-patient/${patientId}`
      );
      dispatch({ type: 'GET_ALL', payload: response.data });
      return Promise.resolve(response.data);
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      return Promise.reject(err);
    }
  }, []);

  const showContestationPageItem = (shift?: ShiftModel): boolean => {
    const {
      isAllowedToCreate: isAllowedToCreateShiftTimeContestation,
    } = useCanAccess('care/shift/execution/time-contestation');
    const { userInfo } = useContext(AuthContext);

    const caregiverId = Number(userInfo?.user?.id);

    if (!shift || !caregiverId) return false;

    return !!(
      !!caregiverOwnAtLeastOneShiftExecution(shift, caregiverId) &&
      isAllowedToCreateShiftTimeContestation
    );
  };

  const getTimeline = useCallback(async (shiftId: number) => {
    try {
      dispatch({ type: 'LOADING' });
      const response = await axios.get(`${endpoint}/timeline/${shiftId}`);
      dispatch({ type: 'SUCCESS' });
      return response.data;
    } catch (err) {
      dispatch({ type: 'ERROR', payload: err });
      throw err;
    }
  }, []);

  return {
    ...state,
    startShift,
    finishShift,
    getByPatientIdPaginated,
    getById,
    getShiftCalendarByPatientId,
    getShiftInProgressWithExecutionInProgress,
    showContestationPageItem,
    getTimeline,
  };
};

export default useShift;
