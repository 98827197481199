import { FormControl } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { colorMapping, ColorsType } from '../../styles/colorMapping';
import type { StyledKeyboardDatePickerProps } from './index';

export const StyledFormControl = styled(FormControl).attrs(({ variant }) => ({
  variant: variant || 'outlined',
}))<{
  $color: ColorsType;
  $borderType: StyledKeyboardDatePickerProps['borderType'];
}>`
  & .MuiInputBase-root {
    color: ${({ $color }) => colorMapping[$color]};
    ${({ $borderType, theme }) =>
      $borderType === 'flat'
        ? css`
            border-radius: ${theme.spacing(0.7)}px;
          `
        : ''}

    ${({ $color }) =>
      $color === 'secondary'
        ? css`
            background-color: white;
          `
        : ''}
  }
  & label:not(.Mui-error) {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & input {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & textarea {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & .MuiInputAdornment-root .MuiIconButton-label {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & .MuiOutlinedInput-root:not(.Mui-error) {
    & fieldset {
      border-color: ${({ $color }) => colorMapping[$color]};
      color: ${({ $color }) => colorMapping[$color]};
    }
    &:hover fieldset {
      border-color: ${({ $color }) => colorMapping[$color]};
      color: ${({ $color }) => colorMapping[$color]};
    }
    &.Mui-focused fieldset {
      border-color: ${({ $color }) => colorMapping[$color]};
      color: ${({ $color }) => colorMapping[$color]};
    }
  }
  margin-top: ${({ theme }) => theme.spacing(2)}px;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;
