/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  addToRequestQueue,
  getQueueData,
  QueueData,
  setQueuedData,
} from './offlineQueue';

const RETRY_SECONDS = 5;
interface AxiosOfflineProxy {
  (url: string, data?: any, config?: AxiosRequestConfig): Promise<
    AxiosResponse<any>
  >;
}
interface FakeResponseType {
  (): Promise<{ statusCode: number; data: any }>;
}

/**
 * Creates a proxy function for httpMethods ('post' or 'patch' only),
 * given an axiosInstance and offline response callback for
 * mocking fake results when user is not connected to the internet
 */
export const createOfflineProxy = (
  axiosInstance: AxiosInstance,
  httpMethod: QueueData['httpMethod'],
  offlineResponseCb?: FakeResponseType
): AxiosOfflineProxy => {
  return async (
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<any>> => {
    try {
      const response = await axiosInstance[httpMethod](
        url,
        data || undefined,
        config || axiosInstance.defaults
      );
      return response;
    } catch (error) {
      if (error?.message === 'Network Error') {
        await addToRequestQueue({
          httpMethod,
          url,
          data: data || undefined,
        });
        if (offlineResponseCb) {
          const { statusCode, data } = await offlineResponseCb();
          return {
            config: config || axiosInstance.defaults,
            data,
            status: statusCode,
            headers: [],
            statusText: '',
          };
        }
      }
      throw error;
    }
  };
};

export const startQueueChecker = (axiosInstance: AxiosInstance) => {
  let isTryingToRequest = false; // control variable

  setInterval(async () => {
    const queueData = getQueueData();
    // could add network connection checking too
    if (queueData.length === 0 || isTryingToRequest) return;

    isTryingToRequest = true;
    const firstQueueData = queueData.shift() as QueueData;

    sendQueueData(axiosInstance, firstQueueData)
      .catch(() => {
        queueData.splice(0, 0, firstQueueData);
      })
      .finally(() => {
        setQueuedData(queueData);
        isTryingToRequest = false;
      });
  }, RETRY_SECONDS * 1000);
};

export const sendQueueData = (
  axiosInstance: AxiosInstance,
  queueData: QueueData
) => {
  const { httpMethod, url, data } = queueData;

  return axiosInstance[httpMethod](
    url,
    data || undefined,
    axiosInstance.defaults
  );
};
