import { Button, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import styled, { css } from 'styled-components';

interface StyleForComplication {
  styledHighlight: 'red' | 'orange' | undefined;
}

export const Container = styled.div<StyleForComplication>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding-top: ${({ theme }) => theme.spacing(1)}px;
  background-color: #fff;
  border-radius: 4px;
  ${({ theme, styledHighlight }) => {
    if (styledHighlight === 'red')
      return css`
        border: 5px solid ${theme.palette.extra.color.red.light};
      `;
    if (styledHighlight === 'orange')
      return css`
        border: 5px solid ${theme.palette.extra.color.yellow};
      `;
  }}
`;

export const RealTimeContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.warning.light};
  color: black;
  padding-left: ${({ theme }) => theme.spacing(1)}px;
`;

export const StyledBoldTitle = styled(Typography)<StyleForComplication>`
  padding: ${({ theme }) => theme.spacing(0, 1)};
  font-weight: bold;
  color: ${({ theme, styledHighlight }) =>
    styledHighlight === 'red'
      ? theme.palette.extra.color.red.light
      : theme.palette.extra.color.yellow};
  line-height: 1rem;
`;

export const Instructions = styled(Typography)`
  line-height: 14px;
  padding: ${({ theme }) => theme.spacing(0, 1)};
`;

export const MeasurementInput = styled.input`
  border: 1px solid ${({ theme }) => theme.palette.extra.color.grey.main};
  padding: ${({ theme }) => theme.spacing(1)}px;
  margin: ${({ theme }) => theme.spacing(1, 1, 0, 1)};
  border-radius: 4px;
`;

export const NoteTypography = styled(Typography)`
  padding-inline: ${({ theme }) => theme.spacing(1)}px;
  line-height: 1.2;
  word-break: break-all;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(0, 1)};
`;

export const StyledCommentButton = styled(Button)`
  border: none;
  min-width: 0px;
  padding: 0;
  color: ${({ theme }) => theme.palette.extra.color.grey.dark};
  margin: ${({ theme }) => theme.spacing(1, 0, 1, 1)};
  align-self: flex-end;
`;

interface CheckButtonProps {
  isChecked: boolean;
}

export const SelectedAccessTimeButton = styled.button<CheckButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: none;
  align-self: center;
  margin: ${({ theme }) => theme.spacing(1, 0, 1, 1)};
`;

export const CloseIconContainer = styled.button<CheckButtonProps>`
  ${({ theme, isChecked }) => {
    const background = isChecked
      ? theme.palette.extra.color.red.main
      : theme.palette.common.white;

    const iconColor = isChecked
      ? theme.palette.extra.color.grey.light
      : theme.palette.extra.color.grey.dark;

    const borderColor = isChecked
      ? background
      : theme.palette.extra.color.grey.dark;

    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border: 2px solid ${borderColor};
      color: ${iconColor};
      background-color: ${background};
      border-radius: 100%;
      margin: ${theme.spacing(1, 0, 1, 1)};
    `;
  }}
`;

export const StyledCloseIcon = styled(Close)`
  width: 15px;
  height: 15px;
`;

export const CheckIconContainer = styled.div<CheckButtonProps>`
  ${({ theme, isChecked }) => {
    const background = isChecked
      ? theme.palette.extra.color.green
      : theme.palette.common.white;

    const iconColor = isChecked
      ? theme.palette.extra.color.grey.light
      : theme.palette.extra.color.grey.dark;

    const borderColor = isChecked
      ? background
      : theme.palette.extra.color.grey.dark;

    return css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border: 2px solid ${borderColor};
      color: ${iconColor};
      background-color: ${background};
      border-radius: 100%;
      margin: ${theme.spacing(1, 0, 1, 1)};
    `;
  }}
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 15px;
  height: 15px;
`;

export const BoldText = styled.span`
  font-weight: bold;
`;

export const CaregiverTypography = styled.div`
  color: ${({ theme }) => theme.palette.text.secondary};
  margin: ${({ theme }) => theme.spacing(1)}px;
  font-weight: 600;
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  &::before {
    content: 'por ';
    font-weight: 400;
  }
`;
