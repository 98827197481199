import React, { useContext } from 'react';
import InternetConnectionErrorBanner from '../InternetConnectionCheck';
import { InternetConnectionContext } from '../../components/InternetConnectionCheck/InternetConnectionContext';
import { AppBar, Toolbar } from '@material-ui/core';

export type HeaderProps = {
  leftContent?: React.ReactNode;
  centerContent?: React.ReactNode;
  rightContent?: React.ReactNode;
};

export function Header(props: HeaderProps) {
  const { leftContent, centerContent, rightContent } = props;
  const BlankDiv = () => <div style={{ height: '40px', width: '40px' }} />;
  const { internetStatus } = useContext(InternetConnectionContext);

  return (
    <>
      {/* AppBar fixo no topo */}
      <AppBar
        style={{
          position: 'sticky',
          top: 0,
          height: '72px',
          zIndex: 5,
          justifyContent: 'center',
        }}
      >
        <Toolbar
          style={{
            justifyContent: 'space-between',
            zIndex: 3,
          }}
        >
          {leftContent ? leftContent : <BlankDiv />}
          {centerContent ? centerContent : <BlankDiv />}
          {rightContent ? rightContent : <BlankDiv />}
        </Toolbar>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            filter: 'brightness(0.1) opacity(0.1)',
          }}
        >
          <img
            src="images/headerElipses/ElipseBlue.svg"
            style={{
              width: '250px',
              height: '250px',
              top: '-200px',
              right: '30px',
              position: 'absolute',
              zIndex: 2,
            }}
          />
          <img
            src="images/headerElipses/Elipsegreen.svg"
            style={{
              width: '250px',
              height: '250px',
              top: '-180px',
              right: '-80px',
              position: 'absolute',
              zIndex: 1,
            }}
          />
          <img
            src="images/headerElipses/ElipseBlue.svg"
            style={{
              width: '250px',
              height: '250px',
              bottom: '-150px',
              left: '-120px',
              position: 'absolute',
              zIndex: 2,
            }}
          />
          <img
            src="images/headerElipses/Elipsegreen.svg"
            style={{
              width: '250px',
              height: '250px',
              bottom: '-220px',
              left: '0px',
              position: 'absolute',
              zIndex: 1,
            }}
          />
        </div>
      </AppBar>

      {/* InternetConnectionErrorBanner fixo abaixo do AppBar */}
      {internetStatus === 'offline' && (
        <div
          style={{
            position: 'sticky',
            top: '72px', // Posição ajustada para ficar logo abaixo do AppBar
            zIndex: 4, // Menor que o AppBar para evitar sobreposição
            width: '100%',
          }}
        >
          <InternetConnectionErrorBanner />
        </div>
      )}
    </>
  );
}
