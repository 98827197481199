import React from 'react';
import { bloodGlycemiaMask } from '../../../../../utils/inputs';
import FormikTextField from '../../../../Forms/FormikTextField';
import StyledMenuItem from '../../../../StyledMenuItem';
import StyledSelect from '../../../../Forms/FormikSelect';
import { bloodGlycemiaAdditionalDetails } from '../../../../VitalSignsMeasurementInput/utils';
import { InputContainer } from '../styles';

interface BloodGlycemiaInputProps {
  value?: string;
  onChange: (value: string) => void;
}

const BloodGlycemiaInput: React.FC<
  BloodGlycemiaInputProps & Partial<HTMLInputElement>
> = ({ value, onChange, ...props }) => {
  return (
    <>
      <InputContainer>
        <FormikTextField
          color="primary"
          inputProps={{
            'data-testid': 'measurementInput',
            maxlength: 255,
          }}
          label="Valor"
          margin="normal"
          autoComplete="off"
          placeholder="100"
          value={value}
          onChange={(e) => {
            const formattedValue = bloodGlycemiaMask(e.target.value);
            onChange(formattedValue);
          }}
          {...props}
        />
        {'mg/dL'}
      </InputContainer>
      <StyledSelect
        id="measurementInputAdditionalDetails"
        label="Momento da medição"
        name="additionalDetails"
        SelectDisplayProps={{
          'data-testid': 'measurementInputAdditionalDetails',
        }}
        color="primary"
      >
        {Object.entries(bloodGlycemiaAdditionalDetails).map(([key, label]) => (
          <StyledMenuItem
            key={`glycemia-${key}`}
            value={key}
            color="primary"
            data-testid={`additional-details-${key}`}
          >
            {label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </>
  );
};

export default BloodGlycemiaInput;
