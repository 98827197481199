import styled from 'styled-components';
import { ReactComponent as OldMan } from '../../../assets/old-man.svg';

export const StyledOldManIllustration = styled(OldMan)`
  margin-block: ${({ theme }) => theme.spacing(10)}px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    color: ${({ theme }) => theme.palette.primary.dark};
    text-align: center;
  }
`;
