import { Form as FormikForm } from 'formik';
import styled from 'styled-components';
import StyledButton from '../../../StyledButton';

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(-1)}px;
`;

export const StyledOptions = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const Button = styled(StyledButton)`
  width: 45%;
`;

export const LargeButton = styled(StyledButton)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;
