import { AnswerConfig } from '@cuidador/database';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router';

import { Typography } from '@material-ui/core';
import Header from '../../components/Headers/Header';
import ShiftQuestion from '../../components/ShiftQuestion';
import StyledButton from '../../components/StyledButton';
import { AuthContext } from '../../contexts/auth';
import { CurrentShiftDataContext } from '../../contexts/CurrentShiftData';
import { isSelfCare } from '../../contexts/permission';
import {
  ButtonContainer,
  Container,
  FinishingShiftHeaderDiv,
  Title,
} from './styles';

type RouteState = {
  careCategoryIndex: number;
  careLineIndex: number;
  careQuestionIndex: number;
  isFinishingShift?: boolean;
};

const defaultRouteState: RouteState = {
  careCategoryIndex: 0,
  careLineIndex: 0,
  careQuestionIndex: 0,
  isFinishingShift: false,
};

const ShiftQuestionPage: React.FC = () => {
  const history = useHistory();
  const { state = defaultRouteState } = useLocation<RouteState>();
  const { useShiftQuestions } = useContext(CurrentShiftDataContext);
  const { userInfo } = useContext(AuthContext);
  const { byId, ids, saveAnswer } = useShiftQuestions();
  const [careCategoryIndex, setCareCategoryIndex] = useState(
    state.careCategoryIndex || defaultRouteState.careCategoryIndex
  );
  const [careLineIndex, setCareLineIndex] = useState(
    state.careLineIndex || defaultRouteState.careLineIndex
  );
  const [careQuestionIndex, setCareQuestionIndex] = useState(
    state.careQuestionIndex || defaultRouteState.careQuestionIndex
  );

  const onAnswerChange = useCallback(
    (questionId, answer) => {
      saveAnswer(questionId, answer, {
        careCategoryIndex: Number(careCategoryIndex),
        careLineIndex: Number(careLineIndex),
        careQuestionIndex: Number(careQuestionIndex),
      });
    },
    [careCategoryIndex, careLineIndex, careQuestionIndex]
  );

  const careCategory = byId[ids[careCategoryIndex]];

  const careLine = careCategory?.careLines?.[careLineIndex];

  const careQuestion = careLine?.careQuestions?.[careQuestionIndex];

  // it doesn't have careCategory or careLine or careQuestion
  if (!careCategory || !careLine || !careQuestion)
    return <Redirect to="/plantao" />;

  const nextQuestion = useMemo(() => {
    if (careQuestionIndex < careLine!.careQuestions!.length - 1) {
      return {
        careCategoryIndex,
        careLineIndex,
        careQuestionIndex: careQuestionIndex + 1,
      };
    }
    if (careLineIndex < careCategory!.careLines!.length - 1) {
      return {
        careCategoryIndex,
        careLineIndex: careLineIndex + 1,
        careQuestionIndex: 0,
      };
    }
    if (careCategoryIndex < ids.length - 1) {
      return {
        careCategoryIndex: careCategoryIndex + 1,
        careLineIndex: 0,
        careQuestionIndex: 0,
      };
    }
    return null;
  }, [careCategoryIndex, careLineIndex, careQuestionIndex]);

  const previousQuestion = useMemo(() => {
    if (careQuestionIndex > 0) {
      return {
        careCategoryIndex,
        careLineIndex,
        careQuestionIndex: careQuestionIndex - 1,
      };
    }
    if (careLineIndex > 0) {
      return {
        careCategoryIndex,
        careLineIndex: careLineIndex - 1,
        careQuestionIndex:
          careCategory!.careLines[careLineIndex - 1]!.careQuestions!.length - 1,
      };
    }
    if (careCategoryIndex > 0) {
      const previousCareCategory = byId[ids[careCategoryIndex - 1]];
      const previousCareLine =
        previousCareCategory.careLines[
          previousCareCategory.careLines.length - 1
        ];
      return {
        careCategoryIndex: careCategoryIndex - 1,
        careLineIndex: previousCareCategory!.careLines!.length - 1,
        careQuestionIndex: previousCareLine!.careQuestions!.length - 1,
      };
    }
    return null;
  }, [careCategoryIndex, careLineIndex, careQuestionIndex]);

  const goToNextQuestion = () => {
    if (nextQuestion) {
      setCareCategoryIndex(nextQuestion.careCategoryIndex);
      setCareLineIndex(nextQuestion.careLineIndex);
      setCareQuestionIndex(nextQuestion.careQuestionIndex);
    } else if (state.isFinishingShift) {
      history.push('/plantao/finalizar');
    } else {
      history.goBack();
    }
  };

  const goToPreviousQuestion = () => {
    if (previousQuestion) {
      setCareCategoryIndex(previousQuestion?.careCategoryIndex);
      setCareLineIndex(previousQuestion?.careLineIndex);
      setCareQuestionIndex(previousQuestion?.careQuestionIndex);
    }
  };

  const totalOfQuestions = useMemo(() => {
    const lastCareCategory = byId[ids[ids.length - 1]];
    const lastCareLine =
      lastCareCategory.careLines[lastCareCategory.careLines.length - 1];
    const lastCareQuestion =
      lastCareLine.careQuestions[lastCareLine.careQuestions.length - 1];
    return lastCareQuestion.questionIndex + 1; // index of last question of this shift
  }, [careCategoryIndex, careLineIndex, careQuestionIndex]);

  return (
    <>
      <Header
        title={
          state.isFinishingShift
            ? `Finalização de ${
                isSelfCare(userInfo?.user) ? 'rotina' : 'plantão'
              }`
            : 'Plano de cuidado'
        }
        leftButtonType="goBack"
      />
      <Container>
        {state.isFinishingShift ? (
          <FinishingShiftHeaderDiv>
            <Typography variant="h6" align="center">
              Revisão das perguntas de cuidado
            </Typography>
          </FinishingShiftHeaderDiv>
        ) : null}
        <Title>{careLine?.name}</Title>
        <ShiftQuestion
          backgroundColor={careCategory.colorHexa}
          currentStep={careQuestion.questionIndex + 1}
          totalQuestions={totalOfQuestions}
          careQuestionText={careQuestion.careQuestionText}
          careQuestionId={careQuestion.id}
          onAnswerChange={onAnswerChange}
          questionAnswerData={careQuestion.answerConfig as AnswerConfig}
        />
        <ButtonContainer>
          <div>
            {previousQuestion && (
              <StyledButton
                data-testid="previous-button"
                color="inherit"
                size="large"
                onClick={goToPreviousQuestion}
              >
                Anterior
              </StyledButton>
            )}
            <StyledButton
              data-testid="next-button"
              color="inherit"
              size="large"
              onClick={goToNextQuestion}
            >
              Próximo
            </StyledButton>
          </div>
          <StyledButton
            color="inherit"
            variant="text"
            onClick={goToNextQuestion}
          >
            Pular
          </StyledButton>
        </ButtonContainer>
      </Container>
    </>
  );
};

export default ShiftQuestionPage;
