import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Header from '../../components/Headers/Header';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import useMotivationalMessage from '../../hooks/useMotivationalMessage';

import {
  Button,
  Container,
  FooterContent,
  Logo,
  MotivationalMessageCard,
  MotivationalMessageLine,
  OutterContainer,
} from './styles';

const MotivationalMessage: React.FC = () => {
  const [messageLines, setMessageLines] = useState(['']);
  const { getOne, loading } = useMotivationalMessage();
  const history = useHistory();

  useEffect(() => {
    getOne()
      .then((messageData) => {
        return breakMessageLines(messageData.message!);
      })
      .then((lines) => setMessageLines(lines))
      .catch(() =>
        toast.error('Erro ao buscar mensagem, tente novamente daquia a pouco.')
      );
  }, []);

  const breakMessageLines = (message: string) => {
    const formattedMessage = message.split('\n');
    return formattedMessage;
  };

  const handleClick = () => {
    history.push(`/plantao`);
  };

  if (loading) {
    return <LoadingBackdrop loading={loading} />;
  }

  return (
    <OutterContainer>
      <Header
        title="Mensagem do dia"
        leftButtonType="goBack"
        rightButtonType="phone"
      />
      <Container>
        <MotivationalMessageCard>
          {messageLines.map((message) => {
            return (
              <>
                <MotivationalMessageLine>{message}</MotivationalMessageLine>
              </>
            );
          })}
        </MotivationalMessageCard>

        <FooterContent>
          <Button onClick={handleClick}>Próximo</Button>
          <Logo />
        </FooterContent>
      </Container>
    </OutterContainer>
  );
};

export default MotivationalMessage;
