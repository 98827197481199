import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import StyledTextField from '../../components/StyledTextField';
import StyledPaper from '../../components/StyledPaper';

export const QuestionCard = styled(StyledPaper)<{
  $backgroundColor?: string;
}>`
  background-color: ${({ $backgroundColor, theme }) =>
    $backgroundColor ? `#${$backgroundColor}` : theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  align-items: center;
`;

export const StepsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  padding: ${({ theme }) =>
    theme.spacing(
      0,
      5
    )}; // enough space to show "99/99" without overlapping the step dots
`;

export const StepCounter = styled(Typography).attrs({ variant: 'caption' })`
  position: absolute;
  left: 0;
`;

export const OptionsWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(1, 0)};
  padding: ${({ theme }) => theme.spacing(1, 2)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: solid 1px ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  & .MuiFormControlLabel-root {
    margin: 0px;
    text-align: justify;

    :not(:first-child) {
      margin-top: ${({ theme }) => theme.spacing(1)}px;
      padding-top: ${({ theme }) => theme.spacing(1)}px;
      border-top: solid 1px rgba(255, 255, 255, 0.3);
    }

    & .MuiRadio-root,
    .MuiCheckbox-root {
      margin-left: ${({ theme }) => -theme.spacing(1)}px;
    }
  }
`;

export const OptionDescriptionContainer = styled.div``;

export const StyledOptionDescriptionTextField = styled(StyledTextField).attrs({
  fullWidth: true,
  multiline: true,
  color: 'white',
})`
  & .MuiInputBase-input {
    &::placeholder {
      opacity: 0.7;
    }
  }
`;

export const Divider = styled.hr`
  margin: ${({ theme }) => theme.spacing(2, 1)};
  border: 0;
  border-top: solid 1px ${({ theme }) => theme.palette.common.white};
`;

export const QuestionText = styled(Typography)`
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;
