import { Typography, useTheme } from '@material-ui/core'
import React from 'react'
import { PatientAvatar } from '../PatientAvatar'

export type PageTitleProps = {
  title: string
  patientId?: number
}

export function PageTitle(props: PageTitleProps) {
  const { title, patientId } = props
  const theme = useTheme()

  return (
    <div style={
      patientId ? {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginInline: theme.spacing(1.55),
        maxWidth: '350px',
      } : {}
    } >
      {patientId && <PatientAvatar patientId={patientId} />}
       <Typography
          variant='h6'
          style={{
            textAlign: 'center',
            fontSize: '16px',
            display: '-webkit-box',
            maxWidth: '250px',
            marginLeft: '10px',
            maxLines: 2,
            overflow: 'hidden',
          }}
        >
          {title}
        </Typography>
    </div>
  )
}
