import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';

import StyledTextField from '../../components/StyledTextField';

import Button from '../../components/StyledButton';
import { sendPasswordResetEmail } from '../../contexts/auth';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { APIError } from '@cuidador/lib';
import { resolveErrorMessage } from '../../utils/error';

import { validationSchema, formInitialValues, FormValues } from './utils';

import {
  Container,
  TypographyContainer,
  BackgroundOverlay,
  OutterContainer,
  Logo,
  TitleTypography,
  ButtonContainer,
  BackButton,
} from './styles';

const ForgotPassword: React.FC = () => {
  const formikRef = useRef<FormikProps<FormValues> | null>();
  const history = useHistory();

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);
    sendPasswordResetEmail(values.email)
      .then(() => {
        toast.info('E-mail de redefinição de senha enviado.');
        history.goBack();
      })
      .catch((err: AxiosError<APIError>) => {
        if (err.response?.data.message === 'User not found.') {
          toast.error('E-mail não cadastrado');
          formikRef.current?.setFieldError('email', 'E-mail não cadastrado');
        } else {
          const displayMessage = resolveErrorMessage(err);
          toast.error(displayMessage);
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <BackgroundOverlay>
      <OutterContainer>
        <Container>
          <Logo />
          <TypographyContainer>
            <TitleTypography>
              <Typography variant="h6">Recuperar senha</Typography>
            </TitleTypography>
            <Typography>
              Informe seu e-mail para que as instruções de recuperar a senha
              sejam enviadas
            </Typography>
          </TypographyContainer>
          <Formik
            innerRef={(ref) => (formikRef.current = ref)}
            initialValues={formInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form>
                <StyledTextField
                  name="email"
                  color="white"
                  label="E-mail"
                  value={formik.values.email.trim()}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.email)}
                  helperText={formik.errors.email}
                  margin="normal"
                  fullWidth
                  inputProps={{ 'data-testid': 'email' }}
                />
                <ButtonContainer>
                  <BackButton>
                    <Button
                      data-testid="back"
                      size="large"
                      color="inherit"
                      onClick={() => history.goBack()}
                      disabled={formik.isSubmitting}
                    >
                      Voltar
                    </Button>
                  </BackButton>
                  <Button
                    data-testid="submit"
                    size="large"
                    color="inherit"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    Enviar
                  </Button>
                </ButtonContainer>
              </Form>
            )}
          </Formik>
        </Container>
      </OutterContainer>
    </BackgroundOverlay>
  );
};

export default ForgotPassword;
