import { EventModel, EventSubCategoryModel } from '@cuidador/database';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBr from 'date-fns/locale/pt-BR';
import { Formik } from 'formik';
import React, { useState } from 'react';
import StyledSelect from '../../Forms/FormikSelect';
import FormikTextField from '../../Forms/FormikTextField';
import StyledMenuItem from '../../StyledMenuItem';
import {
  Centralizer,
  Form,
  FormContainer,
  OptionsButton,
  StyledDateTimePicker,
} from '../styles';
import { validationSchema } from './utils';

export interface Props {
  submit: (eventData: EventModel) => Promise<void>;
  cancel: () => void;
  subCategories: EventSubCategoryModel[];
  maxDateTime?: Date | string;
  minDateTime?: Date | string;
}

interface FormValues {
  comment: string;
  subCategoryId: number | string;
}

const CreateComplicationEventForm: React.FC<Props> = ({
  submit,
  cancel,
  subCategories,
  maxDateTime,
  minDateTime,
}) => {
  const initialValues: FormValues = {
    subCategoryId: '',
    comment: '',
  };

  const [dateTimeEvent, setDateTimeEvent] = useState<Date>(new Date());

  const formatAndSubmit = async (formValues: FormValues) => {
    const eventData: EventModel = {
      comment: formValues.comment,
      subCategoryId: Number(formValues.subCategoryId),
      eventHappensAt: dateTimeEvent.toISOString(),
    };
    await submit(eventData);
  };

  return (
    <FormContainer>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={formatAndSubmit}
        validateOnChange={false}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <StyledSelect
                name="subCategoryId"
                label="Tipo de intercorrência"
                SelectDisplayProps={{
                  'data-testid': 'subCategoryId',
                }}
                color="primary"
              >
                {subCategories.map((item) => (
                  <StyledMenuItem key={item.id} value={item.id} color="primary">
                    {item.name}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
                <StyledDateTimePicker
                  inputVariant="outlined"
                  format="dd/MM/yyyy HH:mm"
                  name="dateTimeEvent"
                  value={dateTimeEvent}
                  cancelLabel="Cancelar"
                  ampm={false}
                  maxDate={maxDateTime}
                  minDate={minDateTime}
                  onChange={(date: Date | null) => {
                    date && setDateTimeEvent(date);
                  }}
                />
              </MuiPickersUtilsProvider>
              <FormikTextField
                color="primary"
                id="comment"
                multiline
                rows={4}
                inputProps={{ 'data-testid': 'comment', maxlength: 2000 }}
                label="Observações"
                name="comment"
                margin="normal"
                autoComplete="off"
              />
              <Centralizer>
                <OptionsButton
                  data-testid="submit-complication"
                  type="submit"
                  size="large"
                  color="primary"
                  disabled={isSubmitting}
                >
                  Salvar
                </OptionsButton>
                <OptionsButton
                  data-testid="submit-complication"
                  onClick={cancel}
                  size="large"
                  color="primary"
                >
                  Cancelar
                </OptionsButton>
              </Centralizer>
            </Form>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

export default CreateComplicationEventForm;
