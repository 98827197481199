import { ExecutionMessageModel, ShiftExecutionModel } from '@cuidador/database';
import { useCallback, useReducer } from 'react';
import axios from '../../config/axios';
import { createReducer, Item, ReducerData } from '../../utils/store/index';
import { createOfflinePatchExecutionMessage } from './patchExecutionNotesOffline';

const endpoint = '/care/execution';

const initialData: ReducerData<ShiftExecutionModel> = {
  byId: {} as Record<string, Item<ShiftExecutionModel>>,
  ids: [] as Array<number>,
  total: 0,
  loading: false,
  error: null,
};

const useExecution = () => {
  const [state, dispatch] = useReducer(
    createReducer<ShiftExecutionModel>(),
    initialData
  );

  const patchExecutionMessage = useCallback(
    async (executionId: number, data: Partial<ExecutionMessageModel>) => {
      try {
        dispatch({ type: 'LOADING' });
        const patchOfflineNotes = createOfflinePatchExecutionMessage(
          axios,
          data
        );
        const response = await patchOfflineNotes(
          `${endpoint}/${executionId}/message`,
          data
        );
        dispatch({ type: 'UPDATE', payload: response.data });
        return Promise.resolve(response);
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err });
        return Promise.reject(err);
      }
    },
    []
  );

  return {
    ...state,
    patchExecutionMessage,
  };
};

export default useExecution;
