import React from 'react'
import { IconButtonStyled } from '../IconButtonStyled'

export type MenuButtonProps = {
  onClick: () => void
}

export function MenuButton (props: MenuButtonProps) {
  const { onClick } = props

  return (
    <IconButtonStyled
      icon={
        <img
          src='images/burger-menu.svg'
          style={{
            fontSize: 40,
            color: 'inherit',
          }}
        />
      }
      onClick={onClick}
    />
  )
}
