import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};

  div {
    max-width: 95%;
  }

  p {
    margin-right: 2.5%;
  }
`;
