import styled from 'styled-components';
import StyledPaper from '../../../StyledPaper';

export const CardBox = styled(StyledPaper)`
  display: flex;
  min-height: 120px;
  padding: 0;
  color: ${({ theme }) => theme.palette.extra.color.grey.main};
  background-color: ${({ theme }) => theme.palette.extra.color.grey.light};
`;

export const LeftContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.extra.color.yellow};
  display: flex;
  padding: ${({ theme }) => theme.spacing(2, 0, 0, 0)};
  flex-direction: column;

  img {
    width: 75px;
    height: 75px;
  }
  border-radius: 4px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  padding: ${({ theme }) => theme.spacing(1, 1, 0, 1)};
`;
