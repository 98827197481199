import { AnswerType, CareQuestionAnswer } from '@cuidador/database';

function isDeeplyAnswered(questionAnswerData: AnswerType): boolean {
  switch (questionAnswerData.type) {
    case 'multi':
      const isCheckedArray = questionAnswerData.options.map(
        (option) => option.isChecked
      );
      return isCheckedArray.some((value) => value);
    case 'single':
      const checkedOption = questionAnswerData.options.find((o) => o.isChecked);
      if (!checkedOption) return false;
      if (!checkedOption.nextAnswer) return true;
      return isDeeplyAnswered(checkedOption.nextAnswer);
    default:
      return false;
  }
}

function countAnsweredShiftCareQuestions(
  careQuestionAnswers: CareQuestionAnswer[]
) {
  const answeredQuestionCount = careQuestionAnswers.reduce(
    (count, currentCareQuestionAnswer) => {
      const questionAnswerData = currentCareQuestionAnswer.questionAnswerData;
      if (!questionAnswerData || typeof questionAnswerData === 'string')
        return 0;

      return count + (isDeeplyAnswered(questionAnswerData) ? 1 : 0);
    },
    0
  );

  return answeredQuestionCount;
}

export function getCareCategoryCardStatusMessage(
  careQuestionAnswers: CareQuestionAnswer[],
  careQuestionCount: number
) {
  const answered = countAnsweredShiftCareQuestions(careQuestionAnswers);
  return `${answered}/${careQuestionCount} perguntas respondidas`;
}
