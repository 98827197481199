import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import StyledButton from '../StyledButton';
import {
  StyledDialogContainer,
  StyledDialogContentText,
  StyledWarningIcon,
} from './styles';

interface LogoutDialogProps {
  open: boolean;
  onConfirm: (() => void) | undefined;
  onClose: () => void;
}

const LogoutDialog: React.FC<LogoutDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <StyledDialogContainer open={open} onClose={onClose}>
      <DialogContent>
        <DialogTitle>
          <StyledWarningIcon />
        </DialogTitle>
        <StyledDialogContentText align="center">
          Tem certeza de que quer sair da sua conta? Será necessário fazer login
          novamente para acessá-la
        </StyledDialogContentText>
        <DialogActions>
          <StyledButton onClick={onClose} color="inherit">
            Voltar
          </StyledButton>
          <StyledButton onClick={onConfirm} variant="text" color="inherit">
            Sair
          </StyledButton>
        </DialogActions>
      </DialogContent>
    </StyledDialogContainer>
  );
};

export default LogoutDialog;
