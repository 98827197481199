import { EventModel, EventSubCategoryModel } from '@cuidador/database';
import { Typography } from '@material-ui/core';
import RightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import { CardDiv, Container, IconContainer, SubTitleContainer } from './styles';
import AppointmentCard from './LastShiftCards/AppointmentCard';
import ComplicationCard from './LastShiftCards/ComplicationCard';
import MedicationCard from './LastShiftCards/MedicationCard';
import ScheduledMeasurementCard from './LastShiftCards/ScheduledMeasurementCard';
import MeasurementCard from './LastShiftCards/MeasurementCard';
import { FormattedEvents } from '../CurrentShiftEventList';
import RoutineCard from './LastShiftCards/RoutineCard';
import { categoryIdMapper } from './utils';

export type ShiftEventListProps = {
  events: EventModel[];
  title: string;
};

const ShiftEventList: React.FC<ShiftEventListProps> = ({ events, title }) => {
  const RenderActivityCardByEvent = (event: FormattedEvents) => {
    const subCategory = event.subCategory as EventSubCategoryModel;
    switch (subCategory.categoryId) {
      case categoryIdMapper['MEDICATION']:
        return (
          <CardDiv key={event.id}>
            <MedicationCard
              id={event.id}
              status={event.status}
              name={subCategory.name}
              dosageFormat={event.medication?.dosageFormat}
              dosageQuantity={event.medication?.dosageQuantity}
              administeredBy={event.medication?.administeredBy}
              medicalNotes={event.medication?.medicalNotes}
              eventNotifiedAt={event.eventNotifiedAt}
              eventHappensAt={event.eventHappensAt}
              updatedTimeHappensAt={event.updatedTimeHappensAt || undefined}
              comment={event.comment}
              caregiverName={event.caregiver?.user?.name}
            />
          </CardDiv>
        );
      case categoryIdMapper['COMPLICATION']:
        return (
          <CardDiv key={event.id}>
            <ComplicationCard
              {...event}
              caregiverName={event.caregiver?.user?.name}
            />
          </CardDiv>
        );
      case categoryIdMapper['GENERAL']:
        if (subCategory.isRoutine) {
          return (
            <CardDiv key={event.id}>
              <RoutineCard
                {...event}
                caregiverName={event.caregiver?.user?.name}
              />
            </CardDiv>
          );
        } else
          return (
            <CardDiv key={event.id}>
              <AppointmentCard
                {...event}
                caregiverName={event.caregiver?.user?.name}
              />
            </CardDiv>
          );
      case categoryIdMapper['MEASUREMENT']:
        if (event.scheduledMeasurements) {
          const key = `schedule-
            ${event.scheduledMeasurements[0]?.eventScheduleId}-${event.scheduledMeasurements?.[0]?.id}`;
          return (
            <CardDiv key={key}>
              <ScheduledMeasurementCard
                readonly={true}
                scheduledMeasurements={event.scheduledMeasurements}
                caregiverName={event.caregiver?.user?.name}
              />
            </CardDiv>
          );
        } else {
          return (
            <CardDiv key={event.id}>
              <MeasurementCard
                {...event}
                caregiverName={event.caregiver?.user?.name}
              />
            </CardDiv>
          );
        }
      default:
        return <></>;
    }
  };

  return (
    <Container>
      {/* Activities */}
      {events.length > 0 && (
        <>
          <SubTitleContainer>
            <IconContainer>
              <RightIcon />
            </IconContainer>
            <Typography variant="h6">{title}</Typography>
          </SubTitleContainer>
          {events.map((event) => {
            return RenderActivityCardByEvent(event);
          })}
        </>
      )}
    </Container>
  );
};

export default ShiftEventList;
