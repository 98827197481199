import styled from 'styled-components';
import StyledPaper from '../../../StyledPaper';

export const CardBox = styled(StyledPaper)`
  width: 100%;
  display: flex;
  margin: 0;
  min-height: 120px;
  background-color: ${({ theme }) => theme.palette.extra.color.green};
  padding: 0;
`;
