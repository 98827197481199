import { ProfessionalReportModel } from '@cuidador/database';
import { AxiosInstance } from 'axios';
import { createOfflineProxy } from '../../config/axios/offlineProxy';

export const createOfflinePostProfessionalReport = (
  axios: AxiosInstance,
  data: ProfessionalReportModel
) =>
  createOfflineProxy(axios, 'post', async () => {
    return {
      data: data,
      statusCode: 200,
    };
  });
