import { CareQuestionAnswerModel } from '@cuidador/database';
import { AxiosInstance } from 'axios';
import { createOfflineProxy } from '../../config/axios/offlineProxy';

export const createOfflinePostAnswer = (
  axios: AxiosInstance,
  answer: CareQuestionAnswerModel
) =>
  createOfflineProxy(axios, 'post', async () => {
    return {
      data: answer,
      statusCode: 200,
    };
  });
