import Card from '@material-ui/core/Card';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${({ theme }) => theme.spacing(0, 1)};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

interface ReportStatusProps {
  isDraft: boolean;
}

export const ReportStatus = styled.div<ReportStatusProps>`
  border-radius: 5px;
  padding-left: ${({ theme }) => theme.spacing(1)}px;
  background: ${({ isDraft, theme }) =>
    isDraft ? theme.palette.warning.light : theme.palette.primary.main};
  color: ${({ isDraft }) => (isDraft ? 'inherit' : '#fff;')};
`;

export const TypographyContainer = styled.div`
  width: 100%;
  text-align: center;
  color: black;
  margin-top: ${({ theme }) => theme.spacing(6)}px;
`;

export const ReportCard = styled(Card).attrs({ elevation: 3 })`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  margin-top: ${({ theme }) => theme.spacing(1)}px;
  & .MuiCardHeader-avatar {
    color: ${({ theme }) => theme.palette.info.main};
    width: 20px;
  }
  & .MuiCardHeader-action {
    margin: 0;
    align-self: center;
  }
`;

export const DateBox = styled.div``;
