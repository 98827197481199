import { EventModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import React from 'react';
import MesurementIcon from '../../../../assets/Icon-measurement.svg';
import { FormValues as CommentModalFormValues } from '../CommentModal';
import { TimerContainer } from '../styles';
import Measurement from './Measurement';
import { CardBox, LeftContainer, RightContainer } from './styles';

interface CardProps {
  scheduledMeasurements: EventModel[];
  readonly?: boolean;
  onChangeComment?: (id: number, values: CommentModalFormValues) => void;
}

const ScheduledMeasurementCard: React.FC<CardProps> = ({
  scheduledMeasurements,
  readonly = false,
  onChangeComment,
}) => {
  const {
    id: eventId,
    eventScheduleId,
    eventHappensAt,
  } = scheduledMeasurements[0];

  const hour = format(new Date(`${eventHappensAt}`), 'HH:mm');

  return (
    <CardBox data-testid={`measurementCard-${eventScheduleId}`}>
      <LeftContainer>
        <img
          src={MesurementIcon}
          title={`measurement-image-${eventScheduleId}`}
        />
        <TimerContainer>
          <Typography variant="h6">{hour}</Typography>
        </TimerContainer>
      </LeftContainer>
      <RightContainer>
        {scheduledMeasurements?.map((event) => {
          return (
            <div key={eventId}>
              <Measurement
                caregiverName={event?.caregiver?.user?.name}
                {...event}
                legacy={event.measurement?.legacy}
                id={event.id!}
                readonly={readonly}
                onChangeComment={onChangeComment!}
              />
            </div>
          );
        })}
      </RightContainer>
    </CardBox>
  );
};

export default ScheduledMeasurementCard;
