import { ShiftModel } from '@cuidador/database';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import React, { useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import { ShiftInProgressContext } from '../../contexts/ShiftInProgress';
import MenuButton from './MenuButton';
import {
  ContainerDayShifts,
  ContainerShifts,
  ShiftBodyDetails,
  ShiftCard,
  ShiftCardTitle,
  StyledDD,
  StyledName,
  StyledYYYYMM,
} from './styles';
import {
  getDayGroup,
  getShiftCardBackgroundColor,
  isShiftMenuVisible,
} from './utils';

interface ShiftsHistoryProps {
  shifts?: Map<string, Map<string, ShiftModel[]>>;
}

const ShiftsHistory: React.FC<ShiftsHistoryProps> = ({ shifts }) => {
  const { shiftInProgress } = useContext(ShiftInProgressContext);
  const { userInfo } = useContext(AuthContext);

  return (
    <>
      {Array.from(shifts?.entries() || []).map(([mapKeyYYYYMM, innerMap]) => {
        const anyShift = Array.from(innerMap.values())[0][0];
        const date = new Date(String(anyShift.plannedToStartAt));
        return (
          <ContainerShifts key={mapKeyYYYYMM}>
            <StyledYYYYMM>
              {format(date, 'MMMM, yyyy', { locale: ptBrLocale })}
            </StyledYYYYMM>
            {Array.from(innerMap.entries()).map(([mapKeyDD, shifts]) => {
              const anyShift = shifts[0];
              const date = new Date(String(anyShift.plannedToStartAt));
              return (
                <ContainerDayShifts key={`${mapKeyYYYYMM}${mapKeyDD}`}>
                  <StyledDD>{getDayGroup(date)}</StyledDD>
                  {shifts.map((shift) => {
                    return (
                      <ShiftCard
                        backgroundColor={getShiftCardBackgroundColor(shift)}
                        key={shift.id}
                        data-testid={`shift-card-${shift.id}`}
                      >
                        <ShiftCardTitle>
                          <StyledName>
                            {`${format(
                              new Date(shift.plannedToStartAt || ''),
                              'HH:mm'
                            )} ${
                              shift.name || shift.shiftSchedule?.name || ''
                            }`}
                          </StyledName>
                          {isShiftMenuVisible(
                            shift,
                            userInfo!.user!,
                            shiftInProgress
                          ) && <MenuButton shift={shift} />}
                        </ShiftCardTitle>
                        {shift.status === 'not_done' && (
                          <ShiftBodyDetails>
                            <Typography>Plantão não iniciado.</Typography>
                          </ShiftBodyDetails>
                        )}
                      </ShiftCard>
                    );
                  })}
                </ContainerDayShifts>
              );
            })}
          </ContainerShifts>
        );
      })}
    </>
  );
};
export default ShiftsHistory;
