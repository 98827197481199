import { Button } from '@material-ui/core';
import React from 'react';
import { ReactComponent as CalendarIcon } from '../../../../assets/calendar-icon.svg';
import { ButtonContainer } from './styles';

interface ShiftCalendarButtonProps {
  onClick?: () => void;
}

const ShiftCalendarButton: React.FC<ShiftCalendarButtonProps> = ({
  onClick,
}) => {
  return (
    <ButtonContainer onClick={onClick} data-testid="shift-schedule-button">
      <Button
        aria-controls="button-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
      >
        <CalendarIcon />
        <span>Plantões</span>
      </Button>
    </ButtonContainer>
  );
};

export default ShiftCalendarButton;
