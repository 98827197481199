import { CalendarEntryDataType } from '@cuidador/database';
import { clientSideScheme } from '@cuidador/whitelabel';
import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { InternetConnectionContext } from '../../../components/InternetConnectionCheck/InternetConnectionContext';
import {
  CardBody,
  CardButton,
  CardContainer,
  CardHeader,
  CardTime,
  CardTitle,
  StyledAvailableIcon,
  StyledDoneIcon,
  StyledInProgressIcon,
  StyledUnavailableIcon,
} from './styles';

interface ShiftCalendarCardProps {
  patientId: number;
  calendarEntry: CalendarEntryDataType;
  handleStartShift: () => Promise<void>;
}

const ShiftCalendarCard: React.FC<ShiftCalendarCardProps> = ({
  patientId,
  calendarEntry,
  handleStartShift,
}) => {
  const history = useHistory();
  const { internetStatus } = useContext(InternetConnectionContext);
  const { appCPalette } = clientSideScheme();

  const formattedDateToStart = format(
    new Date(`${calendarEntry.shiftPlannedToStartAt}`),
    "EEEE, dd/MM 'às' HH'h'mm",
    { locale: ptBrLocale }
  );

  const resolveShiftStatusTitle = (status: string) => {
    switch (status) {
      case 'finished':
        return 'Concluído';
      case 'incomplete':
        return 'Disponível (Incompleto)';
      case 'available':
        return 'Disponível';
      case 'in_progress':
        return 'Em andamento';
      default:
        return 'Indisponível';
    }
  };

  const resolveShiftStatusIcon = (status: string) => {
    switch (status) {
      case 'finished':
        return <StyledDoneIcon />;
      case 'incomplete':
      case 'available':
        return <StyledAvailableIcon />;
      case 'in_progress':
        return <StyledInProgressIcon />;
      default:
        return <StyledUnavailableIcon />;
    }
  };

  const resolveShiftStatusColor = (statusTitle: string) => {
    switch (statusTitle) {
      case 'finished':
        return appCPalette.extra.color.green;
      case 'incomplete':
      case 'available':
        return appCPalette.extra.color.ocean;
      case 'in_progress':
        return appCPalette.extra.color.yellow;
      default:
        return appCPalette.extra.color.grey.main;
    }
  };

  const resolveButtonLabel = (action: CalendarEntryDataType['action']) => {
    switch (action) {
      case 'start':
        return 'Iniciar plantão';
      case 'return':
        return 'Voltar ao plantão';
      case 'follow':
        return 'Ver plantão';
      case 'history':
        return 'Ver histórico';
    }
  };

  const handleClickButton = async (action: CalendarEntryDataType['action']) => {
    switch (action) {
      case 'start':
        return await handleStartShift();
      case 'return':
        return history.push('/plantao');
      case 'follow':
        return history.push(`/historico/plantao/${calendarEntry.shiftId}`);
      case 'history':
        return history.push(`/historico/plantao/paciente/${patientId}`);
    }
  };

  return (
    <CardContainer data-testid={`shiftCalendarCard-${calendarEntry.shiftId}`}>
      <CardHeader
        backgroundColorByStatusTitle={resolveShiftStatusColor(
          calendarEntry.status
        )}
      >
        {resolveShiftStatusIcon(calendarEntry.status)}
        {resolveShiftStatusTitle(calendarEntry.status)}
      </CardHeader>
      <CardBody>
        <div>
          <CardTitle>{calendarEntry.shiftName}</CardTitle>
          <CardTime>Início: {formattedDateToStart}</CardTime>
        </div>
        {!!calendarEntry.action && internetStatus === 'online' && (
          <CardButton
            color="inherit"
            variant="outlined"
            onClick={async () => await handleClickButton(calendarEntry.action)}
            data-testid="action-button"
          >
            {resolveButtonLabel(calendarEntry.action)}
          </CardButton>
        )}
      </CardBody>
    </CardContainer>
  );
};

export default ShiftCalendarCard;
