import { format } from 'date-fns';
import ptBrLocale from 'date-fns/locale/pt-BR';

export const handleFormatDate = (date: string | null) => {
  if (!date) {
    return 'Sem alterações';
  }
  return format(new Date(date), 'MM/dd/yyyy', {
    locale: ptBrLocale,
  });
};
export const handleFormatTime = (time: string | null) => {
  if (!time) {
    return 'Sem alterações';
  }
  return format(new Date(time), 'HH:mm');
};
