import { ExecutionMessage } from '@cuidador/database';
import React from 'react';
import { CollapsibleCardContent } from '../CollapsibleCardContent';
import { CardWithBackground, TypographyWhiteText } from './styles';

interface ExecutionMessageCardProps {
  executionMessage: ExecutionMessage;
  'data-testid'?: string;
}

export const ExecutionMessageCard: React.FC<ExecutionMessageCardProps> = ({
  executionMessage,
  ...props
}) => (
  <CardWithBackground {...props}>
    <TypographyWhiteText
      variant="body1"
      data-testid={`execution-message-card-title`}
    >
      por{' '}
      <b data-testid={`execution-message-card-title-bold`}>
        {executionMessage.caregiver?.user?.name ?? ''}
      </b>
    </TypographyWhiteText>
    <br />
    <CollapsibleCardContent
      text={executionMessage.message ?? ''}
      maxLines={3}
      backgroundVariant="primary"
    />
  </CardWithBackground>
);

export default ExecutionMessageCard;
