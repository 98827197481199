import React from 'react';

import { ContainerFlat, BottomContainer } from './styles';
import StyledButton from '../StyledButton';
import { CircularProgress as MaterialCircularProgress } from '@material-ui/core';

interface LoadContainerProps<T> {
  data: Array<T>;
  loading: boolean;
  hasMoreItems: boolean;
  loadMoreText?: string;
  handlePagination: () => void;
  renderItem: (item: T) => JSX.Element;
  keyExtractor: (item: { item: T }) => string;
}

export default function LoadMoreContainer<T>({
  data,
  loading,
  hasMoreItems,
  loadMoreText,
  handlePagination,
  renderItem,
  keyExtractor,
}: LoadContainerProps<T>): JSX.Element {
  return (
    <ContainerFlat>
      {data.map((item) => (
        <div key={keyExtractor({ item })}>{renderItem(item)}</div>
      ))}
      {loading ? (
        <BottomContainer>
          <MaterialCircularProgress />
        </BottomContainer>
      ) : (
        hasMoreItems && (
          <BottomContainer>
            <StyledButton color="inherit" onClick={() => handlePagination()}>
              {loadMoreText ? loadMoreText : 'Carregar mais'}
            </StyledButton>
          </BottomContainer>
        )
      )}
    </ContainerFlat>
  );
}
