import { MedicationModel } from '@cuidador/database';

const handleDropsLabel = (
  dosageQuantity: MedicationModel['dosageQuantity']
) => {
  if (dosageQuantity) {
    return dosageQuantity <= 1 ? 'gota' : 'gotas';
  }
  return 'gota';
};

export const handleFormatDose = (
  doseFormat?: MedicationModel['dosageFormat'],
  dosageQuantity?: MedicationModel['dosageQuantity']
) => {
  let doseFormatFormated;
  switch (doseFormat) {
    case 'pill':
      doseFormatFormated = dosageQuantity == 1 ? 'comprimido' : 'comprimidos';
      break;
    case 'capsule':
      doseFormatFormated = dosageQuantity == 1 ? 'capsúla' : 'capsúlas';
      break;
    case 'sachet':
      doseFormatFormated = dosageQuantity == 1 ? 'sachê' : 'sachês';
      break;
    case 'drops':
      doseFormatFormated = handleDropsLabel(dosageQuantity);
      break;
    case 'ampoule':
      doseFormatFormated = dosageQuantity == 1 ? 'ampola' : 'ampolas';
      break;
    case 'suppository':
      doseFormatFormated = dosageQuantity == 1 ? 'supositório' : 'supositórios';
      break;
    case 'inhaled':
      doseFormatFormated = dosageQuantity == 1 ? 'jato/puff' : 'jatos/puffs';
      break;
    case 'unit':
      doseFormatFormated =
        dosageQuantity == 1
          ? 'unidade internacional'
          : 'unidades internacionais';
      break;
    case 'ml':
      doseFormatFormated = 'ml';
      break;
    case 'spoon':
      doseFormatFormated = dosageQuantity == 1 ? 'colher' : 'colheres';
      break;
    case 'grams':
      doseFormatFormated = dosageQuantity == 1 ? 'grama' : 'gramas';
      break;
    case 'strip':
      doseFormatFormated =
        dosageQuantity == 1 ? 'placa/tira/fita' : 'placas/tiras/fitas';
      break;
    case 'sticker':
      doseFormatFormated = dosageQuantity == 1 ? 'adesivo' : 'adesivos';
      break;
    default:
      break;
  }

  return doseFormatFormated;
};

export const handleFormatAdministeredBy = (
  administeredBy?: MedicationModel['administeredBy']
) => {
  let viaTypeFormated;
  switch (administeredBy) {
    case 'oral':
      viaTypeFormated = 'Oral';
      break;
    case 'sublingual':
      viaTypeFormated = 'Sublingual';
      break;
    case 'subcutaneous':
      viaTypeFormated = 'Subcutânea';
      break;
    case 'intravenous':
      viaTypeFormated = 'Intravenosa';
      break;
    case 'intramuscular':
      viaTypeFormated = 'Intramuscular';
      break;
    case 'tube':
      viaTypeFormated = 'Sonda';
      break;
    case 'rectal':
      viaTypeFormated = 'Retal';
      break;
    case 'inhalation':
      viaTypeFormated = 'Inalatório';
      break;
    case 'topic':
      viaTypeFormated = 'Cutânea';
      break;
    case 'ocular':
      viaTypeFormated = 'Ocular';
      break;
    case 'auricular':
      viaTypeFormated = 'Auricular';
      break;
    case 'vaginal':
      viaTypeFormated = 'Vaginal';
      break;
    default:
      break;
  }

  return viaTypeFormated;
};
