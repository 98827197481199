import MuiTextField from '@material-ui/core/TextField';
import styled from 'styled-components';

import { colorMapping, ColorsType } from '../../styles/colorMapping';

export const TextField = styled(MuiTextField)<{ $color: ColorsType }>`
  & label:not(.Mui-error) {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & label.Mui-disabled {
    color: ${({ $color }) => colorMapping[$color]};
    opacity: 65%;
  }
  & input {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & textarea {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & .MuiInputAdornment-root .MuiIconButton-label {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & .MuiInputAdornment-root .MuiIconButton-label {
    color: ${({ $color }) => colorMapping[$color]};
  }
  & .MuiOutlinedInput-root:not(.Mui-error) {
    & fieldset {
      border-color: ${({ $color }) => colorMapping[$color]};
      color: ${({ $color }) => colorMapping[$color]};
    }
    &:hover fieldset {
      border-color: ${({ $color }) => colorMapping[$color]};
      color: ${({ $color }) => colorMapping[$color]};
    }
    &.Mui-focused fieldset {
      border-color: ${({ $color }) => colorMapping[$color]};
      color: ${({ $color }) => colorMapping[$color]};
    }
  }
  & .MuiOutlinedInput-root.Mui-disabled {
    & input:disabled {
      color: ${({ $color }) => colorMapping[$color]};
      opacity: 65%;
    }
    & fieldset {
      border-color: ${({ $color }) => colorMapping[$color]};
      opacity: 65%;
    }
  }
`;
