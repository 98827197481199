import { EventSubCategoryModel } from '@cuidador/database';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBr from 'date-fns/locale/pt-BR';
import { Formik } from 'formik';
import React from 'react';
import StyledSelect from '../Forms/FormikSelect';
import StyledMenuItem from '../StyledMenuItem';
import { Form, FormContainer, DatePicker, TextField } from './styles';
import {
  titleOptions,
  validationSchema,
} from '../CreateProfessionalReportForm/utils';

export interface Props {
  subCategories: EventSubCategoryModel[];
  formValues: FormValues;
}

export interface FormValues {
  title: string;
  description: string;
  subCategoryId?: number | string;
  date: string;
  caregiver: string;
  isDraft: boolean;
}

const ViewProfessionalReportForm: React.FC<Props> = ({
  subCategories,
  formValues,
}) => {
  return (
    <FormContainer>
      <Formik
        validationSchema={validationSchema}
        initialValues={formValues}
        validateOnChange={false}
        onSubmit={() => {
          return Promise.resolve();
        }}
      >
        {({ handleBlur, values, setFieldValue, errors }) => {
          return (
            <Form>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
                <DatePicker
                  name="date"
                  label="Data"
                  cancelLabel="Cancelar"
                  data-testid={'date-input'}
                  value={values.date}
                  onChange={(date: Date | null) => {
                    setFieldValue('date', date, true);
                  }}
                  placeholder="dd/mm/aaaa"
                  color="black"
                  margin="normal"
                  inputProps={{
                    'data-testid': 'date',
                    disabled: true,
                    disableUnderline: true,
                  }}
                  helperText={errors.date}
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true }}
                  readOnly={true}
                  disabled={true}
                  variant="dialog"
                  inputVariant="standard"
                />
              </MuiPickersUtilsProvider>
              <StyledSelect
                name="title"
                label="Título"
                SelectDisplayProps={{
                  'data-testid': 'title',
                }}
                color="black"
                disabled={true}
                variant="standard"
                formControlVariant="standard"
              >
                {titleOptions.map((item) => (
                  <StyledMenuItem
                    key={item.value}
                    value={item.value}
                    color="black"
                  >
                    {item.label}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
              <TextField
                color="black"
                id="caregiver"
                inputProps={{ 'data-testid': 'caregiver' }}
                label="Profissional Responsável"
                name="caregiver"
                margin="normal"
                autoComplete="off"
                disabled
                variant="standard"
              />
              <StyledSelect
                name="subCategoryId"
                label="Intercorrência relacionada"
                SelectDisplayProps={{
                  'data-testid': 'subCategoryId',
                }}
                color="black"
                value={formValues?.subCategoryId || values.subCategoryId}
                disabled={true}
                variant="standard"
                formControlVariant="standard"
              >
                {subCategories.map((item) => (
                  <StyledMenuItem key={item.id} value={item.id} color="black">
                    {item.name}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
              <TextField
                color="black"
                id="description"
                multiline
                inputProps={{ 'data-testid': 'description', maxlength: 5000 }}
                label="Descrição"
                name="description"
                margin="normal"
                autoComplete="off"
                disabled
                variant="standard"
              />
            </Form>
          );
        }}
      </Formik>
    </FormContainer>
  );
};

export default ViewProfessionalReportForm;
