import React, { useContext, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce/lib'
import { bloodGlycemiaMask } from '../../../utils/inputs'
import { Row, StyledInput } from '../styles'
import { NewMeasurementValue } from '../../CurrentShiftEventList/CurrentShiftCards/ScheduledMeasurementCard/Measurement'
import {
  bloodGlycemiaAdditionalDetails,
  BloodGlycemiaAdditionalDetailsType,
  staticCareLineIdMapper,
  validateBloodGlycemia,
} from '../utils'
import { ShiftInProgressContext } from '../../../contexts/ShiftInProgress'
import StyledMenuItem from '../../StyledMenuItem'
import StyledSelectField from '../../StyledSelectField'
import { MeasurementModel } from '@cuidador/database'

interface BloodGlycemiaInputProps {
  measurement?: MeasurementModel;
  value?: string;
  patchEvent: (
    newMeasurementValue: NewMeasurementValue,
    status: 'awaiting' | 'accomplished' | 'not_accomplished',
    additionalDetails?: BloodGlycemiaAdditionalDetailsType
  ) => void;
  id?: string;
  disabled?: boolean;
}

const BloodGlycemiaInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  BloodGlycemiaInputProps
> = ({ measurement, value, patchEvent, id, disabled }, ref) => {
  const [glycemiaValue, setGlycemiaValue] = useState(value || '')
  const [
    additionalDetails,
    setAdditionalDetails,
  ] = useState<BloodGlycemiaAdditionalDetailsType>(measurement?.additionalDetails as BloodGlycemiaAdditionalDetailsType || 'fasting')
  const { shiftInProgress } = useContext(ShiftInProgressContext)

  const onChangeAnyValue = useDebouncedCallback(
    (newMeasurementValue: NewMeasurementValue, additionalDetails?: BloodGlycemiaAdditionalDetailsType) => {
      const newStatus = newMeasurementValue.measurementValue
        ? 'accomplished'
        : 'awaiting'
      patchEvent(newMeasurementValue, newStatus, additionalDetails)
    },
    400,
  )

  const hasDiabetes = useMemo(() => {
    return !!shiftInProgress?.patient?.staticCareLines?.some(
      (scl) =>
        ['dysfunctional', 'severe_dysfunctional'].includes(
          String(scl.classification),
        ) &&
        [
          staticCareLineIdMapper.DIABETES_TYPE1,
          staticCareLineIdMapper.DIABETES_TYPE2,
        ].includes(scl.careLineId!),
    )
  }, [shiftInProgress?.patient?.staticCareLines])

  return (
    <Row>
      <StyledInput
        type='text'
        value={glycemiaValue}
        onChange={(e) => {
          const formattedValue = bloodGlycemiaMask(
            e.target.value === '0' ? '' : parseInt(e.target.value).toString(),
          )
          const complicationMeasurement = validateBloodGlycemia(
            formattedValue,
            shiftInProgress?.patient?.dateOfBirth,
            hasDiabetes,
            additionalDetails,
          )
          setGlycemiaValue(formattedValue)
          onChangeAnyValue.callback({
            ...complicationMeasurement,
            measurementValue: formattedValue,
          }, additionalDetails)
        }}
        disabled={disabled}
        ref={ref}
        id={`${id}-glycemia`}
        // The bellow attribute makes the input keyboard be set to only numbers on smartphones.
        pattern='\d*'
      />
      <span>mg/dL</span>
      <StyledSelectField
        id='bloodGlycemiaAdditionalDetails'
        SelectDisplayProps={{
          'data-testid': 'bloodGlycemiaAdditionalDetails',
        }}
        value={additionalDetails}
        color='black'
        onChange={(e) => {
          const complicationMeasurement = validateBloodGlycemia(
            glycemiaValue,
            shiftInProgress?.patient?.dateOfBirth,
            hasDiabetes,
            e.target.value as BloodGlycemiaAdditionalDetailsType,
          )
          onChangeAnyValue.callback({
            ...complicationMeasurement,
            measurementValue: glycemiaValue,
          }, e.target.value as BloodGlycemiaAdditionalDetailsType)
          setAdditionalDetails(
            e.target.value as BloodGlycemiaAdditionalDetailsType,
          )
        }}
      >
        {Object.entries(bloodGlycemiaAdditionalDetails).map(([key, label]) => (
          <StyledMenuItem
            key={`${id}-glycemia-${key}`}
            value={key}
            color='secondary'
            data-testid={`additional-details-${key}`}
          >
            {label}
          </StyledMenuItem>
        ))}
      </StyledSelectField>
    </Row>
  )
}

export default React.forwardRef(BloodGlycemiaInput)
