import React from 'react';

import { Container, StepDot } from './styles';

type StepsProps = {
  steps: number;
  activeStep: number;
};

const Steps: React.FC<StepsProps> = ({ steps, activeStep }) => {
  return (
    <Container>
      {[...new Array(steps)].map((_, index) => (
        <StepDot key={index} $active={index < activeStep} />
      ))}
    </Container>
  );
};

export default Steps;
