import React, { useCallback, useLayoutEffect, useState } from 'react';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@material-ui/icons/ArrowDropUpOutlined';
import {
  CardActionAreaCentered,
  CollapseCentered,
  Divider,
  CardContentColorVariant,
} from './styles';
import { useOverflowDetector } from '../../hooks/useOverflowDetector';

export interface CollapsibleCardContentProps {
  text: string;
  maxLines?: number;
  backgroundVariant?: 'clear' | 'primary';
}

export const CollapsibleCardContent: React.FC<CollapsibleCardContentProps> = ({
  text,
  backgroundVariant,
  maxLines,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [hasPressedSeeMore, setHasPressedSeeMore] = useState(false);

  const toggleCollapse = useCallback(() => {
    setIsExpanded((expanded) => !expanded);
    setHasPressedSeeMore((hasPressedSeeMore) => !hasPressedSeeMore);
  }, [setIsExpanded, setHasPressedSeeMore]);

  const resetStateOnResize = useCallback(() => {
    setIsExpanded(false);
    setHasPressedSeeMore(false);
  }, [setIsExpanded, setHasPressedSeeMore]);

  useLayoutEffect(() => {
    window.addEventListener('resize', resetStateOnResize);
    return () => window.removeEventListener('resize', resetStateOnResize);
  }, []);

  const { overflow, ref } = useOverflowDetector<HTMLDivElement>();

  return (
    <>
      <CardContentColorVariant
        backgroundVariant={backgroundVariant}
        data-testid="collapsible-card-content"
        ref={ref}
        maxLines={isExpanded ? undefined : maxLines}
      >
        {text}
      </CardContentColorVariant>
      {!overflow && !hasPressedSeeMore && <br />}
      {(!!overflow || !!hasPressedSeeMore) && (
        <CollapseCentered>
          <Divider backgroundVariant={backgroundVariant} />
          <CardActionAreaCentered
            backgroundVariant={backgroundVariant}
            onClick={toggleCollapse}
            data-testid="collapsible-card-see-more-button"
          >
            {!isExpanded && (
              <>
                Ver Mais <ArrowDropDownOutlinedIcon />
              </>
            )}
            {!!isExpanded && (
              <>
                Ver Menos <ArrowDropUpOutlinedIcon />
              </>
            )}
          </CardActionAreaCentered>
        </CollapseCentered>
      )}
    </>
  );
};

export default CollapsibleCardContent;
