import { CaregiverModel, SupporterModel, UserModel } from '@cuidador/database';
import { useCallback } from 'react';
import axios from '../config/axios';
import useMedia from './useMedia';

export type CompleteSignupBody = {
  userData: UserModel;
  caregiverData?: Omit<CaregiverModel, 'user' | 'supporter'>;
  supporterData?: SupporterModel;
};

const endpoint = '/user';

const useUser = () => {
  const { uploadMedia, getMedia } = useMedia();
  const completeSignup = useCallback(
    async (data: CompleteSignupBody, completeSignupToken: string) => {
      try {
        const response = await axios.post(`${endpoint}/complete-signup`, data, {
          headers: {
            Authorization: `Bearer ${completeSignupToken}`,
          },
        });
        return Promise.resolve(response.data);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    []
  );

  const checkSignupStatus = useCallback(async (userId: number) => {
    try {
      const response = await axios.get(
        `${endpoint}/check-signup-status/${userId}`
      );
      return Promise.resolve(response.data);
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  const uploadProfilePicture = useCallback(
    async (file: File, userId: number) => {
      try {
        const response = await uploadMedia(
          `/media/profile-picture/user/${userId}`,
          file
        );
        return Promise.resolve(response);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    []
  );

  const getProfilePicture = useCallback(async (id: number) => {
    try {
      const response = await getMedia(`/media/profile-picture/user/${id}`);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }, []);

  const putUserFCMToken = useCallback(
    async (data: { fcmRegistrationToken: string }) => {
      try {
        const response = await axios.put(`${endpoint}/fcm-token`, data);
        return Promise.resolve(response.data);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    []
  );

  const deleteUserFCMToken = useCallback(
    async (fcmRegistrationToken: string) => {
      try {
        const response = await axios.delete(
          `${endpoint}/fcm-token/${fcmRegistrationToken}`
        );
        return Promise.resolve(response.data);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    []
  );

  return {
    completeSignup,
    checkSignupStatus,
    uploadProfilePicture,
    getProfilePicture,
    putUserFCMToken,
    deleteUserFCMToken,
  };
};

export default useUser;
