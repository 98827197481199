/**
 *
 *  This is a copy of familia/src/contexts/permission
 *  Any change here might need be reflected there
 *
 */

import { PermissionModel, UserModel } from '@cuidador/database';
import * as Sentry from '@sentry/react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios, * as axiosModule from '../config/axios';
import { AuthContext } from './auth';

interface UserPermissions {
  [resource: string]: PermissionModel[];
}

export const DEFAULT_ROLES = {
  guardian: 1,
  caregiver: 2,
  admin: 3,
  selfCare: 4,
  visualizerGuardian: 5,
  supporter: 6,
};

export interface ContextProps {
  loading?: boolean;
  hasLoaded?: boolean;
  error?: Error | null;
  permissions?: UserPermissions;
  refreshPermissions?: () => Promise<UserPermissions | undefined>;
}

export const PermissionContext = createContext<ContextProps>(
  {} as ContextProps
);

export const PermissionProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [permissions, setPermissions] = useState<ContextProps['permissions']>(
    {}
  );
  const { user } = useContext(AuthContext);

  useEffect(() => {
    refreshPermissions();
  }, [user]);

  useEffect(() => {
    refreshPermissions();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    axiosModule.addGlobalResponseInterceptor(undefined, (error: any) => {
      if (error.response?.status === 403) {
        // Force permissions refresh on 403 response
        refreshPermissions();
      }
      return Promise.reject(error);
    });
  }, []);

  const refreshPermissions = useCallback(async (): Promise<
    UserPermissions | undefined
  > => {
    if (!user) {
      // User not logged in; Should not refresh permissions
      return undefined;
    }

    setLoading(true);
    try {
      const response = await axios.get(`auth/role/permission/me`);
      setPermissions(response.data);
      setHasLoaded(true);
      return response.data;
    } catch (err) {
      setError(err);
      // Will send to Sentry since Permissions fetch shouldn't fail
      Sentry.captureException(err);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  }, [user, setPermissions, setLoading]);

  if (user && !hasLoaded) return null;

  return (
    <PermissionContext.Provider
      value={{
        loading,
        hasLoaded,
        error,
        permissions,
        refreshPermissions,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermission = () => useContext(PermissionContext);

export default PermissionProvider;

export const isSupporter = (user?: UserModel) => {
  return user?.role?.id === DEFAULT_ROLES.supporter;
};

export const isSelfCare = (user?: UserModel) => {
  return user?.role?.id === DEFAULT_ROLES.selfCare;
};

export const isCaregiver = (user?: UserModel) => {
  return user?.role?.id === DEFAULT_ROLES.caregiver;
};
