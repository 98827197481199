import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { LocationDescriptor } from 'history';

export interface ButtonLinkProps extends ButtonProps {
  to: LocationDescriptor;
}

const LinkForwardRef = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => <Link innerRef={ref} {...props} />
);
LinkForwardRef.displayName = 'LinkForwardRef';

const ButtonLink: React.FC<ButtonLinkProps> = ({
  children,
  variant = 'outlined',
  ...rest
}) => (
  // eslint-disable-next-line
  // @ts-ignore: see https://github.com/mui-org/material-ui/issues/7877
  <Button variant={variant} {...rest} component={LinkForwardRef}>
    {children}
  </Button>
);

export default ButtonLink;
