import { FormControl, Radio, RadioGroup, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddRounded';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ShiftInProgressContext } from '../../contexts/ShiftInProgress';
import StyledButton from '../StyledButton';
import StyledSimpleModal from '../StyledSimpleModal';
import {
  CreateDialogBtnContainer,
  CreateEventDialogContainer,
  Fab,
  StyledFormControlLabel,
} from './styles';

const EventSelectTypeDialog: React.FC<{
  opened: boolean;
  close: () => void;
}> = ({ opened, close }) => {
  const { shiftInProgress } = useContext(ShiftInProgressContext);
  const [eventType, setEventType] = useState('');
  const history = useHistory();
  const handleCreateEventClick = () => {
    history.push(`/plantao/adicionar-evento/${eventType}`);
  };

  const title = shiftInProgress ? undefined : 'Sem plantão ativo';
  const ShiftInProgressDialog = (
    <>
      <CreateEventDialogContainer>
        <FormControl>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            onChange={(e) => setEventType(e.target.value)}
          >
            <StyledFormControlLabel
              value="compromisso"
              control={<Radio color="default" />}
              label="Compromisso"
            />
            <StyledFormControlLabel
              value="intercorrencia"
              control={<Radio color="default" />}
              label="Intercorrência"
            />
            <StyledFormControlLabel
              value="medicamento"
              control={<Radio color="default" />}
              label="Medicamento"
            />
            <StyledFormControlLabel
              value="medicao"
              control={<Radio color="default" />}
              label="Controles e sinais vitais"
            />
            <StyledFormControlLabel
              value="rotina"
              control={<Radio color="default" />}
              label="Rotina"
            />
            <StyledFormControlLabel
              value="anotacoes"
              control={<Radio color="default" />}
              label="Anotações e evolução do plantão"
            />
          </RadioGroup>
        </FormControl>
      </CreateEventDialogContainer>
      <CreateDialogBtnContainer>
        <StyledButton color="inherit" onClick={close}>
          Cancelar
        </StyledButton>
        <StyledButton
          disabled={!eventType}
          color="inherit"
          onClick={handleCreateEventClick}
        >
          Avançar
        </StyledButton>
      </CreateDialogBtnContainer>
    </>
  );

  const ShiftNotInProgressDialog = (
    <>
      <CreateEventDialogContainer>
        <Typography variant="subtitle1">
          Para ser possível adicionar um evento, é necessário estar em um
          plantão em andamento.
        </Typography>
      </CreateEventDialogContainer>
      <CreateDialogBtnContainer>
        <StyledButton color="inherit" onClick={close}>
          Entendi
        </StyledButton>
      </CreateDialogBtnContainer>
    </>
  );

  return (
    <StyledSimpleModal open={opened} title={title}>
      {shiftInProgress ? ShiftInProgressDialog : ShiftNotInProgressDialog}
    </StyledSimpleModal>
  );
};

const CreateEventSelectTypeDialog: React.FC = () => {
  const [isCreateEventDialogOpen, setIsCreateEventDialogOpen] = useState(false);

  return (
    <>
      <Fab size="large" onClick={() => setIsCreateEventDialogOpen(true)}>
        <AddIcon />
      </Fab>
      {isCreateEventDialogOpen && (
        <EventSelectTypeDialog
          opened={isCreateEventDialogOpen}
          close={() => setIsCreateEventDialogOpen(false)}
        />
      )}
    </>
  );
};

export default CreateEventSelectTypeDialog;
