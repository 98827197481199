import { DatePicker, TimePicker } from '@material-ui/pickers';
import { Form as FormikForm } from 'formik';
import styled from 'styled-components';
import StyledButton from '../../components/StyledButton';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.palette.common.white};
  flex-direction: column;
  max-width: 600px;
`;

export const TopGuidelineParagraph = styled.p`
  color: ${({ theme }) => theme.palette.primary.main};
  padding: 8px 24px 8px;
  text-align: center;
`;

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${({ theme }) => theme.palette.extra.color.grey.light};
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing(-1)}px;
`;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  & .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & .MuiOutlinedInput-input {
    padding: ${({ theme }) => theme.spacing(1.5)}px;
    border-radius: ${({ theme }) => theme.spacing(1)}px;
  }
  & .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.spacing(1)}px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  & .MuiInputBase-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & .MuiOutlinedInput-input {
    padding: ${({ theme }) => theme.spacing(1.5)}px;
    border-radius: ${({ theme }) => theme.spacing(1)}px;
  }
  & .MuiOutlinedInput-root {
    border-radius: ${({ theme }) => theme.spacing(1)}px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledOptions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const Button = styled(StyledButton)`
  width: 45%;
`;

export const FormInnerContainer = styled.div`
  padding: 12px 24px 24px 24px;
`;

export const DateTimePickersGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 16px;
  margin-block: 0 16px;
  margin-inline: 16px;

  svg {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
    color: ${({ theme }) => theme.palette.primary.main};
  }

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      margin-right: 8px;
      margin-top: 12px;
    }

    > div {
      margin-bottom: 0;
      > p {
        color: ${({ theme }) => theme.palette.error.main};
      }
    }
  }
`;

export const DateTimeCheckboxGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: bold;
  }
`;

export const JustificationContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)}px;
`;
