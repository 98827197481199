import { ExecutionMessageModel } from '@cuidador/database';
import { AxiosInstance } from 'axios';
import { createOfflineProxy } from '../../config/axios/offlineProxy';

export const createOfflinePatchExecutionMessage = (
  axios: AxiosInstance,
  data: Partial<ExecutionMessageModel>
) =>
  createOfflineProxy(axios, 'patch', async () => {
    return { data, statusCode: 200 };
  });
