import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';

import { colorMapping, ColorsType } from '../../styles/colorMapping';

export const FormControlLabel = styled(MuiFormControlLabel)<{
  $color: ColorsType;
}>`
  & .MuiRadio-root {
    color: ${({ $color }) => colorMapping[$color]};
    padding: ${({ theme }) => theme.spacing(1)}px;
  }
  & .MuiCheckbox-root {
    color: ${({ $color }) => colorMapping[$color]};
    padding: ${({ theme }) => theme.spacing(1)}px;
  }
`;
