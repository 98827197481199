import React from 'react';
import * as Sentry from '@sentry/react';

function FallbackComponent() {
  return <div>Ocorreu um erro</div>;
}

const ErrorBoundary: React.FC = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={FallbackComponent}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
