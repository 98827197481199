import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';

import CareCategoryAccordion from '../CareCategoryAccordion';
import { ShiftInProgressContext } from '../../contexts/ShiftInProgress';

import { Container, HeaderContainer } from './styles';
import { Typography } from '@material-ui/core';
import LoadingBackdrop from '../LoadingBackdrop';
import { CurrentShiftDataContext } from '../../contexts/CurrentShiftData';

const CareCategoryList: React.FC = () => {
  const { shiftInProgress } = useContext(ShiftInProgressContext);
  const { useShiftQuestions } = useContext(CurrentShiftDataContext);
  const { getQuestionsByShiftId, ids, byId, loading } = useShiftQuestions();

  useEffect(() => {
    const shiftId = Number(shiftInProgress!.id);
    getQuestionsByShiftId(shiftId).catch(() => {
      toast.error(
        'Erro ao recuperar lista de cuidados. Tente novamente daqui a pouco'
      );
    });
  }, [getQuestionsByShiftId]);

  return (
    <>
      {loading ? (
        <LoadingBackdrop loading={loading} />
      ) : (
        <Container>
          {ids.length !== 0 ? (
            <>
              <HeaderContainer>
                <Typography variant="body2" align="center">
                  Plano de cuidado criado sob medida para orientar o cuidado
                  diário, porque entendemos que cada pessoa é única.
                </Typography>
              </HeaderContainer>
              {ids.map((id, index) => {
                const item = byId[id];
                return (
                  <CareCategoryAccordion
                    key={item.id}
                    {...item}
                    careCategoryIndex={index}
                  />
                );
              })}
            </>
          ) : (
            <>
              <Typography variant="subtitle1" align="center">
                Não há perguntas de cuidado a serem respondidas.
              </Typography>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default CareCategoryList;
