import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';

const Home: React.FC = () => {
  const { user, signOut } = useContext(AuthContext);
  const { pathname } = useLocation();
  return (
    <div>
      <h1>{pathname}</h1>
      <h2>{`Olá ${user?.displayName}`}</h2>
      <button onClick={signOut}>Sair</button>
    </div>
  );
};

export default Home;
