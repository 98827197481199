import { EventModel, EventSubCategoryModel } from '@cuidador/database';

export const categoryIdMapper = {
  GENERAL: 1,
  MEDICATION: 2,
  COMPLICATION: 3,
  MEASUREMENT: 4,
};

export const separateComplicationsFromActivities = (
  shiftEvents: EventModel[] | undefined
) => {
  if (!shiftEvents || shiftEvents.length === 0)
    return {
      complicationEvents: [],
      eventsWithoutComplications: [],
    };

  const complicationEvents = shiftEvents.filter((event) => {
    const subCategory = event.subCategory as EventSubCategoryModel;
    return subCategory.categoryId === categoryIdMapper['COMPLICATION'];
  });
  const eventsWithoutComplications = shiftEvents.filter((event) => {
    const subCategory = event.subCategory as EventSubCategoryModel;
    return subCategory.categoryId !== categoryIdMapper['COMPLICATION'];
  });

  return { complicationEvents, eventsWithoutComplications };
};
