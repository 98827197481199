import { EventSubCategoryModel, MeasurementModel } from '@cuidador/database';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import React from 'react';
import MesurementIcon from '../../../../assets/Icon-measurement.svg';
import { getMeasurementUnit } from '../../../CurrentShiftEventList/CurrentShiftCards/MeasurementCard/utils';
import { CardFooter, CaregiverTypography, RightContainer } from '../styles';
import {
  CardBox,
  DescriptionContainer,
  LeftContainer,
  TimerContainer,
  TypographyContainer,
} from './styles';

interface CardProps {
  id?: number;
  subCategory?: EventSubCategoryModel;
  measurement?: MeasurementModel;
  eventHappensAt?: string;
  eventNotifiedAt?: string;
  description?: string;
  status?: string;
  comment?: string;
  caregiverName?: string;
}

const MesurementCard: React.FC<CardProps> = ({
  id,
  subCategory,
  measurement,
  description,
  eventHappensAt,
  eventNotifiedAt,
  comment,
  caregiverName,
}) => {
  const formattedDate = eventHappensAt
    ? format(new Date(`${eventHappensAt}`), 'HH:mm')
    : format(new Date(`${eventNotifiedAt}`), 'HH:mm');
  return (
    <CardBox data-testid={`measurementCard-${id}`}>
      <LeftContainer>
        <TimerContainer>
          <Typography variant="h6">{formattedDate}</Typography>
        </TimerContainer>
        <img src={MesurementIcon} title={`measurement-image-${id}`} />
      </LeftContainer>
      <RightContainer>
        <TypographyContainer>
          <Typography variant="h6">{subCategory?.name}</Typography>
          <DescriptionContainer>
            <Typography variant="subtitle2">
              Valores medidos: {measurement?.measurementValue}{' '}
              {getMeasurementUnit(subCategory?.id)}
            </Typography>
          </DescriptionContainer>
          <DescriptionContainer>
            <Typography variant="subtitle2">{description}</Typography>
            {comment && (
              <Typography variant="subtitle2">Obs: {comment}</Typography>
            )}
          </DescriptionContainer>
        </TypographyContainer>
        <CardFooter>
          {caregiverName ? (
            <CaregiverTypography>{caregiverName}</CaregiverTypography>
          ) : (
            <div></div>
          )}
        </CardFooter>
      </RightContainer>
    </CardBox>
  );
};

export default MesurementCard;
