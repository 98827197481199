import { MedicationModel } from '@cuidador/database';
import React from 'react';
import {
  handleFormatDose,
  handleFormatAdministeredBy,
} from '../../../../utils/medication';
import { Container, ContainerText, Label, Text } from './styles';

interface MedicationInformationCardProps {
  medication: MedicationModel;
}

const MedicationInformationCard: React.FC<MedicationInformationCardProps> = ({
  medication,
}) => {
  const {
    dosageQuantity,
    dosageFormat,
    administeredBy,
    medicalNotes,
    subCategory,
  } = medication;

  const stringFirstNumberIndex = Number(subCategory?.name?.search(/\d+/)) - 1;
  const medicationDosage = subCategory?.name?.slice(stringFirstNumberIndex);

  return (
    <Container>
      <ContainerText>
        <Text>{medicationDosage}</Text>
      </ContainerText>
      <ContainerText>
        <Label>Administração: </Label>
        <Text>
          {handleFormatAdministeredBy(administeredBy)}, via {dosageQuantity}{' '}
          {handleFormatDose(dosageFormat, dosageQuantity)}
        </Text>
      </ContainerText>
      {!!medicalNotes && (
        <ContainerText>
          <Label>Recomendações: </Label>
          <Text>{medicalNotes}</Text>
        </ContainerText>
      )}
    </Container>
  );
};

export default MedicationInformationCard;
