import React from 'react';
import { oximetryRateMask } from '../../../../../utils/inputs';
import FormikTextField from '../../../../Forms/FormikTextField';
import { InputContainer } from '../styles';

interface OximetryRateInputProps {
  value?: string;
  onChange: (value: string) => void;
}

const OximetryRateInput: React.FC<
  OximetryRateInputProps & Partial<HTMLInputElement>
> = ({ value, onChange, ...props }) => {
  return (
    <InputContainer>
      <FormikTextField
        color="primary"
        inputProps={{
          'data-testid': 'measurementInput',
          maxlength: 255,
        }}
        label="Valor"
        margin="normal"
        autoComplete="off"
        placeholder="96"
        value={value}
        onChange={(e) => {
          const formattedValue = oximetryRateMask(e.target.value);
          onChange(formattedValue);
        }}
        {...props}
      />
      {'%SaO2'}
    </InputContainer>
  );
};

export default OximetryRateInput;
