import { EventModel, EventSubCategoryModel } from '@cuidador/database'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ptBr from 'date-fns/locale/pt-BR'
import { Formik, FormikHelpers } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { ShiftInProgressContext } from '../../../contexts/ShiftInProgress'
import StyledSelect from '../../Forms/FormikSelect'
import FormikTextField from '../../Forms/FormikTextField'
import StyledMenuItem from '../../StyledMenuItem'
import {
  Centralizer,
  Form,
  FormContainer,
  OptionsButton,
  StyledDateTimePicker,
} from '../styles'
import MeasurementInput from './MeasurementInput'
import { validateSubCategory, validationSchema } from './utils'
import { formatSymptomsOptions } from '../../VitalSignsMeasurementInput/BloodPressureMeasurementInput/utils'
import axiosInstance from '../../../config/axios'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Typography } from '@material-ui/core'
import { subCategoryIdMapper } from '../../VitalSignsMeasurementInput/utils'

export interface Props {
  submit: (eventData: EventModel, continuousAdd?: boolean) => Promise<void>
  cancel: () => void
  subCategories: EventSubCategoryModel[]
  maxDateTime?: Date | string
  minDateTime?: Date | string
}

export interface FormValues {
  comment: string
  measurementValue: string
  subCategoryId: number | string
  additionalDetails: string
  symptomList?: SymptomList[]
}

type SymptomList = {
  id: number
  label: string
  checked: boolean
}

const CreateMeasurementEventForm: React.FC<Props> = ({
  submit,
  cancel,
  subCategories,
  maxDateTime,
  minDateTime,
}) => {
  const [continuousAdd, setContinuousAdd] = useState(false)
  const [showSymptoms, setShowSymptoms] = useState(false)
  const [symptoms, setSymptoms] = useState<SymptomList[]>([])
  const [dateTimeEvent, setDateTimeEvent] = useState<Date>(new Date())
  const { shiftInProgress } = useContext(ShiftInProgressContext)

  const initialValues: FormValues = {
    subCategoryId: '',
    comment: '',
    measurementValue: '',
    additionalDetails: 'fasting',
  }

  const formatAndSubmit = async (
    formValues: FormValues,
    { resetForm }: FormikHelpers<FormValues>,
  ) => {
    const eventData: EventModel = {
      comment: formValues.comment,
      name: formValues.measurementValue,
      subCategoryId: Number(formValues.subCategoryId),
      eventHappensAt: dateTimeEvent.toISOString(),
      measurement: {
        measuredAt: new Date().toISOString(),
        measurementValue: formValues.measurementValue,
        measurementDescription: formValues.comment,
      },
    }

    formValues.symptomList = symptoms.filter(symptom => symptom.checked)
    if (formValues.symptomList) {
      eventData.measurement.symptoms = formValues.symptomList.map(symptom => {return ({ id: symptom.id })})
    }
    let vitalSignValidation

    if (formValues.subCategoryId === subCategoryIdMapper.BLOOD_PRESSURE) {
      const extraFormValues = formValues
      extraFormValues.measurementValue = formValues.measurementValue.split('x')[0]
      vitalSignValidation = validateSubCategory(extraFormValues, shiftInProgress?.patient)
    } else {
      vitalSignValidation = validateSubCategory(formValues, shiftInProgress?.patient)
    }

    if (!!vitalSignValidation)
      eventData.measurement = {
        ...eventData.measurement,
        ...vitalSignValidation,
      }

    await submit(eventData, continuousAdd)
    setSymptoms([])
    resetForm()
  }

  useEffect(() => {
    getSymptoms()
  }, [])

  async function getSymptoms () {
    const symptomList = (await axiosInstance.get('/care/symptom')).data
    const formatedSymptoms: SymptomList[] = formatSymptomsOptions(symptomList)
    setSymptoms(formatedSymptoms)
  }

  function flipSymptom (symptomId: number) {
    const newSymptoms = symptoms
    const index = newSymptoms.findIndex(symptom => symptom.id === symptomId)
    newSymptoms[index].checked = !newSymptoms[index].checked
    setSymptoms(newSymptoms.map(item => item))
  }

  return (
    <FormContainer>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={formatAndSubmit}
        validateOnChange={false}
      >
        {({ isSubmitting, values, setFieldValue }) => {
          return (
            <Form>
              <StyledSelect
                name='subCategoryId'
                label='Controle'
                SelectDisplayProps={{
                  'data-testid': 'subCategoryId',
                }}
                color='primary'
              >
                {subCategories?.map((item) => (
                  <StyledMenuItem key={item.id} value={item.id} color='primary'>
                    {item.name}
                  </StyledMenuItem>
                ))}
              </StyledSelect>
              <MeasurementInput
                subCategoryId={values.subCategoryId}
                onChange={(value: string) =>
                  setFieldValue('measurementValue', value)
                }
                value={values.measurementValue}
                name='measurementValue'
                id='measurementValue'
              />
              <Centralizer>
                {values.subCategoryId === subCategoryIdMapper.BLOOD_PRESSURE && <OptionsButton
                  size='large'
                  color='primary'
                  disabled={isSubmitting}
                  onClick={() => {
                    setShowSymptoms(true)
                  }}
                >
                  Adicionar sintomas
                </OptionsButton>}
              </Centralizer>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBr}>
                <StyledDateTimePicker
                  inputVariant='outlined'
                  format='dd/MM/yyyy HH:mm'
                  name='dateTimeEvent'
                  value={dateTimeEvent}
                  cancelLabel='Cancelar'
                  ampm={false}
                  maxDate={maxDateTime}
                  minDate={minDateTime}
                  onChange={(date: Date | null) => {
                    date && setDateTimeEvent(date)
                  }}
                />
              </MuiPickersUtilsProvider>
              <FormikTextField
                color='primary'
                id='comment'
                multiline
                rows={4}
                inputProps={{ 'data-testid': 'comment', maxlength: 2000 }}
                label='Observações'
                name='comment'
                margin='normal'
                autoComplete='off'
              />

              {symptoms.map((symptom, index) => (
                <>
                  {symptom.checked && <Typography key={index}>{symptom.label}</Typography>}
                </>
              ))}

              <Dialog
                open={showSymptoms}
                onClose={() => setShowSymptoms(false)}
                style={{
                  'padding': '8px',
                }}
              >
                <DialogTitle>Selecione os sintomas</DialogTitle>
                <DialogContent>
                  {symptoms.map((symptom, index) => (
                    <FormControlLabel
                      key={index}
                      style={{ display: 'block' }}
                      control={
                        <Checkbox
                          checked={symptom.checked}
                          onChange={() => flipSymptom(symptom.id)}
                        />
                      }
                      label={symptom.label}
                    />
                  ))}
                </DialogContent>
                <DialogActions>
                  <Centralizer>
                    <OptionsButton
                      color='primary'
                      onClick={() => setShowSymptoms(false)}
                    >
                      Concluir
                    </OptionsButton>
                  </Centralizer>
                </DialogActions>
              </Dialog>

              <Centralizer>
                <OptionsButton
                  data-testid='submit-measurement'
                  type='submit'
                  size='large'
                  color='primary'
                  disabled={isSubmitting}
                  onClick={() => setContinuousAdd(true)}
                >
                  Salvar e adicionar novo
                </OptionsButton>
                <OptionsButton
                  data-testid='submit-measurement'
                  type='submit'
                  size='large'
                  color='primary'
                  disabled={isSubmitting}
                  onClick={() => setContinuousAdd(false)}
                >
                  Salvar e finalizar
                </OptionsButton>
                <OptionsButton
                  data-testid='submit-measurement'
                  onClick={cancel}
                  size='large'
                  color='primary'
                >
                  Cancelar
                </OptionsButton>
              </Centralizer>
            </Form>
          )
        }}
      </Formik>
    </FormContainer>
  )
}

export default CreateMeasurementEventForm
