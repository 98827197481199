import { Form as FormikForm } from 'formik';
import styled from 'styled-components';
import StyledButton from '../StyledButton';
import StyledKeyboardDatePicker from '../StyledKeyboardDatePicker';
import FormikTextField from '../Forms/FormikTextField';

export const Form = styled(FormikForm)`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #000000;
`;

export const FormContainer = styled.div`
  margin: 0 ${({ theme }) => theme.spacing(3)}px; ;
`;

export const Centralizer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
`;

export const OptionsButton = styled(StyledButton)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  width: 100%;
  max-width: 220px;
`;

export const DatePicker = styled(StyledKeyboardDatePicker)`
  input[type='text'][disabled] {
    color: #000000;
    opacity: 1;
  }
`;

export const TextField = styled(FormikTextField)`
  input[type='text'][disabled] {
    color: #000000;
    opacity: 1;
  }
  textarea[disabled] {
    color: #000000;
    opacity: 1;
  }
`;
