import { css } from 'styled-components';

export const ButtonContainerBaseStyles = css`
  padding: ${({ theme }) => theme.spacing(0.5, 0.1)};
  min-width: 35px;
  min-height: 35px;
  margin-inline: ${({ theme }) => theme.spacing(1)}px;

  svg path {
    stroke: ${({ theme }) => theme.palette.primary.dark};
  }
`;
