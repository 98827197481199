import { IconButton, useTheme } from '@material-ui/core'
import React from 'react'

export type IconButtonStyledProps = {
  icon: React.ReactNode
  onClick?: () => void
  backgroundColor?: string
}

export function IconButtonStyled (props: IconButtonStyledProps) {
  const { icon, onClick, backgroundColor } = props
  const theme = useTheme()

  return (
    <IconButton
      style={{
        width: '40px',
        height: '40px',
        fontSize: '40px',
        color: theme.palette.primary.light,
        backgroundColor: backgroundColor,
        borderRadius: '16px',
        padding: '8px',
      }}
      onClick={onClick}
    >
      {icon}
    </IconButton>
  )
}
