import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  MultiselectContainer,
  OptionContainer,
  StyledModal,
  StyledCheckbox,
  StyledTypography,
  OptionsSection,
  HeaderTitle,
  Header,
  HeaderLeftIcon,
  FooterButton,
  StyledInput,
} from './styles';
import LoadingBackdrop from '../../../LoadingBackdrop';

export type MultiselectModalOptionsType = {
  id: number | string;
  label: string;
  checked: boolean;
  customLabel?: string;
  check?: (id: number | string) => void;
  isCustomInput?: boolean;
};

export interface MultiselectModalProps {
  isOpen: boolean;
  close: () => void;
  onClose: () => void;
  options: MultiselectModalOptionsType[];
  title: string;
  onFinishOptionsSelection: (options: MultiselectModalOptionsType[]) => void;
  loading: boolean;
  hasCustomUserInput?: boolean;
  customOptionInitialValue?: Partial<
    Pick<MultiselectModalOptionsType, 'id' | 'checked' | 'customLabel'>
  >;
}

const Option: React.FC<MultiselectModalOptionsType & { testid: string }> = ({
  id,
  label,
  checked,
  check,
  testid,
}) => {
  return (
    <OptionContainer onClick={() => !!check && check(id)} data-testid={testid}>
      <StyledCheckbox checked={checked} />
      <StyledTypography variant="body2">{label}</StyledTypography>
    </OptionContainer>
  );
};

export const MultiselectModal: React.FC<MultiselectModalProps> = ({
  isOpen,
  close,
  options,
  title,
  onFinishOptionsSelection,
  loading,
  hasCustomUserInput,
  customOptionInitialValue,
  onClose,
}: MultiselectModalProps) => {
  const [localOptions, setLocalOptions] = useState<
    MultiselectModalOptionsType[]
  >([]);

  const [customOption, setCustomOption] = useState<MultiselectModalOptionsType>(
    {
      id: 'custom',
      checked: false,
      label: 'Outro',
      isCustomInput: true,
      check: () =>
        setCustomOption(({ checked, ...option }) => ({
          ...option,
          checked: !checked,
        })),
    }
  );

  const [customOptionName, setCustomOptionName] = useState('');

  useEffect(() => {
    if (!customOptionInitialValue) return;

    const { id, customLabel, checked } = customOptionInitialValue;

    setCustomOption({
      id: id ?? 'custom',
      customLabel: customLabel ?? '',
      checked: checked ?? false,
      label: 'Outro',
      isCustomInput: true,
      check: () =>
        setCustomOption(({ checked, ...option }) => ({
          ...option,
          checked: !checked,
        })),
    });

    setCustomOptionName(customLabel ?? '');
  }, [customOptionInitialValue]);

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  const closeWithOnCloseEvent = useCallback(() => {
    close();
    onClose();
  }, [close, onClose]);

  const onSelectButtonClick = useCallback(() => {
    const formattedCustomOption = {
      ...customOption,
      customLabel: customOptionName,
    };

    if (!customOption.checked)
      onFinishOptionsSelection(localOptions.filter(({ checked }) => !!checked));
    else
      onFinishOptionsSelection([
        ...localOptions.filter(({ checked }) => !!checked),
        formattedCustomOption,
      ]);

    close();
  }, [
    localOptions,
    onFinishOptionsSelection,
    close,
    customOption,
    customOptionName,
  ]);

  return (
    <>
      {loading && isOpen && <LoadingBackdrop loading={loading} />}
      <StyledModal
        open={isOpen}
        onBackdropClick={closeWithOnCloseEvent}
        data-testid="multiselect-modal-root"
      >
        <MultiselectContainer>
          <Header>
            <HeaderLeftIcon onClick={closeWithOnCloseEvent}>
              <ChevronLeft />
            </HeaderLeftIcon>
            <HeaderTitle>
              <Typography variant="h6">{title}</Typography>
            </HeaderTitle>
          </Header>
          <OptionsSection data-testid="multiselect-options-section">
            {[...localOptions, customOption].map((option, index) => (
              <>
                <Option
                  {...option}
                  check={
                    !!option.check
                      ? option.check
                      : (id: MultiselectModalOptionsType['id']) =>
                          setLocalOptions((options) =>
                            options.map(({ checked, ...option }) => ({
                              ...option,
                              checked: id === option.id ? !checked : checked,
                            }))
                          )
                  }
                  key={`multiselect-option-${index}`}
                  testid={`multiselect-option-${index}`}
                />
                {!!hasCustomUserInput &&
                  customOption.checked === true &&
                  option.id === customOption.id && (
                    <StyledInput
                      value={customOptionName}
                      onChange={(e) => setCustomOptionName(e.target.value)}
                      data-testid="multiselect-symptom-name-input"
                    />
                  )}
              </>
            ))}
          </OptionsSection>
          <br />
          <FooterButton onClick={onSelectButtonClick}>Selecionar</FooterButton>
        </MultiselectContainer>
      </StyledModal>
    </>
  );
};
